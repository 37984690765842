import * as yup from "yup";

export const addressSchema = yup.object().shape({
  id: yup.string(),
  streetNumber: yup
    .string()
    .required()
    .label("Street Number")
    .default("")
    .transform((value) => value ?? ""),
  streetName: yup
    .string()
    .required()
    .label("Street Name")
    .transform((value) => value ?? ""),
  unit: yup
    .string()
    .label("Unit")
    .transform((value) => value ?? ""),
  postalCode: yup
    .string()
    .label("Postal Code")
    .transform((value) => value ?? ""),
  city: yup
    .string()
    .required()
    .label("City")
    .transform((value) => value ?? ""),
  province: yup
    .string()
    .label("Province")
    .default("ON")
    .transform((value) => value ?? "ON"),
  country: yup
    .string()
    .label("Country")
    .default("CA")
    .transform((value) => value ?? "CA"),
  buzzCode: yup
    .string()
    .label("Buzz Code")
    .transform((value) => value ?? ""),
  note: yup
    .string()
    .label("Note")
    .transform((value) => value ?? ""),
  addressTypeId: yup
    .string()
    .required()
    .label("Address Type")
    .default("")
    .transform((value) => value ?? ""),
});
