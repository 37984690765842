import { useState } from "react";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

import { AddressType } from "../../models";
import { AddressTypeForm } from "../../components/forms";
import { GenericDataGrid } from "../../components/grids";
import { addressTypeColumns } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import {
  useAddressTypes,
  useDeleteAddressType,
} from "../../hooks/address-type";

export const AddressTypesPage = () => {
  const {
    data: addressTypes,
    isLoading,
    isIdle,
    isError,
    error,
  } = useAddressTypes();
  const { mutate: deleteAddressType } = useDeleteAddressType();
  const [addressType, setAddressType] = useState<AddressType>();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (addressType?: AddressType) => {
    addressType ? setAddressType(addressType) : setAddressType(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Address Types"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <GenericDataGrid
        rows={addressTypes}
        columns={addressTypeColumns}
        loading={isLoading}
        onDelete={(addressType) => deleteAddressType(addressType.id)}
        onEdit={onEditCreate}
        onDoubleClick={onEditCreate}
        onCreate={onEditCreate}
        createTitle="Create Address Type"
        deleteTitle="Would you like to delete this Address Type"
        ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        autoHeight
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${addressType ? "Edit" : "Create"} Address Type`}
        >
          <AddressTypeForm
            onSubmitted={() => setOpenModal(false)}
            addressType={addressType}
          />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
    </>
  );
};
