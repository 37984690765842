import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InventoryType } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchInventoryType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (inventoryType: Partial<InventoryType>) =>
      patch({
        route: "inventory-types",
        id: inventoryType.id ?? "",
        entity: inventoryType,
      }),
    {
      onSuccess: (inventoryType) => {
        enqueueSnackbar("Inventory Type Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.INVENTORY_TYPES,
          (inventoryTypes: InventoryType[] | undefined) =>
            !inventoryTypes
              ? []
              : patchEntity<InventoryType>(inventoryTypes, inventoryType, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Inventory Type Updating Error", { variant: "error" });
      },
    }
  );
};
