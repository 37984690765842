import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import { PaymentType } from "../../../models";
import { paymentTypeSchema as schema } from "../schemas";
import {
  useAddPaymentType,
  usePatchPaymentType,
} from "../../../hooks/payment-type";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";

interface Props {
  paymentType?: PaymentType;
  onSubmitted?: () => void;
}

export const PaymentTypeForm = (props: Props) => {
  const { paymentType, onSubmitted } = props;
  const { mutate: createPaymentType } = useAddPaymentType();
  const { mutate: patchPaymentType } = usePatchPaymentType();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<PaymentType>({
    resolver: yupResolver(schema),
    defaultValues: paymentType,
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((payment) => {
        payment.id ? patchPaymentType(payment) : createPaymentType(payment);
        onSubmitted && onSubmitted();
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting || isSubmitSuccessful} />
    </FormContainer>
  );
};
