import { useQuery } from "react-query";

import { CustomerPayment } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useCustomerPaymentsByWorkOrder = (workOrderId?: string) => {
  return useQuery<CustomerPayment[], Error>(
    [RQ.CUSTOMER_PAYMENTS, workOrderId],
    () => getAll(`customer-payments/work-order/${workOrderId}`),
    {
      enabled: Boolean(workOrderId),
    }
  );
};
