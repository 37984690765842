import { createBrowserRouter } from "react-router-dom";

import App from "../App";

import { AddressTypesPage } from "../pages/address-types/AddressTypes.page";
import { CustomersPage } from "../pages/customers/Customers.page";
import { CustomerPage } from "../pages/customers/Customer.page";
import { EmployeeTypesPage } from "../pages/employee-types/EmployeeTypes.page";
import { EmployeesPage } from "../pages/employees/Employees.page";
import { EmployeePage } from "../pages/employees/Employee.page";
import { DispatchPage } from "../pages/dispatch/Dispatch.page";
import { InventoryItemsPage } from "../pages/inventory/Inventory-items.page";
import { InvoicesPage } from "../pages/invoices/Invoices.page";
import { InvoicePage } from "../pages/invoices/Invoice.page";
import { InvoiceView } from "../pages/invoices/invoice.view";
import { JobTypesPage } from "../pages/job-types/JobTypes.page";
import { ScheduleTypesPage } from "../pages/schedule-types/schedule-types.page";
import { VendorsPage } from "../pages/vendors/Vendors.page";
import { WorkOrdersPage } from "../pages/work-orders/WorkOrders.page";
import { WorkOrderStatesPage } from "../pages/work-order-states/work-order-states.page";
import { ProposalsPage } from "../pages/proposals/Proposals.page";
import { ProposalView } from "../pages/proposals/Proposal.view";
import { ProposalPage } from "../pages/proposals/Proposal.page";
import { WorkOrderPage } from "../pages/work-orders/WorkOrder.page";
import { SchedulesPage } from "../pages/schedules/Schedules.page";
import { LoginPage } from "../pages/authentication/LoginPage";
import { CompletePasswordPage } from "../pages/authentication/CompletePasswordPage";
import { ProfilePage } from "../pages/profile/ProfilePage";
import { ReportsPage } from "../pages/reports/Reports.page";
import { ReportPage } from "../pages/reports/Report.page";
import { BalanceSheetReport } from "../pages/reports/reports/balance-sheet/BalanceSheet.report";
import { InventoryUsageReport } from "../pages/reports/reports/inventory-usage/InventoryUsage.report";
import { EmployeeHolidaysReport } from "../pages/reports/reports/employee-holidays/EmployeeHoliday.report";
import { OverdueInvoicesReport } from "../pages/reports/reports/overdue-invoices/OverdueInvoices.report";
import { PrivateRoute } from "../components/authentication/PrivateRoute";
import { MyCalendarPage } from "../pages/my-calendar/MyCalendar.page";
import { PaymentSettingsPage } from "../pages/payment-settings/PaymentSettings.page";
import { InvoiceCategoriesPage } from "../pages/invoice-categories/InvoiceCategories.page";
import { InventoryTypesPage } from "../pages/inventory-type/InventoryTypes.page";
import { StatementsPage } from "../pages/statements/Statements.page";
import { CustomerTiersPage } from "../pages/customer-tiers/CustomerTiers.page";
import { InvoiceDrawRequestView } from "../pages/invoice-draw-request/invoice-draw-request.view";
import { AppDefaultsPage } from "../pages/app-defaults/AppDefaults.page";
import { CompanyDocumentsPage } from "../pages/company-documents/CompanyDocuments.page";
import { PaymentReceiptView } from "../pages/invoices/payment-receipt.view";
import { SettingPage } from "../pages/settings/Settings.page";
import { DashboardPage } from "../pages/dashboard/Dashboard.page";
import { TaskDurationsPage } from "../pages/task-durations/TaskDurations.page";
import { WorkOrderFormPage } from "../pages/work-orders/WorkOrderFormPage";
import { WorkOrderRoleTitlesPage } from "../pages/work-order-role-titles/WorkOrderRoleTitles.page";
import { ForgotPasswordPage } from "../pages/authentication/ForgotPassword.page";
import { ConfirmCodePage } from "../pages/authentication/ConfirmCode.page";
import { OverTheCounterSalePage } from "../pages/over-the-counter-sale/OverTheCounterSale.page";
import { DocumentPage } from "../pages/documents/DocumentPage";
import { ReferralTypesPage } from "../pages/referral-types/ReferralTypes.page";
import { PrivacyPolicyPage } from "../pages/privacy-policy/PrivacyPolicy.page";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <App />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/customers",
        element: <CustomersPage />,
        children: [
          {
            path: ":id",
            element: <CustomerPage />,
          },
        ],
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/document",
        element: <DocumentPage />,
      },
      {
        path: "/statements",
        element: <StatementsPage />,
      },
      {
        path: "/company-documents",
        element: <CompanyDocumentsPage />,
      },
      {
        path: "/customer-tiers",
        element: <CustomerTiersPage />,
      },
      {
        path: "/dispatch",
        element: <DispatchPage />,
      },
      {
        path: "/work-order/edit",
        element: <WorkOrderFormPage />,
      },
      {
        path: "/work-order/:id",
        element: <WorkOrderPage showBackButton={true} />,
      },
      {
        path: "/work-orders",
        element: <WorkOrdersPage />,
        children: [
          {
            path: ":id",
            element: <WorkOrderPage />,
          },
        ],
      },
      {
        path: "/work-order-role-title",
        element: <WorkOrderRoleTitlesPage />,
      },
      {
        path: "/employees",
        element: <EmployeesPage />,
        children: [
          {
            path: ":id",
            element: <EmployeePage />,
          },
        ],
      },
      {
        path: "/over-the-counter-sale",
        element: <OverTheCounterSalePage />,
      },
      {
        path: "/invoices",
        element: <InvoicesPage />,
      },
      {
        path: "/invoices/view/:id",
        element: <InvoiceView />,
      },
      {
        path: "/invoices/edit/:id",
        element: <InvoicePage />,
      },
      {
        path: "/invoices/create",
        element: <InvoicePage />,
      },
      {
        path: "/invoice-draw-request/view/:id",
        element: <InvoiceDrawRequestView />,
      },
      {
        path: "/invoice/payment-receipt/view/:workOrderId",
        element: <PaymentReceiptView />,
      },
      {
        path: "/payment-settings",
        element: <PaymentSettingsPage />,
      },
      {
        path: "/proposals",
        element: <ProposalsPage />,
      },
      {
        path: "/proposals/edit/:id",
        element: <ProposalPage />,
      },
      {
        path: "/proposals/create",
        element: <ProposalPage />,
      },
      {
        path: "/proposals/view/:id",
        element: <ProposalView />,
      },
      {
        path: "/address-types",
        element: <AddressTypesPage />,
      },
      {
        path: "/referral-types",
        element: <ReferralTypesPage />,
      },
      {
        path: "/employee-types",
        element: <EmployeeTypesPage />,
      },
      {
        path: "/inventory-items",
        element: <InventoryItemsPage />,
      },
      {
        path: "/inventory-types",
        element: <InventoryTypesPage />,
      },
      {
        path: "/job-types",
        element: <JobTypesPage />,
      },
      {
        path: "/invoice-categories",
        element: <InvoiceCategoriesPage />,
      },
      {
        path: "/schedules",
        element: <SchedulesPage />,
      },
      {
        path: "/schedule-types",
        element: <ScheduleTypesPage />,
      },
      {
        path: "/app-defaults",
        element: <AppDefaultsPage />,
      },
      {
        path: "/settings",
        element: <SettingPage />,
      },
      {
        path: "/vendors",
        element: <VendorsPage />,
      },
      {
        path: "/work-order-states",
        element: <WorkOrderStatesPage />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: "/reports",
        element: <ReportsPage />,
      },
      {
        path: "/report",
        element: <ReportPage />,
        children: [
          { path: "balance-sheet", element: <BalanceSheetReport /> },
          { path: "inventory-usage", element: <InventoryUsageReport /> },
          { path: "employee-holidays", element: <EmployeeHolidaysReport /> },
          { path: "overdue-invoices", element: <OverdueInvoicesReport /> },
        ],
      },
      {
        path: "/task-durations",
        element: <TaskDurationsPage />,
      },
      {
        path: "/my-calendar",
        element: <MyCalendarPage />,
      },
    ],
  },
  {
    path: "/complete-password",
    element: <CompletePasswordPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/confirm-code",
    element: <ConfirmCodePage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "*",
    element: <>PAGE NOT FOUND</>,
  },
]);
