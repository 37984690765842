import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { JobType } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchJobType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (jobType: JobType) =>
      patch({
        route: "job-types",
        id: jobType.id ?? "",
        entity: jobType,
      }),
    {
      onSuccess: (jobType) => {
        enqueueSnackbar("Job Type Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.JOB_TYPES,
          (jobTypes: JobType[] | undefined) =>
            !jobTypes ? [] : patchEntity<JobType>(jobTypes, jobType, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Job Type Updating Error", { variant: "error" });
      },
    }
  );
};
