import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { WorkOrder } from "../../../models";
import { WarrantyDisplay } from "./WarrantyDisplay";

interface Props {
  workOrder: WorkOrder;
}

export const CustomerEquipmentSection = (props: Props) => {
  const { workOrder } = props;

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 3, border: "none" }}
      elevation={0}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <Typography variant="h6">Equipment To Be Serviced</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workOrder?.customerEquipments.map((customerEquipment) => (
            <TableRow key={customerEquipment.id}>
              <TableCell>
                <Typography variant="body1">
                  {customerEquipment.name}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{ alignItems: "flex-end", justifyContent: "space-between" }}
              >
                <WarrantyDisplay
                  warranty={customerEquipment}
                  stack={{ justifyContent: "flex-end", alignItems: "center" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
