import { Divider, Paper, Stack, Typography } from "@mui/material";
import { Invoice, WorkOrder } from "../../models";
import { DisplayWorkOrder } from "../../components/ui";
import { calculateSimpleInterest } from "../../utils/interest-calculator";
import { differenceInDays } from "date-fns";

interface Props {
  invoice: Invoice;
  workOrder: WorkOrder;
  amountPaid: number;
}

export const InvoiceDisplay = (props: Props) => {
  const { invoice, workOrder, amountPaid } = props;
  const daysSinceDue = differenceInDays(new Date(), new Date(invoice.dueDate));
  const interestTerm = workOrder.customer.interestTerm;
  const daysOverdue = daysSinceDue - interestTerm;

  const getInterest = () => {
    if (invoice.waveInterest) {
      return 0;
    }

    if (invoice.interestDollars) {
      return invoice.interestDollars;
    }

    return calculateSimpleInterest(
      invoice.grandTotal - amountPaid,
      invoice.interestRate,
      daysOverdue,
      {
        onlyInterestPortion: true,
      }
    ).toFixed(2);
  };

  const getGrandTotal = () => {
    if (invoice.waveInterest) {
      return invoice.grandTotal - amountPaid;
    }

    if (invoice.interestDollars) {
      return invoice.grandTotal - amountPaid + invoice.interestDollars;
    }

    return calculateSimpleInterest(
      invoice.grandTotal - amountPaid,
      invoice.interestRate,
      daysOverdue
    ).toFixed(2);
  };

  return (
    <Stack direction="row" spacing={2}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <DisplayWorkOrder workOrder={workOrder} />
      </Paper>
      <Paper elevation={3} sx={{ minWidth: 275, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Cost
        </Typography>
        <>
          {invoice?.invoiceCostsPrices?.map((icp, index) => (
            <div key={index}>
              {icp.invoiceCategory.name}: {Number(icp.cost).toFixed(2)}
              <Divider sx={{ mt: 1 }} />
            </div>
          ))}
        </>
        <b>Total:</b> ${invoice.totalCost.toFixed(2)}
      </Paper>
      <Paper elevation={3} sx={{ minWidth: 275, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Price
        </Typography>
        <>
          {invoice?.invoiceCostsPrices?.map((invoiceCostPrice, index) => (
            <div key={index}>
              {invoiceCostPrice.invoiceCategory.name}:{" "}
              {Number(invoiceCostPrice.price).toFixed(2)}
              <Divider sx={{ mt: 1 }} />
            </div>
          ))}
        </>
        <b>Total:</b> ${invoice.totalPrice.toFixed(2)}
        <br />
        <br />
        Total Profit: {invoice?.totalProfit?.toFixed(2)}
        <Divider sx={{ mt: 1 }} />
        Profit Margin: %{invoice?.profitMargin?.toFixed(2)}
      </Paper>
      <Paper elevation={3} sx={{ minWidth: 275, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Totals
        </Typography>
        Sub Total: {invoice.subtotal.toFixed(2)}
        <Divider sx={{ mt: 1 }} />
        Customer Savings: {invoice?.customerDiscount?.toFixed(2)}
        <Divider sx={{ mt: 1 }} />
        HST: {invoice.hstTotal?.toFixed(2)}
        <Divider sx={{ mt: 1 }} />
        Interest: {getInterest()}{" "}
        {daysOverdue > 0 && <>({daysOverdue} days overdue)</>}
        <Divider sx={{ mt: 1 }} />
        Paid: ${amountPaid.toFixed(2)}
        <Divider sx={{ mt: 1 }} />
        <b>Total:</b> ${invoice?.grandTotal?.toFixed(2)}
        <Divider sx={{ mt: 1 }} />
        <b>Grand Total:</b> ${getGrandTotal()}
      </Paper>
    </Stack>
  );
};
