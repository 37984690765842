import { DatePicker } from "@mui/x-date-pickers";
import { PageHeader } from "../../components/layout";
import { Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { sub } from "date-fns";
import {
  useCompanyDocumentsByDateRange,
  useDownloadCompanyDocument,
} from "../../hooks/company-document";
import { CompanyDocumentDataGrid } from "../../components/grids";
import { CompanyDocument, CompanyDocumentTag } from "../../models";
import { AutoSearchMultiple } from "../../components/ui";
import { useCompanyDocumentTagsSearch } from "../../hooks/company-document-tag";

export const CompanyDocumentsPage = () => {
  const [startDate, setStartDate] = useState<Date>(
    sub(new Date(), { months: 6 })
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [companyDocument, setCompanyDocument] = useState<CompanyDocument>();
  const [download, setDownload] = useState<boolean>(false);
  const [tagGuids, setTagGuids] = useState<string[]>([]);

  const {
    data: companyDocuments,
    isLoading,
    refetch: getCompanyDocuments,
  } = useCompanyDocumentsByDateRange(startDate, endDate, tagGuids);

  const { refetch: downloadCompanyDocument } = useDownloadCompanyDocument(
    companyDocument,
    download
  );

  useEffect(() => {
    startDate && endDate && getCompanyDocuments();
  }, [startDate, endDate, tagGuids, getCompanyDocuments]);

  useEffect(() => {
    download && companyDocument && downloadCompanyDocument();
    setDownload(false);
  }, [download, companyDocument, downloadCompanyDocument]);

  return (
    <>
      <PageHeader title="Company Documents" />
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <DatePicker
          label="Start Date"
          inputFormat="LLL dd, yyyy"
          disableMaskedInput
          value={startDate}
          onChange={(value) => setStartDate(value ?? new Date())}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <DatePicker
          label="End Date"
          inputFormat="LLL dd, yyyy"
          disableMaskedInput
          value={endDate}
          onChange={(value) => setEndDate(value ?? new Date())}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <AutoSearchMultiple
          label="Search Tags..."
          keys={["tag"]}
          onOptionSelected={(
            companyDocumentTags: CompanyDocumentTag[] | null
          ) =>
            companyDocumentTags &&
            setTagGuids(companyDocumentTags.map((a) => a.id))
          }
          searchFunction={useCompanyDocumentTagsSearch}
          sx={{ width: 600 }}
        />
      </Stack>
      <CompanyDocumentDataGrid
        rows={companyDocuments ?? []}
        loading={isLoading}
        onDownload={(companyDocument) => {
          setCompanyDocument(companyDocument);
          setDownload(true);
        }}
        autoHeight
      />
    </>
  );
};
