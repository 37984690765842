import ReactDOM from "react-dom/client";

import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ThemeMode } from "./contexts/ThemeContext";
import { routes } from "./globals/routes";
import { CssBaseline } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AuthProvider } from "./contexts/AuthContext";
import { ChangeDetectionProvider } from "./contexts/ChangeDetectionContext";
import { AppSettingProvider } from "./contexts/AppSettingContext";
import { CurrentUserProvider } from "./contexts";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <SnackbarProvider autoHideDuration={10000}>
    <QueryClientProvider client={queryClient}>
      <ThemeMode>
        <CssBaseline>
          <ChangeDetectionProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AuthProvider>
                <AppSettingProvider>
                  <CurrentUserProvider>
                    <RouterProvider router={routes} />
                  </CurrentUserProvider>
                </AppSettingProvider>
              </AuthProvider>
            </LocalizationProvider>
          </ChangeDetectionProvider>
        </CssBaseline>
      </ThemeMode>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </SnackbarProvider>
);
