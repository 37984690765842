import { useQuery } from "react-query";

import { Employee } from "../../models";
import { getSingle } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useCurrentEmployee = () => {
  return useQuery<Employee, Error>([RQ.CURRENT_USER], () =>
    getSingle<Employee>("employees/current")
  );
};
