import { useState } from "react";
import { SxProps, Theme } from "@mui/material";

import { CustomerPayment, Invoice } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { invoiceColumns } from "./invoice.columns";
import { InvoiceForm } from "../../forms";
import { useDeleteInvoice } from "../../../hooks/invoice";
import { useCustomer } from "../../../hooks/customer";
import { useWorkOrder } from "../../../hooks/work-order";
import { ModalContainerChangeDetection, PaymentModal } from "../../ui";

interface Props {
  rows: Invoice[];
  loading: boolean;
  onClick?: (entity: Invoice) => void;
  onLink?: (entity: Invoice) => void;
  onView?: (entity: Invoice) => void;
  onEdit?: (entity: Invoice) => void;
  onChecked?: (entity: Invoice, checked: boolean) => void;
  onPageChange?: (page: number) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  sx?: SxProps<Theme>;
}

export const InvoiceDataGrid = (props: Props) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onView,
    onEdit,
    onPageChange,
    onModalClosed,
    onChecked,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
    sx,
  } = props;
  const [invoice, setInvoice] = useState<Invoice>();
  const [openModal, setOpenModal] = useState(false);
  const [openCustomerPaymentModal, setOpenCustomerPaymentModal] =
    useState(false);
  const { mutate: deleteInvoice } = useDeleteInvoice();
  const { data: customer } = useCustomer(invoice?.customerId ?? "");
  const { data: workOrder } = useWorkOrder(invoice?.workOrderId);

  const onEditCreate = (invoice?: Invoice) => {
    if (onEdit && invoice) {
      return onEdit(invoice);
    }
    invoice ? setInvoice({ ...invoice }) : setInvoice(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      sx={sx}
      rows={rows}
      columns={invoiceColumns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onLink={onLink}
      onDelete={(invoice) => deleteInvoice(invoice.id)}
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      onView={onView}
      onPay={(invoice) => {
        setInvoice(invoice);
        setOpenCustomerPaymentModal(true);
      }}
      onChecked={onChecked}
      onPageChange={onPageChange}
      title={title}
      createTitle="Create Invoice"
      deleteTitle="Do you want to delete Invoice?"
      ignoreFields={[
        "id",
        "workOrderId",
        "createdTimestamp",
        "modifiedTimestamp",
        "invoiceLineItems",
        "invoiceCostsPrices",
        "customerId",
        "writeOff",
        "workOrderState",
        "dueDate",
        "subtotal",
        "totalCost",
        "workOrderId",
        "workOrderStateId",
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "modifiedTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <>
        {customer && (
          <ModalContainerChangeDetection
            open={openModal}
            handleClose={() => {
              setOpenModal(false);
              onModalClosed && onModalClosed();
            }}
            title={`${invoice?.id ? "Edit" : "Create"} Invoice`}
          >
            <InvoiceForm
              onSubmitted={() => setOpenModal(false)}
              invoice={invoice}
              customer={customer}
            />
          </ModalContainerChangeDetection>
        )}
        {workOrder && invoice && customer && (
          <PaymentModal
            open={openCustomerPaymentModal}
            onSubmitted={() => setOpenCustomerPaymentModal(false)}
            onClose={() => {
              setOpenCustomerPaymentModal(false);
              onModalClosed && onModalClosed();
            }}
            customerPayment={
              { workOrder, customerId: customer.id } as CustomerPayment
            }
            invoice={invoice}
          />
        )}
      </>
    </GenericDataGrid>
  );
};
