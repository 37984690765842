export const getAttributeFromObject = <T>(entity: T, originalPath: string) => {
  let path: string[] = originalPath.split(".");
  let current = entity;
  while (path.length) {
    if (typeof current !== "object") return undefined;
    if (current && path) {
      const element = path.shift();
      if (element) {
        current = current[element as keyof object];
      }
    }
  }
  return current;
};
