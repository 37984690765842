import { Alert, Box, Stack } from "@mui/material";
import { OAuthPopup } from "../../../ui";
import config from "../../../../config/app-config";

interface Props {
  onClose: () => void;
}

export const QuickBooksAlert = (props: Props) => {
  const { onClose } = props;
  return (
    <Alert severity="warning" sx={{ mb: 1 }} onClose={onClose}>
      <Stack direction="row">
        <Box sx={{ mr: 1 }}>
          You are not currently connected to QuickBooks. Consider Signing In.
        </Box>
        <OAuthPopup
          authorizationUrl={`${config.QUICK_BOOKS.AUTHORIZATION_URL}`}
          clientId={`${config.QUICK_BOOKS.CLIENT_ID}`}
          redirectUri={`${config.QUICK_BOOKS.REDIRECT_URL}`}
          scope={`${config.QUICK_BOOKS.SCOPE}`}
        />
      </Stack>
    </Alert>
  );
};
