import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Employee } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddEmployee = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (employee: Employee) => add<Employee>("employees", employee),
    {
      onSuccess: (employee) => {
        enqueueSnackbar("Employee Added", { variant: "success" });
        queryClient.setQueryData<Employee[]>(
          RQ.EMPLOYEES,
          (employees: Employee[] | undefined) => {
            return [...(employees ?? []), employee];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Employee Adding Error", { variant: "error" });
      },
    }
  );
};
