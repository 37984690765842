import * as yup from "yup";

export const employeeTimesheetSchema = yup.object().shape({
  clockIn: yup.date().required().label("Clock In").default(new Date()),
  clockOut: yup.date().required().label("Clock Out").default(new Date()),
  workOrderId: yup
    .string()
    .label("Work Order")
    .transform((value) => value ?? ""),
  employeeId: yup
    .string()
    .label("Employee")
    .transform((value) => value ?? ""),
});
