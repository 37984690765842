import { Button } from "@mui/material";

interface Props {
  text?: string;
  disabled?: boolean;
}

export const FormSubmitButton = (props: Props) => {
  const { text, disabled } = props;
  return (
    <Button
      type="submit"
      variant="contained"
      sx={{ mt: 1 }}
      disabled={disabled}
      color="success"
    >
      {text ?? "Save"}
    </Button>
  );
};
