import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
} from "@mui/material";

interface Props<T> {
  formLabel?: string;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  labelKey: keyof T;
  options: T[];
  onChecked?: (option: string, checked: boolean) => void;
  valueKey: keyof T;
  defaultValues?: string[];
}

export const CheckBoxGroup = <T,>(props: Props<T>) => {
  const {
    formLabel,
    direction,
    labelKey,
    options,
    onChecked,
    valueKey,
    defaultValues,
  } = props;
  return (
    <FormControl>
      {formLabel && <FormLabel component="legend">{formLabel}</FormLabel>}
      <FormGroup>
        <Stack direction={direction}>
          {options.map((option: T, index: number) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    defaultValues &&
                    defaultValues.includes(option[valueKey] as string)
                      ? true
                      : false
                  }
                  onChange={(event, checked) =>
                    onChecked && onChecked(event.target.value, checked)
                  }
                  name={option[labelKey] as string}
                  value={option[valueKey]}
                />
              }
              label={option[labelKey] as string}
              key={index}
            />
          ))}
        </Stack>
      </FormGroup>
    </FormControl>
  );
};
