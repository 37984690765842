import { useState } from "react";
import { useDeleteProposal } from "../../../hooks/proposal";
import { CustomerPayment, Proposal } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { proposalColumns } from "./proposal.columns";
import { ProposalForm } from "../../forms";
import { SxProps, Theme } from "@mui/material";
import { ModalContainerChangeDetection, PaymentModal } from "../../ui";
import { useWorkOrder } from "../../../hooks/work-order";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onView?: (entity: T) => void;
  onEdit?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  sx?: SxProps<Theme>;
}

export const ProposalDataGrid = (props: Props<Proposal>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onView,
    onEdit,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
    sx,
  } = props;
  const [proposal, setProposal] = useState<Proposal | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [openCustomerPaymentModal, setOpenCustomerPaymentModal] =
    useState(false);
  const { mutate: deleteProposal } = useDeleteProposal();
  const { data: workOrder } = useWorkOrder(proposal?.workOrderId);

  const onEditCreate = (proposal?: Proposal) => {
    if (onEdit && proposal) {
      return onEdit(proposal);
    }
    proposal ? setProposal(proposal) : setProposal(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      sx={sx}
      rows={rows}
      columns={proposalColumns}
      loading={loading}
      onClick={onClick}
      onDelete={(proposal) => deleteProposal(proposal.id)}
      onEdit={onEditCreate}
      onDoubleClick={onEditCreate}
      onLink={onLink}
      onView={onView}
      onPay={(proposal) => {
        setProposal(proposal);
        setOpenCustomerPaymentModal(true);
      }}
      createTitle="Create Proposal"
      deleteTitle="Are you sure you want to delete this Proposal?"
      title={title}
      ignoreFields={[
        "id",
        "createdTimestamp",
        "modifiedTimestamp",
        "proposalLineItems",
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <>
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            onModalClosed && onModalClosed();
          }}
          title={`${proposal ? "Edit" : "Create"} Proposal`}
        >
          <ProposalForm
            onSubmitted={() => setOpenModal(false)}
            proposal={proposal}
          />
        </ModalContainerChangeDetection>
        {workOrder && (
          <PaymentModal
            open={openCustomerPaymentModal}
            onSubmitted={() => setOpenCustomerPaymentModal(false)}
            onClose={() => {
              setOpenCustomerPaymentModal(false);
              onModalClosed && onModalClosed();
            }}
            customerPayment={
              {
                workOrder,
                customerId: workOrder?.customer?.id,
              } as CustomerPayment
            }
          />
        )}
      </>
    </GenericDataGrid>
  );
};
