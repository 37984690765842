import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InvoiceCategory } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchInvoiceCategory = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (invoiceCategory: InvoiceCategory) =>
      patch({
        route: "invoice-categories",
        id: invoiceCategory.id ?? "",
        entity: invoiceCategory,
      }),
    {
      onSuccess: (invoiceCategory) => {
        enqueueSnackbar("Invoice Category Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.INVOICE_CATEGORIES,
          (invoiceCategories: InvoiceCategory[] | undefined) =>
            !invoiceCategories
              ? []
              : patchEntity<InvoiceCategory>(
                  invoiceCategories,
                  invoiceCategory,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Invoice Category Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
