import { useQuery } from "react-query";

import { Employee } from "../../models";
import { getSingle } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useEmployee = (id?: string) => {
  return useQuery<Employee, Error>(
    [RQ.EMPLOYEES, id],
    () => getSingle<Employee>(`employees/${id}`),
    {
      enabled: Boolean(id),
    }
  );
};
