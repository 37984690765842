import { Box, Stack, Typography } from "@mui/material";
import { Proposal } from "../../../../models";

interface Props {
  proposal: Proposal;
}

export const ProposalFooter = (props: Props) => {
  const { proposal } = props;
  return (
    <Box
      style={{
        borderStyle: "double",
        borderRight: "none",
        borderLeft: "none",
        borderColor: "#A9A9A9",
      }}
      sx={{ p: 1, mt: 1 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={5}
      >
        <Stack direction="row" spacing={5}>
          <Typography variant="h6">
            Subtotal: ${Number(proposal?.subtotal).toFixed(2)}
          </Typography>
          <Typography variant="h6">
            HST: ${Number(proposal.grandTotal - proposal.subtotal).toFixed(2)}
          </Typography>
        </Stack>
        <Typography variant="h6">
          Total: ${Number(proposal?.grandTotal).toFixed(2)}
        </Typography>
      </Stack>
    </Box>
  );
};
