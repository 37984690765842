import { useQuery } from "react-query";

import { Proposal } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useProposalByWorkOrder = (workOrderId?: string | null) => {
  return useQuery<Proposal, Error>(
    [RQ.PROPOSALS, workOrderId],
    async () => await getSingle(`proposals/work-order/${workOrderId}`),
    {
      enabled: Boolean(workOrderId),
    }
  );
};
