import { useQuery } from "react-query";

import { getSingle } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useRenderDocument = (
  documentId?: string | null,
  documentType?: string | null
) => {
  return useQuery<string, Error>(
    RQ.DOCUMENT,
    async () =>
      getSingle<string>(
        `documents/presigned/${documentId}?documentType=${documentType}`
      ),
    {
      enabled: Boolean(documentId && documentType),
    }
  );
};
