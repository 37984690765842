import { IconButton, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { ThemeToggle } from "../ThemeToggle/ThemeToggle";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useThemeMode } from "../../../contexts/ThemeContext";
import MenuIcon from "@mui/icons-material/Menu";

export const SettingsDropDown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { toggleColorMode } = useThemeMode();

  const logout = () => {
    signOut();
    navigate("/login");
  };

  return (
    <div>
      <IconButton id="basic-button" onClick={handleClick} color="inherit">
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate("/profile")}>
          <IconButton id="basic-button" color="inherit">
            <PersonIcon />
          </IconButton>{" "}
          Profile
        </MenuItem>
        <MenuItem onClick={toggleColorMode}>
          <ThemeToggle /> Theme
        </MenuItem>
        <MenuItem onClick={logout}>
          <IconButton id="basic-button" color="inherit">
            <LogoutIcon />
          </IconButton>{" "}
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};
