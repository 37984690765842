import { getAttributeFromObject } from "./get-attribute-from-object";
import { trimByChar } from "./trim-by-char";

/**
 * This creates a string of attributes from the entity with a separator
 * @param entity The object you would like to parse on
 * @param labelKeys The set of attributes from the object you would like to display
 * @param separator a string to use as the separator
 * @returns {string}
 */
export const entityStringLabel = <T>(
  entity: T,
  labelKeys: (keyof T)[] | string[],
  separator?: string
): string => {
  let label: string = "";
  labelKeys.forEach((key: keyof T | string) => {
    if (getAttributeFromObject(entity, key as string)) {
      label +=
        getAttributeFromObject(entity, key as string) + ` ${separator ?? ""} `;
    }
  });
  label = trimByChar(label, " ");
  label = separator ? trimByChar(label, `${separator}`) : label;
  return label;
};
