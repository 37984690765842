import * as yup from "yup";

export const scheduleTypeSchema = yup.object().shape({
  name: yup.string().required().label("Name"),
  color: yup.string().required().label("Color"),
  allDay: yup.boolean().required().label("All Day Event").default(false),
  displayInBackground: yup
    .boolean()
    .required()
    .label("Display In Background")
    .default(false),
  requiresWorkOrder: yup
    .boolean()
    .required()
    .label("Requires Work Order")
    .default(false),
  showInDropdown: yup
    .boolean()
    .required()
    .label("Show In Dropdown")
    .default(false),
});
