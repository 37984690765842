import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { uploadFile } from "../../api/generic-api";

export const useAddCustomerDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerDocument: CustomerDocument) =>
      uploadFile<CustomerDocument>(
        `/customers/${customerDocument.customerId}/documents`,
        customerDocument
      ),
    {
      onSuccess: (customerDocument, variables) => {
        enqueueSnackbar("Customer Document Added", { variant: "success" });
        queryClient.setQueryData<CustomerDocument[]>(
          [RQ.CUSTOMER_DOCUMENTS, variables.customerId],
          (customerDocuments: CustomerDocument[] | undefined) => {
            return [...(customerDocuments ?? []), customerDocument];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Document Adding Error", { variant: "error" });
      },
    }
  );
};
