import { useState } from "react";
import { useDeleteCustomerDocument } from "../../../hooks/customer-document";
import { Customer, CustomerDocument } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { customerDocumentColumns as columns } from "./customer-document.columns";
import { CustomerDocumentForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";
import { DocumentClassification } from "../../../globals/enums";

interface Props<T> {
  rows: T[];
  customer: Customer;
  loading: boolean;
  onClick?: (entity: T) => void;
  onDownload?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const CustomerDocumentDataGrid = (props: Props<CustomerDocument>) => {
  const {
    rows,
    customer,
    loading,
    onClick,
    onDownload,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [customerDocument, setCustomerDocument] = useState<CustomerDocument>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteCustomerDocument } = useDeleteCustomerDocument();

  const onEditCreate = (customerDocument?: CustomerDocument) => {
    customerDocument
      ? setCustomerDocument({ ...customerDocument, customerId: customer.id })
      : setCustomerDocument({ customerId: customer.id } as CustomerDocument);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDelete={(customerDocument) =>
        deleteCustomerDocument({ ...customerDocument, customerId: customer.id })
      }
      onDownload={onDownload}
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={(document) => {
        const url = new URL(window.location.href);
        url.pathname = `/document`;
        url.searchParams.append("documentId", document.id);
        url.searchParams.append(
          "documentType",
          DocumentClassification.CUSTOMER
        );
        window.open(url.toString(), "_blank");
      }}
      title={title}
      createTitle="Upload Customer Document"
      deleteTitle="Do you want to delete this Customer Document"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${customerDocument ? "Edit" : "Upload"} Customer Document`}
      >
        <CustomerDocumentForm
          onSubmitted={() => setOpenModal(false)}
          customerDocument={customerDocument}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
