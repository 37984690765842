import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Employee } from "../../models";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";
import { remove } from "../../api/generic-api";

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("employees", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Employee Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.EMPLOYEES,
        (employees: Employee[] | undefined) => {
          return !employees ? [] : deleteEntity<Employee>(employees, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Employee Deleting Error", { variant: "error" });
    },
  });
};
