import { useQuery } from "react-query";

import { Address } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useAddressesByCustomer = (customerId: string) => {
  return useQuery<Address[], Error>(
    [RQ.ADDRESSES, customerId],
    () => getAll(`customers/${customerId}/addresses`),
    {
      enabled: Boolean(customerId),
      select: (address) => {
        address.forEach((address) => {
          if (address.addressType) {
            address.addressTypeId = address.addressType.id;
          }
        });
        return address;
      },
    }
  );
};
