import { useState } from "react";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useCustomerEquipmentsByCustomer } from "../../../../hooks/customer-equipment";
import { Customer, CustomerEquipment } from "../../../../models";
import { CustomerEquipmentDataGrid } from "../../../grids";
import { ModalContainer } from "../../../ui";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { arrayAddOrDelete } from "../../../../utils";

interface Props<K extends FieldValues> {
  customer: Customer;
  onChecked?: (row: CustomerEquipment, checked: boolean) => void;
  control: Control<K, any>;
  name: Path<K>;
}

export const CustomerEquipmentModal = <K extends FieldValues>(
  props: Props<K>
) => {
  const { customer, onChecked, control, name } = props;
  const {
    data: customerEquipment,
    isLoading,
    isFetching,
  } = useCustomerEquipmentsByCustomer(customer.id);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<AddCircleIcon />}
        onClick={() => setOpen(true)}
      >
        Select Equipment To Be Serviced
      </Button>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ModalContainer
            title="Select Customer Equipment"
            open={open}
            handleClose={() => setOpen(false)}
          >
            <CustomerEquipmentDataGrid
              rows={customerEquipment ?? []}
              loading={isLoading || isFetching}
              customer={customer}
              onChecked={(row, checked) => {
                field.onChange(arrayAddOrDelete(field.value, row.id, checked));
                onChecked && onChecked(row, checked);
              }}
              disableExport
              sx={{ minWidth: 800, height: 300 }}
            />
          </ModalContainer>
        )}
      />
    </>
  );
};
