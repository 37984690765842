import { Invoice } from "../../../../models";

export const needsDispatchFee = (
  invoice: Invoice,
  dispatch: string,
  dispatchLimit: number
): boolean => {
  let containsDispatchFee = false;
  containsDispatchFee = invoice?.invoiceLineItems?.some(
    (invoiceLineItem) => invoiceLineItem?.inventoryItem?.id === dispatch
  );
  return !containsDispatchFee && invoice.grandTotal < dispatchLimit;
};
