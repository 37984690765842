import { useQuery } from "react-query";

import { Contact } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useContactsByCustomer = (customerId: string) => {
  return useQuery<Contact[], Error>(
    [RQ.CONTACTS, customerId],
    () => getAll(`customers/${customerId}/contacts`),
    {
      enabled: Boolean(customerId),
    }
  );
};
