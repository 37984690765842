import { CircularProgress, Grid } from "@mui/material";

export const LoadingSpinner = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2 }}
    >
      <CircularProgress size={30} sx={{ mr: 2 }} />
      <h2>Loading...</h2>
    </Grid>
  );
};
