import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import axios from "axios";

import { add } from "../../api/generic-api";
import { useCurrentUser } from "../../contexts";

interface Payload {
  invoiceId: string;
  customerId: string;
}

export const useQuickBooksCreateInvoice = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsQBAuthenticated, setQBAlert } = useCurrentUser();
  return useMutation(
    (request: Payload) => add<Payload>("quick-books/invoices", request),
    {
      onSuccess: () => {
        enqueueSnackbar("Quick Books Invoice Added", { variant: "success" });
      },
      onError: (error) => {
        enqueueSnackbar("Quick Books Invoice Adding Error", {
          variant: "error",
        });
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            setIsQBAuthenticated(false);
            setQBAlert("SHOW");
          }
        }
      },
    }
  );
};
