import { memo, useCallback, useEffect, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  Switch,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEqual } from "lodash";

import { Customer } from "../../../models";
import { customerSchema as schema } from "../schemas";
import { useAddCustomer, usePatchCustomer } from "../../../hooks/customer";
import { FormContainer } from "../../layout";
import {
  FormArrayObjectSelect,
  FormAutocompleteFreeSolo,
  FormPhoneNumber,
  FormSubmitButton,
  FormSwitch,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { useCustomerTiers } from "../../../hooks/customer-tier";
import { useReferralTypes } from "../../../hooks/referral-type";

interface Props {
  customer?: Customer;
  onSubmitted?: (customer: Customer) => void;
}

export const CustomerForm = memo((props: Props) => {
  const { customer, onSubmitted } = props;
  console.log(customer);
  const [overrideTax, setOverrideTax] = useState(
    customer && customer.taxRate !== -1 ? true : false
  );
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createCustomer } = useAddCustomer();
  const { mutateAsync: patchCustomer } = usePatchCustomer();
  const { data: customerTiers } = useCustomerTiers();
  const { data: referralTypes } = useReferralTypes();
  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { isSubmitting, defaultValues },
  } = useForm<Omit<Customer, "customerPayments" | "customerNotes">>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(customer ?? ({} as Customer)),
  });
  const inactive = watch("inactive");

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch, setChangeDetection, defaultValues]);

  const resetForm = useCallback(
    (customer?: Customer) => {
      customer
        ? reset(customer)
        : reset({ firstName: "", lastName: "", email: "", phone: "" });
    },
    [reset]
  );

  useEffect(() => {
    customer ? resetForm(customer) : resetForm();
  }, [customer, resetForm]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (customer) => {
        customer = customer.id
          ? await patchCustomer(customer as Customer)
          : await createCustomer(customer as Customer);
        onSubmitted && onSubmitted(customer as Customer);
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="firstName"
          label="First Name / Company"
          control={control}
        />
        <FormTextField name="lastName" label="Last Name" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormPhoneNumber name="phone" label="Phone" control={control} />
        <FormTextField name="email" label="Email" control={control} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {customerTiers && (
          <FormArrayObjectSelect
            name="customerTierId"
            label="Customer Tier"
            data={customerTiers ?? []}
            control={control}
            labelKey="name"
            valueKey="id"
            defaultValue={
              (customer && customer?.customerTier?.id) ?? customerTiers[0].id
            }
            fullWidth
          />
        )}
        <FormTextField
          name="interestTerm"
          label="Interest Term"
          control={control}
          type="number"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">days</InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                onChange={(event) => setOverrideTax(event.target.checked)}
                checked={overrideTax}
              />
            }
            label={overrideTax ? "Override" : "Use System Tax Rate"}
          />
        </FormGroup>
        {overrideTax && (
          <FormTextField
            name="taxRate"
            label="Tax Rate Override"
            control={control}
            type="number"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        )}
        <FormSwitch
          name="inactive"
          label={inactive ? "Inactive" : "Active"}
          control={control}
        />
      </Stack>
      <Stack>
        <FormAutocompleteFreeSolo
          name="referralType"
          label="Referral Type"
          control={control}
          options={referralTypes?.map((x) => x.name) ?? []}
        />
      </Stack>
      <FormSubmitButton
        text={customer ? "Save Edits" : "Save"}
        disabled={isSubmitting}
      />
    </FormContainer>
  );
});
