import { GridColDef } from "@mui/x-data-grid";
import { Employee } from "../../../models";
import { format } from "date-fns";

const getAddress = (employee: Employee): string => {
  const streetNumber = employee.streetNumber ?? "";
  const streetName = employee.streetName ?? "";
  const unit = employee.unit ?? "";
  const city = employee.city ?? "";
  const province = employee.province ?? "";
  return `${unit} ${streetNumber} ${streetName} ${city} ${province}`;
};

export const employeeColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => `${params.row.firstName}  ${params.row.lastName}`,
  },
  { field: "email", headerName: "Email", flex: 1 },
  {
    field: "address",
    headerName: "Address",
    flex: 2,
    valueGetter: (params) => getAddress(params.row),
  },
  {
    field: "startDate",
    headerName: "Start Date",
    flex: 1,
    valueGetter: (params) =>
      format(new Date(params.row.startDate), "yyyy-MM-dd"),
  },
  { field: "homePhone", headerName: "Home Phone", flex: 1 },
  { field: "cellPhone", headerName: "Cell Phone", flex: 1 },
];
