import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

import { InvoiceDrawRequest } from "../../../models/invoice-draw-request";

export const invoiceDrawRequestColumns: GridColDef<InvoiceDrawRequest>[] = [
  {
    field: "invoice",
    headerName: "Invoice",
    flex: 1,
    valueGetter: (params) =>
      params?.row?.invoice?.workOrderNumber
        ? params.row.invoice.workOrderNumber
        : "",
  },
  { field: "total", headerName: "Total Requested", flex: 1 },
  {
    field: "sent",
    headerName: "Sent",
    flex: 1,
    valueGetter: (params) => (params.row.sent ? true : false),
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 1,
    valueGetter: (params) => format(new Date(params.row.dueDate), "yyyy-MM-dd"),
  },
];
