import { ForgotPasswordForm } from "../../components/forms/authentication/ForgotPassword.form";

export const ForgotPasswordPage = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "40%",
        transform: "translate(0px, -50%)",
      }}
    >
      <h2>Forgot Password</h2>
      <p>Please Provide Your Email</p>
      <ForgotPasswordForm />
    </div>
  );
};
