import { useEffect, useRef, useState } from "react";
import { Grid, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import FullCalendar from "@fullcalendar/react";
import { EventSourceInput } from "@fullcalendar/core";

import { PageHeader } from "../../components/layout";
import { useSchedulesByDateRange } from "../../hooks/schedule";
import { CalendarDateRange } from "../../models";
import { DispatchCalendar } from "../../components/ui/Calendar";
import { useEmployeeProfile } from "../../hooks/employee";
import { LoadingSpinner } from "../../components/ui";
import { formatCalendarEvents } from "../../utils";

export const MyCalendarPage = () => {
  const [events, setEvents] = useState<EventSourceInput>([]);
  const [dateRange, setDateRange] = useState<CalendarDateRange>({
    startDate: new Date(),
  });
  const {
    data: employee,
    isLoading: isLoadingEmployee,
    isIdle: isIdleEmployee,
    isError: isErrorEmployee,
  } = useEmployeeProfile();
  const { data: schedules, refetch: getSchedulesByDateRange } =
    useSchedulesByDateRange(dateRange, employee?.id);
  const calendarRef = useRef<FullCalendar>(null);

  useEffect(() => {
    schedules && setEvents(formatCalendarEvents(schedules, calendarRef));
  }, [schedules]);

  useEffect(() => {
    dateRange && getSchedulesByDateRange();
  }, [dateRange, getSchedulesByDateRange]);

  if (isLoadingEmployee || isIdleEmployee) {
    return <LoadingSpinner />;
  }

  if (isErrorEmployee) {
    return <h2>Page Error</h2>;
  }

  return (
    <Grid container>
      <PageHeader title="My Calendar" />
      <Grid item xs={12}>
        <Paper sx={{ p: 1, mb: 1 }}>
          <DatePicker
            label="Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={dateRange.startDate}
            onChange={(value) => {
              setDateRange({ startDate: value ?? new Date() });
              calendarRef.current?.getApi().gotoDate(value ?? new Date());
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <DispatchCalendar
          calendarRef={calendarRef}
          events={events}
          employees={[employee]}
          selectedEmployeeTypes={[]}
          onDateUpdated={(newDate, endDate) => {
            setDateRange({
              startDate: newDate,
              endDate: endDate ?? undefined,
            });
          }}
          onEventDeleted={() => {}}
        />
      </Grid>
    </Grid>
  );
};
