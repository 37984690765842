export const objectAddOrDelete = <T>(
  values: T[],
  value: T,
  checked: boolean,
  comparatorKey: keyof T
) => {
  let list = values ? [...values] : [];
  const index = values?.findIndex(
    (item) => item[comparatorKey] === value[comparatorKey]
  );
  // remove
  if (index >= 0 && !checked) {
    list.splice(index, 1);
    return list;
  }
  // add
  else if (index === -1 && checked) {
    list.push(value);
    return list;
  }
  return list;
};
