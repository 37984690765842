import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { TaskDuration } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchTaskDuration = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (taskDuration: TaskDuration) =>
      patch({
        route: "task-durations",
        id: taskDuration.id ?? "",
        entity: taskDuration,
      }),
    {
      onSuccess: (taskDuration) => {
        enqueueSnackbar("Task Duration Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.TASK_DURATIONS,
          (taskDurations: TaskDuration[] | undefined) =>
            !taskDurations
              ? []
              : patchEntity<TaskDuration>(taskDurations, taskDuration, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Task Duration Updating Error", { variant: "error" });
      },
    }
  );
};
