import { Box, TextField } from "@mui/material";
import { BoxProps } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isValid } from "date-fns";
import {
  Control,
  FieldValues,
  Path,
  UseFormTrigger,
  useController,
} from "react-hook-form";

interface Props<K extends FieldValues> extends BoxProps {
  name: Path<K>;
  label: string;
  control: Control<K, any>;
  dateFormat?: string;
  trigger?: UseFormTrigger<K>;
  onSelected?: (date: Date) => void;
}

export const FormDatePicker = <K extends FieldValues>(props: Props<K>) => {
  const { name, label, control, dateFormat, trigger, onSelected, ...rest } =
    props;
  const { field, fieldState } = useController({ name, control });

  return (
    <Box {...rest}>
      <DatePicker
        label={label}
        inputFormat="LLL dd, yyyy"
        disableMaskedInput
        value={field.value}
        onError={() => trigger && trigger(name)}
        onChange={(date) => {
          if (isValid(date)) {
            field.onChange(
              format(date || new Date(), dateFormat ?? "MM/dd/yyyy")
            );
          }
        }}
        onAccept={(date) => date && onSelected && onSelected(date)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            size="small"
            fullWidth
          />
        )}
      />
    </Box>
  );
};
