import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { entityStringLabel } from "../../../utils";

interface Props<T, K extends FieldValues> extends SelectProps {
  name: Path<K>;
  label: string;
  labelKeys: (keyof T)[];
  valueKey: keyof T;
  data: T[];
  control: Control<K, any>;
  required?: boolean;
  onSelected?: (entity: T) => void;
}

export const FormSelect = <T, K extends FieldValues>(props: Props<T, K>) => {
  const {
    data,
    control,
    name,
    label,
    labelKeys,
    valueKey,
    required,
    onSelected,
    ...rest
  } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <Box>
      <FormControl
        error={fieldState.invalid}
        required={required}
        fullWidth
        size="small"
      >
        <InputLabel id={`${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-label`}
          id={label}
          value={field.value ?? ""}
          label={label}
          onChange={(item) => {
            onSelected &&
              onSelected(
                data.find((x) => x[valueKey] === item.target.value) ?? ({} as T)
              );
            field.onChange(item.target.value);
          }}
          size="small"
          {...rest}
        >
          {data.map((item: T, index: number) => (
            <MenuItem key={index} value={item[valueKey as keyof object]}>
              {entityStringLabel(item, labelKeys)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      </FormControl>
    </Box>
  );
};
