import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { EmployeeType } from "../../models";
import { EmployeeTypeForm } from "../../components/forms";
import { employeeTypeColumns, GenericDataGrid } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import {
  useDeleteEmployeeType,
  useEmployeeTypes,
} from "../../hooks/employee-type";

export const EmployeeTypesPage = () => {
  const {
    data: employeeTypes,
    isLoading,
    isIdle,
    isError,
    error,
  } = useEmployeeTypes();
  const [employeeType, setEmployeeType] = useState<EmployeeType>();
  const { mutate: deleteEmployeeType } = useDeleteEmployeeType();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (employeeType?: EmployeeType) => {
    employeeType ? setEmployeeType(employeeType) : setEmployeeType(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Employee Types"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <GenericDataGrid
        rows={employeeTypes}
        columns={employeeTypeColumns}
        loading={isLoading}
        onDelete={(employeeType) => deleteEmployeeType(employeeType.id)}
        onEdit={onEditCreate}
        onCreate={onEditCreate}
        onDoubleClick={onEditCreate}
        createTitle="Create Employee Type"
        deleteTitle="Would you like to delete this Employee Type"
        ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        autoHeight
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${employeeType ? "Edit" : "Create"} Employee Type`}
        >
          <EmployeeTypeForm
            onSubmitted={() => setOpenModal(false)}
            employeeType={employeeType}
          />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
    </>
  );
};
