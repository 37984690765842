import { Box, BoxProps, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import {
  Control,
  FieldValues,
  Path,
  UseFormTrigger,
  useController,
} from "react-hook-form";

interface Props<K extends FieldValues> extends BoxProps {
  name: Path<K>;
  label: string;
  control: Control<K, any>;
  dateFormat?: string;
  trigger?: UseFormTrigger<K>;
  minTime?: Date;
  maxTime?: Date;
  onSelected?: (date: Date) => void;
}

export const FormDateTimePicker = <K extends FieldValues>(props: Props<K>) => {
  const {
    name,
    label,
    control,
    trigger,
    minTime,
    maxTime,
    onSelected,
    ...rest
  } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <Box {...rest}>
      <DateTimePicker
        label={label}
        disableMaskedInput
        value={field.value}
        onError={() => trigger && trigger(name)}
        onChange={(date) => {
          if (isValid(date) && date) field.onChange(date);
        }}
        onAccept={(date) => date && onSelected && onSelected(date)}
        minTime={minTime}
        maxTime={maxTime}
        minutesStep={15}
        renderInput={(params) => (
          <TextField
            {...params}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            size="small"
            fullWidth
          />
        )}
      />
    </Box>
  );
};
