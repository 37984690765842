import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { add } from "date-fns";
import { isEqual } from "lodash";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { customerEquipmentColumns } from "../../grids";
import {
  Address,
  CustomerEquipment,
  Schedule,
  ScheduleType,
  WorkOrder,
} from "../../../models";
import { scheduleSchema, workOrderSchema } from "../schemas";
import { useAddWorkOrder, usePatchWorkOrder } from "../../../hooks/work-order";
import { useJobTypes } from "../../../hooks/job-type";
import { useEmployees } from "../../../hooks/employee";
import {
  useAddSchedule,
  useDeleteSchedule,
  usePatchSchedule,
  useSchedulesByWorkOrder,
} from "../../../hooks/schedule";
import { useScheduleTypes } from "../../../hooks/schedule-type";
import { useWorkOrderStates } from "../../../hooks/work-order-state";
import { FormContainer } from "../../layout";
import {
  FormCheckBoxGroup,
  FormSelect,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useAddressesByCustomer } from "../../../hooks/address";
import { getDeleted, objectAddOrDelete } from "../../../utils";
import { useCustomer } from "../../../hooks/customer";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { FormAutocomplete } from "../../form-components";
import { APPLICATION_SETTING } from "../../../globals/enums";
import { useAppSettingsContext } from "../../../contexts";
import { useCustomerSearch } from "../../../hooks/customer-address";
import { ModalContainer, SubTitle } from "../../ui";
import { AddressForm } from "../address/Address.form";
import { CustomerEquipmentModal } from "./components/CustomerEquipment.modal";
import { GenericDataGrid } from "../../grids";
import { WorkOrderRolesModal } from "./components/WorkOrderRoles.modal";
import { TimeSchedules } from "./components/TimeSchedules";
import { BlockSchedules } from "./components/BlockSchedules";
import { WorkOrderNotes } from "./components/WorkOrderNotes";
import { useWorkOrderRoleTitles } from "../../../hooks/work-order-role-title";

const schema = yup.object().shape({
  workOrder: workOrderSchema,
  schedules: yup.array().of(scheduleSchema),
});

type FormData = {
  workOrder: Omit<WorkOrder, "schedules">;
  schedules: Partial<Omit<Schedule, "workOrder">>[];
};

interface Props {
  workOrder: Partial<WorkOrder>;
  onSubmitted?: (workOrder: WorkOrder, schedules?: Schedule[]) => void;
}

export const WorkOrderForm = (props: Props) => {
  const { workOrder, onSubmitted } = props;
  const { getSetting } = useAppSettingsContext();
  const ON_NEW_WORK_ORDER = getSetting(
    APPLICATION_SETTING.ON_NEW_WORK_ORDER
  ).settingId;
  const BILLING_ADDRESS_TYPE = getSetting(
    APPLICATION_SETTING.BILLING_ADDRESS_TYPE
  ).settingId;
  const SITE_ADDRESS_TYPE = getSetting(
    APPLICATION_SETTING.SITE_ADDRESS_TYPE
  ).settingId;
  const NORMAL = getSetting(APPLICATION_SETTING.NORMAL).settingId;
  const USE_BLOCK_SCHEDULING = getSetting(
    APPLICATION_SETTING.USE_BLOCK_SCHEDULING
  ).defaultValue;
  const ON_NEW_SCHEDULE = getSetting(
    APPLICATION_SETTING.ON_NEW_SCHEDULE
  ).settingId;
  const [addressModal, setAddressModal] = useState(false);
  const [customerSearch, setCustomerSearch] = useState(
    workOrder.customer?.firstName
  );
  const [showCustomerSearch, setShowCustomerSearch] = useState(false);
  const [customerId, setCustomerId] = useState(
    workOrder && workOrder?.customer
      ? workOrder?.customer?.id
      : workOrder?.customerId
  );
  const { setChangeDetection } = useChangeDetection();
  const { data: jobTypes } = useJobTypes();
  const { data: scheduleTypes } = useScheduleTypes();
  const { data: workOrderStates } = useWorkOrderStates();
  const { data: addresses } = useAddressesByCustomer(customerId ?? "");
  const { data: customer } = useCustomer(customerId ?? "");
  const { data: schedules } = useSchedulesByWorkOrder(
    (workOrder && workOrder.id) ?? ""
  );
  const { data: employees } = useEmployees();
  const { data: workOrderRoleTitles } = useWorkOrderRoleTitles();
  const { data: customers } = useCustomerSearch(customerSearch ?? "");
  const { mutateAsync: createWorkOrder } = useAddWorkOrder();
  const { mutateAsync: patchWorkOrder } = usePatchWorkOrder();
  const { mutateAsync: createSchedule } = useAddSchedule();
  const { mutateAsync: patchSchedule } = usePatchSchedule();
  const { mutateAsync: deleteSchedule } = useDeleteSchedule();
  const [billingAddresses, setBillingAddresses] = useState<Address[] | null>();
  const [defaultScheduleType, setDefaultScheduleType] = useState<
    ScheduleType | undefined
  >();

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { isSubmitting, defaultValues },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({
      workOrder: {
        ...workOrder,
        jobTypeIds: workOrder?.jobTypes?.map((x) => x.id) ?? [],
        customerEquipmentIds:
          workOrder?.customerEquipments?.map((x) => x.id) ?? [],
        customerEquipments: workOrder?.customerEquipments,
        billingAddressId: workOrder?.billingAddress?.id,
        siteAddressId: workOrder?.siteAddress?.id,
        workOrderStateId: workOrder?.workOrderState?.id,
        customerId,
      },
      schedules: schedules,
      workOrderJobTypes: workOrder?.workOrdersJobTypes?.map((x) => x.jobTypeId),
    }),
  });
  const { fields, append, remove, replace } = useFieldArray({
    name: "schedules",
    control,
  });
  const {
    fields: noteFields,
    append: noteAppend,
    remove: noteRemove,
  } = useFieldArray({
    name: "workOrder.workOrderNotes",
    control,
  });
  const {
    fields: workOrderRoleFields,
    append: workOrderRoleAppend,
    remove: workOrderRoleRemove,
  } = useFieldArray({
    name: "workOrder.workOrderRoles",
    control,
  });
  const watchSchedules = watch("schedules");
  const watchWorkOrder = watch("workOrder");
  const watchCustomerEquipment = watch("workOrder.customerEquipments");

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  // set the default work order state
  useEffect(() => {
    if (!workOrder.workOrderState) {
      workOrderStates &&
        setValue(
          "workOrder.workOrderStateId",
          workOrderStates.find((x) => x.id === ON_NEW_WORK_ORDER)?.id ?? ""
        );
    }
  }, [workOrderStates, workOrder, ON_NEW_WORK_ORDER, setValue]);

  // filter billing addresses
  useEffect(
    () =>
      setBillingAddresses(
        addresses?.filter(
          (address) => address?.addressType?.id === BILLING_ADDRESS_TYPE
        )
      ),
    [addresses, BILLING_ADDRESS_TYPE]
  );

  // set the default schedule type
  useEffect(() => {
    scheduleTypes &&
      setDefaultScheduleType(scheduleTypes.find((x) => x.id === NORMAL));
  }, [scheduleTypes, NORMAL]);

  // set the proper ids on the schedule levels for their dropdowns
  // TODO find out what if the schedule.scheduleType is necessay
  useEffect(() => {
    watchSchedules &&
      watchSchedules.forEach((schedule, index) => {
        if (schedule) {
          schedule.employee &&
            setValue(`schedules.${index}.employeeId`, schedule?.employee?.id);

          schedule.scheduleType &&
            setValue(
              `schedules.${index}.scheduleTypeId`,
              schedule?.scheduleType?.id
            );

          if (watchWorkOrder && !watchWorkOrder.id) {
            setValue("workOrder.workOrderStateId", ON_NEW_SCHEDULE);
          }
        }
      });
  }, [watchSchedules, watchWorkOrder, setValue, ON_NEW_SCHEDULE]);

  useEffect(() => {
    schedules && replace(schedules);
  }, [schedules, replace]);

  const onDateSelected = (date: Date, index: number) =>
    setValue(`schedules.${index}.endDate`, add(date, { hours: 2 }));

  const onScheduleAdded = () =>
    append({
      scheduleTypeId: defaultScheduleType && defaultScheduleType.id,
      workOrderId: workOrder.id ?? "",
      startDate: new Date(),
      endDate: add(new Date(), { hours: 3 }),
    });

  const onSubmit = async (formData: FormData) => {
    formData.workOrder = formData.workOrder.id
      ? await patchWorkOrder(formData.workOrder)
      : await createWorkOrder(formData.workOrder as WorkOrder);

    // handle deleted schedules
    const original = workOrder.schedules?.map((x) => x.id) ?? [];
    const updated =
      formData.schedules && formData.schedules?.map((x) => x.id ?? "");
    for (const scheduleId of getDeleted(original, updated)) {
      await deleteSchedule(scheduleId);
    }

    if (formData.schedules) {
      for (const schedule of formData.schedules) {
        schedule.workOrderId = formData.workOrder.id;
        schedule.id
          ? await patchSchedule(schedule)
          : await createSchedule(schedule);
      }
    }

    onSubmitted &&
      onSubmitted(
        formData.workOrder as WorkOrder,
        formData.schedules as Schedule[]
      );
    setChangeDetection(false);
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          <SubTitle title="CUSTOMER" />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Box sx={{ mb: 2 }}>
              {customer && (
                <>
                  {customer.firstName} {customer.lastName}
                  <br />
                  {customer.phone} <br />
                  {customer.email}
                </>
              )}
              <br />
              {workOrder && workOrder.id && (
                <Button
                  onClick={() => setShowCustomerSearch(!showCustomerSearch)}
                >
                  Transfer Customer
                </Button>
              )}
            </Box>
            {showCustomerSearch && (
              <FormAutocomplete
                name="workOrder.customerId"
                valueKey="customerId"
                control={control}
                options={customers ?? []}
                loading={false}
                label="Customer"
                labelKeys={["fullName", "email", "phone", "address"]}
                onInputChange={(value) => setCustomerSearch(value)}
                onChange={(value) => setCustomerId(value.customerId)}
                sx={{ width: 500 }}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTitle title="WORK ORDER" />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column" spacing={2} sx={{ mb: 1 }}>
              {workOrderStates && (
                <FormSelect
                  name="workOrder.workOrderStateId"
                  label="Work Order State"
                  labelKeys={["name"]}
                  valueKey="id"
                  data={workOrderStates}
                  control={control}
                  defaultValue={workOrder?.workOrderState}
                  sx={{ width: 222 }}
                />
              )}
            </Stack>
            <WorkOrderRolesModal
              control={control}
              fields={workOrderRoleFields}
              append={workOrderRoleAppend}
              remove={workOrderRoleRemove}
              setValue={setValue}
              employees={employees}
              workOrderRoleTitles={workOrderRoleTitles}
              workOrder={watchWorkOrder}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
            <FormTextField
              name="workOrder.purchaseOrderNumber"
              label="P.O Number"
              control={control}
              fullWidth
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            {addresses && (
              <>
                {billingAddresses && (
                  <FormSelect
                    name="workOrder.billingAddressId"
                    label="Billing Address"
                    labelKeys={["streetNumber", "streetName", "city", "note"]}
                    valueKey="id"
                    data={billingAddresses ?? []}
                    control={control}
                    sx={{ minWidth: 300 }}
                    defaultValue={workOrder.billingAddress}
                    autoWidth
                  />
                )}
                <FormSelect
                  name="workOrder.siteAddressId"
                  label="Site Address"
                  labelKeys={["streetNumber", "streetName", "city", "note"]}
                  valueKey="id"
                  data={addresses}
                  control={control}
                  sx={{ minWidth: 300 }}
                  defaultValue={workOrder.siteAddress}
                  autoWidth
                />
                <Button
                  variant="outlined"
                  endIcon={<AddCircleIcon />}
                  onClick={() => setAddressModal(true)}
                >
                  Add Address
                </Button>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FormCheckBoxGroup
            name="workOrder.jobTypeIds"
            label="Job Type"
            options={jobTypes ?? []}
            labelKey="name"
            valueKey="id"
            control={control}
            direction="row"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="red">
            Warning - Job Description is Customer Facing
          </Typography>
          <FormTextField
            name="workOrder.jobDescription"
            label="Job Description"
            control={control}
            multiline
            fullWidth
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <SubTitle title="CUSTOMER EQUIPMENT" />
          {customer && (
            <CustomerEquipmentModal
              customer={customer}
              control={control}
              name="workOrder.customerEquipmentIds"
              onChecked={(row, checked) => {
                const allCustomerEquipment = objectAddOrDelete(
                  watchWorkOrder.customerEquipments,
                  row,
                  checked,
                  "id"
                );
                setValue("workOrder.customerEquipments", allCustomerEquipment);
                const ids = allCustomerEquipment.map((x) => x.id);
                setValue("workOrder.customerEquipmentIds", ids);
              }}
            />
          )}
          <Stack sx={{ pt: 1 }} direction="column">
            {watchCustomerEquipment && watchCustomerEquipment.length > 0 && (
              <GenericDataGrid
                rows={watchCustomerEquipment}
                loading={false}
                columns={customerEquipmentColumns}
                onDelete={(customerEquipment) => {
                  const allCustomerEquipment: CustomerEquipment[] =
                    objectAddOrDelete(
                      watchWorkOrder.customerEquipments,
                      customerEquipment,
                      false,
                      "id"
                    );
                  setValue(
                    "workOrder.customerEquipments",
                    allCustomerEquipment
                  );
                  const ids = allCustomerEquipment.map((x) => x.id);
                  setValue("workOrder.customerEquipmentIds", ids);
                }}
                autoHeight
                disableCreate
                disableExport
                disableQuickFilter
                disableColumnsButton
                columnVisibilityModel={{
                  warrantyPartsStartDate: false,
                  warrantyPartsExpiryDate: false,
                  warrantyLabourStartDate: false,
                  warrantyLabourExpiryDate: false,
                  vendor: false,
                }}
                hideFooter
                ignoreFields={["customer"]}
                skipConfirmation
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      {workOrder && !workOrder.id && (
        <Grid container>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <SubTitle title="Work Order Notes" />
            <WorkOrderNotes
              control={control}
              fields={noteFields}
              remove={noteRemove}
              append={noteAppend}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubTitle title="QUICK SCHEDULING" />
          {USE_BLOCK_SCHEDULING ? (
            <BlockSchedules
              control={control}
              fields={fields}
              scheduleTypes={scheduleTypes ?? []}
              employees={employees ?? []}
              schedules={schedules ?? []}
              trigger={trigger}
              onScheduleAdded={onScheduleAdded}
              remove={remove}
              setValue={setValue}
            />
          ) : (
            <TimeSchedules
              control={control}
              fields={fields}
              scheduleTypes={scheduleTypes ?? []}
              employees={employees ?? []}
              schedules={schedules ?? []}
              trigger={trigger}
              onDateSelected={onDateSelected}
              onScheduleAdded={onScheduleAdded}
              remove={remove}
            />
          )}
        </Grid>
      </Grid>
      <FormSubmitButton
        disabled={isSubmitting}
        text={workOrder?.id ? "Save Work Order Edits" : "Save Work Order"}
      />
      <ModalContainer
        open={addressModal}
        handleClose={() => setAddressModal(false)}
      >
        <AddressForm
          address={{ customerId: customer?.id } as Address}
          onSubmitted={(address) => {
            if (address.addressType?.id === SITE_ADDRESS_TYPE) {
              setValue("workOrder.siteAddressId", address.id);
            }
            if (address.addressType?.id === BILLING_ADDRESS_TYPE) {
              setValue("workOrder.billingAddressId", address.id);
            }
            setAddressModal(false);
          }}
          defaultAddressTypeId={SITE_ADDRESS_TYPE}
        />
      </ModalContainer>
    </FormContainer>
  );
};
