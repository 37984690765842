import { format, parseISO } from "date-fns";
import { Stack, Box, StackProps } from "@mui/material";

import { CustomerEquipment, InvoiceLineItem } from "../../../models";

interface Props {
  warranty: InvoiceLineItem | CustomerEquipment;
  stack?: StackProps;
}

export const WarrantyDisplay = (props: Props) => {
  const { warranty, stack } = props;

  const formatDate = (
    startDate?: Date | null | undefined,
    endDate?: Date | null | undefined
  ) => {
    let date = [];
    if (startDate) {
      date.push(format(parseISO(startDate?.toString() ?? ""), "MMM dd, yyyy"));
    }
    if (endDate) {
      date.push(format(parseISO(endDate?.toString() ?? ""), "MMM dd, yyyy"));
    }
    return date.join(" - ");
  };

  return (
    <Box>
      {(warranty.warrantyLabourExpiryDate ||
        warranty.warrantyLabourStartDate) && (
        <Stack direction="row" spacing={1} {...stack}>
          <b>Labour Warranty:</b>
          <Box>
            {formatDate(
              warranty?.warrantyLabourStartDate,
              warranty?.warrantyLabourExpiryDate
            )}
          </Box>
        </Stack>
      )}
      {(warranty.warrantyPartsStartDate ||
        warranty.warrantyPartsExpiryDate) && (
        <Stack direction="row" spacing={1} {...stack}>
          <b>Parts Warranty:</b>
          <Box>
            {formatDate(
              warranty?.warrantyPartsStartDate,
              warranty?.warrantyPartsExpiryDate
            )}
          </Box>
        </Stack>
      )}
    </Box>
  );
};
