import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";

import { Employee } from "../../models";
import { employeeColumns, GenericDataGrid } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  FileUploadModal,
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import {
  useDeleteEmployee,
  useEmployees,
  useUploadEmployees,
} from "../../hooks/employee";
import { EmployeeForm } from "../../components/forms";
import { useAuth } from "../../contexts/AuthContext";
import { Role } from "../../globals/enums";
import { Paper } from "@mui/material";

export const EmployeesPage = () => {
  const { data: employees, isLoading, isIdle, isError, error } = useEmployees();
  const { mutate: deleteEmployee } = useDeleteEmployee();
  const { mutate: uploadEmployees } = useUploadEmployees();
  const [employee, setEmployee] = useState<Employee>();
  const [openModal, setOpenModal] = useState(false);
  const { hasRole } = useAuth();
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (employee?: Employee) => {
    employee ? setEmployee(employee) : setEmployee(undefined);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    navigate("/employees");
  };

  return (
    <>
      <PageHeader title="Employees" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <FileUploadModal
          buttonTitle={"Upload Employees"}
          subTitle="* Records with an existing email will update the record"
          sampleFileUrl="sample-files/employees.csv"
          onSubmit={(file: File) => uploadEmployees(file)}
        />
      </Paper>
      <GenericDataGrid
        sx={{ height: 300 }}
        rows={employees}
        columns={employeeColumns}
        loading={isLoading}
        onDelete={(employee) => {
          deleteEmployee(employee.id);
          navigate("/employees");
        }}
        onEdit={onEditCreate}
        onCreate={onEditCreate}
        onDoubleClick={onEditCreate}
        onClick={(employee: Employee) => {
          setEmployee(employee);
          navigate(`/employees/${employee.id}`);
        }}
        createTitle="Create Employee"
        disableCreate={!hasRole([Role.Admin])}
        deleteTitle="Would you like to delete this Employee?"
        ignoreFields={[
          "id",
          "createdTimestamp",
          "modifiedTimestamp",
          "employeeTypes",
          "employeeTypeIds",
          "employeeRole",
          "employeeRoleId",
        ]}
        initialState={{
          sorting: {
            sortModel: [{ field: "firstName", sort: "asc" }],
          },
        }}
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={handleClose}
          title={`${employee ? "Edit" : "Create"} Employee`}
        >
          <EmployeeForm onSubmitted={handleClose} employee={employee} />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
      <Outlet />
    </>
  );
};
