import { useQuery } from "react-query";

import { Invoice } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useInvoiceByWorkOrder = (workOrderId?: string | null) => {
  return useQuery<Invoice, Error>(
    [RQ.INVOICES, workOrderId],
    async () => await getSingle(`invoices/work-order/${workOrderId}`),
    {
      enabled: Boolean(workOrderId),
    }
  );
};
