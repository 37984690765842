import { useState } from "react";
import { ReportType } from "../../../models";
import { ReportTypeForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { reportTypeColumns } from "./report-type.columns";
import { useDeleteReportType } from "../../../hooks/report-type";

interface Props {
  rows: ReportType[];
  loading: boolean;
  onClick?: (entity: ReportType) => void;
  onView?: (entity: ReportType) => void;
  onModalClosed?: () => void;
  onSubmitted?: (entity: ReportType) => void;
}

export const ReportTypeDataGrid = (props: Props) => {
  const { rows, loading, onClick, onView, onModalClosed, onSubmitted } = props;
  const [reportType, setReportType] = useState<ReportType>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteReportType } = useDeleteReportType();

  const onEditCreate = (reportType?: ReportType) => {
    reportType ? setReportType(reportType) : setReportType(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={reportTypeColumns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onView={onView}
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      onDelete={(customerPayment) => deleteReportType(customerPayment.id)}
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "modifiedTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton
      disableExport
      disableQuickFilter
      autoHeight
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${reportType?.id ? "Edit" : "Create"} Report Type`}
      >
        <ReportTypeForm
          onSubmitted={(reportType) => {
            setOpenModal(false);
            onSubmitted && onSubmitted(reportType);
          }}
          reportType={reportType}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
