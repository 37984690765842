import { Modal, Box, Typography, BoxProps } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  overflow: "scroll",
  overflowY: "auto",
  overflowX: "auto",
  maxHeight: "900px",
  display: "block",
  boxShadow: 24,
  p: 4,
};

interface Props extends BoxProps {
  open: boolean;
  handleClose?: () => void;
  children: JSX.Element | JSX.Element[];
  title?: string;
}

export const ModalContainer = (props: Props) => {
  const { open, handleClose, children, title } = props;
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ textAlign: "center" }}>
          {title && (
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
          )}
        </Box>
        {children}
      </Box>
    </Modal>
  );
};
