import { EventContentArg } from "@fullcalendar/core";
import {
  Box,
  IconButton,
  Link,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LoopIcon from "@mui/icons-material/Loop";
import { Role } from "../../../globals/enums";
import React from "react";

export const CalendarEvent = (
  eventInfo: EventContentArg,
  ON_SERVICE: string,
  onDelete: (eventInfo: EventContentArg) => void,
  onEdit: (eventInfo: EventContentArg) => void,
  onDuplicate: (eventInfo: EventContentArg) => void,
  onWorkOrderEdit: (eventInfo: EventContentArg) => void,
  hasRole: (roles: Role[]) => boolean
) => {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  if (eventInfo.event.extendedProps["requiresWorkOrder"] === true) {
    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{eventInfo.event.title}</Typography>
            <div>
              {eventInfo.event.extendedProps["customerName"]} {" - "}
              <b>{eventInfo.event.extendedProps["workOrderStateName"]}</b>{" "}
              <br />
              {eventInfo.event.extendedProps["address"]}
              <br />
              {eventInfo.event.extendedProps["jobTypes"]}
            </div>
          </React.Fragment>
        }
        placement="top-start"
      >
        <Stack direction="column">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pl: 1, flexWrap: "wrap" }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={() => onWorkOrderEdit(eventInfo)}
            >
              {eventInfo.event.title}
            </Link>
            {hasRole([Role.Admin]) && (
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
                sx={{ flexWrap: "wrap" }}
              >
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => onEdit(eventInfo)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="duplicate"
                  size="small"
                  onClick={() => onDuplicate(eventInfo)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => onDelete(eventInfo)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                {eventInfo.event.extendedProps["repeats"] && (
                  <LoopIcon fontSize="small" />
                )}
              </Stack>
            )}
          </Stack>
          <Box sx={{ p: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <b>{eventInfo.timeText}</b>
            </Stack>
            <div>
              {eventInfo.event.extendedProps["customerName"]} {" - "}
              <b>{eventInfo.event.extendedProps["workOrderStateName"]}</b>{" "}
              <br />
              {eventInfo.event.extendedProps["address"]}
              <br />
              {eventInfo.event.extendedProps["jobTypes"]}
            </div>
          </Box>
        </Stack>
      </HtmlTooltip>
    );
  }

  if (eventInfo.event.extendedProps["scheduleTypeId"] === ON_SERVICE) {
    return (
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ fontSize: "small" }}
      >
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => onEdit(eventInfo)}
          >
            <EditIcon fontSize="small" />
          </IconButton>

          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => onDelete(eventInfo)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          {eventInfo.event.extendedProps["repeats"] && (
            <LoopIcon fontSize="small" sx={{ mt: 0.5 }} />
          )}
        </Stack>
        <>ON SERVICE</>
      </Stack>
    );
  }

  return <b>{eventInfo.event.title}</b>;
};
