import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { CustomerAddress, Invoice, WorkOrder } from "../../models";
import {
  GenericDataGrid,
  InvoiceDataGrid,
  InvoiceDrawRequestDataGrid,
  invoiceLineItemColumns,
} from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { AutoSearch, ChipDropDown, LoadingSpinner } from "../../components/ui";
import { useInvoicesByFilter } from "../../hooks/invoice";
import { useWorkOrdersSearch } from "../../hooks/work-order";
import { useWorkOrderStates } from "../../hooks/work-order-state";
import { useInvoiceDrawRequestsByInvoice } from "../../hooks/invoice-draw-request";
import { useCustomerSearch } from "../../hooks/customer-address";
import { objectAddOrDelete } from "../../utils";
import { SendToQuickBooksModal } from "./components/SendToQuickBooks.modal";
import { Role } from "../../globals/enums";
import { useAuth } from "../../contexts/AuthContext";

export const InvoicesPage = () => {
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { hasRole } = useAuth();
  const [workOrderId, setWorkOrderId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [paidStatus, setPaidStatus] = useState<string | undefined>("unpaid");
  const [workOrderStateGuids, setWorkOrderStateGuids] = useState<string[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const {
    data: invoices,
    isLoading,
    isIdle,
    isError,
    error,
    refetch: getInvoices,
  } = useInvoicesByFilter(
    startDate,
    endDate,
    workOrderStateGuids,
    workOrderId,
    customerId,
    paidStatus,
    page
  );
  const { data: workOrderStates } = useWorkOrderStates();
  const [invoice, setInvoice] = useState<Invoice | undefined>();
  const { data: invoiceDrawRequests } = useInvoiceDrawRequestsByInvoice(
    invoice?.id
  );
  const navigate = useNavigate();

  useEffect(() => {
    getInvoices();
  }, [
    startDate,
    endDate,
    workOrderStateGuids,
    workOrderId,
    customerId,
    paidStatus,
    page,
  ]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader title="Invoices" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Start Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={startDate}
            onChange={(value) => setStartDate(value)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={endDate}
            onChange={(value) => setEndDate(value)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <AutoSearch
            label="Work Order Search..."
            keys={["workOrderNumber"]}
            onOptionSelected={(workOrder: WorkOrder | null) =>
              workOrder ? setWorkOrderId(workOrder?.id) : setWorkOrderId("")
            }
            searchFunction={useWorkOrdersSearch}
            separator="|"
            sx={{ width: 200 }}
          />
          <ChipDropDown
            data={workOrderStates ?? []}
            label="Work Order States"
            labelKey={"name"}
            valueKey={"id"}
            onOptionChange={(selectedWorkOrderStates) =>
              setWorkOrderStateGuids(selectedWorkOrderStates.map((x) => x.id))
            }
          />
          <AutoSearch
            label="Search Customer By Name, Address, or P.O ..."
            keys={["fullName", "email", "phone", "address", "po"]}
            onOptionSelected={(customer: CustomerAddress | null) =>
              customer ? setCustomerId(customer.customerId) : setCustomerId("")
            }
            searchFunction={useCustomerSearch}
            separator="|"
            sx={{ width: 500 }}
          />
        </Stack>
        <Stack spacing={2} sx={{ mt: 2 }} direction="row">
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={paidStatus}
            onChange={(_, value) => setPaidStatus(value)}
          >
            {["all", "paid", "unpaid"].map((option, index) => (
              <ToggleButton value={option} key={index}>
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {selectedInvoices &&
            selectedInvoices.length > 0 &&
            hasRole([Role.Admin]) && (
              <SendToQuickBooksModal invoices={selectedInvoices} />
            )}
        </Stack>
      </Paper>
      <InvoiceDataGrid
        rows={invoices ?? []}
        loading={isLoading}
        onView={(invoice) => navigate(`/invoices/view/${invoice.id}`)}
        onEdit={(invoice) => navigate(`/invoices/edit/${invoice.id}`)}
        onPageChange={(page) => setPage(page)}
        onClick={(invoice) => setInvoice(invoice)}
        onChecked={(row, checked) =>
          setSelectedInvoices((current) =>
            objectAddOrDelete(current, row, checked, "id")
          )
        }
        sx={{ height: 300 }}
      />
      <br />
      {invoice && (
        <>
          <GenericDataGrid
            rows={invoice.invoiceLineItems}
            columns={invoiceLineItemColumns}
            loading={isLoading}
            title="Invoice Line Items"
            autoHeight
            disableColumnsButton
            disableExport
            disableQuickFilter
          />
          <InvoiceDrawRequestDataGrid
            sx={{ mt: 2 }}
            rows={invoiceDrawRequests ?? []}
            onView={(invoice) =>
              navigate(`/invoice-draw-request/view/${invoice.id}`)
            }
            title="Invoice Draw Request"
            invoiceId={invoice.id}
            loading={false}
            disableColumnsButton
            disableExport
            disableQuickFilter
            autoHeight
          />
        </>
      )}
    </>
  );
};
