import { useQuery } from "react-query";

import { OverdueInvoice } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useOverdueInvoices = () => {
  return useQuery<OverdueInvoice[], Error>(
    RQ.REPORT_OVERDUE_INVOICES,
    async () => getAll<OverdueInvoice>("reports/overdue-invoices")
  );
};
