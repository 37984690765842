import { useQuery } from "react-query";
import { format } from "date-fns";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useWorkOrdersByFilter = (
  startDate: Date,
  endDate?: Date,
  workOrderStates?: string[],
  customerId?: string,
  workOrderId?: string
) => {
  let params = new URLSearchParams();
  startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
  endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
  workOrderStates &&
    workOrderStates.length > 0 &&
    params.append("workOrderStates", workOrderStates.join(","));
  customerId && params.append("customerId", customerId);
  workOrderId && params.append("workOrderId", workOrderId);
  return useQuery<WorkOrder[], Error>(
    [RQ.WORK_ORDERS],
    async () => await getAll(`work-orders/filter?${params}`),
    {
      enabled: Boolean(startDate),
    }
  );
};
