import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { AddressType } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddAddressType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (addressType: AddressType) =>
      add<AddressType>("address-types", addressType),
    {
      onSuccess: (addressType) => {
        enqueueSnackbar("Address Type Added", { variant: "success" });
        queryClient.setQueryData<AddressType[]>(
          RQ.ADDRESS_TYPES,
          (addressTypes: AddressType[] | undefined) => {
            return [...(addressTypes ?? []), addressType];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Address Type Adding Error", { variant: "error" });
      },
    }
  );
};
