import { useQuery } from "react-query";

import { ScheduleType } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useScheduleTypes = (dropDownFilter: boolean = true) => {
  return useQuery<ScheduleType[], Error>(
    RQ.SCHEDULE_TYPES,
    async () => getAll<ScheduleType>("schedule-types"),
    {
      select: (scheduleTypes) => {
        if (dropDownFilter) {
          scheduleTypes = scheduleTypes.filter((x) => x.showInDropdown);
        }
        return scheduleTypes;
      },
    }
  );
};
