import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { PaymentType } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddPaymentType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (paymentType: PaymentType) =>
      add<PaymentType>("payment-types", paymentType),
    {
      onSuccess: (paymentType) => {
        enqueueSnackbar("Payment Type Added", { variant: "success" });
        queryClient.setQueryData<PaymentType[]>(
          RQ.PAYMENT_TYPES,
          (paymentTypes: PaymentType[] | undefined) => {
            return [...(paymentTypes ?? []), paymentType];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Payment Type Adding Error", { variant: "error" });
      },
    }
  );
};
