import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
import { useEffect } from "react";

import { Employee } from "../../../models";
import { employeeSchema as schema } from "./employee.schema";
import { useAddEmployee, usePatchEmployee } from "../../../hooks/employee";
import { FormContainer } from "../../layout";
import {
  FormArrayObjectSelect,
  FormDatePicker,
  FormPhoneNumber,
  FormSubmitButton,
  FormSwitch,
  FormTextField,
} from "../../form-components";
import { AddressSearch } from "../../ui";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { useEmployeeRoles } from "../../../hooks/employee-role";
import { useAuth } from "../../../contexts/AuthContext";
import { Role } from "../../../globals/enums";

interface Props {
  employee?: Employee;
  onSubmitted?: (employee: Employee) => void;
}

export const EmployeeForm = (props: Props) => {
  const { employee, onSubmitted } = props;
  const { hasRole } = useAuth();
  const { data: employeeRoles } = useEmployeeRoles();
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createEmployee } = useAddEmployee();
  const { mutateAsync: patchEmployee } = usePatchEmployee();
  const {
    reset,
    control,
    trigger,
    watch,
    handleSubmit,
    formState: { defaultValues },
  } = useForm<Employee>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(employee),
  });
  const inactive = watch("inactive");

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (employee) => {
        employee = employee.id
          ? await patchEmployee(employee)
          : await createEmployee(employee);
        onSubmitted && onSubmitted(employee);
        setChangeDetection(false);
      })}
    >
      <Stack spacing={2}>
        <AddressSearch
          sx={{ width: 460 }}
          label="Address"
          onSelected={(selectedAddress) =>
            reset({ ...defaultValues, ...selectedAddress })
          }
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="firstName"
          label="First Name"
          control={control}
          required
          fullWidth
        />
        <FormTextField
          name="lastName"
          label="Last Name"
          control={control}
          required
          fullWidth
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormPhoneNumber
          name="homePhone"
          label="Home Phone"
          control={control}
        />
        <FormPhoneNumber
          name="cellPhone"
          label="Cell Phone"
          control={control}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="email" label="Email" control={control} required />
        <FormDatePicker
          control={control}
          name="startDate"
          label="Start Date"
          trigger={trigger}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="streetNumber"
          label="Street Number"
          control={control}
        />
        <FormTextField
          name="streetName"
          label="Street Name"
          control={control}
        />
        <FormTextField name="unit" label="Unit" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="postalCode"
          label="Postal Code"
          control={control}
        />
        <FormTextField name="city" label="City" control={control} />
        <FormTextField name="province" label="Province" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="dispatchPriority"
          label="Dispatch Priority"
          type="number"
          control={control}
        />
        <FormSwitch
          name="inactive"
          label={inactive ? "Inactive" : "Active"}
          control={control}
        />
        {hasRole([Role.Admin]) && (
          <FormArrayObjectSelect
            name="employeeRoleId"
            label="Employee Role"
            data={employeeRoles ?? []}
            control={control}
            labelKey="name"
            valueKey="id"
            defaultValue={employee?.employeeRole?.id}
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="signOff"
          label="Email Sign Off"
          control={control}
          multiline
          fullWidth
          rows={4}
        />
      </Stack>
      <FormSubmitButton />
    </FormContainer>
  );
};
