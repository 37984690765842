import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerNote } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchCustomerNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerNote: CustomerNote) =>
      patch({
        route: "customer-notes",
        id: customerNote.id ?? "",
        entity: customerNote,
      }),
    {
      onSuccess: (customerNote, variables) => {
        enqueueSnackbar("Customer Note Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMER_NOTES, variables.customerId],
          (customerNotes: CustomerNote[] | undefined) =>
            !customerNotes
              ? []
              : patchEntity<CustomerNote>(customerNotes, customerNote, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Note Updating Error", { variant: "error" });
      },
    }
  );
};
