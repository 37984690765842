import { Box, Button, Grid, Typography } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

interface Props {
  title?: string;
  createTitle: string;
  createHandler: () => void;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export function DataGridToolBar(props: Props) {
  const {
    title,
    createTitle,
    createHandler,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Box sx={{ ml: 1 }}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          {!disableColumnsButton && <GridToolbarColumnsButton sx={{ mr: 2 }} />}
          {!disableExport && <GridToolbarExport sx={{ mr: 2 }} />}
          {!disableQuickFilter && <GridToolbarQuickFilter />}
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          {!disableCreate && (
            <Button onClick={() => createHandler()}>{createTitle}</Button>
          )}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}
