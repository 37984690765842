import { useQuery } from "react-query";

import { InvoiceLineItem } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useInvoiceLineItemsByWorkOrder = (workOrderId?: string) => {
  return useQuery<InvoiceLineItem[], Error>(
    [RQ.INVOICE_LINE_ITEMS, workOrderId],
    async () => await getAll(`/invoice-line-items/proposals/${workOrderId}`),
    {
      enabled: Boolean(workOrderId),
    }
  );
};
