import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { ReferralType } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddReferralType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (referralType: ReferralType) =>
      add<ReferralType>("referral-types", referralType),
    {
      onSuccess: (referralType) => {
        enqueueSnackbar("Referral Type Added", { variant: "success" });
        queryClient.setQueryData<ReferralType[]>(
          RQ.REFERRAL_TYPES,
          (referralTypes: ReferralType[] | undefined) => {
            return [...(referralTypes ?? []), referralType];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Referral Type Adding Error", { variant: "error" });
      },
    }
  );
};
