import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Control, ControllerRenderProps } from "react-hook-form";

import { ModalContainer } from "../../../ui";
import { FormDatePicker } from "../../../form-components";
import { Invoice } from "../../../../models";

interface Props {
  control: Control<Invoice, any>;
  field: ControllerRenderProps<Invoice, `invoiceLineItems.${number}`>;
  index: number;
}

export const WarrantyModal = (props: Props) => {
  const { control, index, field } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Warranty</Button>
      <ModalContainer
        open={open}
        title="Warranty"
        handleClose={() => setOpen(false)}
      >
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Typography variant="h6">Parts</Typography>
          <FormDatePicker
            name={`invoiceLineItems.${index}.warrantyPartsStartDate`}
            label="Warranty Parts Start Date"
            control={control}
          />
          <FormDatePicker
            name={`invoiceLineItems.${index}.warrantyPartsExpiryDate`}
            label="Warranty Parts Expiry Date"
            control={control}
          />
        </Stack>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Typography variant="h6">Labour</Typography>
          <FormDatePicker
            name={`invoiceLineItems.${index}.warrantyLabourStartDate`}
            label="Warranty Labour Start Date"
            control={control}
          />
          <FormDatePicker
            name={`invoiceLineItems.${index}.warrantyLabourExpiryDate`}
            label="Warranty Labour Expiry Date"
            control={control}
          />
        </Stack>
      </ModalContainer>
    </>
  );
};
