import { useQuery } from "react-query";
import { format } from "date-fns";

import { WorkOrderDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useWorkOrderDocumentsByDateRange = (
  workOrderId: string | null,
  startDate?: Date,
  endDate?: Date
) => {
  let params = new URLSearchParams();
  startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
  endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
  workOrderId && params.append("workOrderId", workOrderId);
  return useQuery<WorkOrderDocument[], Error>(
    [RQ.WORK_ORDER_DOCUMENTS, workOrderId],
    async () =>
      await getAll(`documents/work-order-documents/date-range?${params}`),
    {
      enabled: Boolean(workOrderId),
    }
  );
};
