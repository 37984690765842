import { useState } from "react";
import { Button, IconButton, Stack } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { ModalContainer } from "../../../ui";
import { FormAutocomplete } from "../../../form-components";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFormSetValue,
} from "react-hook-form";
import {
  Employee,
  Schedule,
  WorkOrder,
  WorkOrderRole,
  WorkOrderRoleTitle,
} from "../../../../models";
import { FormArrayObjectSelect } from "../../../form-components";

type FormData = {
  workOrder: Omit<WorkOrder, "schedules">;
  schedules: Partial<Omit<Schedule, "workOrder">>[];
};

interface Props {
  control: Control<FormData, any>;
  fields: FieldArrayWithId<FormData, "workOrder.workOrderRoles", "id">[];
  append: UseFieldArrayAppend<FormData, "workOrder.workOrderRoles">;
  remove: (index?: number | number[] | undefined) => void;
  setValue: UseFormSetValue<FormData>;
  employees?: Employee[];
  workOrderRoleTitles?: WorkOrderRoleTitle[];
  workOrder: WorkOrder | Partial<WorkOrder>;
}

export const WorkOrderRolesModal = (props: Props) => {
  const {
    control,
    fields,
    remove,
    append,
    setValue,
    employees,
    workOrderRoleTitles,
    workOrder,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<GroupAddIcon />}
        onClick={() => setOpen(true)}
      >
        Assign Work Order Roles
      </Button>
      <ModalContainer
        title="Work Order Roles"
        open={open}
        handleClose={() => setOpen(false)}
      >
        <>
          {fields.map((field, index) => (
            <Controller
              name={`workOrder.workOrderRoles.${index}`}
              control={control}
              key={field.id}
              render={({ field }) => {
                return (
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    <FormArrayObjectSelect
                      name={`workOrder.workOrderRoles.${index}.workOrderRoleTitleId`}
                      label="Work Order Role Title"
                      data={workOrderRoleTitles ?? []}
                      control={control}
                      labelKey="name"
                      valueKey="id"
                      defaultValue={
                        workOrder &&
                        workOrder.workOrderRoles &&
                        workOrder?.workOrderRoles[index]?.workOrderRoleTitle?.id
                      }
                      onSelected={(entity) =>
                        setValue(
                          `workOrder.workOrderRoles.${index}.workOrderRoleTitle`,
                          entity
                        )
                      }
                    />
                    <FormAutocomplete
                      name={`workOrder.workOrderRoles.${index}.employeeId`}
                      valueKey="id"
                      control={control}
                      options={employees ?? []}
                      loading={false}
                      label="Primary Tech"
                      labelKeys={["firstName", "lastName"]}
                      defaultValue={
                        workOrder &&
                        workOrder.workOrderRoles &&
                        workOrder?.workOrderRoles[index]?.employee
                      }
                      onChange={(entity) =>
                        setValue(
                          `workOrder.workOrderRoles.${index}.employee`,
                          entity
                        )
                      }
                      sx={{ width: 500 }}
                    />
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                );
              }}
            />
          ))}
          <Button
            onClick={() =>
              append({
                employeeId: "",
                workOrderRoleTitleId: "",
              } as WorkOrderRole)
            }
            endIcon={<AddCircleIcon />}
            variant="outlined"
          >
            Add Work Order Role
          </Button>
        </>
        <br />
        <br />
        <Button variant="contained" onClick={() => setOpen(false)}>
          Okay
        </Button>
      </ModalContainer>
    </>
  );
};
