import {
  FormControl,
  FormHelperText,
  Stack,
  StandardTextFieldProps,
  Typography,
} from "@mui/material";
import { CirclePicker } from "react-color";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface Props<K extends FieldValues> extends StandardTextFieldProps {
  name: Path<K>;
  label: string;
  control: Control<K, any>;
}

export const FormColorPicker = <K extends FieldValues>(props: Props<K>) => {
  const { name, label, control } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <FormControl size="small" error={fieldState.invalid}>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Typography>{label}</Typography>
        <div
          style={{
            backgroundColor: `${field.value}`,
            width: 100,
            height: 25,
            border: "2px solid white",
            borderRadius: 7,
          }}
        />
      </Stack>
      <CirclePicker
        colors={[
          "#F44336",
          "#4caf50",
          "#00d084",
          "#03a9f4",
          "#ff5722",
          "#ba68c8",
          "#ffeb3b",
          "#607d8b",
          "#795548",
          "#ff9800",
          "#ffc107",
          "#cddc39",
          "#3f51b5",
          "#F78DA7",
        ]}
        onChange={(value) => field.onChange(value.hex)}
      />
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};
