import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InventoryItem } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchInventoryItem = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (inventoryItem: InventoryItem) =>
      patch({
        route: "inventory-items",
        id: inventoryItem.id ?? "",
        entity: inventoryItem,
      }),
    {
      onSuccess: (inventoryItem) => {
        enqueueSnackbar("Inventory Item Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.INVENTORY_ITEMS,
          (inventoryItems: InventoryItem[] | undefined) =>
            !inventoryItems
              ? []
              : patchEntity<InventoryItem>(inventoryItems, inventoryItem, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Inventory Item Updating Error", { variant: "error" });
      },
    }
  );
};
