import { Paper } from "@mui/material";
import { DisplayInfo, LoadingSpinner } from "../../../../components/ui";
import { useBalanceSheet } from "../../../../hooks/report";
import { ReportHeader } from "../../components/ReportHeader";

export const BalanceSheetReport = () => {
  const { data, isLoading, isIdle, isError, error } = useBalanceSheet();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <ReportHeader title="Balance Sheet Report" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <DisplayInfo entity={data} ignore={["id"]} displayEmpties />
      </Paper>
    </>
  );
};
