import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";

interface Props<K extends FieldValues> {
  name: Path<K>;
  label?: string;
  control: Control<K, any>;
  onFileSelected?: (file: File) => void;
}

export const FormFileSelector = <K extends FieldValues>(props: Props<K>) => {
  const { name, label, control, onFileSelected } = props;
  const { field, fieldState } = useController({ name, control });

  return (
    <FormControl error={fieldState.invalid}>
      <div>
        <input
          accept="*"
          id="file-upload"
          type="file"
          onChange={(value) => {
            const file = value.target as HTMLInputElement;
            if (file && file.files) {
              if (value.target.type === "file") {
                field.onChange(file.files[0]);
                onFileSelected && onFileSelected(file.files[0]);
              }
            }
          }}
          style={{ display: "none" }}
        />
        <label htmlFor="file-upload">
          <IconButton component="span">
            <AttachFileIcon />
          </IconButton>
          {label}
        </label>
      </div>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};
