import { useQuery } from "react-query";

import { ReportType } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useReportTypes = () => {
  return useQuery<ReportType[], Error>(RQ.REPORT_TYPES, async () =>
    getAll<ReportType>("report-types")
  );
};
