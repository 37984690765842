/**
 * Trims the beginning and end of a string
 * @param originalString the original string
 * @param character The character you would like trimmed
 * @returns string
 */
export function trimByChar(originalString: string, character: string) {
  if (character === "]") character = "\\]";
  if (character === "^") character = "\\^";
  if (character === "\\") character = "\\\\";
  return originalString.replace(
    new RegExp("^[" + character + "]+|[" + character + "]+$", "g"),
    ""
  );
}
