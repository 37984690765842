import { useState } from "react";
import { useDeleteWorkOrderDocument } from "../../../hooks/work-order-document";
import { WorkOrder, WorkOrderDocument } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { workOrderDocumentColumns as columns } from "./work-order-document.columns";
import { WorkOrderDocumentForm, WorkOrderDocumentsForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";
import { DocumentClassification } from "../../../globals/enums";

interface Props<T> {
  rows: T[];
  workOrder: WorkOrder;
  loading: boolean;
  onClick?: (entity: T) => void;
  onDownload?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const WorkOrderDocumentDataGrid = (props: Props<WorkOrderDocument>) => {
  const {
    rows,
    workOrder,
    loading,
    onClick,
    onDownload,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [workOrderDocument, setWorkOrderDocument] =
    useState<WorkOrderDocument>();
  const [openModal, setOpenModal] = useState(false);
  const [multipleModal, setMultipleModal] = useState(false);
  const { mutate: deleteWorkOrderDocument } = useDeleteWorkOrderDocument();

  const onEditCreate = (workOrderDocument?: WorkOrderDocument) => {
    workOrderDocument
      ? setWorkOrderDocument({
          ...workOrderDocument,
          workOrderId: workOrder.id,
        })
      : setWorkOrderDocument({
          workOrderId: workOrder.id,
        } as WorkOrderDocument);
    setOpenModal(true);
  };

  const onCreate = () => {
    setMultipleModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDelete={(workOrderDocument) =>
        deleteWorkOrderDocument({
          ...workOrderDocument,
          workOrderId: workOrder.id,
        })
      }
      onDownload={onDownload}
      onCreate={onCreate}
      onEdit={onEditCreate}
      onLink={(document) => {
        const url = new URL(window.location.href);
        url.pathname = `/document`;
        url.searchParams.append("documentId", document.id);
        url.searchParams.append(
          "documentType",
          DocumentClassification.WORK_ORDER
        );
        window.open(url.toString(), "_blank");
      }}
      title={title}
      createTitle="Upload Work Order Document"
      deleteTitle="Do you want to delete this Work Order Document"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <>
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            onModalClosed && onModalClosed();
          }}
          title={`${workOrderDocument ? "Edit" : "Upload"} Work Order Document`}
        >
          <WorkOrderDocumentForm
            onSubmitted={() => setOpenModal(false)}
            workOrderDocument={workOrderDocument}
          />
        </ModalContainerChangeDetection>
        <ModalContainerChangeDetection
          open={multipleModal}
          handleClose={() => {
            setMultipleModal(false);
            onModalClosed && onModalClosed();
          }}
          title={`Upload Work Order Documents`}
        >
          <WorkOrderDocumentsForm
            onSubmitted={() => setMultipleModal(false)}
            workOrderId={workOrder.id}
          />
        </ModalContainerChangeDetection>
      </>
    </GenericDataGrid>
  );
};
