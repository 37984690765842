import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { uploadFile } from "../../api/generic-api";

export const useAddWorkOrderDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderDocument: WorkOrderDocument) =>
      uploadFile<WorkOrderDocument>(
        `/work-orders/${workOrderDocument.workOrderId}/documents`,
        workOrderDocument
      ),
    {
      onSuccess: (workOrderDocument, variables) => {
        enqueueSnackbar("Work Order Document Added", { variant: "success" });
        queryClient.setQueryData<WorkOrderDocument[]>(
          [RQ.WORK_ORDER_DOCUMENTS, variables.workOrderId],
          (workOrderDocuments: WorkOrderDocument[] | undefined) => {
            return [...(workOrderDocuments ?? []), workOrderDocument];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Document Adding Error", {
          variant: "error",
        });
      },
    }
  );
};
