import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface Props<T, K extends FieldValues> extends SelectProps {
  name: Path<K>;
  data: T[];
  control: Control<K, any>;
  required?: boolean;
  labelKey: keyof T;
  valueKey: keyof T;
  onSelected?: (option: T) => void;
}

export const FormArrayObjectSelect = <T, K extends FieldValues>(
  props: Props<T, K>
) => {
  const {
    data,
    control,
    name,
    label,
    required,
    labelKey,
    valueKey,
    onSelected,
    ...rest
  } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <FormControl
      size="small"
      required={required}
      error={fieldState.invalid}
      fullWidth
    >
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        label={label}
        onChange={(item) => {
          field.onChange(item.target.value);
          onSelected &&
            onSelected(
              data.find((x) => x[valueKey] === item.target.value) as T
            );
        }}
        size="small"
        {...rest}
      >
        {data.map((item: T, index: number) => (
          <MenuItem key={index} value={item[valueKey as keyof object]}>
            {item[labelKey as keyof object]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};
