import { Navigate, useLocation } from "react-router-dom";
import { AccessDenied } from "./AccessDenied";
import { useAuth } from "../../contexts/AuthContext";
import { menuItems } from "../../globals/menu-items";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  let location = useLocation();
  const { isAuthenticated, authState, hasRole } = useAuth();

  if (authState === "NEW_PASSWORD_REQUIRED") {
    return <Navigate to="/complete-password" state={{ from: location }} />;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isAuthenticated()) {
    const toRoute = menuItems.find(
      (menuItem) => menuItem.to === location.pathname
    );

    if (toRoute && !hasRole(toRoute.roles)) {
      return <AccessDenied />;
    }
  }

  if (location.pathname === "/") {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }

  return children;
};
