import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Schedule } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchSchedule = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (schedule: Partial<Schedule>) =>
      patch({ route: "schedules", id: schedule.id ?? "", entity: schedule }),
    {
      onSuccess: (schedule) => {
        enqueueSnackbar("Schedule Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.SCHEDULES],
          (schedules: Schedule[] | undefined) =>
            !schedules ? [] : patchEntity<Schedule>(schedules, schedule, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Schedule Updating Error", { variant: "error" });
      },
    }
  );
};
