import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerTier } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddCustomerTier = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerTier: CustomerTier) =>
      add<CustomerTier>("customer-tiers", customerTier),
    {
      onSuccess: (customerTier) => {
        enqueueSnackbar("Customer Tier Added", { variant: "success" });
        queryClient.setQueryData<CustomerTier[]>(
          RQ.CUSTOMER_TIERS,
          (customerTiers: CustomerTier[] | undefined) => {
            return [...(customerTiers ?? []), customerTier];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Tier Adding Error", { variant: "error" });
      },
    }
  );
};
