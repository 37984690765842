import { Divider, Grid, InputAdornment, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  CustomerPayment,
  Invoice,
  PaymentMethod,
  PaymentType,
  WorkOrder,
} from "../../../models";
import { customerPaymentSchema as schema } from "../schemas";
import {
  useAddCustomerPayment,
  useCustomerPaymentsByWorkOrder,
  usePatchCustomerPayment,
} from "../../../hooks/customer-payment";
import { FormContainer } from "../../layout";
import {
  FormArrayObjectSelect,
  FormAutocomplete,
  FormDatePicker,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useWorkOrdersByCustomer } from "../../../hooks/work-order";
import { usePaymentTypes } from "../../../hooks/payment-type";
import { useEffect, useState } from "react";
import { usePaymentMethods } from "../../../hooks/payment-method";
import { GenericDataGrid, customerPaymentColumns } from "../../grids";
import { useCustomer } from "../../../hooks/customer";
import { APPLICATION_SETTING } from "../../../globals/enums";
import { useAppSettingsContext } from "../../../contexts";
import { sumBy } from "lodash";

interface Props {
  customerPayment?: CustomerPayment;
  workOrder?: WorkOrder;
  invoice?: Invoice;
  onSubmitted?: (customerPayment: CustomerPayment) => void;
}

export const CustomerPaymentForm = (props: Props) => {
  const { customerPayment, invoice, onSubmitted } = props;
  const { getSetting } = useAppSettingsContext();
  const { mutateAsync: createCustomerPayment } = useAddCustomerPayment();
  const { mutateAsync: patchCustomerPayment } = usePatchCustomerPayment();
  const [workOrderId, setWorkOrderId] = useState(
    customerPayment?.workOrder?.id
  );
  const [defaultPaymentType, setDefaultPaymentType] = useState<PaymentType>();
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<PaymentMethod>();
  const {
    data: workOrders,
    isLoading: isLoadingWorkOrders,
    isFetching: isFetchingWorkOrders,
  } = useWorkOrdersByCustomer(customerPayment?.customerId);
  const { data: customerPayments } =
    useCustomerPaymentsByWorkOrder(workOrderId);
  const { data: customer } = useCustomer(customerPayment?.customerId ?? "");
  const { data: paymentTypes } = usePaymentTypes();
  const { data: paymentMethods } = usePaymentMethods();
  const {
    trigger,
    handleSubmit,
    setValue,
    control,
    formState: { isSubmitting },
  } = useForm<Omit<CustomerPayment, "workOrder">>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({
      ...customerPayment,
      workOrderId,
    }),
  });
  const DEFAULT_PAYMENT_TYPE = getSetting(
    APPLICATION_SETTING.DEFAULT_PAYMENT_TYPE
  ).settingId;
  const DEFAULT_PAYMENT_METHOD = getSetting(
    APPLICATION_SETTING.DEFAULT_PAYMENT_METHOD
  ).settingId;

  // set default payment type
  useEffect(() => {
    if (paymentTypes && !defaultPaymentType) {
      setDefaultPaymentType(
        paymentTypes?.find((x) => x.id === DEFAULT_PAYMENT_TYPE)
      );

      if (!customerPayment?.id) {
        const paymentType = paymentTypes?.find(
          (x) => x.id === DEFAULT_PAYMENT_TYPE
        );

        if (paymentType) {
          setValue("paymentTypeId", paymentType.id);
          setValue("paymentType", paymentType);
        }
      }
    }
  }, [
    paymentTypes,
    DEFAULT_PAYMENT_TYPE,
    setValue,
    defaultPaymentType,
    customerPayment,
  ]);

  // set default payment method
  useEffect(() => {
    if (paymentMethods && !defaultPaymentMethod) {
      setDefaultPaymentMethod(
        paymentMethods?.find((x) => x.id === DEFAULT_PAYMENT_METHOD)
      );

      if (!customerPayment?.id) {
        const paymentMethod = paymentMethods?.find(
          (x) => x.id === DEFAULT_PAYMENT_METHOD
        );

        if (paymentMethod) {
          setValue("paymentMethodId", paymentMethod.id);
          setValue("paymentMethod", paymentMethod);
        }
      }
    }
  }, [
    paymentMethods,
    DEFAULT_PAYMENT_METHOD,
    customerPayment,
    defaultPaymentMethod,
    setValue,
  ]);

  const preventEnterKeySubmission = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const target = e.target;
    if (e.key === "Enter" && target instanceof HTMLInputElement) {
      e.preventDefault();
    }
  };

  return (
    <FormContainer
      onKeyDown={preventEnterKeySubmission}
      onSubmit={handleSubmit(async (customerPayment) => {
        customerPayment = customerPayment.id
          ? await patchCustomerPayment(customerPayment as CustomerPayment)
          : await createCustomerPayment(customerPayment as CustomerPayment);
        onSubmitted && onSubmitted(customerPayment as CustomerPayment);
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormAutocomplete
            name="workOrderId"
            label="Work Order Number"
            labelKeys={["workOrderNumber"]}
            valueKey="id"
            options={workOrders ?? []}
            loading={isLoadingWorkOrders || isFetchingWorkOrders}
            control={control}
            defaultValue={customerPayment?.workOrder ?? undefined}
            onChange={(entity) => setWorkOrderId(entity.id)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            name="amount"
            label="Amount"
            control={control}
            type="number"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormDatePicker
            control={control}
            name="paymentDate"
            label="Payment Date"
            trigger={trigger}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField name="note" label="Note" control={control} fullWidth />
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mt: 1 }}
      >
        {paymentTypes && defaultPaymentType && (
          <FormArrayObjectSelect
            name="paymentTypeId"
            label="Payment Type"
            data={paymentTypes}
            control={control}
            labelKey="name"
            valueKey="id"
            defaultValue={
              (customerPayment && customerPayment?.paymentType?.id) ??
              defaultPaymentType.id
            }
          />
        )}
        {paymentMethods && defaultPaymentMethod && (
          <FormArrayObjectSelect
            name="paymentMethodId"
            label="Payment Method"
            data={paymentMethods}
            control={control}
            labelKey="name"
            valueKey="id"
            defaultValue={
              (customerPayment && customerPayment?.paymentMethod?.id) ??
              defaultPaymentMethod.id
            }
          />
        )}
      </Stack>
      <FormSubmitButton
        text={customerPayment?.id ? "Edit Payment" : "Save Payment"}
        disabled={isSubmitting}
      />
      {invoice && (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          style={{ marginTop: 15, fontWeight: "bold" }}
        >
          <div>Invoice Total: {invoice.grandTotal.toFixed(2)}</div>
          <div>
            Total Paid: {sumBy(customerPayments, (s) => s.amount).toFixed(2)}
          </div>
          <div>
            Total Owing:{" "}
            {Number(
              invoice.grandTotal - sumBy(customerPayments, (s) => s.amount)
            ).toFixed(2)}
          </div>
        </Stack>
      )}

      {customer && (
        <>
          <h3>Payment History</h3>
          <GenericDataGrid
            rows={customerPayments ?? []}
            columns={customerPaymentColumns}
            loading={false}
            sx={{ width: 1000, height: 300, mt: 1 }}
            disableExport
            disableColumnsButton
            disableQuickFilter
          />
        </>
      )}
    </FormContainer>
  );
};
