import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Stack } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  open: boolean;
  title: string;
  children?: JSX.Element;
  showWarning?: boolean;
  buttonOptions: string[];
  onClose: (selection?: string) => void;
}

export const ModalPrompt = (props: Props) => {
  const { open, title, children, onClose, showWarning, buttonOptions } = props;
  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box sx={style}>
        <Stack direction="row">
          {showWarning && <WarningAmberIcon fontSize="large" />}
          <Typography variant="h5">{title}</Typography>
        </Stack>
        {children}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          {buttonOptions.map((buttonOption, index) => {
            return (
              <Button
                key={index}
                variant="contained"
                onClick={() => onClose(buttonOption)}
              >
                {buttonOption}
              </Button>
            );
          })}
        </Stack>
      </Box>
    </Modal>
  );
};
