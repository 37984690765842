import { useQuery } from "react-query";

import { InvoiceDrawRequest } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useInvoiceDrawRequestsByInvoice = (invoiceId?: string) => {
  return useQuery<InvoiceDrawRequest[], Error>(
    [RQ.INVOICE_DRAW_REQUESTS, invoiceId],
    async () => await getSingle(`invoice-draw-requests/invoice/${invoiceId}`),
    {
      enabled: Boolean(invoiceId),
    }
  );
};
