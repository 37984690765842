import * as yup from "yup";

export const customerEquipmentSchema = yup.object().shape({
  name: yup.string().required().label("Equipment Name"),
  description: yup.string().label("Description").nullable(),
  model: yup.string().label("Model").nullable(),
  sku: yup.string().label("SKU").nullable(),
  vendor: yup.string().label("Vendor").nullable(),
  serialNumber: yup.string().label("Serial Number").nullable(),
  warrantyPartsStartDate: yup
    .date()
    .label("Warranty Parts Start Date")
    .nullable(),
  warrantyPartsExpiryDate: yup
    .date()
    .label("Warranty Parts Expiry Date")
    .nullable(),
  warrantyLabourStartDate: yup
    .date()
    .label("Warranty Labour Start Date")
    .nullable(),
  warrantyLabourExpiryDate: yup
    .date()
    .label("Warranty Labour Expiry Date")
    .nullable(),
  customerId: yup.string().required().label("Customer"),
});
