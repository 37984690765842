import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";

export const workOrderTimelineColumns: GridColDef[] = [
  {
    field: "event",
    headerName: "Event",
    flex: 1,
  },
  {
    field: "workOrderState",
    headerName: "Work Order State",
    flex: 1,
    valueGetter: (params) =>
      params.row.workOrderState ? params.row.workOrderState.name : "",
  },
  {
    field: "note",
    headerName: "Note",
    flex: 1,
  },
  {
    field: "createdTimestamp",
    headerName: "Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.createdTimestamp
        ? format(parseISO(params.row.createdTimestamp), "PPpp")
        : "",
  },
  {
    field: "employee",
    headerName: "Updated By",
    flex: 1,
    valueGetter: (params) =>
      params.row.employee
        ? `${params.row.employee.firstName} ${params.row.employee.lastName}`
        : "",
  },
];
