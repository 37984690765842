import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { isEqual } from "lodash";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";

import { WorkOrderDocument } from "../../../models";
import { workOrderDocumentSchema as schema } from "./work-order-document.schema";
import { useAddWorkOrderDocument } from "../../../hooks/work-order-document";
import { FormContainer } from "../../layout";
import {
  FormFileSelector,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { FilesSelector } from "../../ui";

interface Props {
  workOrderId: string;
  onSubmitted?: () => void;
}

interface FormData {
  workOrderDocuments: WorkOrderDocument[];
}

const workOrderDocumentsSchema = yup.object().shape({
  workOrderDocuments: yup.array().of(schema).min(1),
});

export const WorkOrderDocumentsForm = (props: Props) => {
  const { workOrderId, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createWorkOrderDocument } = useAddWorkOrderDocument();
  const {
    control,
    watch,
    handleSubmit,
    formState: { defaultValues },
  } = useForm<FormData>({
    resolver: yupResolver(workOrderDocumentsSchema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "workOrderDocuments",
    control,
  });

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (form) => {
        for (const workOrderDocument of form.workOrderDocuments) {
          workOrderDocument.workOrderId = workOrderId;
          await createWorkOrderDocument(workOrderDocument);
        }
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <FilesSelector
        onFilesSelected={(files) => {
          files.forEach((file) => {
            append({
              file: file,
              name: file.name,
            } as WorkOrderDocument);
          });
        }}
      />
      {fields &&
        fields.map((field, index) => {
          return (
            <Box key={index}>
              <Divider />
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <b>{index + 1}</b>
                <FormFileSelector
                  name={`workOrderDocuments.${index}.file`}
                  control={control}
                />
                <FormTextField
                  name={`workOrderDocuments.${index}.name`}
                  label="Name"
                  control={control}
                  required
                />
                <FormTextField
                  name={`workOrderDocuments.${index}.note`}
                  label="Note"
                  control={control}
                />
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
          );
        })}
      <FormSubmitButton />
    </FormContainer>
  );
};
