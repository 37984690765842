import { useQuery } from "react-query";

import { WorkOrderTimeline } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { orderBy } from "lodash";

export const useWorkOrderTimelinesByWorkOrder = (
  workOrderId?: string | null
) => {
  return useQuery<WorkOrderTimeline[], Error>(
    [RQ.WORK_ORDER_TIMELINES, workOrderId],
    () => getAll(`work-order-timelines/work-order/${workOrderId}`),
    {
      enabled: Boolean(workOrderId),
      select: (workOrderTimelines) =>
        orderBy(workOrderTimelines, ["createdTimestamp"], "desc"),
    }
  );
};
