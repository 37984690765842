import { useQuery } from "react-query";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";
import { orderBy } from "lodash";

export const useUnscheduledWorkOrder = () => {
  return useQuery<WorkOrder[], Error>(
    [RQ.WORK_ORDERS_UNSCHEDULED],
    () => getAll<WorkOrder>("work-orders/unscheduled"),
    {
      select: (workOrders) => {
        return orderBy(workOrders, ["workOrderNumber"], "desc");
      },
    }
  );
};
