import { Box, BoxProps } from "@mui/material";

export const FormContainer = (props: BoxProps) => {
  const { children, ...rest } = props;
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { mt: 1, mb: 1 },
      }}
      noValidate
      autoComplete="off"
      {...rest}
    >
      {children}
    </Box>
  );
};
