import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";

import { InvoiceCategoryDataGrid } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { LoadingSpinner } from "../../components/ui";
import { useInvoiceCategories } from "../../hooks/invoice-category";

export const InvoiceCategoriesPage = () => {
  const {
    data: invoiceCategories,
    isLoading: isLoadingInvoiceCategories,
    isIdle,
    isError,
    error,
  } = useInvoiceCategories();
  const navigate = useNavigate();

  if (isLoadingInvoiceCategories || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title="Invoice Categories"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <InvoiceCategoryDataGrid
        rows={invoiceCategories}
        loading={isLoadingInvoiceCategories}
        disableColumnsButton
        disableExport
        disableQuickFilter
        autoHeight
      />
    </>
  );
};
