import { useQuery } from "react-query";
import { downloadFile } from "../../api/generic-api";
import { CompanyDocument } from "../../models";

export const useDownloadCompanyDocument = (
  companyDocument?: CompanyDocument,
  download: boolean = false
) => {
  return useQuery<any, Error>(
    [],
    async () =>
      downloadFile(
        `documents/company-document/download/${companyDocument?.id}`,
        companyDocument?.name ?? "file"
      ),
    {
      enabled: Boolean(download && companyDocument),
    }
  );
};
