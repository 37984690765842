import {
  Box,
  Button,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  AddressDisplay,
  AutoSearch,
  BasicAccordion,
  ConfirmationModal,
  DisplayCustomer,
  LoadingSpinner,
  PaymentModal,
} from "../../components/ui";
import {
  AddressDataGrid,
  ContactDataGrid,
  CustomerEquipmentDataGrid,
  CustomerNoteDataGrid,
  CustomerPaymentDataGrid,
  InvoiceDataGrid,
  InvoiceDrawRequestDataGrid,
  ProposalDataGrid,
  WorkOrderDataGrid,
} from "../../components/grids";
import { useCustomer, useCustomerBalance } from "../../hooks/customer";
import { useCustomerPaymentsByFilter } from "../../hooks/customer-payment";
import { useInvoicesByFilter } from "../../hooks/invoice";
import {
  useWorkOrdersByCustomer,
  useWorkOrdersSearch,
} from "../../hooks/work-order";
import { useProposalsByCustomer } from "../../hooks/proposal";
import { useCustomerNotesByCustomer } from "../../hooks/customer-notes";
import { useContactsByCustomer } from "../../hooks/contact";
import { useEffect, useState } from "react";
import {
  Address,
  CustomerDocument,
  CustomerPayment,
  Invoice,
  WorkOrder,
} from "../../models";
import { useInvoiceDrawRequestsByInvoice } from "../../hooks/invoice-draw-request";
import { APPLICATION_SETTING } from "../../globals/enums";
import { CustomerDocumentDataGrid } from "../../components/grids";
import {
  useCustomerDocumentsByDateRange,
  useDownloadCustomerDocument,
} from "../../hooks/customer-document";
import { useAppSettingsContext } from "../../contexts";
import { useCustomerEquipmentsByCustomer } from "../../hooks/customer-equipment";
import {
  useQuickBooksCreateCustomer,
  useQuickBooksCustomerInvoices,
} from "../../hooks/quick-books";
import { QuickBooksDataGrid } from "../../components/grids";

export const CustomerPage = () => {
  const { id } = useParams<{ id: string }>();
  const [workOrderId, setWorkOrderId] = useState("");
  const [workOrderIdForInvoice, setWorkOrderIdForInvoice] = useState("");
  const [paidStatus, setPaidStatus] = useState<string | undefined>("unpaid");
  const { getSetting } = useAppSettingsContext();
  const BILLING_ADDRESS_TYPE = getSetting(
    APPLICATION_SETTING.BILLING_ADDRESS_TYPE
  ).settingId;
  const {
    data: customer,
    isLoading,
    isIdle,
    isError,
    error,
  } = useCustomer(id ?? "");
  const [customerDocument, setCustomerDocument] = useState<CustomerDocument>();
  const [download, setDownload] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [openCustomerPaymentModal, setOpenCustomerPaymentModal] =
    useState(false);
  const { data: workOrders } = useWorkOrdersByCustomer(id);
  const { data: customerEquipment, isLoading: isLoadingCustomerEquipment } =
    useCustomerEquipmentsByCustomer(id);
  const { data: customerPayments, refetch: getCustomerPayments } =
    useCustomerPaymentsByFilter(id, workOrderId);
  const { data: proposals, isLoading: isLoadingProposals } =
    useProposalsByCustomer(id);
  const { data: customerBalance, refetch: getCustomerBalance } =
    useCustomerBalance(id);
  const { data: quickBookInvoices, isLoading: isLoadingQuickBookInvoices } =
    useQuickBooksCustomerInvoices(id);
  const { mutateAsync: createQuickBooksInvoice } =
    useQuickBooksCreateCustomer();
  const navigate = useNavigate();
  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    refetch: getInvoices,
  } = useInvoicesByFilter(
    null,
    null,
    [],
    workOrderIdForInvoice,
    id,
    paidStatus
  );
  const { data: customerNotes, isLoading: isLoadingCustomerNotes } =
    useCustomerNotesByCustomer(id);
  const { data: contacts, isLoading: contactsLoading } = useContactsByCustomer(
    id || ""
  );
  const { data: customerDocuments, isLoading: customerDocumentsLoading } =
    useCustomerDocumentsByDateRange(id || "");
  const [billingAddress, setBillingAddress] = useState<Address>();
  const [invoice, setInvoice] = useState<Invoice>();
  const { data: invoiceDrawRequests } = useInvoiceDrawRequestsByInvoice(
    invoice?.id
  );
  const { refetch: downloadCustomerDocument } = useDownloadCustomerDocument(
    customerDocument,
    download
  );

  useEffect(() => {
    download && customerDocument && downloadCustomerDocument();
    setDownload(false);
  }, [download, customerDocument, downloadCustomerDocument]);

  useEffect(() => {
    if (customer && customer.addresses && BILLING_ADDRESS_TYPE) {
      setBillingAddress(
        customer.addresses.find(
          (x) => x.addressType?.id === BILLING_ADDRESS_TYPE
        )
      );
    }
  }, [customer, BILLING_ADDRESS_TYPE]);

  useEffect(() => {
    getCustomerPayments();
  }, [workOrderId, id, getCustomerPayments]);

  useEffect(() => {
    getInvoices();
  }, [workOrderIdForInvoice, id, paidStatus, getInvoices]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <Box sx={{ mt: 1 }}>
      {customer && customer.inactive && (
        <Typography color="red" variant="h4" align="center">
          Customer Is Inactive
        </Typography>
      )}
      <Stack direction="row">
        <Paper sx={{ width: 700, p: 2, mb: 2, mr: 2 }}>
          <DisplayCustomer customer={customer} />
        </Paper>
        {billingAddress && (
          <Paper sx={{ width: 200, p: 2, mb: 2, mr: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              <b>Billing Address</b>
            </Typography>
            <AddressDisplay address={billingAddress} />
          </Paper>
        )}
        <Paper sx={{ width: 400, p: 2, mb: 2, mr: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            <b>Payment Status</b>
          </Typography>
          {<>Outstanding Balance $ {customerBalance?.toFixed(2)}</>}
          <br />
          <Button
            onClick={() => navigate(`/statements?customerId=${id}`)}
            variant="outlined"
            sx={{ mt: 2 }}
          >
            Prepare Statement
          </Button>
          <br />
          <Button
            onClick={() => setOpenCustomerPaymentModal(true)}
            variant="outlined"
            sx={{ mt: 2 }}
            endIcon={<AttachMoneyIcon />}
          >
            Make Payment
          </Button>
          {!Boolean(customer.customerQuickBooksDetails?.length > 0) && (
            <Button
              onClick={() => setConfirmationModal(true)}
              variant="outlined"
              sx={{ mt: 2 }}
              endIcon={<CloudUploadIcon />}
            >
              Transfer Customer To QuickBooks
            </Button>
          )}
        </Paper>
      </Stack>
      <BasicAccordion title={`Addresses - ${customer.addresses?.length}`}>
        <AddressDataGrid
          rows={customer.addresses ?? []}
          customer={customer}
          loading={isLoading}
        />
      </BasicAccordion>
      <BasicAccordion title={`Contacts - ${customer.contacts?.length}`}>
        <ContactDataGrid
          rows={contacts ?? []}
          loading={contactsLoading}
          customerId={customer.id}
        />
      </BasicAccordion>
      <BasicAccordion title={`Customer Notes - ${customerNotes?.length}`}>
        <CustomerNoteDataGrid
          rows={customerNotes ?? []}
          customer={customer}
          loading={isLoadingCustomerNotes}
        />
      </BasicAccordion>
      <BasicAccordion
        title={`Customer Equipment - ${customerEquipment?.length}`}
      >
        <CustomerEquipmentDataGrid
          rows={customerEquipment ?? []}
          customer={customer}
          loading={isLoadingCustomerEquipment}
          sx={{ height: 300 }}
        />
      </BasicAccordion>
      <BasicAccordion title={`Customer Payments - ${customerPayments?.length}`}>
        <>
          {workOrders && (
            <AutoSearch
              data={workOrders}
              label="Work Order Search..."
              keys={["workOrderNumber"]}
              onOptionSelected={(workOrder: WorkOrder | null) =>
                workOrder ? setWorkOrderId(workOrder.id) : setWorkOrderId("")
              }
              searchFunction={useWorkOrdersSearch}
              separator="|"
              sx={{ mb: 1, width: 300 }}
            />
          )}
          <CustomerPaymentDataGrid
            rows={customerPayments ?? []}
            customer={customer}
            loading={isLoading}
            onSubmitted={() => getCustomerBalance()}
            onView={(customerPayment) =>
              navigate(
                `/invoice/payment-receipt/view/${customerPayment.workOrder.id}`
              )
            }
          />
        </>
      </BasicAccordion>
      <BasicAccordion title={`Invoice - ${invoices?.length}`}>
        <>
          <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
            {workOrders && (
              <AutoSearch
                data={workOrders}
                label="Work Order Search..."
                keys={["workOrderNumber"]}
                onOptionSelected={(workOrder: WorkOrder | null) =>
                  workOrder
                    ? setWorkOrderIdForInvoice(workOrder.id)
                    : setWorkOrderIdForInvoice("")
                }
                searchFunction={useWorkOrdersSearch}
                separator="|"
                sx={{ width: 300 }}
              />
            )}
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={paidStatus}
              onChange={(_, value) => setPaidStatus(value)}
            >
              {["all", "paid", "unpaid"].map((option, index) => (
                <ToggleButton value={option} key={index}>
                  {option}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <InvoiceDataGrid
            rows={invoices ?? []}
            loading={isLoadingInvoices}
            onView={(invoice) => navigate(`/invoices/view/${invoice.id}`)}
            onLink={(invoice) =>
              navigate(
                `/invoices/edit/${invoice.id}?workOrderId=${invoice.workOrderId}`
              )
            }
            onClick={(invoice) => setInvoice(invoice)}
            disableColumnsButton
            disableExport
            disableQuickFilter
            autoHeight
          />
          {invoice && (
            <InvoiceDrawRequestDataGrid
              sx={{ mt: 2 }}
              rows={invoiceDrawRequests ?? []}
              onView={(invoice) =>
                navigate(`/invoice-draw-request/view/${invoice.id}`)
              }
              title="Invoice Draw Request"
              invoiceId={invoice.id}
              loading={false}
              disableColumnsButton
              disableExport
              disableQuickFilter
              autoHeight
            />
          )}
        </>
      </BasicAccordion>
      <BasicAccordion title={`Work Orders - ${workOrders?.length}`}>
        <WorkOrderDataGrid
          rows={workOrders ?? []}
          loading={isLoading}
          onLink={(workOrder) =>
            navigate(
              `/work-order/${workOrder.id}?customerId=${customer.id}&workOrderNumber=${workOrder.workOrderNumber}&workOrderId=${workOrder.id}`
            )
          }
          disableColumnsButton
          disableExport
          disableQuickFilter
          autoHeight
        />
      </BasicAccordion>
      <BasicAccordion title={`Proposals - ${proposals?.length}`}>
        <ProposalDataGrid
          rows={proposals ?? []}
          loading={isLoadingProposals}
          onLink={(proposal) => navigate(`/proposals/edit/${proposal.id}`)}
          disableColumnsButton
          disableExport
          disableQuickFilter
          autoHeight
        />
      </BasicAccordion>
      <BasicAccordion
        title={`Customer Documents - ${customerDocuments?.length}`}
      >
        <CustomerDocumentDataGrid
          rows={customerDocuments ?? []}
          customer={customer}
          loading={customerDocumentsLoading}
          onDownload={(customerDocument) => {
            setCustomerDocument(customerDocument);
            setDownload(true);
          }}
          autoHeight
        />
      </BasicAccordion>
      <PaymentModal
        open={openCustomerPaymentModal}
        onSubmitted={() => setOpenCustomerPaymentModal(false)}
        onClose={() => {
          setOpenCustomerPaymentModal(false);
        }}
        customerPayment={
          {
            customerId: id,
          } as CustomerPayment
        }
      />
      <BasicAccordion
        title={`QuickBooks Invoices - ${quickBookInvoices?.length ?? 0}`}
      >
        <QuickBooksDataGrid
          rows={quickBookInvoices ?? []}
          loading={isLoadingQuickBookInvoices}
          onLink={(invoice) =>
            navigate(
              `/invoices/edit/${invoice.id}?workOrderId=${invoice.workOrderId}`
            )
          }
          onClick={(invoice) => setInvoice(invoice)}
          disableColumnsButton
          disableExport
          disableQuickFilter
          autoHeight
        />
      </BasicAccordion>
      <ConfirmationModal
        title={"Are You Sure You Want Transfer Customer To QuickBooks"}
        open={confirmationModal}
        onClose={(selection: boolean) => {
          selection && createQuickBooksInvoice(customer.id);
          setConfirmationModal(false);
        }}
      />
    </Box>
  );
};
