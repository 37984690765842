import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Vendor } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchVendor = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (vendor: Vendor) =>
      patch({
        route: "vendors",
        id: vendor.id ?? "",
        entity: vendor,
      }),
    {
      onSuccess: (vendor) => {
        enqueueSnackbar("Vendor Updated", { variant: "success" });
        queryClient.setQueryData(RQ.VENDORS, (vendors: Vendor[] | undefined) =>
          !vendors ? [] : patchEntity<Vendor>(vendors, vendor, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Vendor Updating Error", { variant: "error" });
      },
    }
  );
};
