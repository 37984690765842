import { useQuery } from "react-query";

import { CustomerAddress } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useCustomerSearch = (searchTerm: string) => {
  return useQuery<CustomerAddress[], Error>(
    [RQ.CUSTOMERS_SEARCH, searchTerm],
    () => getAll(`customers/search?searchTerm=${searchTerm}`),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
