import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { useInvoice } from "../../hooks/invoice/useInvoice";
import {
  EmailModal,
  JobDescription,
  LegalDisclaimer,
  LoadingSpinner,
} from "../../components/ui";
import { usePatchInvoice } from "../../hooks/invoice";
import { InvoiceCategorySection } from "./components/InvoiceCategorySection";
import { InvoiceIndividualSection } from "./components/InvoiceIndividualSection";
import { useWorkOrderByInvoice } from "../../hooks/work-order";
import { useSendInvoice } from "../../hooks/email/useSendInvoice";
import { EmailPayload } from "../../models";
import { useAuth } from "../../contexts/AuthContext";
import { InvoiceTotalFooter } from "./components/InvoiceTotalFooter";
import { ModalContainer } from "../../components/ui";
import { APPLICATION_SETTING } from "../../globals/enums";
import { useAppSettingsContext } from "../../contexts";
import { useEmployeeProfile } from "../../hooks/employee";
import config from "../../config/app-config";
import { WorkOrderStateModal } from "../work-orders/modals";
import { CustomerEquipmentSection } from "./components/CustomerEquipmentSection";
import { Role } from "../../globals/enums";
import { useQuickBooksCreateInvoice } from "../../hooks/quick-books";

export const InvoiceView = () => {
  const { id } = useParams<{ id: string }>();
  const { hasRole } = useAuth();
  const [workOrderState, setWorkOrderState] = useState("");
  const { getSetting } = useAppSettingsContext();
  const { data: employee } = useEmployeeProfile();
  const { data: invoice, isLoading, isIdle, isError } = useInvoice(id ?? "");
  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    isIdle: isIdleWorkOrder,
    isError: isErrorWorkOrder,
    refetch: getWorkOrder,
  } = useWorkOrderByInvoice(id);
  const { mutateAsync: patchInvoice } = usePatchInvoice();
  const { mutateAsync: sendInvoice } = useSendInvoice();
  const { mutateAsync: sendQuickBooksInvoice } = useQuickBooksCreateInvoice();
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [invoiceLegal] = useState(
    getSetting(APPLICATION_SETTING.INVOICE_LEGAL_DISCLAIMER)
      .defaultValue as string
  );
  const [companyAddress] = useState(
    getSetting(APPLICATION_SETTING.COMPANY_ADDRESS).defaultValue as string
  );
  const [categoryView, setCategoryView] = useState(
    invoice?.categoryView ?? false
  );
  const [showJobDescription, setShowJobDescription] = useState(
    invoice?.showJobDescription ?? false
  );
  const navigate = useNavigate();
  const { email } = useAuth();

  useEffect(() => {
    invoice && setCategoryView(invoice.categoryView);
  }, [invoice]);

  useEffect(() => {
    workOrder && setWorkOrderState(workOrder?.workOrderState?.name);
  }, [workOrder]);

  if ((isLoading || isLoadingWorkOrder) && (isIdle || isIdleWorkOrder) && id) {
    return <LoadingSpinner />;
  }

  if (isError || isErrorWorkOrder) {
    return <h2>Error</h2>;
  }

  const sendEmail = async (emailPayload: EmailPayload) => {
    if (id && workOrder && emailPayload) {
      const sent =
        emailPayload.to.includes(workOrder.customer.email ?? "") ?? false;
      emailPayload.from = email();
      await patchInvoice({ id, sent, categoryView, showJobDescription });
      invoice &&
        workOrder &&
        sendInvoice({ invoiceId: invoice.id, emailPayload });
    }
    setOpenEmailModal(false);
  };

  const print = () => {
    let printContents = document.getElementById("printsection")?.innerHTML;
    let originalContents = document.body.innerHTML;
    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
    }
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <>
      {invoice && workOrder && (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 2, mb: 1, width: "80%" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                      variant="contained"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => navigate(`/invoices/edit/${invoice.id}`)}
                      variant="contained"
                      sx={{ ml: 2 }}
                    >
                      Edit Invoice
                    </Button>
                    <Button onClick={print} variant="contained">
                      Print
                    </Button>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event) =>
                              setCategoryView(event.target.checked)
                            }
                            defaultChecked={invoice.categoryView}
                          />
                        }
                        label="Invoice View"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={(event) =>
                              setShowJobDescription(event.target.checked)
                            }
                            defaultChecked={invoice.showJobDescription}
                          />
                        }
                        label="Show Job Description"
                      />
                    </FormGroup>
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Customer Invoice</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    endIcon={<EmailIcon />}
                    onClick={() => setOpenEmailModal(true)}
                  >
                    Prepare Email
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 2, mb: 1, width: "80%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <WorkOrderStateModal
                    workOrder={workOrder}
                    onSubmit={(updatedWorkOrder) =>
                      setWorkOrderState(updatedWorkOrder?.workOrderState?.name)
                    }
                  />
                  - Current Work Order State: <b>{workOrderState}</b>
                </Box>
                <Box>
                  {!invoice.quickbooksInvoiceId && (
                    <>
                      {hasRole([Role.Admin]) && (
                        <Button
                          variant="contained"
                          onClick={async () => {
                            await sendQuickBooksInvoice({
                              invoiceId: invoice.id,
                              customerId: workOrder.customer.id,
                            });
                            getWorkOrder();
                          }}
                        >
                          Send To QuickBooks
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Stack>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 7, width: "80%" }} id="printsection">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img
                        src={`${config.COMPANY_IMAGE_URL}/logo.png`}
                        width={200}
                        alt="logo"
                      />
                    </Grid>
                    <Grid item>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: companyAddress,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    sx={{ width: 250 }}
                  >
                    <Box sx={{ textAlign: "right" }}>
                      <Typography variant="h4" gutterBottom>
                        Invoice
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      {format(new Date(), "MMM dd, yyyy")}
                    </Box>
                    <Grid container sx={{ textAlign: "right" }}>
                      <Grid item xs={6}>
                        Invoice #
                      </Grid>
                      <Grid item xs={6}>
                        {workOrder?.workOrderNumber}
                      </Grid>
                      <Grid item xs={6}>
                        Due:
                      </Grid>
                      <Grid item xs={6}>
                        {invoice?.dueDate &&
                          format(
                            parseISO(invoice?.dueDate?.toString() ?? ""),
                            "MMM dd, yyyy"
                          )}
                      </Grid>
                      <Grid item xs={6}>
                        Call Date:
                      </Grid>
                      <Grid item xs={6}>
                        {workOrder?.callDate &&
                          format(
                            parseISO(workOrder?.callDate?.toString() ?? ""),
                            "MMM dd, yyyy"
                          )}
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3 }}
              >
                <Grid item>
                  <Typography variant="h6">Bill To</Typography>
                  {workOrder?.customer?.firstName}{" "}
                  {workOrder?.customer?.lastName}
                  <br />
                  {workOrder?.billingAddress?.streetNumber}{" "}
                  {workOrder?.billingAddress?.streetName} <br />
                  {workOrder?.billingAddress?.city}{" "}
                  {workOrder?.billingAddress?.province}{" "}
                  {workOrder?.billingAddress?.postalCode} <br />
                  {workOrder?.customer?.phone}
                </Grid>
                <Grid item>
                  <Typography variant="h6">Job Site</Typography>
                  {workOrder?.customer?.firstName}{" "}
                  {workOrder?.customer?.lastName}
                  <br />
                  {workOrder?.siteAddress?.streetNumber}{" "}
                  {workOrder?.siteAddress?.streetName} <br />
                  {workOrder?.siteAddress?.city}{" "}
                  {workOrder?.siteAddress?.province}{" "}
                  {workOrder?.siteAddress?.postalCode}{" "}
                </Grid>
              </Grid>
              {showJobDescription && (
                <JobDescription description={workOrder?.jobDescription} />
              )}
              {workOrder.customerEquipments &&
                workOrder.customerEquipments.length > 0 && (
                  <CustomerEquipmentSection workOrder={workOrder} />
                )}
              {categoryView && <InvoiceCategorySection invoice={invoice} />}
              {!categoryView && <InvoiceIndividualSection invoice={invoice} />}
              <br />
              <InvoiceTotalFooter invoice={invoice} />
              <LegalDisclaimer body={invoiceLegal} sx={{ mt: 5 }} />
            </Paper>
          </Box>
          <ModalContainer
            open={openEmailModal}
            title="Prepare Email"
            handleClose={() => setOpenEmailModal(false)}
          >
            <EmailModal
              onSubmit={(emailPayload) => sendEmail(emailPayload)}
              defaultSearchTerm={workOrder.customer.email}
              customer={workOrder.customer}
              defaultBody={employee?.signOff}
            />
          </ModalContainer>
        </>
      )}
    </>
  );
};
