import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { CustomerNote } from "../../../models";
import { customerNotesSchema as schema } from "../schemas";
import {
  useAddCustomerNote,
  usePatchCustomerNote,
} from "../../../hooks/customer-notes";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";

interface Props {
  customerNote?: CustomerNote;
  onSubmitted?: (customerNote: CustomerNote) => void;
}

export const CustomerNoteForm = (props: Props) => {
  const { customerNote, onSubmitted } = props;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<CustomerNote>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(customerNote),
  });
  const { mutateAsync: createCustomerNote } = useAddCustomerNote();
  const { mutateAsync: patchCustomerNote } = usePatchCustomerNote();

  return (
    <FormContainer
      onSubmit={handleSubmit(async (customerNote) => {
        customerNote = customerNote.id
          ? await patchCustomerNote(customerNote)
          : await createCustomerNote(customerNote);
        onSubmitted && onSubmitted(customerNote);
        reset();
      })}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
        spacing={2}
      >
        <FormTextField name="note" label="Note" control={control} />
        <FormSubmitButton
          text={customerNote?.id ? "Save Edits" : "Save"}
          disabled={isSubmitting}
        />
      </Stack>
    </FormContainer>
  );
};
