import { Paper } from "@mui/material";
import { LoadingSpinner } from "../../../../components/ui";
import { useOverdueInvoices } from "../../../../hooks/report";
import { ReportHeader } from "../../components/ReportHeader";
import { GenericDataGrid } from "../../../../components/grids";
import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";

export const overdueInvoicesColumns: GridColDef[] = [
  { field: "workOrderNumber", headerName: "WorkOrder", flex: 1 },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "phone", headerName: "Phone", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "invoiceTotal", headerName: "Invoice Total", flex: 1 },
  { field: "paid", headerName: "Paid", flex: 1 },
  { field: "owing", headerName: "Owing", flex: 1 },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.dueDate
        ? format(parseISO(params.row.dueDate), "MMM dd, yyyy")
        : "",
  },
];

export const OverdueInvoicesReport = () => {
  const { data, isLoading, isIdle, isError, error } = useOverdueInvoices();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <ReportHeader title="Overdue Invoices Report" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <GenericDataGrid
          rows={data}
          columns={overdueInvoicesColumns}
          loading={isLoading}
          ignoreFields={["id"]}
          initialState={{
            sorting: {
              sortModel: [{ field: "item", sort: "asc" }],
            },
          }}
          autoHeight
        />
      </Paper>
    </>
  );
};
