import { useQuery } from "react-query";

import { WorkOrderRoleTitle } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useWorkOrderRoleTitles = () => {
  return useQuery<WorkOrderRoleTitle[], Error>(
    RQ.WORK_ORDER_ROLE_TITLES,
    async () => getAll<WorkOrderRoleTitle>("work-order-role-titles")
  );
};
