import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import { AppSetting } from "../../../models";
import { appSettingSchema as schema } from "../schemas";
import { usePatchAppSetting } from "../../../hooks/app-settings";
import { FormContainer } from "../../layout";
import {
  FormSubmitButton,
  FormSwitch,
  FormTextField,
} from "../../form-components";
import { FormAutocomplete } from "../../form-components";
import { RecursiveKeyof } from "../../../types";
import { FormAutocompleteMultiple } from "../../form-components";

interface Props<T> {
  appSetting?: AppSetting;
  options?: T[];
  optionType?: string;
  labelKeys?: RecursiveKeyof<T>[];
  valueKey?: keyof T;
  label: string;
  onSubmitted?: (appSetting: AppSetting) => void;
  onInputChange?: (searchTerm: string) => void;
}

export const AppSettingForm = <T,>(props: Props<T>) => {
  const {
    appSetting,
    options,
    optionType,
    labelKeys,
    valueKey,
    label,
    onSubmitted,
    onInputChange,
  } = props;
  const { mutateAsync: patchAppSetting } = usePatchAppSetting();
  const { control, handleSubmit, watch } = useForm<AppSetting>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...appSetting,
      settingIds: appSetting?.appSettingDetails?.map((x) => x.settingId),
    },
  });
  const watchForm = watch();

  const renderOption = (options: T[], optionType?: string) => {
    switch (optionType) {
      case "multiple":
        return (
          <FormAutocompleteMultiple
            name="settingIds"
            label={label}
            keys={labelKeys as RecursiveKeyof<T>[]}
            valueKey={"id" as keyof T}
            control={control}
            data={options}
            loading={false}
            initialValue={options.filter((x) =>
              watchForm?.settingIds?.includes(x[valueKey as keyof object])
            )}
          />
        );
      case "autocomplete":
        return (
          <FormAutocomplete
            name="settingId"
            valueKey={valueKey}
            control={control}
            options={options}
            loading={false}
            label={label}
            labelKeys={labelKeys as RecursiveKeyof<T>[]}
            defaultValue={
              options.find(
                (x) => x[valueKey as keyof T] === appSetting?.settingId
              ) as T
            }
            onInputChange={onInputChange}
          />
        );
    }
  };

  return (
    <FormContainer
      onSubmit={handleSubmit(async (appSetting) => {
        await patchAppSetting(appSetting);
        onSubmitted && onSubmitted(appSetting);
      })}
    >
      <Stack direction="row" spacing={4}>
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="start"
          spacing={2}
        >
          <Grid item xs={2}>
            {appSetting?.name}
          </Grid>
          <Grid item xs={2}>
            {appSetting?.displayName}
          </Grid>
          <Grid item xs={2}>
            {options ? renderOption(options, optionType) : <>NA</>}
          </Grid>
          <Grid item xs={2}>
            <FormTextField
              name="defaultValue"
              label="Default Value"
              control={control}
              multiline
            />
          </Grid>
          <Grid item xs={2}>
            <FormSwitch name="enabled" label="Enabled" control={control} />
          </Grid>
          <Grid item xs={2}>
            <FormSubmitButton />
          </Grid>
        </Grid>
      </Stack>
    </FormContainer>
  );
};
