import { useQuery } from "react-query";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useWorkOrderByProposal = (proposalId?: string) => {
  return useQuery<WorkOrder, Error>(
    [RQ.WORK_ORDERS, proposalId],
    async () => await getSingle(`work-orders/proposal/${proposalId}`),
    {
      enabled: Boolean(proposalId),
    }
  );
};
