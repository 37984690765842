import { useEffect } from "react";

interface Props {
  url: string;
}

const Favicon = (props: Props) => {
  const { url } = props;
  useEffect(() => {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, [url]);

  return null;
};

export default Favicon;
