import { useQuery } from "react-query";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useWorkOrdersByCustomer = (customerId?: string) => {
  return useQuery<WorkOrder[], Error>(
    [RQ.WORK_ORDERS, customerId],
    async () => await getAll(`work-orders/customer/${customerId}`),
    {
      enabled: Boolean(customerId),
    }
  );
};
