import { StandardTextFieldProps, TextField } from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface Props<K extends FieldValues> extends StandardTextFieldProps {
  name: Path<K>;
  label: string;
  control: Control<K, any>;
  onInputChanged?: (data: string | number) => void;
}

export const FormTextField = <K extends FieldValues>(props: Props<K>) => {
  const { name, label, control, onInputChanged, ...rest } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <TextField
      label={label}
      size="small"
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      value={field.value || field.value === 0 ? field.value : ""}
      inputRef={field.ref}
      onFocus={(event) => {
        if (event.target.type === "number") {
          event.target.select();
        }
      }}
      onChange={(value) => {
        if (value.target.type === "number") {
          field.onChange(Number(value.target.value));
        } else {
          field.onChange(value.target.value);
        }
        onInputChanged && onInputChanged(value.target.value);
      }}
      {...rest}
    />
  );
};
