import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { uploadFile } from "../../api/generic-api";
import { EmailPayload } from "../../models";
import { format } from "date-fns";

export const useSendCustomerStatements = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (payload: {
      startDate: Date;
      endDate: Date;
      customerId?: string;
      paidStatus?: string;
      isOverdue?: string;
      workOrderStates?: string[];
      addresses?: string[];
      excludes?: string[];
      showOverdueCharges?: boolean;
      showDaysOverdue?: boolean;
      emailPayload: EmailPayload;
    }) => {
      const {
        startDate,
        endDate,
        customerId,
        paidStatus,
        isOverdue,
        workOrderStates,
        addresses,
        excludes,
        showOverdueCharges,
        showDaysOverdue,
        emailPayload,
      } = payload;
      let params = new URLSearchParams();
      startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
      endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
      workOrderStates &&
        workOrderStates.length > 0 &&
        params.append("workOrderStates", workOrderStates.join(","));
      addresses &&
        addresses.length > 0 &&
        params.append("addresses", addresses.join(","));
      excludes &&
        excludes.length > 0 &&
        params.append("excludes", excludes.join(","));
      customerId && params.append("customerId", customerId);
      paidStatus && params.append("paidStatus", paidStatus);
      isOverdue && params.append("isOverdue", isOverdue);
      showOverdueCharges &&
        params.append("showOverdueCharges", showOverdueCharges.toString());
      showDaysOverdue &&
        params.append("showDaysOverdue", showDaysOverdue.toString());
      return uploadFile<EmailPayload>(
        `/emails/statements?${params}`,
        emailPayload
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Customer Statements Sent", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Customer Statements Send Error", { variant: "error" });
      },
    }
  );
};
