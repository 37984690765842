import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderNote } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddWorkOrderNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderNote: WorkOrderNote) =>
      add<WorkOrderNote>(
        `work-orders/${workOrderNote.workOrderId}/work-order-notes`,
        workOrderNote
      ),
    {
      onSuccess: (workOrderNote, variables) => {
        enqueueSnackbar("Work Order Note Added", { variant: "success" });
        queryClient.setQueryData<WorkOrderNote[]>(
          [RQ.WORK_ORDER_NOTES, variables.workOrderId],
          (workOrderNotes: WorkOrderNote[] | undefined) => {
            return [...(workOrderNotes ?? []), workOrderNote];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Note Adding Error", { variant: "error" });
      },
    }
  );
};
