import { Outlet } from "react-router-dom";
import { MainContainer } from "./components/layout";
import { Amplify } from "aws-amplify";

import "./assets/css/App.css";
import awsExports from "./aws-exports";
import { Favicon } from "./components/ui";
import config from "./config/app-config";

// Configure
Amplify.configure(awsExports);

function App() {
  return (
    <MainContainer>
      <Favicon url={config.FAVICON_URL} />
      <Outlet />
    </MainContainer>
  );
}

export default App;
