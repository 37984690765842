import { useState } from "react";
import { useDeleteEmployeeTimesheet } from "../../../hooks/employee-timesheet";
import { Employee, EmployeeTimesheet, WorkOrder } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { employeeTimesheetColumns as columns } from "./employee-timesheet.columns";
import { EmployeeTimesheetForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";

interface Props<T> {
  rows: T[];
  workOrder?: WorkOrder;
  employee?: Employee;
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const EmployeeTimesheetDataGrid = (props: Props<EmployeeTimesheet>) => {
  const {
    rows,
    workOrder,
    employee,
    loading,
    onClick,
    onLink,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [employeeTimesheet, setEmployeeTimesheet] =
    useState<EmployeeTimesheet>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteEmployeeTimesheet } = useDeleteEmployeeTimesheet();

  const onEditCreate = (employeeTimesheet?: EmployeeTimesheet) => {
    employeeTimesheet
      ? setEmployeeTimesheet(employeeTimesheet)
      : setEmployeeTimesheet({
          workOrderId: workOrder?.id,
          workOrder,
          employee,
          employeeId: employee?.id,
        } as EmployeeTimesheet);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDelete={(employeeTimesheet) =>
        deleteEmployeeTimesheet(employeeTimesheet.id)
      }
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onDoubleClick={onEditCreate}
      onLink={onLink}
      title={title}
      createTitle="Create Employee Timesheet"
      deleteTitle="Do you want to delete this Employee Timesheet"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${employeeTimesheet ? "Edit" : "Create"} Employee Timesheet`}
      >
        <EmployeeTimesheetForm
          onSubmitted={() => setOpenModal(false)}
          employeeTimesheet={employeeTimesheet}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
