import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export const AccessDenied = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    signOut();
    navigate("/login");
  };

  return (
    <>
      <h3>Access Denied</h3>
      <button onClick={logout}>Log-Out</button>
    </>
  );
};
