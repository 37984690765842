import { useState } from "react";

import { Address, Customer } from "../../../models";
import { ModalContainerChangeDetection } from "../../ui";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { addressColumns } from "./address.columns";
import { AddressForm } from "../../forms";
import { useDeleteAddress } from "../../../hooks/address";
import { useAppSettingsContext } from "../../../contexts";
import { APPLICATION_SETTING } from "../../../globals/enums";

interface Props<T> {
  rows: T[];
  customer: Customer;
  loading: boolean;
  onClick?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
}

export const AddressDataGrid = (props: Props<Address>) => {
  const { rows, customer, loading, onClick, onModalClosed, title } = props;
  const { getSetting } = useAppSettingsContext();
  const [address, setAddress] = useState<Address | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteAddress } = useDeleteAddress();
  const SITE_ADDRESS_TYPE = getSetting(
    APPLICATION_SETTING.SITE_ADDRESS_TYPE
  ).settingId;

  const onEditCreate = (address?: Address) => {
    address
      ? setAddress({ ...address, customerId: customer?.id } as Address)
      : setAddress({ customerId: customer?.id } as Address);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={addressColumns}
      loading={loading}
      onClick={onClick}
      onDelete={(address) =>
        deleteAddress({ ...address, customerId: customer.id })
      }
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      title={title}
      createTitle="Create Address"
      deleteTitle="Do you to delete this Address?"
      ignoreFields={[
        "id",
        "addressType",
        "contacts",
        "createdTimestamp",
        "modifiedTimestamp",
        "addressTypeId",
      ]}
      autoHeight
      disableExport
      disableColumnsButton
      disableQuickFilter
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${address?.id ? "Edit" : "Create"} Address`}
      >
        <AddressForm
          onSubmitted={() => setOpenModal(false)}
          address={address}
          defaultAddressTypeId={SITE_ADDRESS_TYPE}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
