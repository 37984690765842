import { useQuery } from "react-query";

import { PaymentMethod } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const usePaymentMethods = () => {
  return useQuery<PaymentMethod[], Error>(RQ.PAYMENT_METHODS, async () =>
    getAll<PaymentMethod>("payment-methods")
  );
};
