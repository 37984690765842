import * as yup from "yup";
import { addressSchema } from "./address.schema";
import { contactSchema } from "./contact.schema";
import { customerNotesSchema } from "./customer-note.schema";

export const newCustomerSchema = yup.object().shape({
  firstName: yup.string().required().label("First Name").default(""),
  lastName: yup.string().label("Last Name").default(""),
  email: yup.string().email().label("Email").default(""),
  phone: yup.string().label("Phone").default("").required(),
  customerTierId: yup.string().label("Customer Tier").default("").required(),
  addresses: yup.array().of(addressSchema),
  contacts: yup.array().of(contactSchema),
  customerNotes: yup.array().of(customerNotesSchema),
});
