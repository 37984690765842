import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Contact } from "../../../../models";
import { useContactsByCustomer } from "../../../../hooks/contact";

interface Props {
  onSelected: (contact: Contact) => void;
  customerId: string;
}

export const SelectContactsModal = (props: Props) => {
  const { onSelected, customerId } = props;
  const { data: contacts } = useContactsByCustomer(customerId);

  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell align="left">
              <b>Email</b>
            </TableCell>
            <TableCell align="right">Selected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts &&
            contacts.map((contact, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{contact.name}</TableCell>
                <TableCell align="left">{contact.email}</TableCell>
                <TableCell align="right">
                  <Checkbox
                    onChange={(_, checked) => {
                      onSelected(contact);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
