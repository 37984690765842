import * as yup from "yup";

export const workOrderSchema = yup.object().shape({
  callDate: yup.date().default(new Date()).label("Call Date"),
  workOrderStateId: yup
    .string()
    .label("Work Order State")
    .default("")
    .transform((value) => value ?? ""),
  jobDescription: yup
    .string()
    .required()
    .label("Job Description")
    .default("")
    .transform((value) => value ?? ""),
  purchaseOrderNumber: yup
    .string()
    .label("P.O Number")
    .default("")
    .transform((value) => value ?? ""),
  billingAddressId: yup
    .string()
    .required()
    .label("Billing Address")
    .default("")
    .transform((value) => value ?? ""),
  siteAddressId: yup
    .string()
    .required()
    .label("Site Address")
    .default("")
    .transform((value) => value ?? ""),
  customerId: yup
    .string()
    .required()
    .default("")
    .transform((value) => value ?? ""),
  jobTypeIds: yup.array().required().min(1).label("Job Type").default([]),
  customerEquipmentIds: yup.array().label("Customer Equipment").default([]),
  customerEquipments: yup.array().of(yup.object()).default([]),
  workOrderNotes: yup
    .array()
    .of(
      yup.object().shape({
        note: yup.string().required().default(""),
      })
    )
    .default([]),
  workOrderRoles: yup
    .array()
    .of(
      yup.object().shape({
        employeeId: yup
          .string()
          .required()
          .default("")
          .transform((value) => value ?? ""),
        workOrderRoleTitleId: yup
          .string()
          .required()
          .default("")
          .transform((value) => value ?? ""),
      })
    )
    .default([]),
});
