import { useState } from "react";
import { useDeleteCustomerEquipment } from "../../../hooks/customer-equipment";
import { Customer, CustomerEquipment } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { customerEquipmentColumns as columns } from "./customer-equipment.columns";
import { CustomerEquipmentForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";
import { SxProps, Theme } from "@mui/material";

interface Props<T> {
  rows: T[];
  customer: Customer;
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  onChecked?: (row: T, checked: boolean) => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  sx?: SxProps<Theme>;
}

export const CustomerEquipmentDataGrid = (props: Props<CustomerEquipment>) => {
  const {
    rows,
    customer,
    loading,
    onClick,
    onLink,
    onModalClosed,
    onChecked,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
    sx,
  } = props;
  const [customerEquipment, setCustomerEquipment] =
    useState<CustomerEquipment>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteCustomerEquipment } = useDeleteCustomerEquipment();

  const onEditCreate = (customerEquipment?: CustomerEquipment) => {
    customerEquipment
      ? setCustomerEquipment({ ...customerEquipment, customerId: customer.id })
      : setCustomerEquipment({ customerId: customer.id } as CustomerEquipment);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      sx={sx}
      rows={rows}
      columns={columns}
      columnVisibilityModel={{
        warrantyPartsStartDate: false,
        warrantyPartsExpiryDate: false,
        warrantyLabourStartDate: false,
        warrantyLabourExpiryDate: false,
        vendor: false,
      }}
      loading={loading}
      onClick={onClick}
      onDelete={(customerEquipment) =>
        deleteCustomerEquipment(customerEquipment.id)
      }
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={onLink}
      title={title}
      createTitle="Create Customer Equipment"
      deleteTitle="Do you want to delete this Customer Equipment"
      ignoreFields={[
        "id",
        "createdTimestamp",
        "modifiedTimestamp",
        "customer",
        "customerId",
        "workOrder",
        "workOrderId",
        "invoiceLineItem",
        "invoiceLineItemId",
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "desc" }],
        },
      }}
      onChecked={onChecked}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${customerEquipment ? "Edit" : "Create"} Customer Equipment`}
      >
        <CustomerEquipmentForm
          onSubmitted={() => setOpenModal(false)}
          customerEquipment={customerEquipment}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
