import { Typography } from "@mui/material";
import { Address, WorkOrder } from "../../../models";

interface Props {
  address: Address;
  workOrder?: WorkOrder;
  title?: string;
}

export const AddressDisplay = (props: Props) => {
  const { address, workOrder, title } = props;
  return (
    <>
      {title && (
        <Typography variant="subtitle1" gutterBottom>
          <b>{title}</b>
        </Typography>
      )}
      {address?.streetNumber} {address?.streetName} <br />
      {address?.city}, {address?.province} {address?.postalCode} <br />
      {address?.unit ? (
        <>
          Unit: {address?.unit} <br />
        </>
      ) : undefined}
      {address?.buzzCode ? (
        <>
          Buzz Code: {address?.buzzCode} <br />
        </>
      ) : undefined}
      {address?.note ? (
        <>
          Note: {address?.note} <br />
        </>
      ) : undefined}
      {workOrder?.customer?.phone}
    </>
  );
};
