import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useAuth } from "../../../contexts/AuthContext";

interface Login {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().required().label("Email"),
  password: yup.string().required().label("Password"),
});

export const LoginForm = () => {
  const { signIn } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Login>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  return (
    <FormContainer
      onSubmit={handleSubmit(async (login) => {
        signIn(login.email, login.password).then((user: any) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            navigate("/complete-password");
          } else {
            navigate(`/`);
          }
        });
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="email" label="Email" control={control} required />
        <FormTextField
          name="password"
          label="Password"
          control={control}
          required
          type="password"
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} text="Log In" />
    </FormContainer>
  );
};
