import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { Vendor } from "../../../models";
import { vendorSchema as schema } from "../schemas";
import { useAddVendor, usePatchVendor } from "../../../hooks/vendor";
import { FormContainer } from "../../layout";
import {
  FormPhoneNumber,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { AddressSearch } from "../../ui";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  vendor?: Vendor;
  onSubmitted?: () => void;
}

export const VendorForm = (props: Props) => {
  const { vendor, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createVendor } = useAddVendor();
  const { mutate: patchVendor } = usePatchVendor();
  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, defaultValues },
  } = useForm<Vendor>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(vendor),
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit((vendor) => {
        vendor.id ? patchVendor(vendor) : createVendor(vendor);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} />
        <FormPhoneNumber name="phone" label="Phone" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="email" label="Email" control={control} fullWidth />
      </Stack>
      <Stack direction="row" spacing={2}>
        <AddressSearch
          sx={{ width: 460 }}
          label="Address"
          onSelected={(selectedAddress) =>
            reset({ ...selectedAddress }, { keepDirtyValues: true })
          }
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="streetNumber"
          label="Street Number"
          control={control}
        />
        <FormTextField
          name="streetName"
          label="Street Name"
          control={control}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="unit" label="Unit" control={control} />
        <FormTextField
          name="postalCode"
          label="Postal Code"
          control={control}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="city" label="City" control={control} />
        <FormTextField name="province" label="Province" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="buzzCode" label="Buzz Code" control={control} />
        <FormTextField name="country" label="Country" control={control} />
      </Stack>
      <FormSubmitButton disabled={isSubmitting || isSubmitSuccessful} />
    </FormContainer>
  );
};
