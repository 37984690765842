import { useQuery } from "react-query";
import { format } from "date-fns";

import { CompanyDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useCompanyDocumentsByDateRange = (
  startDate: Date,
  endDate?: Date,
  tagGuids?: string[]
) => {
  let params = new URLSearchParams();
  startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
  endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
  tagGuids && tagGuids.length > 0 && params.append("tags", tagGuids.join(","));
  return useQuery<CompanyDocument[], Error>(
    [RQ.COMPANY_DOCUMENTS],
    async () =>
      await getAll(`documents/company-documents/date-range?${params}`),
    {
      enabled: Boolean(startDate && endDate),
    }
  );
};
