import { useQuery } from "react-query";

import { Schedule } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useSchedule = (id?: string) => {
  return useQuery<Schedule, Error>(
    [RQ.SCHEDULES, id],
    () => getSingle(`schedules/${id}`),
    {
      enabled: Boolean(id),
    }
  );
};
