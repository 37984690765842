import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Vendor } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddVendor = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((vendor: Vendor) => add<Vendor>("vendors", vendor), {
    onSuccess: (vendor) => {
      enqueueSnackbar("Vendor Added", { variant: "success" });
      queryClient.setQueryData<Vendor[]>(
        RQ.VENDORS,
        (vendors: Vendor[] | undefined) => {
          return [...(vendors ?? []), vendor];
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Vendor Adding Error", { variant: "error" });
    },
  });
};
