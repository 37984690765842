import { useState } from "react";
import { useDeleteSchedule } from "../../../hooks/schedule";
import {
  EmailPayload,
  Employee,
  Schedule,
  ScheduleType,
  WorkOrder,
} from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { scheduleColumns } from "./schedule.columns";
import { ScheduleForm } from "../../forms";
import {
  EmailModal,
  ModalContainer,
  ModalContainerChangeDetection,
} from "../../ui";
import { useSendCustomerAppointment } from "../../../hooks/email";
import { useAuth } from "../../../contexts/AuthContext";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  autoHeight?: boolean;
  employee?: Employee;
  workOrder?: WorkOrder;
  scheduleType?: ScheduleType;
  workOrderOnly?: boolean;
}

export const ScheduleDataGrid = (props: Props<Schedule>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onModalClosed,
    autoHeight,
    employee,
    workOrder,
    scheduleType,
    workOrderOnly,
  } = props;
  const [schedule, setSchedule] = useState<Schedule | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const { mutate: deleteSchedule } = useDeleteSchedule();
  const { mutateAsync: sendCustomerAppointment } = useSendCustomerAppointment();
  const { email } = useAuth();

  const onEditCreate = (schedule?: Schedule) => {
    schedule
      ? setSchedule(schedule)
      : setSchedule({ employee, workOrder, scheduleType } as Schedule);
    setOpenModal(true);
  };

  const sendEmail = (emailPayload: EmailPayload) => {
    emailPayload.from = email();
    if (schedule) {
      sendCustomerAppointment({ scheduleId: schedule?.id, emailPayload });
    }
    setOpenEmailModal(false);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={scheduleColumns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onDelete={(schedule) => deleteSchedule(schedule.id)}
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      onLink={onLink}
      onSend={(schedule) => {
        setSchedule(schedule);
        setOpenEmailModal(true);
      }}
      createTitle="Create Schedule"
      deleteTitle="Would you like to delete this Schedule?"
      ignoreFields={[
        "id",
        "createdTimestamp",
        "modifiedTimestamp",
        "workOrder",
        "workOrderId",
        "scheduleTypeId",
        "employeeId",
        "employee",
        "scheduleType.id",
        "scheduleType.createdTimestamp",
        "scheduleType.modifiedTimestamp",
        "scheduleType.color",
        "repeatDayIds",
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "startDate", sort: "desc" }],
        },
      }}
      sx={{ height: 300 }}
      autoHeight={autoHeight}
    >
      <>
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            onModalClosed && onModalClosed();
          }}
          title={`${schedule ? "Edit" : "Create"} Schedule`}
        >
          <ScheduleForm
            onSubmitted={() => setOpenModal(false)}
            schedule={schedule}
            workOrderOnly={workOrderOnly}
          />
        </ModalContainerChangeDetection>
        {schedule && schedule.workOrder && (
          <ModalContainer
            open={openEmailModal}
            title="Prepare Email"
            handleClose={() => setOpenEmailModal(false)}
          >
            <EmailModal
              onSubmit={(emailPayload) => sendEmail(emailPayload)}
              defaultSearchTerm={schedule.workOrder.customer.email}
              customer={schedule.workOrder.customer}
            />
          </ModalContainer>
        )}
      </>
    </GenericDataGrid>
  );
};
