import { useQuery } from "react-query";
import { downloadFile } from "../../api/generic-api";
import { format } from "date-fns";

export const useDownloadInvoiceStatements = (
  startDate: Date,
  endDate: Date,
  customerId?: string,
  isPaid?: string,
  isOverdue?: string,
  workOrderStates?: string[],
  addresses?: string[],
  excludes?: string[],
  showOverdueCharges?: boolean,
  showDaysOverdue?: boolean,
  download: boolean = false
) => {
  let params = new URLSearchParams();
  startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
  endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
  workOrderStates &&
    workOrderStates.length > 0 &&
    params.append("workOrderStates", workOrderStates.join(","));
  addresses &&
    addresses.length > 0 &&
    params.append("addresses", addresses.join(","));
  excludes &&
    excludes.length > 0 &&
    params.append("excludes", excludes.join(","));
  customerId && params.append("customerId", customerId);
  isPaid && params.append("isPaid", isPaid);
  isOverdue && params.append("isOverdue", isOverdue);
  showOverdueCharges &&
    params.append("showOverdueCharges", showOverdueCharges.toString());
  showDaysOverdue &&
    params.append("showDaysOverdue", showDaysOverdue.toString());

  return useQuery<any, Error>(
    [],
    async () =>
      downloadFile(
        `invoices/download?${params}`,
        format(new Date(), "yyyy-MM-dd-HH-mm-ss") + "-statements.pdf"
      ),
    {
      enabled: download,
    }
  );
};
