import { useQuery } from "react-query";

import { TaskDuration } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useTaskDurations = () => {
  return useQuery<TaskDuration[], Error>(RQ.TASK_DURATIONS, async () =>
    getAll<TaskDuration>("task-durations")
  );
};
