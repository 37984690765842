import { useQuery } from "react-query";
import axios from "axios";

import { QuickBooksInvoice } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";
import { useCurrentUser } from "../../contexts";

export const useQuickBooksCustomerInvoices = (customerId?: string) => {
  const { setIsQBAuthenticated, setQBAlert } = useCurrentUser();
  return useQuery<QuickBooksInvoice[], Error>(
    [RQ.QUICK_BOOKS_CUSTOMER_INVOICES, customerId],
    async () => await getAll(`quick-books/customers/${customerId}/invoices`),
    {
      enabled: Boolean(customerId),
      onError(error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            setIsQBAuthenticated(false);
            setQBAlert("SHOW");
          }
        }
      },
    }
  );
};
