import { Button, Stack, TextField, Typography } from "@mui/material";
import { WorkOrder, WorkOrderNote, WorkOrderState } from "../../../models";
import { useWorkOrderStates } from "../../../hooks/work-order-state";
import { ModalContainer, SelectDropDown } from "../../../components/ui";
import { useState } from "react";
import { usePatchWorkOrder } from "../../../hooks/work-order";

interface Props {
  workOrder: WorkOrder;
  onSubmit?: (workOrder: WorkOrder) => void;
}

export const WorkOrderStateModal = (props: Props) => {
  const { workOrder, onSubmit } = props;
  const { data: workOrderStates } = useWorkOrderStates();
  const { mutateAsync: patchWorkOrder } = usePatchWorkOrder();
  const [workOrderState, setWorkOrderState] = useState<WorkOrderState>();
  const [note, setNote] = useState<WorkOrderNote>();
  const [open, setOpen] = useState(false);

  const onUpdate = async () => {
    const updatedWorkOrder = await patchWorkOrder({
      id: workOrder.id,
      workOrderStateId: workOrderState?.id,
      workOrderNotes: [note!],
    });
    onSubmit && onSubmit(updatedWorkOrder);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Update Work Order State</Button>
      <ModalContainer
        open={open}
        handleClose={() => setOpen(false)}
        title="Update Work Order State"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Typography variant="h6" gutterBottom>
            {workOrder.workOrderNumber}
          </Typography>
          <SelectDropDown
            data={workOrderStates ?? []}
            idKey="id"
            valueKey="name"
            label="Work Order State"
            initialValue={workOrder.workOrderState}
            onSelected={(selectedWorkOrderState) =>
              setWorkOrderState(selectedWorkOrderState)
            }
            sx={{ width: 400 }}
          />
          <TextField
            size="small"
            label="Add Note..."
            onChange={(event) =>
              setNote({ note: event.target.value } as WorkOrderNote)
            }
            multiline
            sx={{ minWidth: 400 }}
          />
          <Button variant="contained" onClick={onUpdate}>
            Update
          </Button>
        </Stack>
      </ModalContainer>
    </>
  );
};
