import { useQuery } from "react-query";
import { format } from "date-fns";

import { Invoice } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";
import { orderBy } from "lodash";

export const useInvoicesByFilter = (
  startDate?: Date | null,
  endDate?: Date | null,
  workOrderStates?: string[],
  workOrderId?: string,
  customerId?: string,
  paidStatus?: string,
  page: number = 0
) => {
  let params = new URLSearchParams({
    page: page.toString(),
  });
  startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
  endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
  workOrderStates &&
    workOrderStates.length > 0 &&
    params.append("workOrderStates", workOrderStates.join(","));
  customerId && params.append("customerId", customerId);
  paidStatus && params.append("paidStatus", paidStatus);
  workOrderId && params.append("workOrderId", workOrderId);
  return useQuery<Invoice[], Error>(
    [RQ.INVOICES],
    async () => await getAll(`invoices/filter?${params}`),
    {
      // enabled: Boolean(startDate || endDate || page),
      select: (invoices) => {
        invoices.forEach((invoice) => {
          if (invoice.invoiceLineItems && invoice.invoiceLineItems.length > 0) {
            invoice.invoiceLineItems = orderBy(invoice.invoiceLineItems, [
              "ordinal",
            ]);

            invoice.invoiceLineItems.forEach((invoiceLineItem) => {
              invoiceLineItem.inventoryItemId =
                invoiceLineItem.inventoryItem.id;
            });
          }
        });
        return invoices;
      },
    }
  );
};
