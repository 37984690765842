import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InvoiceDrawRequest } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchInvoiceDrawRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (invoiceDrawRequest: InvoiceDrawRequest) =>
      patch({
        route: "invoice-draw-requests",
        id: invoiceDrawRequest.id ?? "",
        entity: invoiceDrawRequest,
      }),
    {
      onSuccess: (invoiceDrawRequest) => {
        enqueueSnackbar("Invoice Draw Request Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.INVOICE_DRAW_REQUESTS, invoiceDrawRequest.invoiceId],
          (invoiceDrawRequests: InvoiceDrawRequest[] | undefined) =>
            !invoiceDrawRequests
              ? []
              : patchEntity<InvoiceDrawRequest>(
                  invoiceDrawRequests,
                  invoiceDrawRequest,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Invoice Draw Request Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
