import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Contact } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteContact = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((contact: Contact) => remove("contacts", contact.id), {
    onSuccess: (_, contact) => {
      enqueueSnackbar("Contact Deleted", { variant: "success" });
      queryClient.setQueryData(
        [RQ.CONTACTS, contact.customerId],
        (contacts: Contact[] | undefined) => {
          return !contacts
            ? []
            : deleteEntity<Contact>(contacts, "id", contact.id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Contact Deleting Error", { variant: "error" });
    },
  });
};
