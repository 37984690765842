import { useQuery } from "react-query";

import { Employee } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useEmployeeSearch = (searchTerm: string) => {
  return useQuery<Employee[], Error>(
    [RQ.EMPLOYEES_SEARCH, searchTerm],
    () => getAll(`employees/search?searchTerm=${searchTerm}`),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
