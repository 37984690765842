import { GridColDef } from "@mui/x-data-grid";

export const customerEquipmentColumns: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1 },
  { field: "model", headerName: "Model", flex: 1 },
  { field: "sku", headerName: "SKU", flex: 1 },
  { field: "serialNumber", headerName: "Serial Number", flex: 1 },
  { field: "vendor", headerName: "Vendor", flex: 1 },
  {
    field: "warrantyPartsStartDate",
    headerName: "Warranty Parts Start",
    flex: 1,
  },
  {
    field: "warrantyPartsExpiryDate",
    headerName: "Warranty Parts Expiry",
    flex: 1,
  },
  {
    field: "warrantyLabourStartDate",
    headerName: "Warranty Labour Start",
    flex: 1,
  },
  {
    field: "warrantyLabourExpiryDate",
    headerName: "Warranty Labour Expiry",
    flex: 1,
  },
];
