import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  DisplayWorkOrder,
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import { ProposalForm } from "../../components/forms";
import { useProposal } from "../../hooks/proposal";
import { useWorkOrder } from "../../hooks/work-order";

export const ProposalPage = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  let workOrderId = searchParams.get("workOrderId");
  const {
    data: proposal,
    isLoading,
    isIdle,
    isError,
    error,
  } = useProposal(id ?? "");
  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    isIdle: isIdleWorkOrder,
  } = useWorkOrder(proposal?.workOrderId ?? workOrderId);
  const [openWorkOrderModal, setOpenWorkOrderModal] = useState(false);
  const navigate = useNavigate();

  if ((isLoading || isIdle) && id) {
    return <LoadingSpinner />;
  }

  if ((isLoadingWorkOrder || isIdleWorkOrder) && workOrderId) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 1 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
                variant="contained"
              >
                Back
              </Button>
              {workOrder && (
                <>
                  <Button
                    onClick={() => setOpenWorkOrderModal(true)}
                    variant="contained"
                  >
                    View Work Order
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/work-order/${workOrder?.id}`)}
                  >
                    Edit Work Order
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {`${proposal ? "Edit" : "Create"} Proposal`}
              {workOrder &&
                workOrder.workOrderNumber &&
                ` - ${workOrder?.workOrderNumber}`}
            </Typography>
          </Grid>
          <Grid item></Grid>
          <Grid item></Grid>
        </Grid>
      </Paper>
      <ProposalForm proposal={proposal} />
      {workOrder && (
        <ModalContainerChangeDetection
          open={openWorkOrderModal}
          handleClose={() => setOpenWorkOrderModal(false)}
          title="Work Order"
        >
          <DisplayWorkOrder workOrder={workOrder} />
        </ModalContainerChangeDetection>
      )}
    </>
  );
};
