import * as yup from "yup";
import { add, isValid } from "date-fns";

export const invoiceDrawRequestSchema = yup.object().shape({
  dueDate: yup
    .date()
    .required()
    .label("Due Date")
    .transform((value) =>
      isValid(value) ? (value as Date) : add(new Date(), { months: 1 })
    )
    .default(add(new Date(), { months: 1 })),
  total: yup.number().label("Total").default(0),
  sent: yup.boolean().default(false),
});
