import { useQuery } from "react-query";

import { Vendor } from "../../models";
import { queryEntity } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useVendorsByQuery = (searchTerm: string) => {
  return useQuery<Vendor[], Error>(
    [RQ.VENDORS_SEARCH],
    async () => queryEntity("vendors", searchTerm),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
