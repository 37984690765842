export enum RQ {
  ADDRESS = "ADDRESS",
  ADDRESSES = "ADDRESSES",

  ADDRESSES_SEARCH = "ADDRESSES_SEARCH",

  ADDRESSES_CUSTOMERS = "ADDRESSES_CUSTOMERS",

  ADDRESS_TYPES = "ADDRESS_TYPES",

  APP_SETTINGS = "APP_SETTINGS",

  CUSTOMER_DOCUMENTS = "CUSTOMER_DOCUMENTS",
  CUSTOMER_DOCUMENT_TAGS = "CUSTOMER_DOCUMENT_TAGS",

  CONTACTS = "CONTACTS",

  CURRENT_USER = "CURRENT_USER",

  CUSTOMERS = "CUSTOMERS",
  CUSTOMERS_SEARCH = "CUSTOMERS_SEARCH",

  CUSTOMER_EQUIPMENT = "CUSTOMER_EQUIPMENT",
  CUSTOMER_EQUIPMENTS = "CUSTOMER_EQUIPMENTS",

  CUSTOMER_NOTES = "CUSTOMER_NOTES",
  CUSTOMER_NOTES_SEARCH = "CUSTOMER_NOTES_SEARCH",
  COMPANY_DOCUMENTS = "COMPANY_DOCUMENTS",

  CUSTOMER_PAYMENTS = "CUSTOMER_PAYMENTS",

  CUSTOMER_TIERS = "CUSTOMER_TIERS",

  CUSTOMER_STATEMENTS = "CUSTOMER_STATEMENTS",

  DOCUMENT = "DOCUMENT",

  EMAIL_SEARCH = "EMAIL_SEARCH",

  EMPLOYEE_HOLIDAYS = "EMPLOYEE_HOLIDAYS",

  EMPLOYEE = "EMPLOYEE",
  EMPLOYEES = "EMPLOYEES",
  EMPLOYEES_SEARCH = "EMPLOYEES_SEARCH",

  EMPLOYEE_TIMESHEETS = "EMPLOYEE_TIMESHEETS",
  EMPLOYEE_TIMESHEET_OPEN = "EMPLOYEE_TIMESHEET_OPEN",

  EMPLOYEE_TYPE = "EMPLOYEE_TYPE",
  EMPLOYEE_TYPES = "EMPLOYEE_TYPES",
  EMPLOYEE_ROLES = "EMPLOYEE_ROLES",

  EMPLOYEE_EMPLOYEE_TYPES = "EMPLOYEE_EMPLOYEE_TYPES",

  INVOICE_DRAW_REQUEST = "INVOICE_DRAW_REQUEST",
  INVOICE_DRAW_REQUESTS = "INVOICE_DRAW_REQUESTS",

  INVOICE = "INVOICE",
  INVOICES = "INVOICES",
  SEND_INVOICE = "SEND_INVOICE",

  INVENTORY_TYPES = "INVENTORY_TYPES",

  INVENTORY_ITEM = "INVENTORY_ITEM",
  INVENTORY_ITEMS = "INVENTORY_ITEMS",
  INVENTORY_ITEMS_SEARCH = "INVENTORY_ITEMS_SEARCH",

  INVOICE_LINE_ITEMS = "INVOICE_LINE_ITEMS",

  INVOICE_CATEGORIES = "INVOICE_CATEGORIES",

  INVOICE_STATEMENT = "INVOICE_STATEMENT",
  INVOICE_STATEMENTS = "INVOICE_STATEMENTS",

  JOB_TYPES = "JOB_TYPES",

  JOB_TYPES_EMPLOYEE_TYPES = "JOB_TYPES_EMPLOYEE_TYPES",

  PAYMENT_METHODS = "PAYMENT_METHODS",
  PAYMENT_TYPES = "PAYMENT_TYPES",

  PROPOSALS = "PROPOSALS",
  PROPOSAL_NOTES = "PROPOSAL_NOTES",
  PROPOSAL_LINE_ITEMS = "PROPOSAL_LINE_ITEMS",

  QUICK_BOOKS_CUSTOMER = "QUICK_BOOKS_CUSTOMER",
  QUICK_BOOKS_CUSTOMERS = "QUICK_BOOKS_CUSTOMERS",
  QUICK_BOOKS_CUSTOMER_INVOICES = "QUICK_BOOKS_CUSTOMER_INVOICES",

  REFERRAL_TYPES = "REFERRAL_TYPES",

  REPEAT_DAYS = "REPEAT_DAYS",

  REPORT_TYPES = "REPORT_TYPES",

  REPORT_BALANCE_SHEET = "REPORT_BALANCE_SHEET",
  REPORT_INVENTORY_USAGE = "REPORT_INVENTORY_USAGE",
  REPORT_EMPLOYEE_HOLIDAY = "REPORT_EMPLOYEE_HOLIDAY",
  REPORT_OVERDUE_INVOICES = "REPORT_OVERDUE_INVOICES",

  SCHEDULES = "SCHEDULES",
  SCHEDULE_TYPES = "SCHEDULE_TYPES",
  HAS_AFTER_HOURS_SCHEDULED = "HAS_AFTER_HOURS_SCHEDULED",

  TASK_DURATIONS = "TASK_DURATIONS",

  WORK_ORDER = "WORK_ORDER",
  WORK_ORDERS = "WORK_ORDERS",
  WORK_ORDER_DOCUMENTS = "WORK_ORDER_DOCUMENTS",
  WORK_ORDERS_UNSCHEDULED = "WORK_ORDERS_UNSCHEDULED",
  WORK_ORDER_COUNT = "WORK_ORDER_COUNT",
  WORK_ORDERS_SEARCH = "WORK_ORDERS_SEARCH",
  WORK_ORDER_NOTES = "WORK_ORDER_NOTES",
  WORK_ORDER_ROLE_TITLES = "WORK_ORDER_ROLE_TITLES",

  WORK_ORDER_JOB_TYPES = "WORK_ORDER_JOB_TYPES",

  VENDORS = "VENDORS",
  VENDORS_SEARCH = "VENDORS_SEARCH",

  WORK_ORDER_STATES = "WORK_ORDER_STATES",

  WORK_ORDER_TIMELINES = "WORK_ORDER_TIMELINES",
}
