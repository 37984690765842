import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, IconButton, InputAdornment, Stack } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { isEqual } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import { CustomerTier, CustomerTierDetail } from "../../../models";
import { customerTierSchema as schema } from "./customer-tier.schema";
import {
  useAddCustomerTier,
  usePatchCustomerTier,
} from "../../../hooks/customer-tier";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { FormAutocomplete } from "../../form-components";
import { useInvoiceCategories } from "../../../hooks/invoice-category";
import { LoadingSpinner } from "../../ui";

interface Props {
  customerTier?: CustomerTier;
  onSubmitted?: () => void;
}

export const CustomerTierForm = (props: Props) => {
  const { customerTier, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createCustomerTier } = useAddCustomerTier();
  const { mutate: patchCustomerTier } = usePatchCustomerTier();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<CustomerTier>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(customerTier),
  });
  const { fields, append, remove } = useFieldArray({
    name: "customerTierDetails",
    control,
  });
  const {
    data: invoiceCategories,
    isLoading: isLoadingInvoiceCategories,
    isFetching: isFetchingInvoiceCategories,
  } = useInvoiceCategories();

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  if (isLoadingInvoiceCategories || isFetchingInvoiceCategories) {
    return <LoadingSpinner />;
  }

  return (
    <FormContainer
      onSubmit={handleSubmit((customerTier) => {
        customerTier.id
          ? patchCustomerTier(customerTier)
          : createCustomerTier(customerTier);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} fullWidth />
      </Stack>

      <Stack>
        {fields.map((field, index) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1} key={index}>
              {invoiceCategories && (
                <FormAutocomplete
                  name={`customerTierDetails.${index}.invoiceCategoryId`}
                  control={control}
                  options={invoiceCategories}
                  loading={isLoadingInvoiceCategories}
                  label="Invoice Category"
                  labelKeys={["name"]}
                  valueKey="id"
                  defaultValue={
                    field && field.invoiceCategory
                      ? invoiceCategories.find(
                          (x) => x.id === field?.invoiceCategory?.id
                        )
                      : invoiceCategories.find(
                          (x) => x.id === field.invoiceCategoryId
                        )
                  }
                  sx={{ width: 500 }}
                />
              )}
              <FormTextField
                name={`customerTierDetails.${index}.discountRate`}
                label="Discount Rate"
                control={control}
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
              <IconButton onClick={() => remove(index)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
      <Stack>
        <Button
          variant="outlined"
          onClick={() =>
            append({
              invoiceCategoryId: invoiceCategories && invoiceCategories[0].id,
              discountRate: 0,
            } as CustomerTierDetail)
          }
          endIcon={<AddIcon />}
        >
          Add Invoice Category
        </Button>
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
