import { useState } from "react";
import { Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FileSelector, ModalContainer } from "../../../components/ui";

interface Props {
  title?: string;
  subTitle?: string;
  buttonTitle: string;
  sampleFileUrl: string;
  onSubmit: (file: File) => void;
}

export const FileUploadModal = (props: Props) => {
  const {
    title = "Upload File",
    subTitle,
    buttonTitle,
    sampleFileUrl,
    onSubmit,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        endIcon={<UploadFileIcon />}
      >
        {buttonTitle}
      </Button>
      <ModalContainer
        open={open}
        title={title}
        handleClose={() => setOpen(false)}
      >
        <>
          You can download and use the <a href={sampleFileUrl}>sample file</a>{" "}
          as a starting point
        </>
        <>{subTitle && <p>{subTitle}</p>}</>
        <>
          <FileSelector onFileSelected={setSelectedFile} title="Select File" />
        </>
        <>
          {selectedFile && (
            <Button variant="contained" onClick={() => onSubmit(selectedFile)}>
              Upload
            </Button>
          )}
        </>
      </ModalContainer>
    </>
  );
};
