import { Alert, Button } from "@mui/material";

interface Props {
  onMerge: () => void;
}

export const DuplicateItemAlert = (props: Props) => {
  const { onMerge } = props;
  return (
    <Alert severity="warning" sx={{ mb: 1 }}>
      Invoice contains duplicate inventory items
      <Button onClick={onMerge}>View Duplicates</Button>
    </Alert>
  );
};
