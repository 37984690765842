import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Customer } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchCustomer = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customer: Customer) =>
      patch({
        route: "customers",
        id: customer.id ?? "",
        entity: customer,
      }),
    {
      onSuccess: (customer) => {
        enqueueSnackbar("Customer Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.CUSTOMERS,
          (customers: Customer[] | undefined) =>
            !customers ? [] : patchEntity<Customer>(customers, customer, "id")
        );
        queryClient.setQueryData([RQ.CUSTOMERS, customer.id], customer);
      },
      onError: () => {
        enqueueSnackbar("Customer Updating Error", { variant: "error" });
      },
    }
  );
};
