import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { PaymentMethod } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchPaymentMethod = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (paymentMethod: PaymentMethod) =>
      patch({
        route: "payment-methods",
        id: paymentMethod.id ?? "",
        entity: paymentMethod,
      }),
    {
      onSuccess: (paymentMethod) => {
        enqueueSnackbar("Payment Method Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.PAYMENT_METHODS,
          (paymentMethods: PaymentMethod[] | undefined) =>
            !paymentMethods
              ? []
              : patchEntity<PaymentMethod>(paymentMethods, paymentMethod, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Payment Method Updating Error", { variant: "error" });
      },
    }
  );
};
