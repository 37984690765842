import { useMutation } from "react-query";
import { downloadFile } from "../../api/generic-api";
// import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { WorkOrder } from "../../models";

export const useDownloadWorkOrder = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrder: WorkOrder) =>
      downloadFile(
        `work-orders/download?workOrderId=${workOrder.id}`,
        `${workOrder.workOrderNumber}-work-order.pdf`
      ),
    {
      onError: () => {
        enqueueSnackbar("Work Order Download Error", {
          variant: "error",
        });
      },
    }
  );
};
