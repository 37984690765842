import { UseFormSetValue } from "react-hook-form";
import { Invoice, InvoiceCostPrice, InvoiceLineItem } from "../../../../models";

export const assignCalculatedValues = (
  invoice: Invoice,
  setValue: UseFormSetValue<Invoice>
) => {
  for (const keyName in invoice) {
    if (!Array.isArray(invoice[keyName as keyof Invoice])) {
      setValue(
        `${keyName as keyof Invoice}`,
        invoice[keyName as keyof Invoice]
      );
    }
  }
  invoice.invoiceCostsPrices.forEach((invoiceCostsPrice, index) => {
    for (const keyName in invoiceCostsPrice) {
      setValue(
        `invoiceCostsPrices.${index}.${keyName as keyof InvoiceCostPrice}`,
        invoiceCostsPrice[keyName as keyof InvoiceCostPrice]
      );
    }
  });
  invoice.invoiceLineItems.forEach((invoiceLineItem, index) => {
    for (const keyName in invoiceLineItem) {
      setValue(
        `invoiceLineItems.${index}.${keyName as keyof InvoiceLineItem}`,
        invoiceLineItem[keyName as keyof InvoiceLineItem]
      );
    }
  });
};
