/**
 * returns a set of dates between the start and end dat that match the day
 * @param start start date of the interval
 * @param end start date of the interval
 * @param interval day of the week 0 = Sunday to 6 = Saturday
 * @returns boolean
 */
export const getDatesByInterval = (
  start: Date,
  end: Date,
  interval: number
): Date[] => {
  let currentDate = new Date(start);
  let matchingDates: Date[] = [];
  while (currentDate <= end) {
    if (currentDate.getDay() === interval) {
      matchingDates.push(new Date(currentDate));
    }
    let newDate = currentDate.setDate(currentDate.getDate() + 1);
    currentDate = new Date(newDate);
  }
  return matchingDates;
};
