import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useAuth } from "../../../contexts/AuthContext";

interface FormData {
  code: string;
  email: string;
  password: string;
}

const schema = yup.object().shape({
  code: yup.string().required().label("Code"),
  email: yup.string().required().label("Email"),
  password: yup.string().required().label("Password"),
});

export const ConfirmCodeForm = () => {
  const { forgotPasswordSubmit } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  return (
    <FormContainer
      onSubmit={handleSubmit((form) => {
        forgotPasswordSubmit(form.email, form.code, form.password).then(() =>
          navigate("/")
        );
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="email" label="Email" control={control} />
        <FormTextField
          name="code"
          label="Confirmation Code"
          control={control}
          required
        />
        <FormTextField
          name="password"
          label="Password"
          control={control}
          required
          type="password"
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} text="Reset Password" />
    </FormContainer>
  );
};
