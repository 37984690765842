import { GridColDef } from "@mui/x-data-grid";
import { Vendor } from "../../../models";

const getAddress = (vendor: Vendor): string => {
  const streetNumber = vendor.streetNumber ?? "";
  const streetName = vendor.streetName ?? "";
  const unit = vendor.unit ?? "";
  const city = vendor.city ?? "";
  const province = vendor.province ?? "";
  return `${unit} ${streetNumber} ${streetName} ${city} ${province}`;
};

export const vendorColumns: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "phone", headerName: "Phone", flex: 1 },
  {
    field: "address",
    headerName: "Address",
    flex: 2,
    valueGetter: (params) => getAddress(params.row),
  },
];
