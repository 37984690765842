import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { JobType } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddJobType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (jobType: Omit<JobType, "id">) => add<JobType>("job-types", jobType),
    {
      onSuccess: (jobType) => {
        enqueueSnackbar("Job Type Added", { variant: "success" });
        queryClient.setQueryData<JobType[]>(
          RQ.JOB_TYPES,
          (jobTypes: JobType[] | undefined) => {
            return [...(jobTypes ?? []), jobType];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Job Type Adding Error", { variant: "error" });
      },
    }
  );
};
