import { ConfirmCodeForm } from "../../components/forms/authentication/ConfirmCode.form";

export const ConfirmCodePage = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "40%",
        transform: "translate(0px, -50%)",
      }}
    >
      <h2>Confirm Code</h2>
      <p>Please Enter The Code Sent To The Email Provided</p>
      <ConfirmCodeForm />
    </div>
  );
};
