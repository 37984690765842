import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerPayment } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchCustomerPayment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerPayment: CustomerPayment) =>
      patch({
        route: "customer-payments",
        id: customerPayment.id ?? "",
        entity: customerPayment,
      }),
    {
      onSuccess: (customerPayment) => {
        enqueueSnackbar("Customer Payment Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMER_PAYMENTS, customerPayment.customerId],
          (customerPayments: CustomerPayment[] | undefined) =>
            !customerPayments
              ? []
              : patchEntity<CustomerPayment>(
                  customerPayments,
                  customerPayment,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Payment Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
