import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Contact } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchContact = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (contact: Contact) =>
      patch({
        route: "contacts",
        id: contact.id ?? "",
        entity: contact,
      }),
    {
      onSuccess: (contact, variables) => {
        enqueueSnackbar("Contact Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CONTACTS, variables.customerId],
          (contacts: Contact[] | undefined) =>
            !contacts ? [] : patchEntity<Contact>(contacts, contact, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Contact Updating Error", { variant: "error" });
      },
    }
  );
};
