import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InvoiceCategory } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddInvoiceCategory = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (invoiceCategory: InvoiceCategory) =>
      add<InvoiceCategory>("invoice-categories", invoiceCategory),
    {
      onSuccess: (invoiceCategory) => {
        enqueueSnackbar("Invoice Category Added", { variant: "success" });
        queryClient.setQueryData<InvoiceCategory[]>(
          RQ.INVOICE_CATEGORIES,
          (invoiceCategories: InvoiceCategory[] | undefined) => {
            return [...(invoiceCategories ?? []), invoiceCategory];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Invoice Category Adding Error", { variant: "error" });
      },
    }
  );
};
