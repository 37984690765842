import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { uploadFile } from "../../api/generic-api";
import { EmailPayload } from "../../models";

export const useSendProposal = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (payload: { proposalId: string; emailPayload: EmailPayload }) =>
      uploadFile<EmailPayload>(
        `emails/proposal/${payload.proposalId}`,
        payload.emailPayload
      ),
    {
      onSuccess: () => {
        enqueueSnackbar("Proposal Sent", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Proposal Send Error", { variant: "error" });
      },
    }
  );
};
