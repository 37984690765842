import { Button, IconButton, InputAdornment, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  FormAutocomplete,
  FormDatePicker,
  FormSelect,
  FormTextField,
} from "../../../form-components";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import {
  Employee,
  ScheduleType,
  Schedule,
  WorkOrder,
  TaskDuration,
} from "../../../../models";
import { add } from "date-fns";
import { AutoSearch } from "../../../ui";
import { useTaskDurationsSearch } from "../../../../hooks/task-duration";

type FormData = {
  workOrder: Omit<WorkOrder, "schedules">;
  schedules: Partial<Omit<Schedule, "workOrder">>[];
};

interface Props {
  control: Control<FormData, any>;
  fields: FieldArrayWithId<FormData, "schedules", "id">[];
  scheduleTypes: ScheduleType[];
  employees: Employee[];
  schedules: Schedule[];
  trigger: UseFormTrigger<FormData>;
  onScheduleAdded: () => void;
  remove: (index?: number | number[] | undefined) => void;
  setValue: UseFormSetValue<FormData>;
}

export const BlockSchedules = (props: Props) => {
  const {
    control,
    fields,
    scheduleTypes,
    employees,
    schedules,
    trigger,
    onScheduleAdded,
    remove,
    setValue,
  } = props;

  return (
    <>
      {fields.map((field, index) => (
        <Controller
          name={`schedules.${index}`}
          control={control}
          key={field.id}
          render={({ field }) => {
            return (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <FormAutocomplete
                  name={`schedules.${index}.employeeId`}
                  label="Employee"
                  labelKeys={["firstName", "lastName"]}
                  valueKey="id"
                  options={employees}
                  loading={false}
                  defaultValue={schedules && schedules[index]?.employee}
                  control={control}
                  sx={{ width: 300 }}
                />
                <FormDatePicker
                  name={`schedules.${index}.startDate`}
                  label="Start Date"
                  trigger={trigger}
                  onSelected={(date) =>
                    setValue(
                      `schedules.${index}.endDate`,
                      add(date, { hours: 1 })
                    )
                  }
                  control={control}
                />
                <AutoSearch
                  label="Task Duration Search..."
                  keys={["name", "duration"]}
                  onOptionSelected={(taskDuration: TaskDuration | null) =>
                    taskDuration &&
                    setValue(
                      `schedules.${index}.duration`,
                      taskDuration.duration
                    )
                  }
                  searchFunction={useTaskDurationsSearch}
                  separator="|"
                  sx={{ width: 400 }}
                />
                <FormTextField
                  name={`schedules.${index}.duration`}
                  label="Duration"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Hr</InputAdornment>
                    ),
                  }}
                  type="number"
                  control={control}
                  sx={{ width: 100 }}
                />
                {scheduleTypes && (
                  <FormSelect
                    name={`schedules.${index}.scheduleTypeId`}
                    label="Schedule Type"
                    labelKeys={["name"]}
                    valueKey="id"
                    data={scheduleTypes.filter((s) => s.requiresWorkOrder)}
                    control={control}
                    sx={{ width: 200 }}
                    defaultValue={schedules && schedules[index]?.scheduleTypeId}
                  />
                )}
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }}
        />
      ))}
      <Button
        onClick={() => onScheduleAdded()}
        endIcon={<AddCircleIcon />}
        variant="outlined"
      >
        Schedule Tech
      </Button>
    </>
  );
};
