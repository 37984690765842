import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrder } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteWorkOrder = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("work-orders", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("WorkOrder Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.WORK_ORDERS,
        (workOrders: WorkOrder[] | undefined) => {
          return !workOrders
            ? []
            : deleteEntity<WorkOrder>(workOrders, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Work Order Deleting Error", {
        variant: "error",
      });
    },
  });
};
