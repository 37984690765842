import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { add } from "../../api/generic-api";
import { useCurrentUser } from "../../contexts";

export const useQuickBooksCallback = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsQBAuthenticated, setQBAlert } = useCurrentUser();
  return useMutation(
    (authCode: string) => add<null>(`quick-books/callback/${authCode}`),
    {
      onSuccess: () => {
        enqueueSnackbar("Quick Books Auth Configured", { variant: "success" });
        setIsQBAuthenticated(true);
        setQBAlert("HIDE");
      },
      onError: () => {
        enqueueSnackbar("Quick Books Auth Configuration Error", {
          variant: "error",
        });
      },
    }
  );
};
