import { CustomerPayment, Invoice } from "../../../models";
import { CustomerPaymentForm } from "../../forms";
import { ModalContainerChangeDetection } from "../ModalContainerChangeDetection/ModalContainerChangeDetection";

interface Props {
  customerPayment?: CustomerPayment;
  invoice?: Invoice;
  open: boolean;
  onClose: () => void;
  onSubmitted: () => void;
}
export const PaymentModal = (props: Props) => {
  const { customerPayment, invoice, open, onClose, onSubmitted } = props;
  return (
    <ModalContainerChangeDetection
      open={open}
      handleClose={() => {
        onClose && onClose();
      }}
      title="Make Customer Payment"
    >
      <CustomerPaymentForm
        onSubmitted={onSubmitted}
        customerPayment={customerPayment}
        invoice={invoice}
      />
    </ModalContainerChangeDetection>
  );
};
