import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import { WorkOrderRoleTitle } from "../../../models";
import { workOrderRoleTitleSchema as schema } from "./work-order-role-title.schema";
import {
  useAddWorkOrderRoleTitle,
  usePatchWorkOrderRoleTitle,
} from "../../../hooks/work-order-role-title";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";

interface Props {
  workOrderRoleTitle?: WorkOrderRoleTitle;
  onSubmitted?: () => void;
}

export const WorkOrderRoleTitleForm = (props: Props) => {
  const { workOrderRoleTitle, onSubmitted } = props;
  const { mutate: createWorkOrderRoleTitle } = useAddWorkOrderRoleTitle();
  const { mutate: patchWorkOrderRoleTitle } = usePatchWorkOrderRoleTitle();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<WorkOrderRoleTitle>({
    resolver: yupResolver(schema),
    defaultValues: workOrderRoleTitle,
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((workOrderRoleTitle) => {
        workOrderRoleTitle.id
          ? patchWorkOrderRoleTitle(workOrderRoleTitle)
          : createWorkOrderRoleTitle(workOrderRoleTitle);
        onSubmitted && onSubmitted();
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting || isSubmitSuccessful} />
    </FormContainer>
  );
};
