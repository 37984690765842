import * as yup from "yup";

export const inventoryTypeSchema = yup.object().shape({
  name: yup.string().required().label("Name").default(""),
  showInInvoiceCategorySection: yup
    .boolean()
    .required()
    .label("Show In Invoice Category Section")
    .default(false),
  proportional: yup.boolean().required().label("Proportional").default(false),
  trackQuantity: yup
    .boolean()
    .required()
    .label("Track Quantity")
    .default(false),
  invoiceCategoryId: yup.string().required().label("Invoice Category"),
});
