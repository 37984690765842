import { useQuery } from "react-query";

import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useHasAfterHoursScheduled = (workOrderId?: string) => {
  return useQuery<boolean, Error>(
    [RQ.HAS_AFTER_HOURS_SCHEDULED, workOrderId],
    () =>
      getSingle(`/schedules/work-order/${workOrderId}/hasAfterHoursScheduled`),
    {
      enabled: Boolean(workOrderId),
    }
  );
};
