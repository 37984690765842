import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InvoiceDrawRequest } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddInvoiceDrawRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (invoiceDrawRequest: Omit<InvoiceDrawRequest, "id">) =>
      add<InvoiceDrawRequest>("invoice-draw-requests", invoiceDrawRequest),
    {
      onSuccess: (invoiceDrawRequest) => {
        enqueueSnackbar("Invoice Draw Request Added", { variant: "success" });
        queryClient.setQueryData<InvoiceDrawRequest[]>(
          [RQ.INVOICE_DRAW_REQUESTS, invoiceDrawRequest.invoiceId],
          (invoiceDrawRequests: InvoiceDrawRequest[] | undefined) => {
            return [...(invoiceDrawRequests ?? []), invoiceDrawRequest];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Invoice Draw Request Adding Error", {
          variant: "error",
        });
      },
    }
  );
};
