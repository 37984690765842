import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment, Stack, IconButton, Button } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { isEqual } from "lodash";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import { InventoryItem, InventoryType, VendorCost } from "../../../models";
import { inventoryItemSchema as schema } from "../schemas";
import {
  useAddInventoryItem,
  usePatchInventoryItem,
} from "../../../hooks/inventory-item";
import { useVendorsByQuery } from "../../../hooks/vendor";
import { FormContainer } from "../../layout";
import {
  FormArrayObjectSelect,
  FormAutocompleteFreeSolo,
  FormSubmitButton,
  FormSwitch,
  FormTextField,
} from "../../form-components";
import { useInventoryTypes } from "../../../hooks/inventory-type";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { APPLICATION_SETTING, UNIT_OF_MEASURE } from "../../../globals/enums";
import { useAppSettingsContext } from "../../../contexts";
import { FormAutocomplete } from "../../form-components/";

interface Props {
  inventoryItem?: InventoryItem;
  onSubmitted?: () => void;
}

const calculatePrice = (markup: number, cost: number) => {
  const percent = markup / 100 + 1;
  return Number((cost * percent).toFixed(2));
};

const calculateCost = (markup: number, sellingCost: number) => {
  const percent = markup / 100 + 1;
  return Number((sellingCost / percent).toFixed(2));
};

export const InventoryItemForm = (props: Props) => {
  const { inventoryItem, onSubmitted } = props;
  const { getSetting } = useAppSettingsContext();
  const { setChangeDetection } = useChangeDetection();
  const [defaultInventoryType, setDefaultInventoryType] =
    useState<InventoryType>();
  const { data: inventoryTypes } = useInventoryTypes();
  const { mutate: createInventoryItem } = useAddInventoryItem();
  const { mutate: patchInventoryItem } = usePatchInventoryItem();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: vendors, refetch } = useVendorsByQuery(searchTerm);
  const {
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<InventoryItem>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(inventoryItem),
  });
  const { fields, append, remove } = useFieldArray({
    name: "vendorCosts",
    control,
  });
  const markup = watch("markup");
  const cost = watch("cost");
  const vendorCosts = watch("vendorCosts");
  const DEFAULT_INVENTORY_TYPE = getSetting(
    APPLICATION_SETTING.DEFAULT_INVENTORY_TYPE
  ).settingId;

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, setChangeDetection, defaultValues]);

  useEffect(() => {
    searchTerm && refetch();
  }, [searchTerm, refetch]);

  // set default inventory type
  useEffect(() => {
    inventoryTypes &&
      setDefaultInventoryType(
        inventoryTypes.find((i) => i.id === DEFAULT_INVENTORY_TYPE)
      );
    if (inventoryTypes && !inventoryItem) {
      inventoryTypes &&
        setValue(
          "inventoryTypeId",
          inventoryTypes.find((i) => i.id === DEFAULT_INVENTORY_TYPE)?.id ?? ""
        );
    }
  }, [
    inventoryTypes,
    setDefaultInventoryType,
    setValue,
    inventoryItem,
    DEFAULT_INVENTORY_TYPE,
  ]);

  // set initial dropdown value id
  useEffect(() => {
    inventoryItem &&
      setValue("inventoryTypeId", inventoryItem.inventoryType.id);
  }, [inventoryItem, setValue]);

  // set primary
  const setPrimary = (index: number) => {
    vendorCosts.forEach((vendorCost, vendorCostIndex) => {
      if (index === vendorCostIndex) {
        setValue(`vendorCosts.${vendorCostIndex}.primaryCost`, true);
        setValue("cost", vendorCost.cost);
        setValue("sku", vendorCost.sku);
      } else {
        setValue(`vendorCosts.${vendorCostIndex}.primaryCost`, false);
      }
    });
  };

  const onSubmit = (inventoryItem: InventoryItem) => {
    inventoryItem.id
      ? patchInventoryItem(inventoryItem)
      : createInventoryItem(inventoryItem);
    onSubmitted && onSubmitted();
    setChangeDetection(false);
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <FormTextField
          name="name"
          label="Name"
          control={control}
          required
          fullWidth
        />
        {inventoryTypes && (defaultInventoryType || inventoryItem) && (
          <FormArrayObjectSelect
            name="inventoryTypeId"
            label="Inventory Type"
            data={inventoryTypes}
            control={control}
            labelKey="name"
            valueKey="id"
            defaultValue={
              (inventoryItem && inventoryItem?.inventoryType.id) ??
              defaultInventoryType?.id
            }
          />
        )}
        <FormTextField
          name="description"
          label="Description"
          control={control}
          fullWidth
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="model" label="Model" control={control} fullWidth />
        <FormTextField name="sku" label="SKU" control={control} fullWidth />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField
          name="cost"
          label="Cost"
          control={control}
          type="number"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onInputChanged={(value) =>
            markup && setValue("price", calculatePrice(markup, Number(value)))
          }
        />
        <FormTextField
          name="price"
          label="Price"
          control={control}
          type="number"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onInputChanged={(value) =>
            markup && setValue("cost", calculateCost(markup, Number(value)))
          }
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField
          name="markup"
          label="Markup"
          control={control}
          type="number"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          onInputChanged={(value) =>
            setValue("price", calculatePrice(Number(value), cost))
          }
        />
        <FormTextField
          name="quantity"
          label="Quantity"
          control={control}
          type="number"
          fullWidth
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField
          name="rate"
          label="Rate"
          control={control}
          type="number"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <FormAutocompleteFreeSolo
          name="unitsOfMeasure"
          label="Units Of Measure"
          control={control}
          options={Object.values(UNIT_OF_MEASURE)}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField
          name="warrantyPartsPeriod"
          label="Warranty Parts Period"
          control={control}
          type="number"
          fullWidth
        />
        <FormTextField
          name="warrantyLabourPeriod"
          label="Warranty Labour Period"
          control={control}
          type="number"
          fullWidth
        />
      </Stack>
      <Stack>
        <FormSwitch
          name={`skipDuplicationCheck`}
          label="Skip Duplication Check"
          control={control}
        />
      </Stack>
      <Stack>
        {fields.map((field, index) => (
          <Controller
            name={`vendorCosts.${index}`}
            control={control}
            key={field.id}
            render={() => (
              <Stack direction="row" spacing={2}>
                <FormAutocomplete
                  name={`vendorCosts.${index}.vendorId`}
                  label="Vendor"
                  labelKeys={["name"]}
                  valueKey="id"
                  loading={false}
                  options={vendors ?? []}
                  control={control}
                  sx={{ width: 250 }}
                  onInputChange={setSearchTerm}
                  defaultValue={
                    (inventoryItem &&
                      inventoryItem.vendorCosts &&
                      inventoryItem.vendorCosts[index]?.vendor) ??
                    undefined
                  }
                />
                <FormTextField
                  name={`vendorCosts.${index}.cost`}
                  label="Cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  control={control}
                  sx={{ width: 100 }}
                />
                <FormTextField
                  name={`vendorCosts.${index}.sku`}
                  label="SKU"
                  control={control}
                />
                <FormSwitch
                  name={`vendorCosts.${index}.primaryCost`}
                  label="Make Primary"
                  control={control}
                  onChanged={(checked) => {
                    if (checked) {
                      setPrimary(index);
                    }
                  }}
                />
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            )}
          />
        ))}
        <Button
          onClick={() =>
            append({
              cost: 0,
              primaryCost: false,
            } as VendorCost)
          }
        >
          Add Vendor Cost
        </Button>
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
