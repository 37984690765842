import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { InventoryType } from "../../../models";
import { inventoryTypeSchema as schema } from "../schemas/inventory-type.schema";
import {
  useAddInventoryType,
  usePatchInventoryType,
} from "../../../hooks/inventory-type";
import { FormContainer } from "../../layout";
import {
  FormArrayObjectSelect,
  FormSubmitButton,
  FormSwitch,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { useInvoiceCategories } from "../../../hooks/invoice-category";

interface Props {
  inventoryType?: InventoryType;
  onSubmitted?: () => void;
}

export const InventoryTypeForm = (props: Props) => {
  const { inventoryType, onSubmitted } = props;
  const { data: invoiceCategories } = useInvoiceCategories();
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createInventoryType } = useAddInventoryType();
  const { mutate: patchInventoryType } = usePatchInventoryType();
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<InventoryType>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(inventoryType),
  });

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  useEffect(() => {
    invoiceCategories &&
      !inventoryType?.id &&
      setValue("invoiceCategoryId", invoiceCategories[0].id);
  }, [invoiceCategories, inventoryType]);

  return (
    <FormContainer
      onSubmit={handleSubmit((inventoryType) => {
        inventoryType.id
          ? patchInventoryType(inventoryType)
          : createInventoryType(inventoryType);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} />
      </Stack>
      <Stack direction="column">
        <FormSwitch
          name="showInInvoiceCategorySection"
          label="Add To Total"
          control={control}
        />
        <FormSwitch
          name="proportional"
          label="Is Proportional Cost"
          control={control}
        />
        <FormSwitch
          name="trackQuantity"
          label="Track Quantity"
          control={control}
        />
      </Stack>
      <Stack spacing={2} sx={{ mt: 2 }}>
        {invoiceCategories && (
          <FormArrayObjectSelect
            name="invoiceCategoryId"
            label="Invoice Category"
            data={invoiceCategories}
            control={control}
            labelKey="name"
            valueKey="id"
            defaultValue={
              (inventoryType && inventoryType?.invoiceCategory?.id) ??
              invoiceCategories[0].id
            }
          />
        )}
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
