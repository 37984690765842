import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CompanyDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { uploadFile } from "../../api/generic-api";

export const useAddCompanyDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (companyDocument: CompanyDocument) =>
      uploadFile<CompanyDocument>("documents", companyDocument),
    {
      onSuccess: (companyDocument) => {
        enqueueSnackbar("Customer Document Added", { variant: "success" });
        queryClient.setQueryData<CompanyDocument[]>(
          RQ.COMPANY_DOCUMENTS,
          (companyDocuments: CompanyDocument[] | undefined) => {
            return [...(companyDocuments ?? []), companyDocument];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Document Adding Error", { variant: "error" });
      },
    }
  );
};
