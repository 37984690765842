import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { EmailModal, LoadingSpinner } from "../../components/ui";
import { useWorkOrder } from "../../hooks/work-order";
import { EmailPayload } from "../../models";
import { useAuth } from "../../contexts/AuthContext";
import { ModalContainer } from "../../components/ui";
import { APPLICATION_SETTING } from "../../globals/enums";
import { useAppSettingsContext } from "../../contexts";
import { useEmployeeProfile } from "../../hooks/employee";
import config from "../../config/app-config";
import { useCustomerPaymentsByWorkOrder } from "../../hooks/customer-payment";
import { sumBy } from "lodash";
import { useSendPaymentReceipt } from "../../hooks/email";

export const PaymentReceiptView = () => {
  const { workOrderId } = useParams<{ workOrderId: string }>();
  const { getSetting } = useAppSettingsContext();
  const { data: employee } = useEmployeeProfile();
  const { mutateAsync: sendPaymentReceipt } = useSendPaymentReceipt();
  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    isIdle: isIdleWorkOrder,
    isError: isErrorWorkOrder,
  } = useWorkOrder(workOrderId);
  const { data: customerPayments } = useCustomerPaymentsByWorkOrder(
    workOrder?.id
  );
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const navigate = useNavigate();
  const { email } = useAuth();
  const COMPANY_ADDRESS = getSetting(
    APPLICATION_SETTING.COMPANY_ADDRESS
  ).defaultValue;

  if (isLoadingWorkOrder && isIdleWorkOrder && workOrderId) {
    return <LoadingSpinner />;
  }

  if (isErrorWorkOrder) {
    return <h2>Error</h2>;
  }

  const sendEmail = async (emailPayload: EmailPayload) => {
    if (emailPayload) {
      emailPayload.from = email();
      workOrder &&
        sendPaymentReceipt({ workOrderId: workOrder.id, emailPayload });
    }
    setOpenEmailModal(false);
  };

  const print = () => {
    let printContents = document.getElementById("printsection")?.innerHTML;
    let originalContents = document.body.innerHTML;
    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
    }
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <>
      {workOrder && (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 2, mb: 1, width: "80%" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                      variant="contained"
                    >
                      Back
                    </Button>
                    {workOrder && workOrder.invoice && (
                      <Button
                        onClick={() =>
                          navigate(`/invoices/edit/${workOrder.invoice.id}`)
                        }
                        variant="contained"
                        sx={{ ml: 2 }}
                      >
                        Edit Invoice
                      </Button>
                    )}
                    <Button onClick={print} variant="contained">
                      Print
                    </Button>
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Payment Receipt</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    endIcon={<EmailIcon />}
                    onClick={() => setOpenEmailModal(true)}
                  >
                    Prepare Email
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 7, width: "80%" }} id="printsection">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img
                        src={`${config.COMPANY_IMAGE_URL}/logo.png`}
                        width={200}
                        alt="logo"
                      />
                    </Grid>
                    <Grid item>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: COMPANY_ADDRESS as string,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    sx={{ width: 250 }}
                  >
                    <Box sx={{ textAlign: "right" }}>
                      <Typography variant="h4" gutterBottom>
                        Payment Receipt
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      {format(new Date(), "MMM dd, yyyy")}
                    </Box>
                    <Grid container sx={{ textAlign: "right" }}>
                      <Grid item xs={6}>
                        Invoice #
                      </Grid>
                      <Grid item xs={6}>
                        {workOrder?.workOrderNumber}
                      </Grid>
                    </Grid>
                    {workOrder && workOrder.invoice && (
                      <Grid container sx={{ textAlign: "right" }}>
                        <Grid item xs={6}>
                          Original Amount
                        </Grid>
                        <Grid item xs={6}>
                          ${Number(workOrder.invoice?.grandTotal).toFixed(2)}
                        </Grid>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3 }}
              >
                <Grid item>
                  <Typography variant="h6">Customer</Typography>
                  {workOrder?.customer?.firstName}{" "}
                  {workOrder?.customer?.lastName}
                  <br />
                  {workOrder?.billingAddress?.streetNumber}{" "}
                  {workOrder?.billingAddress?.streetName} <br />
                  {workOrder?.billingAddress?.city}{" "}
                  {workOrder?.billingAddress?.province}{" "}
                  {workOrder?.billingAddress?.postalCode} <br />
                  {workOrder?.customer?.phone}
                </Grid>
              </Grid>
              <br />
              <TableContainer
                component={Paper}
                sx={{ mt: 3, border: "none" }}
                elevation={0}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">Payment Date</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6">Payment Type</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6">Payment Method</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h6">Amount</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerPayments?.map((customerPayment, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="body1">
                              {format(
                                new Date(customerPayment.paymentDate),
                                "MMM dd, yyyy"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body1">
                              {customerPayment.paymentType.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body1">
                              {customerPayment.paymentMethod.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            $ {Number(customerPayment.amount).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                style={{
                  borderStyle: "double",
                  borderRight: "none",
                  borderLeft: "none",
                  borderColor: "#DCDCDC",
                }}
                sx={{ p: 1 }}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={5}
                >
                  <Typography variant="body1">
                    Total Paid: ${" "}
                    {sumBy(customerPayments, (s) => s.amount).toFixed(2)}
                  </Typography>
                </Stack>
              </Box>
            </Paper>
          </Box>
          <ModalContainer
            open={openEmailModal}
            title="Prepare Email"
            handleClose={() => setOpenEmailModal(false)}
          >
            <EmailModal
              onSubmit={(emailPayload) => sendEmail(emailPayload)}
              defaultSearchTerm={workOrder.customer.email}
              customer={workOrder.customer}
              defaultBody={employee?.signOff}
            />
          </ModalContainer>
        </>
      )}
    </>
  );
};
