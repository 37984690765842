import { useQuery } from "react-query";

import { Address } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useAddressSearch = (searchTerm: string) => {
  return useQuery<Address[], Error>(
    [RQ.ADDRESSES_SEARCH, searchTerm],
    () => getAll(`addresses/search?searchTerm=${searchTerm}`),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
