import { useQuery } from "react-query";

import { Schedule } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useSchedulesByWorkOrder = (workOrderId?: string | null) => {
  return useQuery<Schedule[], Error>(
    [RQ.SCHEDULES],
    () => getAll(`schedules/work-order/${workOrderId}`),
    {
      enabled: Boolean(workOrderId),
      select: (schedules) => {
        schedules.forEach((schedule) => {
          if (schedule && schedule.employee) {
            schedule.employeeId = schedule.employee.id;
          }
        });
        return schedules;
      },
    }
  );
};
