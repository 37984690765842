import { createRef, useCallback, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { Draggable } from "@fullcalendar/interaction";
import { EventSourceInput } from "@fullcalendar/core";
import { Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useSearchParams } from "react-router-dom";

import { CalendarDateRange, UnscheduledEvent, WorkOrder } from "../../models";
import { CheckBoxGroup, LoadingSpinner } from "../../components/ui";
import { useUnscheduledWorkOrder } from "../../hooks/work-order";
import {
  DispatchCalendar,
  UnscheduledEvents,
} from "../../components/ui/Calendar";
import { useScheduleTypes } from "../../hooks/schedule-type";
import { useEmployees } from "../../hooks/employee";
import { useSchedulesByDateRange } from "../../hooks/schedule";
import { formatCalendarEvents } from "../../utils";
import { useAppSettingsContext } from "../../contexts";
import { APPLICATION_SETTING } from "../../globals/enums";
import { useEmployeeTypes } from "../../hooks/employee-type";

export const DispatchPage = () => {
  const { getSetting } = useAppSettingsContext();
  const formatDate = (date: string) => new Date(date.replace(/-/g, "/"));
  const [searchParams] = useSearchParams();
  const [goToDate, setGoToDate] = useState(searchParams.get("goToDate"));
  const [dateRange, setDateRange] = useState<CalendarDateRange>({
    startDate: goToDate ? formatDate(goToDate) : new Date(),
  });
  const DEFAULT_EMPLOYEE_TYPES = getSetting(
    APPLICATION_SETTING.DEFAULT_EMPLOYEE_TYPES
  )?.appSettingDetails;
  const [selectedEmployeeTypes, setSelectedEmployeeTypes] = useState<string[]>(
    DEFAULT_EMPLOYEE_TYPES?.map((x) => x.settingId)
  );
  const {
    data: employees,
    isLoading: isLoadingEmployees,
    isIdle: isIdleEmployees,
    isError: isErrorEmployees,
  } = useEmployees();
  const {
    data: schedules,
    isLoading: schedulesIsLoading,
    isIdle: schedulesIsIdle,
    isError: schedulesIsError,
    refetch: getSchedulesByDateRange,
  } = useSchedulesByDateRange(dateRange);
  const { data: employeeTypes } = useEmployeeTypes();
  const { data: workOrders, refetch: getWorkOrders } =
    useUnscheduledWorkOrder();
  const {
    data: scheduleTypes,
    isLoading: isLoadingScheduleTypes,
    isIdle: isIdleScheduleTypes,
  } = useScheduleTypes(false);
  const [unscheduledEvents, setUnscheduledEvents] = useState<
    UnscheduledEvent[]
  >([]);
  const unscheduledEventsRef = createRef<HTMLDivElement>();
  const [events, setEvents] = useState<EventSourceInput>([]);
  const calendarRef = useRef<FullCalendar>(null);

  const getUnscheduledWorkOrders = useCallback(
    (workOrders: WorkOrder[]) => {
      const newUnscheduledEvents: UnscheduledEvent[] = [];

      workOrders.forEach((workOrder: WorkOrder) => {
        const jobTypeNames = workOrder.jobTypes.map((x) => x.name);

        const address = workOrder.siteAddress
          ? `${workOrder.siteAddress?.streetNumber} ${workOrder.siteAddress?.streetName} ${workOrder.siteAddress?.city}`
          : "";

        const unscheduledEvent: UnscheduledEvent = {
          id: workOrder.id,
          title: workOrder.workOrderNumber,
          color: "#ccc",
          workOrderId: workOrder.id,
          workOrderStateId: workOrder.workOrderState.id,
          workOrderStateName: workOrder.workOrderState.name,
          jobTypes: jobTypeNames.toString(),
          address: address,
          scheduleTypeId: scheduleTypes ? scheduleTypes[0].id : "",
          customerName:
            `${workOrder.customer?.firstName} ${workOrder.customer?.lastName}` ??
            "",
        };
        newUnscheduledEvents.push(unscheduledEvent);
      });
      setUnscheduledEvents(newUnscheduledEvents);
    },
    [scheduleTypes]
  );

  const deleteUrlParameter = (name: string) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete(name);
    window.history.replaceState({}, document.title, url.origin + "/dispatch");
  };

  useEffect(() => {
    if (workOrders && scheduleTypes) {
      setTimeout(() => {
        if (calendarRef.current) {
          calendarRef.current?.getApi().gotoDate(dateRange.startDate);
        }
      }, 0);
      getUnscheduledWorkOrders(workOrders);
    }
  }, [workOrders, dateRange, scheduleTypes, getUnscheduledWorkOrders]);

  useEffect(() => {
    schedules && setEvents(formatCalendarEvents(schedules, calendarRef));
    if (goToDate) {
      calendarRef.current?.getApi().gotoDate(goToDate);
      deleteUrlParameter("goToDate");
      setGoToDate(null);
    }
  }, [schedules, goToDate, calendarRef]);

  useEffect(() => {
    dateRange && getSchedulesByDateRange();
  }, [dateRange, getSchedulesByDateRange]);

  useEffect(() => {
    if (unscheduledEventsRef.current) {
      let draggable = new Draggable(unscheduledEventsRef.current, {
        itemSelector: ".fc-event",
        eventData: (eventEl) =>
          ({
            id: eventEl.dataset.id,
            title: eventEl.getAttribute("title"),
            color: eventEl.dataset.color,
            workOrderId: eventEl.dataset.workorderid,
            workOrderStateId: eventEl.dataset.workorderstateId,
            workOrderStateName: eventEl.dataset.workorderstateName,
            jobTypes: eventEl.dataset.jobtypes,
            address: eventEl.dataset.address,
          } as UnscheduledEvent),
      });
      return () => draggable?.destroy();
    }
  });

  if (
    isLoadingEmployees ||
    isIdleEmployees ||
    schedulesIsLoading ||
    schedulesIsIdle ||
    isLoadingScheduleTypes ||
    isIdleScheduleTypes
  ) {
    return <LoadingSpinner />;
  }

  if (isErrorEmployees || schedulesIsError) {
    return <h2>Page Error</h2>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ p: 1, mb: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end"
            sx={{ flexWrap: "wrap" }}
          >
            <CheckBoxGroup
              formLabel="Employee Types"
              options={employeeTypes ?? []}
              labelKey="name"
              valueKey="id"
              direction="row"
              defaultValues={selectedEmployeeTypes}
              onChecked={(option, checked) => {
                if (checked) {
                  setSelectedEmployeeTypes([...selectedEmployeeTypes, option]);
                } else {
                  setSelectedEmployeeTypes(
                    selectedEmployeeTypes.filter(
                      (selectedEmployeeType) => selectedEmployeeType !== option
                    )
                  );
                }
              }}
            />
            <DatePicker
              label="Date"
              inputFormat="LLL dd, yyyy"
              disableMaskedInput
              value={dateRange.startDate}
              onChange={(value) => {
                setDateRange({ startDate: value ?? new Date() });
                calendarRef.current?.getApi().gotoDate(value ?? new Date());
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h5">Unscheduled</Typography>
        <UnscheduledEvents
          unsecheduledEvents={unscheduledEvents ?? []}
          ref={unscheduledEventsRef}
        />
      </Grid>
      <Grid item xs={10}>
        <DispatchCalendar
          calendarRef={calendarRef}
          events={events}
          employees={employees}
          selectedEmployeeTypes={selectedEmployeeTypes}
          onDateUpdated={(newDate, endDate) => {
            setDateRange({
              startDate: newDate,
              endDate: endDate ?? undefined,
            });
          }}
          onEventDeleted={() => getWorkOrders()}
        />
      </Grid>
    </Grid>
  );
};
