import { useQuery } from "react-query";
import { orderBy } from "lodash";

import { Employee } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useEmployees = () => {
  return useQuery<Employee[], Error>(
    RQ.EMPLOYEES,
    async () => getAll<Employee>("employees"),
    {
      select: (employees) => {
        employees.forEach((employee) => {
          if (employee.employeeRole) {
            employee.employeeRoleId = employee.employeeRole.id;
          }
        });
        return orderBy(employees, ["firstName"], "asc");
      },
    }
  );
};
