import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

import { Proposal } from "../../../models";

export const proposalColumns: GridColDef<Proposal>[] = [
  { field: "workOrderNumber", headerName: "Work Order Number", flex: 1 },
  { field: "workOrderState", headerName: "Work Order State", flex: 1 },
  {
    field: "customerName",
    headerName: "Customer",
    flex: 1,
    valueGetter: (params) =>
      `${params.row.customerFirstName}  ${params.row.customerLastName}`,
  },
  {
    field: "subtotal",
    headerName: "Subtotal",
    flex: 1,
    valueGetter: (params) => params.row.subtotal.toFixed(2),
  },
  {
    field: "grandTotal",
    headerName: "Grand Total",
    flex: 1,
    valueGetter: (params) => params.row.grandTotal.toFixed(2),
  },
  {
    field: "createdTimestamp",
    headerName: "Created",
    flex: 1,
    valueGetter: (params) =>
      format(new Date(params.row.createdTimestamp), "yyyy-MM-dd"),
  },
];
