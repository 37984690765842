import * as yup from "yup";
import { add, isValid } from "date-fns";
import { invoiceLineItemSchema } from "./invoice-line-item.schema";
import { invoiceCostPriceSchema } from "./invoice-cost-price.schema";

export const invoiceSchema = yup.object().shape({
  dueDate: yup
    .date()
    .required()
    .label("Due Date")
    .transform((value) =>
      isValid(value) ? (value as Date) : add(new Date(), { months: 1 })
    )
    .default(add(new Date(), { months: 1 })),
  profitMargin: yup.number().default(0),
  subtotal: yup.number().default(0),
  totalCost: yup.number().default(0),
  totalPrice: yup.number().default(0),
  totalProfit: yup.number().default(0),
  invoiceLineItems: yup
    .array()
    .of(invoiceLineItemSchema)
    .min(1)
    .label("Line Items"),
  invoiceCostsPrices: yup
    .array()
    .of(invoiceCostPriceSchema)
    .min(1)
    .label("Invoice Cost price"),
  interestRate: yup.number().default(2),
  hstRate: yup.number().default(0),
  interestDollars: yup.number().default(0),
  waveInterest: yup.boolean().default(false),
  writeOff: yup.boolean().default(false),
  workOrderId: yup
    .string()
    .label("Work Order Number")
    .transform((value) => value ?? "")
    .when("mode", {
      is: "",
      then: yup.string().required(),
    }),
  mode: yup.string().default(""),
});
