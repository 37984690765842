import { Paper, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { sub } from "date-fns";

import { LoadingSpinner } from "../../../../components/ui";
import { useEmployeeHolidays } from "../../../../hooks/report";
import { ReportHeader } from "../../components/ReportHeader";
import {
  GenericDataGrid,
  employeeHolidaysColumns,
} from "../../../../components/grids";

export const EmployeeHolidaysReport = () => {
  const [startDate, setStartDate] = useState<Date>(
    sub(new Date(), { months: 1 })
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const {
    data: schedules,
    isLoading,
    isIdle,
    isError,
    error,
    refetch: getEmployeeHolidays,
  } = useEmployeeHolidays(startDate, endDate);

  useEffect(() => {
    startDate && endDate && getEmployeeHolidays();
  }, [startDate, endDate, getEmployeeHolidays]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <ReportHeader title="Employee Holiday Report" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Start Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={startDate}
            onChange={(value) => {
              setStartDate(value ?? new Date());
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={endDate}
            onChange={(value) => {
              setEndDate(value ?? new Date());
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Stack>
      </Paper>

      <Paper sx={{ p: 2, mb: 1 }}>
        <GenericDataGrid
          rows={schedules}
          columns={employeeHolidaysColumns}
          loading={isLoading}
          title="Schedule"
          ignoreFields={[
            "id",
            "scheduleTypeId",
            "workOrder",
            "employee",
            "workOrderId",
            "employeeId",
            "repeatDays",
            "createdTimestamp",
            "modifiedTimestamp",
          ]}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          autoHeight
        />
      </Paper>
    </>
  );
};
