import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  LoadingSpinner,
  ModalContainerChangeDetection,
  LegalDisclaimer,
  EmailModal,
  JobDescription,
} from "../../components/ui";
import { usePatchProposal, useProposal } from "../../hooks/proposal";
import { useWorkOrderByProposal } from "../../hooks/work-order";
import { useSendProposal } from "../../hooks/email";
import { EmailPayload } from "../../models";
import { useAuth } from "../../contexts/AuthContext";
import { APPLICATION_SETTING } from "../../globals/enums";
import { useAppSettingsContext } from "../../contexts";
import { useEmployeeProfile } from "../../hooks/employee";
import config from "../../config/app-config";
import { WorkOrderStateModal } from "../work-orders/modals";

export const ProposalView = () => {
  const { id } = useParams<{ id: string }>();
  const { getSetting } = useAppSettingsContext();
  const { data: employee } = useEmployeeProfile();
  const {
    data: proposal,
    isLoading,
    isIdle,
    isError,
    error,
  } = useProposal(id ?? "");
  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    isIdle: isIdleWorkOrder,
  } = useWorkOrderByProposal(id);
  const { mutateAsync: patchProposal } = usePatchProposal();
  const { mutateAsync: sendProposal } = useSendProposal();
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [workOrderState, setWorkOrderState] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [showJobDescription, setShowJobDescription] = useState(
    proposal?.showJobDescription ?? false
  );
  const [proposalLegal] = useState(
    getSetting(APPLICATION_SETTING.PROPOSAL_LEGAL_DISCLAIMER)
      .defaultValue as string
  );
  const [companyAddress] = useState(
    getSetting(APPLICATION_SETTING.COMPANY_ADDRESS).defaultValue as string
  );
  const [depositRate] = useState(
    getSetting(APPLICATION_SETTING.DEPOSIT_RATE).defaultValue as number
  );
  const navigate = useNavigate();
  const { email } = useAuth();

  useEffect(() => {
    workOrder && setWorkOrderState(workOrder?.workOrderState?.name);
  }, [workOrder]);

  useEffect(() => {
    proposal && setShowJobDescription(proposal.showJobDescription);
  }, [proposal]);

  useEffect(() => {
    proposal && setDepositAmount(proposal.grandTotal * (depositRate / 100));
  }, [proposal, depositRate]);

  if ((isLoading || isIdle || isLoadingWorkOrder || isIdleWorkOrder) && id) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const sendEmail = async (emailPayload: EmailPayload) => {
    if (id && workOrder && emailPayload) {
      const sent =
        emailPayload.to.includes(workOrder.customer.email ?? "") ?? false;
      emailPayload.from = email();
      await patchProposal({ id, sent, showJobDescription });
      proposal && workOrder && sendProposal({ proposalId: id, emailPayload });
    }
    setOpenEmailModal(false);
  };

  const print = () => {
    let printContents = document.getElementById("printsection")?.innerHTML;
    let originalContents = document.body.innerHTML;
    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
    }
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <>
      {proposal && workOrder && (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 2, mb: 1, width: "80%" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                      variant="contained"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => navigate(`/proposals/edit/${proposal.id}`)}
                      variant="contained"
                      sx={{ ml: 2 }}
                    >
                      Edit Proposal
                    </Button>
                    <Button onClick={print} variant="contained">
                      Print
                    </Button>
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Customer Proposal</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    endIcon={<EmailIcon />}
                    onClick={() => setOpenEmailModal(true)}
                  >
                    Prepare Email
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 2, mb: 1, width: "80%" }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <WorkOrderStateModal
                    workOrder={workOrder}
                    onSubmit={(updatedWorkOrder) =>
                      setWorkOrderState(updatedWorkOrder?.workOrderState?.name)
                    }
                  />{" "}
                  - Current Work Order State: <b>{workOrderState}</b>
                </Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event) =>
                          setShowJobDescription(event.target.checked)
                        }
                        defaultChecked={proposal.showJobDescription}
                      />
                    }
                    label="Show Job Description"
                  />
                </FormGroup>
              </Stack>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 7, width: "80%" }} id="printsection">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img
                        src={`${config.COMPANY_IMAGE_URL}/logo.png`}
                        width={200}
                        alt="logo"
                      />
                    </Grid>
                    <Grid item>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: companyAddress,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    sx={{ width: 250 }}
                  >
                    <Box sx={{ textAlign: "right" }}>
                      <Typography variant="h4" gutterBottom>
                        Proposal
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      {format(new Date(), "MMM dd, yyyy")}
                    </Box>
                    <Grid container sx={{ textAlign: "right" }}>
                      <Grid item xs={6}>
                        Reference #
                      </Grid>
                      <Grid item xs={6}>
                        {workOrder?.workOrderNumber}
                      </Grid>
                      <Grid item xs={6}>
                        Call Date:
                      </Grid>
                      <Grid item xs={6}>
                        {workOrder?.callDate &&
                          format(
                            parseISO(workOrder?.callDate?.toString() ?? ""),
                            "MMM dd, yyyy"
                          )}
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 5 }}
              >
                <Grid item>
                  <Typography variant="h6">Bill To</Typography>
                  {workOrder?.customer?.firstName}{" "}
                  {workOrder?.customer?.lastName}
                  <br />
                  {workOrder?.billingAddress?.streetNumber}{" "}
                  {workOrder?.billingAddress?.streetName} <br />
                  {workOrder?.billingAddress?.city}{" "}
                  {workOrder?.billingAddress?.province}{" "}
                  {workOrder?.billingAddress?.postalCode} <br />
                  {workOrder?.customer?.phone}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={8}>
                    <Box>
                      <Typography variant="h6">Job Site</Typography>
                      {workOrder?.customer?.firstName}{" "}
                      {workOrder?.customer?.lastName}
                      <br />
                      {workOrder?.siteAddress?.streetNumber}{" "}
                      {workOrder?.siteAddress?.streetName} <br />
                      {workOrder?.siteAddress?.city}{" "}
                      {workOrder?.siteAddress?.province}{" "}
                      {workOrder?.siteAddress?.postalCode}{" "}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              {showJobDescription && (
                <JobDescription description={workOrder?.jobDescription} />
              )}
              <TableContainer
                component={Paper}
                sx={{ mt: 3, border: "none" }}
                elevation={0}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        <Typography variant="h6">
                          We Hearby Submit Specifications and Estimate For:
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {proposal.proposalLineItems
                      .sort((a, b) => Number(a.ordinal) - Number(b.ordinal))
                      .map((proposalLineItem) => (
                        <TableRow key={proposalLineItem.id}>
                          <TableCell>
                            <Typography
                              variant="body1"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {proposalLineItem.display}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" align="right">
                              $ {proposalLineItem.price}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              {proposal.grandTotal > 0 && (
                <Box
                  style={{
                    borderStyle: "double",
                    borderRight: "none",
                    borderLeft: "none",
                    borderColor: "#A9A9A9",
                  }}
                  sx={{ p: 1 }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={5}
                  >
                    <Stack direction="row" spacing={5}>
                      <Typography variant="h6">
                        Subtotal: ${Number(proposal?.subtotal).toFixed(2)}
                      </Typography>
                      <Typography variant="h6">
                        HST: $
                        {Number(
                          proposal.grandTotal - proposal.subtotal
                        ).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Typography variant="h6">
                      Total: ${Number(proposal?.grandTotal).toFixed(2)}
                    </Typography>
                  </Stack>
                </Box>
              )}

              {proposal && proposal.grandTotal > 0 && depositRate > 0 && (
                <Box sx={{ mt: 5 }}>
                  <b>Payment to be made as follows:</b>
                  <br />
                  DEPOSIT {depositRate}% WHICH WILL BE TAKEN OFF YOUR FINAL
                  BALANCE FOR THE AMOUNT OF ${Number(depositAmount).toFixed(2)}
                </Box>
              )}
              {proposalLegal && (
                <LegalDisclaimer body={proposalLegal} sx={{ mt: 2 }} />
              )}
              <Grid
                container
                sx={{ mt: 3 }}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
              >
                <Grid item xs={3}>
                  Authorized Signature:
                </Grid>
                <Grid item xs={3}>
                  Acceptance Signature:
                </Grid>
                <Grid item xs={3}>
                  Date:
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <ModalContainerChangeDetection
            open={openEmailModal}
            title="Prepare Email"
            handleClose={() => setOpenEmailModal(false)}
          >
            <EmailModal
              onSubmit={(emailPayload) => sendEmail(emailPayload)}
              defaultSearchTerm={workOrder?.customer?.email}
              customer={workOrder?.customer}
              defaultBody={employee?.signOff}
            />
          </ModalContainerChangeDetection>
        </>
      )}
    </>
  );
};
