import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState } from "react";
import { MenuItem } from "../../../globals/props";
import { hasNavigationChildren } from "../../../utils";
import { Link } from "react-router-dom";

export const NavigationMenuItem = ({ item }: any) => {
  const Component = hasNavigationChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }: any) => {
  return (
    <List>
      <ListItem key={item.title} component={Link} to={item.to} disablePadding>
        <ListItemButton
          style={{
            margin: 0,
            paddingLeft: 15,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

const MultiLevel = ({ item }: any) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        key={item.title}
        style={{
          margin: 0,
          paddingLeft: 15,
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{
            margin: 0,
            paddingLeft: 15,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {children.map((child: MenuItem, key: number) => (
            <NavigationMenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
