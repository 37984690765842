import { Box, Divider, Grid, Typography } from "@mui/material";
import { WorkOrder } from "../../../models";
import { format } from "date-fns";
import React from "react";

interface Props {
  workOrder: WorkOrder;
}

export const DisplayWorkOrderShort = (props: Props) => {
  const { workOrder } = props;
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <strong>Work Order Number</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrder.workOrderNumber}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Work Order State</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrder.workOrderState.name}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Call Date</strong>
        </Grid>
        <Grid item xs={9}>
          {format(new Date(workOrder.callDate), "MMMM do, yyyy")}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Job Description</strong>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {workOrder.jobDescription}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Job Type</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrder.jobTypes.map((jobType, index) => {
            return (
              <React.Fragment key={index}>{`${jobType.name}, `}</React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
