import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { JobType } from "../../../models";
import { jobTypeSchema as schema } from "./job-type.schema";
import { useAddJobType, usePatchJobType } from "../../../hooks/job-type";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  jobType?: JobType;
  onSubmitted?: () => void;
}

export const JobTypeForm = (props: Props) => {
  const { jobType, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createJobType } = useAddJobType();
  const { mutate: patchJobType } = usePatchJobType();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<JobType>({
    resolver: yupResolver(schema),
    defaultValues: jobType,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  return (
    <FormContainer
      onSubmit={handleSubmit((jobType) => {
        jobType.id ? patchJobType(jobType) : createJobType(jobType);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
