import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

import {
  PaymentMethodDataGrid,
  PaymentTypeDataGrid,
} from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { LoadingSpinner } from "../../components/ui";
import { usePaymentMethods } from "../../hooks/payment-method";
import { usePaymentTypes } from "../../hooks/payment-type";

export const PaymentSettingsPage = () => {
  const {
    data: paymentTypes,
    isLoading: isLoadingPaymentTypes,
    isIdle,
    isError,
    error,
  } = usePaymentTypes();
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } =
    usePaymentMethods();
  const navigate = useNavigate();

  if (isLoadingPaymentTypes || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title="Payment Settings"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <PaymentTypeDataGrid
        rows={paymentTypes}
        loading={isLoadingPaymentTypes}
        title="Payment Types"
        disableColumnsButton
        disableExport
        disableQuickFilter
        autoHeight
      />
      <br />
      <PaymentMethodDataGrid
        rows={paymentMethods ?? []}
        loading={isLoadingPaymentMethods}
        title="Payment Methods"
        disableColumnsButton
        disableExport
        disableQuickFilter
        autoHeight
      />
    </>
  );
};
