import { useState } from "react";
import { SxProps, Theme } from "@mui/material";

import {
  useDeleteWorkOrder,
  useDownloadWorkOrder,
} from "../../../hooks/work-order";
import { CustomerPayment, WorkOrder } from "../../../models";
import { PaymentModal } from "../../ui";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { workOrderColumns } from "./work-order.columns";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  sx?: SxProps<Theme>;
}

export const WorkOrderDataGrid = (props: Props<WorkOrder>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
    sx,
  } = props;
  const [workOrder, setWorkOrder] = useState<Partial<WorkOrder> | undefined>();
  const [openCustomerPaymentModal, setOpenCustomerPaymentModal] =
    useState(false);
  const { mutate: deleteWorkOrder } = useDeleteWorkOrder();
  const { mutate: downloadWorkOrder } = useDownloadWorkOrder();

  const onEditCreate = (workOrder: WorkOrder) =>
    workOrder &&
    window
      .open(`/work-order/edit?workOrderId=${workOrder.id}`, "_blank")
      ?.focus();

  return (
    <GenericDataGrid
      sx={sx}
      rows={rows}
      columns={workOrderColumns}
      loading={loading}
      onClick={onClick}
      onDelete={(workOrder) => deleteWorkOrder(workOrder.id)}
      onEdit={onEditCreate}
      onDoubleClick={onEditCreate}
      onDownload={(workOrder) => downloadWorkOrder(workOrder)}
      onLink={onLink}
      onPay={(workOrder) => {
        setWorkOrder(workOrder);
        setOpenCustomerPaymentModal(true);
      }}
      createTitle="Create Work Order"
      deleteTitle="Do you to delete this Work Order?"
      title={title}
      ignoreFields={[
        "id",
        "callDate",
        "workOrderState",
        "workOrderStateId",
        "jobDescription",
        "workOrderNotes",
        "customerId",
        "siteAddressId",
        "billingAddressId",
        "invoiceId",
        "billingAddress",
        "siteAddress",
        "createdTimestamp",
        "modifiedTimestamp",
        "customer",
        "jobTypes",
        "invoice",
        "proposal",
        "proposalId",
        "jobTypeIds",
        "workOrderState.id",
        "workOrderState.createdTimestamp",
        "workOrderState.modifiedTimestamp",
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "workOrderNumber", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <>
        {workOrder && (
          <PaymentModal
            open={openCustomerPaymentModal}
            onSubmitted={() => setOpenCustomerPaymentModal(false)}
            onClose={() => {
              setOpenCustomerPaymentModal(false);
              onModalClosed && onModalClosed();
            }}
            customerPayment={
              {
                workOrder,
                customerId: workOrder?.customer?.id,
              } as CustomerPayment
            }
            invoice={workOrder?.invoice}
          />
        )}
      </>
    </GenericDataGrid>
  );
};
