import { useQuery } from "react-query";

import { CompanyDocumentTag } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useCompanyDocumentTagsSearch = (searchTerm: string) => {
  return useQuery<CompanyDocumentTag[], Error>(
    [RQ.CUSTOMER_DOCUMENT_TAGS, searchTerm],
    () =>
      getAll(`documents/company-document-tags/search?searchTerm=${searchTerm}`),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
