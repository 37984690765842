import { useQuery } from "react-query";

import { AppSetting } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useAppSettings = () => {
  return useQuery<AppSetting[], Error>(RQ.APP_SETTINGS, async () =>
    getAll<AppSetting>("app-settings")
  );
};
