import { useState } from "react";
import { useDeleteCustomerPayment } from "../../../hooks/customer-payment";
import { Customer, CustomerPayment } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { customerPaymentColumns } from "./customer-payment.columns";
import { CustomerPaymentForm } from "../../forms";
import { SxProps, Theme } from "@mui/material";
import { ModalContainerChangeDetection } from "../../ui";

interface Props {
  rows: CustomerPayment[];
  loading: boolean;
  customer: Customer;
  onClick?: (entity: CustomerPayment) => void;
  onView?: (entity: CustomerPayment) => void;
  onModalClosed?: () => void;
  onSubmitted?: (entity: CustomerPayment) => void;
  title?: string;
  sx?: SxProps<Theme>;
}

export const CustomerPaymentDataGrid = (props: Props) => {
  const {
    rows,
    loading,
    customer,
    onClick,
    onView,
    onModalClosed,
    onSubmitted,
    title,
    sx = { height: 300 },
  } = props;
  const [customerPayment, setCustomerPayment] = useState<CustomerPayment>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteCustomerPayment } = useDeleteCustomerPayment();

  const onEditCreate = (customerPayment?: CustomerPayment) => {
    customerPayment
      ? setCustomerPayment({ ...customerPayment, customerId: customer.id })
      : setCustomerPayment({ customerId: customer.id } as CustomerPayment);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      sx={sx}
      rows={rows}
      columns={customerPaymentColumns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onDelete={(customerPayment) =>
        deleteCustomerPayment({ ...customerPayment, customerId: customer.id })
      }
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      onView={onView}
      createTitle="Make Payment"
      deleteTitle="Do you to delete this Customer Payment?"
      title={title}
      ignoreFields={[
        "id",
        "customerId",
        "workOrder",
        "createdTimestamp",
        "modifiedTimestamp",
        "workOrderId",
        "paymentTypeId",
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "modifiedTimestamp", sort: "desc" }],
        },
      }}
      disableExport
      disableColumnsButton
      disableQuickFilter
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${customerPayment?.id ? "Edit" : "Make"} Customer Payment`}
      >
        <CustomerPaymentForm
          onSubmitted={(customerPayment) => {
            setOpenModal(false);
            onSubmitted && onSubmitted(customerPayment);
          }}
          customerPayment={customerPayment}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
