import { CompletePasswordForm } from "../../components/forms";

export const CompletePasswordPage = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "40%",
        transform: "translate(0px, -50%)",
      }}
    >
      <h2>Enter New Password</h2>
      <CompletePasswordForm />
    </div>
  );
};
