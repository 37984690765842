export const arrayAddOrDelete = (
  values: string[],
  value: string,
  checked: boolean
) => {
  let list = [...values];
  const index = list.indexOf(value);
  // remove
  if (index >= 0 && !checked) {
    list.splice(index, 1);
    return list;
  }
  // add
  else if (index === -1 && checked) {
    list.push(value);
    return list;
  }
  return list;
};
