import { useState } from "react";
import { useDeleteCompanyDocument } from "../../../hooks/company-document";
import { CompanyDocument } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { companyDocumentColumns as columns } from "./company-document.columns";
import { CompanyDocumentForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";
import { DocumentClassification } from "../../../globals/enums";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onDownload?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const CompanyDocumentDataGrid = (props: Props<CompanyDocument>) => {
  const {
    rows,
    loading,
    onClick,
    onDownload,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [companyDocument, setCompanyDocument] = useState<CompanyDocument>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteCompanyDocument } = useDeleteCompanyDocument();

  const onEditCreate = (companyDocument?: CompanyDocument) => {
    companyDocument
      ? setCompanyDocument(companyDocument)
      : setCompanyDocument(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onDelete={(companyDocument) => deleteCompanyDocument(companyDocument)}
      onDownload={onDownload}
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={(document) => {
        const url = new URL(window.location.href);
        url.pathname = `/document`;
        url.searchParams.append("documentId", document.id);
        url.searchParams.append("documentType", DocumentClassification.COMPANY);
        window.open(url.toString(), "_blank");
      }}
      title={title}
      createTitle="Upload Company Document"
      deleteTitle="Do you want to delete this Company Document"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${companyDocument ? "Edit" : "Upload"} Company Document`}
      >
        <CompanyDocumentForm
          onSubmitted={() => setOpenModal(false)}
          companyDocument={companyDocument}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
