import { GridColDef } from "@mui/x-data-grid";
import { WorkOrder } from "../../../models";

export const workOrderColumns: GridColDef<WorkOrder>[] = [
  { field: "workOrderNumber", headerName: "Work Order Number", flex: 1 },
  {
    field: "customer",
    headerName: "Customer",
    flex: 1,
    valueGetter: (params) =>
      params.row.customer
        ? params.row.customer.firstName + " " + params.row.customer.lastName
        : "",
  },
  { field: "jobDescription", headerName: "Job Description", flex: 1 },
  { field: "purchaseOrderNumber", headerName: "P.O Number", flex: 1 },
  {
    field: "workOrderState",
    headerName: "Work Order State",
    flex: 1,
    valueGetter: (params) =>
      params.row.workOrderState ? params.row.workOrderState.name : "",
  },
  {
    field: "jobTypes",
    headerName: "Job Type",
    flex: 1,
    valueGetter: (params) =>
      params.row.jobTypes
        ? params.row.jobTypes.map((x) => x.name).toString()
        : "",
  },
];
