import { GridColDef } from "@mui/x-data-grid";
import { WorkOrderNote } from "../../../models";
import { format, parseISO } from "date-fns";

export const workOrderNoteColumns: GridColDef<WorkOrderNote>[] = [
  { field: "note", headerName: "Notes", flex: 1 },
  {
    field: "employee",
    headerName: "Created By",
    flex: 1,
    minWidth: 150,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) =>
      params.row.employee
        ? `${params.row.employee.firstName} ${params.row.employee.lastName}`
        : "",
  },
  {
    field: "modifiedTimestamp",
    headerName: "Last Updated",
    minWidth: 150,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) =>
      params.row.modifiedTimestamp
        ? format(parseISO(params.row.modifiedTimestamp), "Pp")
        : "",
  },
];
