import { useNavigate } from "react-router-dom";

import { ReportTypeDataGrid } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { LoadingSpinner } from "../../components/ui";
import { useReportTypes } from "../../hooks/report-type";

export const ReportsPage = () => {
  const {
    data: reportTypes,
    isLoading,
    isIdle,
    isError,
    error,
  } = useReportTypes();
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader title="Reports" />
      <ReportTypeDataGrid
        rows={reportTypes}
        loading={isLoading}
        onView={(reportType) => navigate(`/report/${reportType.url}`)}
      />
    </>
  );
};
