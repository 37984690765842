import { useQuery } from "react-query";

import { RepeatDay } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useRepeatDays = () => {
  return useQuery<RepeatDay[], Error>(RQ.REPEAT_DAYS, async () =>
    getAll<RepeatDay>("repeat-days")
  );
};
