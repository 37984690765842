import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";

import {
  ScheduleGroupForm,
  WorkOrderForm,
  WorkOrderNoteForm,
} from "../../components/forms";
import { PageHeader } from "../../components/layout";
import { useCustomer } from "../../hooks/customer";
import { useWorkOrder } from "../../hooks/work-order";

import {
  Invoice,
  Proposal,
  Schedule,
  WorkOrder,
  WorkOrderDocument,
  WorkOrderNote,
} from "../../models";
import {
  BasicAccordion,
  LoadingSpinner,
  ModalContainerChangeDetection,
  SubTitle,
} from "../../components/ui";
import { useAppSettingsContext } from "../../contexts";
import { APPLICATION_SETTING } from "../../globals/enums";
import {
  EmployeeTimesheetDataGrid,
  GenericDataGrid,
  InvoiceDrawRequestDataGrid,
  ScheduleDataGrid,
  WorkOrderDocumentDataGrid,
  WorkOrderTimelineDataGrid,
  invoiceColumns,
  proposalColumns,
} from "../../components/grids";
import { useWorkOrderTimelinesByWorkOrder } from "../../hooks/work-order-timeline";
import { useSchedulesByWorkOrder } from "../../hooks/schedule";
import { useScheduleTypes } from "../../hooks/schedule-type";
import {
  useDownloadWorkOrderDocument,
  useWorkOrderDocumentsByDateRange,
} from "../../hooks/work-order-document";
import { WorkOrderNoteDataGrid } from "../../components/grids/work-order-note/WorkOrderNote.grid";
import { useWorkOrderNotesByWorkOrder } from "../../hooks/work-order-note";
import { useDeleteInvoice, useInvoiceByWorkOrder } from "../../hooks/invoice";
import {
  useDeleteProposal,
  useProposalByWorkOrder,
} from "../../hooks/proposal";
import { useInvoiceDrawRequestsByInvoice } from "../../hooks/invoice-draw-request";
import { useEmployeeTimesheetsByFilter } from "../../hooks/employee-timesheet";

export const WorkOrderFormPage = () => {
  const [searchParams] = useSearchParams();
  const { getSetting } = useAppSettingsContext();
  const BILLING_ADDRESS_TYPE = getSetting(
    APPLICATION_SETTING.BILLING_ADDRESS_TYPE
  )?.settingId;
  const NORMAL = getSetting(APPLICATION_SETTING.NORMAL).settingId;
  const navigate = useNavigate();
  const [workOrderId, setWorkOrderId] = useState(
    searchParams.get("workOrderId")
  );
  const customerId = searchParams.get("customerId");
  const [workOrderDocument, setWorkOrderDocument] =
    useState<WorkOrderDocument>();
  const [download, setDownload] = useState<boolean>(false);
  const [newWorkOrder, setNewWorkOrder] = useState({ customerId } as WorkOrder);
  const [openScheduleGroupModal, setOpenScheduleGroupModal] = useState(false);
  const { data: scheduleTypes } = useScheduleTypes();
  const { mutate: deleteInvoice } = useDeleteInvoice();
  const { mutate: deleteProposal } = useDeleteProposal();
  const { data: workOrder, isLoading: isLoadingWorkOrder } =
    useWorkOrder(workOrderId);
  const { data: customer } = useCustomer(customerId);
  const { data: workOrderTimelines } = useWorkOrderTimelinesByWorkOrder(
    workOrder?.id ?? workOrderId
  );
  const { data: workOrderDocuments, isLoading: workOrderDocumentsLoading } =
    useWorkOrderDocumentsByDateRange(workOrder?.id ?? workOrderId);
  const { data: schedules, isLoading: isLoadingSchedules } =
    useSchedulesByWorkOrder(workOrder?.id ?? workOrderId);
  const { data: workOrderNotes } = useWorkOrderNotesByWorkOrder(
    workOrder?.id ?? workOrderId
  );
  const { data: invoice, isLoading: isLoadingInvoice } = useInvoiceByWorkOrder(
    workOrder?.id ?? workOrderId
  );
  const { data: proposal, isLoading: isLoadingProposal } =
    useProposalByWorkOrder(workOrder?.id ?? workOrderId);
  const { refetch: downloadWorkOrderDocument } = useDownloadWorkOrderDocument(
    workOrderDocument,
    download
  );
  const { data: invoiceDrawRequests, refetch: getInvoiceDrawRequests } =
    useInvoiceDrawRequestsByInvoice(invoice?.id);
  const {
    data: employeeTimesheets,
    isLoading: isLoadingEmployeeTimesheets,
    refetch: getEmployeeTimesheets,
  } = useEmployeeTimesheetsByFilter(workOrder?.id ?? workOrderId ?? "");

  const handleProposalCreateEdit = (workOrder: WorkOrder) => {
    workOrder?.proposal?.id
      ? navigate(`/proposals/edit/${workOrder.proposal.id}`)
      : navigate(`/proposals/create?workOrderId=${workOrder.id}`);
  };

  const handleInvoiceCreateEdit = (workOrder: WorkOrder) => {
    workOrder?.invoice?.id
      ? navigate(`/invoices/edit/${workOrder.invoice.id}`)
      : navigate(
          `/invoices/create?workOrderId=${workOrder.id}&customerId=${workOrder?.customer?.id}`
        );
  };

  const [canCreateInvoice, setCanCreateInvoice] = useState<
    { onCreate: () => void } | { disableCreate: boolean }
  >();
  const [canCreateProposal, setCanCreateProposal] = useState<
    { onCreate: () => void } | { disableCreate: boolean }
  >();

  const onEditCreateInvoice = useCallback(() => {
    if (workOrder) {
      workOrder.invoice
        ? navigate(`/invoices/edit/${workOrder.invoice.id}`)
        : navigate(
            `/invoices/create?workOrderId=${workOrder.id}&customerId=${workOrder?.customerId}`
          );
    }
  }, [workOrder, navigate]);

  const onEditCreateProposal = useCallback(
    (proposal?: Proposal) => {
      if (workOrder) {
        proposal
          ? navigate(`/proposals/edit/${proposal.id}`)
          : navigate(`/proposals/create?workOrderId=${workOrder.id}`);
      }
    },
    [navigate, workOrder]
  );

  useEffect(() => {
    invoice && getInvoiceDrawRequests();
  }, [invoice, getInvoiceDrawRequests]);

  useEffect(() => {
    setCanCreateInvoice(
      invoice === undefined || invoice.toString() === ""
        ? { onCreate: () => onEditCreateInvoice() }
        : { disableCreate: true }
    );
  }, [invoice, workOrder, onEditCreateInvoice]);

  useEffect(() => {
    setCanCreateProposal(
      proposal === undefined || proposal.toString() === ""
        ? { onCreate: () => onEditCreateProposal() }
        : { disableCreate: true }
    );
  }, [proposal, onEditCreateProposal]);

  useEffect(() => {
    if (customer && !workOrderId) {
      setNewWorkOrder({
        customer: customer,
        customerId: customer.id,
        billingAddress: customer.addresses.find(
          (address) => address.addressType?.id === BILLING_ADDRESS_TYPE
        ),
      } as WorkOrder);
    }
  }, [customer, workOrderId, BILLING_ADDRESS_TYPE]);

  useEffect(() => {
    download && workOrderDocument && downloadWorkOrderDocument();
    setDownload(false);
  }, [download, workOrderDocument, downloadWorkOrderDocument]);

  useEffect(() => {
    workOrderId && getEmployeeTimesheets();
  }, [workOrderId, getEmployeeTimesheets]);

  if (isLoadingWorkOrder) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageHeader
        title={
          workOrder
            ? `Edit Work Order - ${workOrder.workOrderNumber}`
            : "Create Work Order"
        }
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
        right={
          <>
            {workOrder && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant="contained"
                  onClick={() => handleProposalCreateEdit(workOrder)}
                >
                  {workOrder.proposal ? (
                    <>Update Proposal</>
                  ) : (
                    <>Create Proposal</>
                  )}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleInvoiceCreateEdit(workOrder)}
                >
                  {workOrder.invoice ? (
                    <>Update Invoice</>
                  ) : (
                    <>Create Invoice</>
                  )}
                </Button>
              </Stack>
            )}
          </>
        }
      />
      <WorkOrderForm
        workOrder={workOrder ?? newWorkOrder}
        onSubmitted={(_, schedules) => {
          setWorkOrderId(_.id);
          if (
            schedules &&
            schedules.length > 0 &&
            schedules[schedules.length - 1].startDate
          ) {
            const lastScheduled = schedules[schedules.length - 1].startDate;
            if (lastScheduled) {
              const date = format(new Date(lastScheduled), "yyyy-MM-dd");
              navigate(`/dispatch?goToDate=${date}`);
            }
          }
        }}
      />
      <br />
      {workOrder && (
        <>
          <SubTitle title="DETAILS" sx={{ mb: 2 }} />
          <WorkOrderNoteForm
            workOrderNote={{ workOrderId: workOrder.id } as WorkOrderNote}
          />
          <WorkOrderNoteDataGrid
            rows={workOrderNotes ?? []}
            workOrder={workOrder}
            loading={false}
            disableCreate
            disableExport
            disableColumnsButton
            disableQuickFilter
            sx={{ height: 150 }}
          />
          <BasicAccordion title={`Schedules - ${schedules?.length}`}>
            <Button
              variant="contained"
              onClick={() => setOpenScheduleGroupModal(true)}
              endIcon={<GroupAddIcon />}
              sx={{ mb: 1 }}
            >
              Schedule Group
            </Button>
            <ScheduleDataGrid
              rows={schedules ?? []}
              loading={isLoadingSchedules}
              onLink={(schedule) => {
                const startDate = format(
                  new Date(schedule.startDate as Date),
                  "yyyy-MM-dd"
                );
                navigate(`/dispatch?goToDate=${startDate}`);
              }}
              workOrder={workOrder}
              workOrderOnly
              scheduleType={scheduleTypes?.find(
                (scheduleType) => scheduleType.id === NORMAL
              )}
              autoHeight
            />
          </BasicAccordion>
          <BasicAccordion
            title={`Employee Timesheets - ${employeeTimesheets?.length}`}
          >
            <EmployeeTimesheetDataGrid
              rows={employeeTimesheets ?? []}
              loading={isLoadingEmployeeTimesheets}
              workOrder={workOrder}
              autoHeight
            />
          </BasicAccordion>
          <BasicAccordion
            title={`WorkOrder Documents - ${workOrderDocuments?.length}`}
          >
            <WorkOrderDocumentDataGrid
              rows={workOrderDocuments ?? []}
              workOrder={workOrder}
              loading={workOrderDocumentsLoading}
              onDownload={(workOrderDocument) => {
                setWorkOrderDocument(workOrderDocument);
                setDownload(true);
              }}
              autoHeight
            />
          </BasicAccordion>
          <Box sx={{ mt: 2 }}>
            <GenericDataGrid
              rows={proposal ? ([proposal] as Proposal[]) : []}
              columns={proposalColumns}
              loading={isLoadingProposal}
              onDelete={(proposal) => deleteProposal(proposal.id)}
              onEdit={(proposal) => onEditCreateProposal(proposal)}
              onView={(proposal) => navigate(`/proposals/view/${proposal.id}`)}
              {...canCreateProposal}
              title="Proposal"
              createTitle="Create Proposal"
              ignoreFields={[
                "id",
                "proposalLineItems",
                "createdTimestamp",
                "modifiedTimestamp",
              ]}
              autoHeight
              hideFooter
              disableExport
              disableColumnsButton
              disableQuickFilter
            />
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <GenericDataGrid
              rows={invoice ? ([invoice] as Invoice[]) : []}
              columns={invoiceColumns}
              loading={isLoadingInvoice}
              onDelete={(invoice) => deleteInvoice(invoice.id)}
              onEdit={() => onEditCreateInvoice()}
              onView={(invoice) => navigate(`/invoices/view/${invoice.id}`)}
              {...canCreateInvoice}
              title="Invoice"
              createTitle="Create Invoice"
              ignoreFields={[
                "id",
                "invoiceLineItems",
                "createdTimestamp",
                "modifiedTimestamp",
              ]}
              autoHeight
              hideFooter
              disableExport
              disableColumnsButton
              disableQuickFilter
            />
            {invoice && (
              <InvoiceDrawRequestDataGrid
                sx={{ mt: 2 }}
                rows={invoiceDrawRequests ?? []}
                onView={(invoice) =>
                  navigate(`/invoice-draw-request/view/${invoice.id}`)
                }
                title="Invoice Draw Request"
                invoiceId={invoice.id}
                loading={false}
                disableColumnsButton
                disableExport
                disableQuickFilter
                autoHeight
              />
            )}
          </Box>
          <BasicAccordion title={`Timeline - ${workOrderTimelines?.length}`}>
            <WorkOrderTimelineDataGrid
              rows={workOrderTimelines ?? []}
              loading={false}
            />
          </BasicAccordion>
        </>
      )}
      <ModalContainerChangeDetection
        open={openScheduleGroupModal}
        handleClose={() => {
          setOpenScheduleGroupModal(false);
        }}
        title="Create Group Schedule"
      >
        <ScheduleGroupForm
          schedule={
            {
              workOrder,
              scheduleType: scheduleTypes?.find(
                (scheduleType) => scheduleType.id === NORMAL
              ),
            } as Schedule
          }
          onSubmitted={() => setOpenScheduleGroupModal(false)}
          workOrderOnly={true}
        />
      </ModalContainerChangeDetection>
    </>
  );
};
