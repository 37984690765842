import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderNote } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteWorkOrderNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderNote: WorkOrderNote) =>
      remove("work-order-notes", workOrderNote.id),
    {
      onSuccess: (_, variables) => {
        enqueueSnackbar("Work Order Note Deleted", { variant: "success" });
        queryClient.setQueryData(
          [RQ.WORK_ORDER_NOTES, variables.workOrderId],
          (workOrderNotes: WorkOrderNote[] | undefined) => {
            return !workOrderNotes
              ? []
              : deleteEntity<WorkOrderNote>(workOrderNotes, "id", variables.id);
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Note Deleting Error", { variant: "error" });
      },
    }
  );
};
