import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  EmailModal,
  JobDescription,
  LegalDisclaimer,
  LoadingSpinner,
} from "../../components/ui";
import { useWorkOrderByInvoice } from "../../hooks/work-order";
import { EmailPayload } from "../../models";
import { useAuth } from "../../contexts/AuthContext";
import { ModalContainer } from "../../components/ui";
import { useInvoiceDrawRequest } from "../../hooks/invoice-draw-request";
import { useSendInvoiceDrawRequest } from "../../hooks/email";
import { useCustomerPaymentsByWorkOrder } from "../../hooks/customer-payment";
import { useEmployeeProfile } from "../../hooks/employee";
import { useAppSettingsContext } from "../../contexts";
import { APPLICATION_SETTING } from "../../globals/enums";
import config from "../../config/app-config";
import { WorkOrderStateModal } from "../work-orders/modals";

export const InvoiceDrawRequestView = () => {
  const { getSetting } = useAppSettingsContext();
  const { id } = useParams<{ id: string }>();
  const { data: employee } = useEmployeeProfile();
  const {
    data: invoiceDrawRequest,
    isLoading,
    isIdle,
    isError,
  } = useInvoiceDrawRequest(id ?? "");
  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    isIdle: isIdleWorkOrder,
    isError: isErrorWorkOrder,
  } = useWorkOrderByInvoice(invoiceDrawRequest?.invoice.id);
  const { mutateAsync: sendInvoiceDrawRequest } = useSendInvoiceDrawRequest();
  const { data: customerPayments } = useCustomerPaymentsByWorkOrder(
    workOrder?.id
  );
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [workOrderState, setWorkOrderState] = useState("");
  const navigate = useNavigate();
  const { email } = useAuth();

  const [invoiceLegal] = useState(
    getSetting(APPLICATION_SETTING.INVOICE_LEGAL_DISCLAIMER)
      .defaultValue as string
  );
  const [companyAddress] = useState(
    getSetting(APPLICATION_SETTING.COMPANY_ADDRESS).defaultValue as string
  );

  useEffect(() => {
    workOrder && setWorkOrderState(workOrder?.workOrderState?.name);
  }, [workOrder]);

  if ((isLoading || isLoadingWorkOrder) && (isIdle || isIdleWorkOrder) && id) {
    return <LoadingSpinner />;
  }

  if (isError || isErrorWorkOrder) {
    return <h2>Error</h2>;
  }

  const sendEmail = async (emailPayload: EmailPayload) => {
    if (id && emailPayload && workOrder) {
      emailPayload.from = email();
      invoiceDrawRequest &&
        sendInvoiceDrawRequest({
          invoiceDrawRequestId: invoiceDrawRequest.id,
          emailPayload,
        });
    }
    setOpenEmailModal(false);
  };

  const print = () => {
    let printContents = document.getElementById("printsection")?.innerHTML;
    let originalContents = document.body.innerHTML;
    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
    }
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <>
      {invoiceDrawRequest && workOrder && (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 2, mb: 1, width: "80%" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                      variant="contained"
                    >
                      Back
                    </Button>
                    <Button onClick={print} variant="contained">
                      Print
                    </Button>
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Invoice Draw Request</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    endIcon={<EmailIcon />}
                    onClick={() => setOpenEmailModal(true)}
                  >
                    Prepare Email
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 2, mb: 1, width: "80%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <WorkOrderStateModal
                    workOrder={workOrder}
                    onSubmit={(updatedWorkOrder) =>
                      setWorkOrderState(updatedWorkOrder?.workOrderState?.name)
                    }
                  />
                  - Current Work Order State: <b>{workOrderState}</b>
                </Box>
              </Stack>
            </Paper>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper sx={{ p: 7, width: "80%" }} id="printsection">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img
                        src={`${config.COMPANY_IMAGE_URL}/logo.png`}
                        width={200}
                        alt="logo"
                      />
                    </Grid>
                    <Grid item>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: companyAddress,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    sx={{ width: 320 }}
                  >
                    <Box sx={{ textAlign: "right" }}>
                      <Typography variant="h4" gutterBottom>
                        Invoice Draw Request
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      {format(new Date(), "MMM dd, yyyy")}
                    </Box>
                    <Grid container sx={{ textAlign: "right" }}>
                      <Grid item xs={6}>
                        Invoice #
                      </Grid>
                      <Grid item xs={6}>
                        {workOrder?.workOrderNumber}
                      </Grid>
                      <Grid item xs={6}>
                        Due:
                      </Grid>
                      <Grid item xs={6}>
                        {invoiceDrawRequest?.dueDate &&
                          format(
                            parseISO(
                              invoiceDrawRequest?.dueDate?.toString() ?? ""
                            ),
                            "MMM dd, yyyy"
                          )}
                      </Grid>
                      <Grid item xs={6}>
                        Original Invoice Amount $
                      </Grid>
                      <Grid item xs={6}>
                        {invoiceDrawRequest.invoice.grandTotal}
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3 }}
              >
                <Grid item>
                  <Typography variant="h6">Bill To</Typography>
                  {workOrder?.customer?.firstName}{" "}
                  {workOrder?.customer?.lastName}
                  <br />
                  {workOrder?.billingAddress?.streetNumber}{" "}
                  {workOrder?.billingAddress?.streetName} <br />
                  {workOrder?.billingAddress?.city}{" "}
                  {workOrder?.billingAddress?.province}{" "}
                  {workOrder?.billingAddress?.postalCode} <br />
                  {workOrder?.customer?.phone}
                </Grid>
                <Grid item>
                  <Typography variant="h6">Job Site</Typography>
                  {workOrder?.customer?.firstName}{" "}
                  {workOrder?.customer?.lastName}
                  <br />
                  {workOrder?.siteAddress?.streetNumber}{" "}
                  {workOrder?.siteAddress?.streetName} <br />
                  {workOrder?.siteAddress?.city}{" "}
                  {workOrder?.siteAddress?.province}{" "}
                  {workOrder?.siteAddress?.postalCode}{" "}
                </Grid>
              </Grid>
              <JobDescription description={workOrder?.jobDescription} />
              <br />
              <TableContainer
                component={Paper}
                sx={{ mt: 3, border: "none" }}
                elevation={0}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h6" align="center">
                          Payment History
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body1">Amount</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">Payment Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">Payment Method</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" align="right">
                          Date
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerPayments &&
                      customerPayments.map((customerPayment) => (
                        <TableRow key={customerPayment.id}>
                          <TableCell>
                            <Typography variant="body1">
                              {customerPayment.amount}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {customerPayment.paymentType.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {customerPayment.paymentMethod.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body1">
                              {customerPayment.paymentDate &&
                                format(
                                  parseISO(
                                    customerPayment.paymentDate?.toString() ??
                                      ""
                                  ),
                                  "MMM dd, yyyy"
                                )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                style={{
                  borderStyle: "double",
                  borderRight: "none",
                  borderLeft: "none",
                  borderColor: "#DCDCDC",
                }}
                sx={{ p: 1 }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={5}
                >
                  <Typography variant="h6">
                    Total Paid: $
                    {customerPayments && (
                      <>
                        {customerPayments.reduce((pre, curr) => {
                          return pre + curr.amount;
                        }, 0)}
                      </>
                    )}
                  </Typography>
                  <Typography variant="h6">
                    Invoice Total: $
                    {Number(invoiceDrawRequest?.total).toFixed(2)}
                  </Typography>
                </Stack>
              </Box>
              <LegalDisclaimer body={invoiceLegal} sx={{ mt: 5 }} />
            </Paper>
          </Box>

          <ModalContainer
            open={openEmailModal}
            title="Prepare Email"
            handleClose={() => setOpenEmailModal(false)}
          >
            <EmailModal
              onSubmit={(emailPayload) => sendEmail(emailPayload)}
              defaultSearchTerm={workOrder.customer.email}
              customer={workOrder.customer}
              defaultBody={employee?.signOff}
            />
          </ModalContainer>
        </>
      )}
    </>
  );
};
