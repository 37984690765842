import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Invoice } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteInvoice = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("invoices", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Invoice Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.INVOICES,
        (invoices: Invoice[] | undefined) => {
          return !invoices ? [] : deleteEntity<Invoice>(invoices, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Invoice Deleting Error", { variant: "error" });
    },
  });
};
