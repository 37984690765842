import { useQuery } from "react-query";

import { InvoiceCategory } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useInvoiceCategories = () => {
  return useQuery<InvoiceCategory[], Error>(RQ.INVOICE_CATEGORIES, async () =>
    getAll<InvoiceCategory>("invoice-categories")
  );
};
