import { Box, Divider, Grid, Typography } from "@mui/material";
import { WorkOrderTimeline } from "../../../models";
import { format } from "date-fns";

interface Props {
  workOrderTimeline: WorkOrderTimeline;
}

export const DisplayWorkOrderTimeline = (props: Props) => {
  const { workOrderTimeline } = props;
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <strong>Event</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrderTimeline.event}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Work Order State</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrderTimeline.workOrder.workOrderState.name}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Note</strong>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {workOrderTimeline.note}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Date</strong>
        </Grid>
        <Grid item xs={9}>
          {format(new Date(workOrderTimeline.eventDate), "MMMM do, yyyy")}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Updated By</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrderTimeline.employee &&
            `${workOrderTimeline.employee?.firstName} ${workOrderTimeline.employee?.lastName}`}
        </Grid>
      </Grid>
    </Box>
  );
};
