import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { EmployeeTimesheet } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchEmployeeTimesheet = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (employeeTimesheet: Partial<EmployeeTimesheet>) =>
      patch({
        route: "employee-timesheets",
        id: employeeTimesheet.id ?? "",
        entity: employeeTimesheet,
      }),
    {
      onSuccess: (employeeTimesheet) => {
        enqueueSnackbar("Employee Timesheet Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.EMPLOYEE_TIMESHEETS,
          (employeeTimesheets: EmployeeTimesheet[] | undefined) =>
            !employeeTimesheets
              ? []
              : patchEntity<EmployeeTimesheet>(
                  employeeTimesheets,
                  employeeTimesheet,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Employee Timesheet Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
