import { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Paper, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { CustomerAddress } from "../../models";
import {
  AutoSearch,
  ConfirmationModal,
  DisplayInfo,
  ModalContainerChangeDetection,
} from "../../components/ui";
import { CustomerForm, NewCustomerForm } from "../../components/forms";
import { useCustomer, useDeleteCustomer } from "../../hooks/customer";
import { useCustomerSearch } from "../../hooks/customer-address";
import { PageHeader } from "../../components/layout";

export const CustomersPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { mutate: deleteCustomer } = useDeleteCustomer();
  const [customerId, setCustomerId] = useState<string>(id ?? "");
  const { data: customer } = useCustomer(customerId);
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const onConfirmationModalClosed = (selection: boolean) => {
    if (selection && customer) {
      deleteCustomer(customer.id);
      navigate("/customers");
    }
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <PageHeader title="Customers" />
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" spacing={2}>
          <AutoSearch
            label="Search Customer By Name, Address, or P.O ..."
            keys={[
              "customerNumber",
              "fullName",
              "email",
              "phone",
              "address",
              "po",
            ]}
            onOptionSelected={(customer: CustomerAddress | null) => {
              if (customer) {
                setCustomerId(customer.customerId);
                navigate(`/customers/${customer.customerId}`);
              }
            }}
            searchFunction={useCustomerSearch}
            separator="|"
            sx={{ width: 700 }}
          />
          <Button
            onClick={() => setOpenCreateCustomerModal(true)}
            endIcon={<AddCircleIcon />}
            variant="outlined"
          >
            Create Customer
          </Button>
          {customer && (
            <>
              <Button
                onClick={() => setOpenEditCustomerModal(true)}
                endIcon={<EditIcon />}
                variant="outlined"
              >
                Edit Customer
              </Button>
              <Button
                onClick={() =>
                  navigate(`/work-order/edit?customerId=${customer.id}`)
                }
                endIcon={<AddCircleIcon />}
                variant="outlined"
                disabled={customer.inactive}
              >
                Create Work Order
              </Button>
            </>
          )}
        </Stack>
      </Paper>
      <ModalContainerChangeDetection
        open={openCreateCustomerModal}
        handleClose={() => setOpenCreateCustomerModal(false)}
        title="Create Customer"
      >
        <NewCustomerForm
          onSubmitted={(customer) => {
            setCustomerId(customer.id);
            setOpenCreateCustomerModal(false);
            navigate(`/customers/${customer.id}`);
          }}
        />
      </ModalContainerChangeDetection>
      <ModalContainerChangeDetection
        open={openEditCustomerModal}
        handleClose={() => setOpenEditCustomerModal(false)}
        title={`${customer ? "Edit" : "Create"} Customer`}
      >
        <CustomerForm
          onSubmitted={(customer) => {
            setCustomerId(customer.id);
            navigate(`/customers/${customer.id}`);
            setOpenEditCustomerModal(false);
          }}
          customer={customer}
        />
      </ModalContainerChangeDetection>
      <ConfirmationModal
        title="Are you sure you want to delete this Customer"
        open={openConfirmationModal}
        onClose={(selection: boolean) => onConfirmationModalClosed(selection)}
      >
        {customer && (
          <Box sx={{ mb: 1 }}>
            <DisplayInfo
              entity={customer}
              displayEmpties={true}
              ignore={[
                "id",
                "customerId",
                "createdTimestamp",
                "modifiedTimestamp",
                "addresses",
              ]}
            />
          </Box>
        )}
      </ConfirmationModal>
      <Outlet />
    </>
  );
};
