import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InventoryItem } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddInventoryItem = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (inventoryItem: Omit<InventoryItem, "id">) =>
      add<InventoryItem>("inventory-items", inventoryItem),
    {
      onSuccess: (inventoryItem) => {
        enqueueSnackbar("Inventory Item Added", { variant: "success" });
        queryClient.setQueryData<InventoryItem[]>(
          RQ.INVENTORY_ITEMS,
          (inventoryItems: InventoryItem[] | undefined) => {
            return [...(inventoryItems ?? []), inventoryItem];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Inventory Item Adding Error", { variant: "error" });
      },
    }
  );
};
