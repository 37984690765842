import { useState } from "react";
import { useDeleteInvoiceCategory } from "../../../hooks/invoice-category";
import { InvoiceCategory } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { invoiceCategoryColumns as columns } from "./invoice-category.columns";
import { InvoiceCategoryForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const InvoiceCategoryDataGrid = (props: Props<InvoiceCategory>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [invoiceCategory, setInvoiceCategory] = useState<InvoiceCategory>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteInvoiceCategory } = useDeleteInvoiceCategory();

  const onEditCreate = (invoiceCategory?: InvoiceCategory) => {
    invoiceCategory
      ? setInvoiceCategory(invoiceCategory)
      : setInvoiceCategory(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onDelete={(invoiceCategory) => deleteInvoiceCategory(invoiceCategory.id)}
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={onLink}
      title={title}
      createTitle="Create Invoice Category"
      deleteTitle="Do you want to delete this Invoice Category"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${invoiceCategory ? "Edit" : "Create"} Invoice Category`}
      >
        <InvoiceCategoryForm
          onSubmitted={() => setOpenModal(false)}
          invoiceCategory={invoiceCategory}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
