import { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Paper, Stack } from "@mui/material";
import { ControllerRenderProps, useFieldArray, useForm } from "react-hook-form";
import { orderBy } from "lodash";

import { Proposal, ProposalLineItem } from "../../../models";
import { proposalSchema as schema } from "../schemas";
import { useAddProposal, usePatchProposal } from "../../../hooks/proposal";
import {
  useWorkOrder,
  useWorkOrderByProposal,
  useWorkOrdersByQuery,
} from "../../../hooks/work-order";
import {
  useInventoryItem,
  useSearchInventoryItems,
} from "../../../hooks/inventory-item";
import { FormContainer } from "../../layout";
import { FormAutocomplete, FormSubmitButton } from "../../form-components";
import { DisplayCustomerShort, LoadingSpinner } from "../../ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { calculateProposal } from "./functions/calculate-proposal";
import { ProposalFooter } from "./components/ProposalFooter";
import { useShouldBlock } from "../../../globals/callbacks/useShouldBlock";
import { APPLICATION_SETTING } from "../../../globals/enums";
import { useAppSettingsContext } from "../../../contexts/AppSettingContext";
import { ProposalLineItems } from "./components/ProposalLineItems";

interface Props {
  proposal?: Proposal;
  onSubmitted?: () => void;
}

export const ProposalForm = (props: Props) => {
  const { proposal, onSubmitted } = props;
  const { getSetting } = useAppSettingsContext();
  const HST = Number(getSetting(APPLICATION_SETTING.HST).defaultValue);
  const DEFAULT_PROPOSAL_INVENTORY_ITEM = getSetting(
    APPLICATION_SETTING.DEFAULT_PROPOSAL_INVENTORY_ITEM
  ).settingId;
  const { mutateAsync: createProposal } = useAddProposal();
  const { mutateAsync: patchProposal } = usePatchProposal();
  const [searchParams] = useSearchParams();
  let workOrderId = searchParams.get("workOrderId");
  const [searchTermInventoryItem, setSearchTermInventoryItem] =
    useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [calculateAction, setCalculateAction] = useState<boolean>(false);
  const [navigateToPreview, setNavigateToPreview] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setChangeDetection, getChangeDetection } = useChangeDetection();
  const {
    data: workOrders,
    isLoading: isLoadingWorkOrders,
    isFetching: isFetchingWorkOrders,
  } = useWorkOrdersByQuery(searchTerm);
  const {
    data: workOrderByProposal,
    isLoading: isLoadingWorkOrderByProposal,
    isIdle: isIdleWorkOrderByProposal,
  } = useWorkOrderByProposal(proposal?.id);
  const { data: workOrder } = useWorkOrder(workOrderId);
  const { data: inventoryItems, refetch: getInventoryItems } =
    useSearchInventoryItems(searchTermInventoryItem);
  useShouldBlock({ changeDetection: getChangeDetection });
  const { data: defaultInventoryItem } = useInventoryItem(
    DEFAULT_PROPOSAL_INVENTORY_ITEM
  );

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Proposal>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(proposal),
  });
  const { fields, append, remove, move } = useFieldArray({
    name: "proposalLineItems",
    control,
    keyName: "_id",
  });
  const watchProposal = watch();

  const calculate = useCallback(() => {
    const updatedProposal = calculateProposal(watchProposal, HST);
    setValue("grandTotal", updatedProposal.grandTotal);
    setValue("subtotal", updatedProposal.subtotal);
    setValue("hstTotal", updatedProposal.hstTotal);
    updatedProposal.proposalLineItems.forEach((proposalLineItem, index) => {
      for (const keyName in proposalLineItem) {
        setValue(
          `proposalLineItems.${index}.${keyName as keyof ProposalLineItem}`,
          proposalLineItem[keyName as keyof ProposalLineItem]
        );
      }
    });
  }, [watchProposal, HST, setValue]);

  const resolveDefaultItem = (
    field: ControllerRenderProps<Proposal, `proposalLineItems.${number}`>
  ) => {
    if (field?.value?.inventoryItem) {
      return field.value.inventoryItem;
    }
  };

  const onRemoveItem = (index: number) => {
    remove(index);
    setCalculateAction(true);
  };

  const applyOrdinal = (proposal: Proposal) => {
    proposal.proposalLineItems.forEach(
      (proposalLineItem, index) => (proposalLineItem.ordinal = index)
    );
    return proposal;
  };

  // INITIAL CALCULATE
  useEffect(() => {
    HST && setCalculateAction(true);
  }, [HST]);

  useEffect(() => {
    if (proposal) {
      setValue(
        "proposalLineItems",
        orderBy(proposal.proposalLineItems, ["ordinal"])
      );
    }
  }, [proposal, setValue]);

  // IF PROPOSAL EMPTY THEN ADD LINE ITEM BY DEFAULT
  useEffect(() => {
    if (!proposal?.id && defaultInventoryItem) {
      append({
        display: "",
        addToTotal: true,
        inventoryItem: defaultInventoryItem,
        inventoryItemId: defaultInventoryItem && defaultInventoryItem.id,
        price: 0,
      } as ProposalLineItem);
    }
  }, [proposal, defaultInventoryItem, append]);

  useEffect(() => {
    searchTerm && searchTerm.length > 0 && getInventoryItems();
  }, [searchTerm, getInventoryItems]);

  useEffect(() => {
    workOrderByProposal && setValue("workOrderId", workOrderByProposal.id);
  }, [workOrderByProposal, setValue]);

  useEffect(() => {
    workOrderId && setValue("workOrderId", workOrderId);
  }, [workOrderId, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name?.includes("proposalLineItems.")) {
        setChangeDetection(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setChangeDetection]);

  useEffect(() => {
    if (watchProposal && calculateAction) {
      calculate();
      setCalculateAction(false);
    }
  }, [watchProposal, calculateAction, calculate]);

  if (
    (isLoadingWorkOrderByProposal || isIdleWorkOrderByProposal) &&
    proposal?.id
  ) {
    return <LoadingSpinner />;
  }

  return (
    <FormContainer
      onSubmit={handleSubmit(async (proposal) => {
        proposal = applyOrdinal(proposal);
        proposal = proposal.id
          ? await patchProposal(proposal as Proposal)
          : await createProposal(proposal as Proposal);
        setValue("id", proposal.id);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
        navigateToPreview && navigate(`/proposals/view/${proposal.id}`);
      })}
    >
      <Paper sx={{ p: 1, mb: 1 }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <FormAutocomplete
                  name="workOrderId"
                  label="Work Order Number"
                  labelKeys={["workOrderNumber"]}
                  valueKey="id"
                  options={workOrders ?? []}
                  loading={isLoadingWorkOrders || isFetchingWorkOrders}
                  onInputChange={setSearchTerm}
                  control={control}
                  defaultValue={workOrderByProposal ?? workOrder}
                  sx={{ width: 200 }}
                />
              </Grid>
              <Grid item>
                {workOrderByProposal?.customer && (
                  <DisplayCustomerShort
                    customer={workOrderByProposal?.customer}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              onClick={() => setNavigateToPreview(true)}
              color="success"
            >
              Save & View Proposal Email
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Stack direction="column">
        <Paper sx={{ p: 2, mt: 1, mb: 1 }}>
          {defaultInventoryItem && (
            <ProposalLineItems
              control={control}
              fields={fields}
              errors={errors}
              append={append}
              move={move}
              inventoryItems={inventoryItems ?? []}
              defaultInventoryItem={defaultInventoryItem}
              onRemoveItem={onRemoveItem}
              onInventoryItemInputChanged={setSearchTermInventoryItem}
              onInventoryItemChanged={(inventoryItem, index) => {
                if (inventoryItem && index) {
                  setValue(
                    `proposalLineItems.${index}.price`,
                    inventoryItem.price
                  );
                }
                setCalculateAction(true);
              }}
              resolveDefaultItem={resolveDefaultItem}
              onCalculate={calculate}
            />
          )}
        </Paper>
      </Stack>
      {watchProposal && <ProposalFooter proposal={watchProposal} />}
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
