import { isValid } from "date-fns";
import * as yup from "yup";

export const invoiceLineItemSchema = yup.object().shape({
  inventoryItem: yup.object().default({}),
  inventoryItemId: yup
    .string()
    .default("")
    .transform((value) => value ?? ""),
  quantity: yup.number().label("Quantity"),
  serialNumber: yup
    .string()
    .label("Serial Number")
    .transform((value) => value ?? ""),
  cost: yup
    .number()
    .label("Cost")
    .default(0)
    .transform((value) => value ?? 0),
  price: yup
    .number()
    .label("Price")
    .default(0)
    .transform((value) => value ?? 0),
  note: yup
    .string()
    .label("Note")
    .transform((value) => value ?? ""),
  hide: yup.boolean().label("Hide").default(false),
  warrantyPartsStartDate: yup
    .date()
    .label("Warranty Parts Start Date")
    .transform((value) => (isValid(value) ? (value as Date) : null))
    .nullable()
    .default(null),
  warrantyPartsExpiryDate: yup
    .date()
    .label("Warranty Parts Expiry Date")
    .transform((value) => (isValid(value) ? (value as Date) : null))
    .nullable()
    .default(null),
  warrantyLabourStartDate: yup
    .date()
    .label("Warranty Labour Start Date")
    .transform((value) => (isValid(value) ? (value as Date) : null))
    .nullable()
    .default(null),

  warrantyLabourExpiryDate: yup
    .date()
    .label("Warranty Labour Expiry Date")
    .transform((value) => (isValid(value) ? (value as Date) : null))
    .nullable()
    .default(null),
});
