import { useState } from "react";

import { InvoiceDrawRequest } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { invoiceDrawRequestColumns } from "./invoice-draw-request.columns";
import { InvoiceDrawRequestForm } from "../../forms";
import { useDeleteInvoiceDrawRequest } from "../../../hooks/invoice-draw-request";
import { SxProps, Theme } from "@mui/material";
import { ModalContainerChangeDetection } from "../../ui";

interface Props {
  rows: InvoiceDrawRequest[];
  invoiceId: string;
  loading: boolean;
  onClick?: (entity: InvoiceDrawRequest) => void;
  onLink?: (entity: InvoiceDrawRequest) => void;
  onView?: (entity: InvoiceDrawRequest) => void;
  onEdit?: (entity: InvoiceDrawRequest) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  sx?: SxProps<Theme>;
}

export const InvoiceDrawRequestDataGrid = (props: Props) => {
  const {
    rows,
    invoiceId,
    loading,
    onClick,
    onLink,
    onView,
    onEdit,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
    sx,
  } = props;
  const [invoiceDrawRequest, setInvoiceDrawRequest] =
    useState<InvoiceDrawRequest>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteInvoiceDrawRequest } = useDeleteInvoiceDrawRequest();

  const onEditCreate = (invoiceDrawRequest?: InvoiceDrawRequest) => {
    if (onEdit && invoiceDrawRequest) {
      return onEdit(invoiceDrawRequest);
    }
    invoiceDrawRequest
      ? setInvoiceDrawRequest({ ...invoiceDrawRequest })
      : setInvoiceDrawRequest(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      sx={sx}
      rows={rows}
      columns={invoiceDrawRequestColumns}
      loading={loading}
      onClick={onClick}
      onLink={onLink}
      onDelete={(invoiceDrawRequest) =>
        deleteInvoiceDrawRequest(invoiceDrawRequest)
      }
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      onDoubleClick={onEditCreate}
      onView={onView}
      title={title}
      createTitle="Create Invoice Draw Request"
      deleteTitle="Do you want to delete Invoice Draw Request?"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp", "invoice"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "modifiedTimestamp", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${
          invoiceDrawRequest?.id ? "Edit" : "Create"
        } Invoice Draw Request`}
      >
        <InvoiceDrawRequestForm
          onSubmitted={() => setOpenModal(false)}
          invoiceDrawRequest={invoiceDrawRequest}
          invoiceId={invoiceId}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
