import { ResourceLabelContentArg } from "@fullcalendar/resource-common";
import Button from "@mui/material/Button";

export function renderResourceContent(
  eventInfo: ResourceLabelContentArg,
  onServiceToggle: (eventInfo: ResourceLabelContentArg) => void
) {
  return (
    <Button onClick={() => onServiceToggle(eventInfo)}>
      <b>{eventInfo.resource.title}</b>
    </Button>
  );
}
