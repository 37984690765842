import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";

import { PageHeader } from "../../components/layout";
import { LoadingSpinner } from "../../components/ui";
import { useCustomerTiers } from "../../hooks/customer-tier";
import { CustomerTierDataGrid } from "../../components/grids";

export const CustomerTiersPage = () => {
  const {
    data: customerTiers,
    isLoading,
    isIdle,
    isError,
    error,
  } = useCustomerTiers();
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title="Customer Tiers"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <CustomerTierDataGrid rows={customerTiers} loading={false} autoHeight />
    </>
  );
};
