import { rateToDecimal } from "./rate-to-decimal";

/**
 * This does a simple interest calculation
 * For example a $500 princial that is calculated over a period of 2 years at a rate of 2% will be 520
 * @param principal The original amount owed
 * @param rate The interest rate in non-decimal form for example a value of 2 and not 0.02.
 * @param daysOverdue This is the number of periods to calculate against - you can think of this as the amount of years overdue for example. Default period is 1
 * @param rateIsDecimal Is this only used when you are providing a rate value that is already in it's decimal form so for example 0.02 instead of 2
 * @returns {number}
 */
export const calculateSimpleInterest = (
  principal: number,
  rate: number,
  daysOverdue: number,
  options: {
    rateIsDecimal?: boolean;
    onlyInterestPortion?: boolean;
  } = {
    rateIsDecimal: false,
    onlyInterestPortion: false,
  }
): number => {
  let total = 0;
  daysOverdue = daysOverdue > 0 ? daysOverdue : 0;
  const dailyInterest = options.rateIsDecimal
    ? (principal * rate) / 365
    : (principal * rateToDecimal(rate)) / 365;

  total = options.onlyInterestPortion
    ? dailyInterest * daysOverdue
    : dailyInterest * daysOverdue + principal;

  // if (options.rateIsDecimal) {
  //   total = Number(principal * (1 + rate * periods));
  // } else {
  //   total = Number(principal * (1 + rateToDecimal(rate) * periods));
  // }

  // if (options.onlyInterestPortion) {

  //   total = total - principal;
  // }

  return total;
};
