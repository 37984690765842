import { useQuery } from "react-query";

import { Employee } from "../../models";
import { queryEntity } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useEmployeesByQuery = (searchTerm: string) => {
  return useQuery<Employee[], Error>(
    [RQ.EMPLOYEES_SEARCH],
    async () => queryEntity("employees", searchTerm),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
