import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteWorkOrderDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderDocument: WorkOrderDocument) =>
      remove("work-orders/documents", workOrderDocument.id),
    {
      onSuccess: (_, workOrderDocument) => {
        enqueueSnackbar("Work Order Document Deleted", { variant: "success" });
        queryClient.setQueryData(
          [RQ.WORK_ORDER_DOCUMENTS, workOrderDocument.workOrderId],
          (workOrderDocuments: WorkOrderDocument[] | undefined) => {
            return !workOrderDocuments
              ? []
              : deleteEntity<WorkOrderDocument>(
                  workOrderDocuments,
                  "id",
                  workOrderDocument.id
                );
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Document Deleting Error", {
          variant: "error",
        });
      },
    }
  );
};
