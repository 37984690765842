import * as yup from "yup";

export const vendorCostSchema = yup.object().shape({
  cost: yup.number().label("Cost").default(0),
  primaryCost: yup.boolean().label("Primary Cost").default(false),
  vendorId: yup
    .string()
    .label("Vendor")
    .default("")
    .transform((value) => value ?? ""),
});
