import { useQuery } from "react-query";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { InvoiceStatement } from "../../models";
import { format } from "date-fns";

export const useInvoiceStatements = (
  startDate: Date,
  endDate: Date,
  customerId?: string,
  isPaid?: string,
  isOverdue?: string,
  workOrderStates?: string[],
  addresses?: string[],
  excludes?: string[]
) => {
  let params = new URLSearchParams();
  startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
  endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
  workOrderStates &&
    workOrderStates.length > 0 &&
    params.append("workOrderStates", workOrderStates.join(","));
  addresses &&
    addresses.length > 0 &&
    params.append("addresses", addresses.join(","));
  excludes &&
    excludes.length > 0 &&
    params.append("excludes", excludes.join(","));
  customerId && params.append("customerId", customerId);
  isPaid && params.append("isPaid", isPaid);
  isOverdue && params.append("isOverdue", isOverdue);
  return useQuery<InvoiceStatement[], Error>(RQ.INVOICE_STATEMENTS, async () =>
    getAll<InvoiceStatement>(`invoice-statements?${params}`)
  );
};
