import {
  Autocomplete,
  Box,
  BoxProps,
  CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { RecursiveKeyof } from "../../../types";
import { entityStringLabel } from "../../../utils";
import { isEqual } from "lodash";

interface Props<T, K extends FieldValues> extends BoxProps {
  name: Path<K>;
  label: string;
  keys: RecursiveKeyof<T>[];
  valueKey?: keyof T;
  control: Control<K, any>;
  separator?: string;
  loading: boolean;
  data: T[];
  initialValue?: T[] | undefined;
  onInputChanged?: (searchTerm: string) => void;
  onOptionSelected?: (entity: T[] | null) => void;
}

export const FormAutocompleteMultiple = <T, K extends FieldValues>(
  props: Props<T, K>
) => {
  const {
    name,
    label,
    keys,
    valueKey,
    control,
    separator,
    loading,
    data,
    initialValue,
    onInputChanged,
    onOptionSelected,
    ...rest
  } = props;
  let value =
    initialValue && typeof initialValue === "object"
      ? { defaultValue: initialValue }
      : null;
  const { field, fieldState } = useController({ name, control });

  return (
    <Box {...rest}>
      <Autocomplete
        multiple
        clearOnBlur
        id={label}
        getOptionLabel={(option) => entityStringLabel(option, keys, separator)}
        onChange={(_, value) => {
          value && valueKey
            ? field.onChange(value.map((x) => x[valueKey]))
            : field.onChange(value);
          value && onOptionSelected && onOptionSelected(value);
        }}
        loading={loading}
        onInputChange={(event, value) => {
          if (event && event.type !== "click") {
            onInputChanged && onInputChanged(value);
          }
        }}
        options={data ?? []}
        isOptionEqualToValue={(option, value) => isEqual(option, value)}
        {...value}
        // {...defaultValue}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
