import { Paper } from "@mui/material";
import { LoadingSpinner } from "../../../../components/ui";
import { ReportHeader } from "../../components/ReportHeader";
import { useInventoryUsage } from "../../../../hooks/report";
import { GenericDataGrid } from "../../../../components/grids";
import { GridColDef } from "@mui/x-data-grid";

export const inventoryUsageColumns: GridColDef[] = [
  { field: "item", headerName: "Item", flex: 1 },
  { field: "count", headerName: "Count", flex: 1 },
];

export const InventoryUsageReport = () => {
  const { data, isLoading, isIdle, isError, error } = useInventoryUsage();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <ReportHeader title="Inventory Usage" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <GenericDataGrid
          rows={data}
          columns={inventoryUsageColumns}
          loading={isLoading}
          title="Inventory Usage"
          ignoreFields={["id"]}
          initialState={{
            sorting: {
              sortModel: [{ field: "item", sort: "asc" }],
            },
          }}
          autoHeight
        />
      </Paper>
    </>
  );
};
