import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";

export const employeeHolidaysColumns: GridColDef[] = [
  {
    field: "employeeId",
    headerName: "Employee",
    flex: 1,
    valueGetter: (params) =>
      params.row.employee
        ? `${params.row.employee.firstName} ${params.row.employee.lastName}`
        : "",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.startDate
        ? format(parseISO(params.row.startDate), "PPpp")
        : "",
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.endDate ? format(parseISO(params.row.endDate), "PPpp") : "",
  },
];
