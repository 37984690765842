import { GridColDef } from "@mui/x-data-grid";
import { Address } from "../../../models";

const getAddress = (address: Address): string => {
  const streetNumber = address.streetNumber ?? "";
  const streetName = address.streetName ?? "";
  const unit = address.unit ? `${address.unit} - ` : "";
  const city = address.city ?? "";
  const province = address.province ?? "";
  return `${unit} ${streetNumber} ${streetName} ${city}, ${province}`;
};

export const addressColumns: GridColDef<Address>[] = [
  {
    field: "address",
    headerName: "Address",
    flex: 1,
    valueGetter: (params) => getAddress(params.row),
  },
  {
    field: "name",
    headerName: "Address Type",
    flex: 1,
    valueGetter: (params) => params.row.addressType?.name,
  },
];
