import { useParams, useNavigate, Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { Invoice, Proposal, Schedule, WorkOrderDocument } from "../../models";
import {
  EmployeeTimesheetDataGrid,
  GenericDataGrid,
  InvoiceDrawRequestDataGrid,
  ScheduleDataGrid,
  WorkOrderDocumentDataGrid,
  WorkOrderTimelineDataGrid,
  invoiceColumns,
  proposalColumns,
} from "../../components/grids";
import {
  AddressDisplay,
  BasicAccordion,
  DisplayCustomer,
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import { useDeleteInvoice, useInvoiceByWorkOrder } from "../../hooks/invoice";
import {
  useDeleteProposal,
  useProposalByWorkOrder,
} from "../../hooks/proposal";
import { useWorkOrderTimelinesByWorkOrder } from "../../hooks/work-order-timeline";
import { useSchedulesByWorkOrder } from "../../hooks/schedule";
import { WorkOrderNoteDataGrid } from "../../components/grids/work-order-note/WorkOrderNote.grid";
import { useWorkOrderNotesByWorkOrder } from "../../hooks/work-order-note";
import { useWorkOrder } from "../../hooks/work-order";
import { format } from "date-fns";
import { useScheduleTypes } from "../../hooks/schedule-type";
import { ScheduleGroupForm } from "../../components/forms";
import { useAppSettingsContext } from "../../contexts";
import { APPLICATION_SETTING } from "../../globals/enums";
import {
  useDownloadWorkOrderDocument,
  useWorkOrderDocumentsByDateRange,
} from "../../hooks/work-order-document";
import { DisplayWorkOrderShort } from "../../components/ui";
import { PageHeader } from "../../components/layout";
import { useInvoiceDrawRequestsByInvoice } from "../../hooks/invoice-draw-request";
import { useEmployeeTimesheetsByFilter } from "../../hooks/employee-timesheet";

interface Props {
  showBackButton?: boolean;
}

export const WorkOrderPage = (props: Props) => {
  const { showBackButton } = props;
  const { id } = useParams<{ id: string }>();
  const [customerId, setCustomerId] = useState("");
  const { getSetting } = useAppSettingsContext();
  const NORMAL = getSetting(APPLICATION_SETTING.NORMAL).settingId;
  const [openScheduleGroupModal, setOpenScheduleGroupModal] = useState(false);
  const {
    data: workOrder,
    isLoading,
    isError: isErrorWorkOrder,
    error: errorWorkOrder,
    isIdle,
    refetch: getWorkOrder,
  } = useWorkOrder(id);
  const [workOrderDocument, setWorkOrderDocument] =
    useState<WorkOrderDocument>();
  const [download, setDownload] = useState<boolean>(false);
  const { data: workOrderTimelines } = useWorkOrderTimelinesByWorkOrder(
    id ?? ""
  );
  const { mutate: deleteInvoice } = useDeleteInvoice();
  const { mutate: deleteProposal } = useDeleteProposal();
  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    isIdle: isIdleInvoice,
    isError: isErrorInvoice,
    error: errorInvoice,
  } = useInvoiceByWorkOrder(id);
  const { data: proposal, isLoading: isLoadingProposal } =
    useProposalByWorkOrder(id);
  const navigate = useNavigate();
  const { data: schedules, isLoading: isLoadingSchedules } =
    useSchedulesByWorkOrder(id);
  const { data: workOrderNotes } = useWorkOrderNotesByWorkOrder(id);
  const { data: workOrderDocuments, isLoading: workOrderDocumentsLoading } =
    useWorkOrderDocumentsByDateRange(id || "");
  const { data: invoiceDrawRequests, refetch: getInvoiceDrawRequests } =
    useInvoiceDrawRequestsByInvoice(invoice?.id);
  const { refetch: downloadWorkOrderDocument } = useDownloadWorkOrderDocument(
    workOrderDocument,
    download
  );
  const { data: scheduleTypes } = useScheduleTypes();
  const {
    data: employeeTimesheets,
    isLoading: isLoadingEmployeeTimesheets,
    refetch: getEmployeeTimesheets,
  } = useEmployeeTimesheetsByFilter(id);

  const [canCreateInvoice, setCanCreateInvoice] = useState<
    { onCreate: () => void } | { disableCreate: boolean }
  >();
  const [canCreateProposal, setCanCreateProposal] = useState<
    { onCreate: () => void } | { disableCreate: boolean }
  >();

  useEffect(() => {
    workOrder && setCustomerId(workOrder.customer.id);
  }, [workOrder]);

  useEffect(() => {
    id && getWorkOrder();
  }, [id, customerId, getWorkOrder]);

  useEffect(() => {
    id && getEmployeeTimesheets();
  }, [id, getEmployeeTimesheets]);

  const onEditCreateInvoice = useCallback(() => {
    if (workOrder) {
      workOrder.invoice
        ? navigate(`/invoices/edit/${workOrder.invoice.id}`)
        : navigate(
            `/invoices/create?workOrderId=${id}&customerId=${workOrder?.customerId}`
          );
    }
  }, [workOrder, navigate, id]);

  const onEditCreateProposal = useCallback(
    (proposal?: Proposal) => {
      proposal
        ? navigate(`/proposals/edit/${proposal.id}`)
        : navigate(`/proposals/create?workOrderId=${id}`);
    },
    [navigate, id]
  );

  useEffect(() => {
    invoice && getInvoiceDrawRequests();
  }, [invoice, getInvoiceDrawRequests]);

  useEffect(() => {
    setCanCreateInvoice(
      invoice === undefined || invoice.toString() === ""
        ? { onCreate: () => onEditCreateInvoice() }
        : { disableCreate: true }
    );
  }, [invoice, workOrder, onEditCreateInvoice]);

  useEffect(() => {
    setCanCreateProposal(
      proposal === undefined || proposal.toString() === ""
        ? { onCreate: () => onEditCreateProposal() }
        : { disableCreate: true }
    );
  }, [proposal, onEditCreateProposal]);

  useEffect(() => {
    download && workOrderDocument && downloadWorkOrderDocument();
    setDownload(false);
  }, [download, workOrderDocument, downloadWorkOrderDocument]);

  if (isLoading || isIdle || isLoadingInvoice || isIdleInvoice) {
    return <LoadingSpinner />;
  }

  if (isErrorWorkOrder) {
    return <h2>WORK ORDER ERROR: {errorWorkOrder.message}</h2>;
  }

  if (isErrorInvoice) {
    return <h2>INVOICE ERROR: {errorInvoice.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title={`Work Order - ${workOrder.workOrderNumber}`}
        left={
          showBackButton ? (
            <Button
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
              variant="contained"
            >
              Back
            </Button>
          ) : undefined
        }
      />
      {workOrder && (
        <Stack direction="row" sx={{ mt: 2 }}>
          <Paper sx={{ p: 2, mb: 2, mr: 2 }}>
            <DisplayWorkOrderShort workOrder={workOrder} />
          </Paper>
          <Paper sx={{ width: 700, p: 2, mb: 2, mr: 2 }}>
            <DisplayCustomer customer={workOrder.customer} />
            <br />
            <Link to={`/customers/${workOrder.customer.id}`}>
              View Customer
            </Link>
          </Paper>
          <Paper sx={{ width: 500, p: 2, mb: 2, mr: 2 }}>
            <AddressDisplay
              title="Billing Address"
              address={workOrder?.billingAddress}
              workOrder={workOrder}
            />
            <br />
            <br />
            <AddressDisplay
              title="Site Address"
              address={workOrder?.siteAddress}
              workOrder={workOrder}
            />
          </Paper>
        </Stack>
      )}
      <BasicAccordion
        title={`Work Order Notes - ${workOrderNotes?.length}`}
        defaultExpanded={workOrderNotes && workOrderNotes?.length > 0}
      >
        <WorkOrderNoteDataGrid
          rows={workOrderNotes ?? []}
          workOrder={workOrder}
          loading={false}
          autoHeight
          disableExport
          disableColumnsButton
          disableQuickFilter
        />
      </BasicAccordion>
      <BasicAccordion
        title={`WorkOrder Documents - ${workOrderDocuments?.length}`}
      >
        <WorkOrderDocumentDataGrid
          rows={workOrderDocuments ?? []}
          workOrder={workOrder}
          loading={workOrderDocumentsLoading}
          onDownload={(workOrderDocument) => {
            setWorkOrderDocument(workOrderDocument);
            setDownload(true);
          }}
          autoHeight
        />
      </BasicAccordion>
      <BasicAccordion title={`Schedules - ${schedules?.length}`}>
        <Button
          variant="contained"
          onClick={() => setOpenScheduleGroupModal(true)}
          endIcon={<GroupAddIcon />}
          sx={{ mb: 1 }}
        >
          Schedule Group
        </Button>
        <ScheduleDataGrid
          rows={schedules ?? []}
          loading={isLoadingSchedules}
          onLink={(schedule) => {
            const startDate = format(
              new Date(schedule.startDate as Date),
              "yyyy-MM-dd"
            );
            navigate(`/dispatch?goToDate=${startDate}`);
          }}
          workOrder={workOrder}
          workOrderOnly
          scheduleType={scheduleTypes?.find(
            (scheduleType) => scheduleType.id === NORMAL
          )}
          autoHeight
        />
      </BasicAccordion>

      <BasicAccordion
        title={`Employee Timesheets - ${employeeTimesheets?.length}`}
      >
        <EmployeeTimesheetDataGrid
          rows={employeeTimesheets ?? []}
          loading={isLoadingEmployeeTimesheets}
          workOrder={workOrder}
          autoHeight
        />
      </BasicAccordion>

      <Box sx={{ mt: 2 }}>
        <GenericDataGrid
          rows={proposal ? ([proposal] as Proposal[]) : []}
          columns={proposalColumns}
          loading={isLoadingProposal}
          onDelete={(proposal) => deleteProposal(proposal.id)}
          onEdit={(proposal) => onEditCreateProposal(proposal)}
          onView={(proposal) => navigate(`/proposals/view/${proposal.id}`)}
          {...canCreateProposal}
          title="Proposal"
          createTitle="Create Proposal"
          ignoreFields={[
            "id",
            "proposalLineItems",
            "createdTimestamp",
            "modifiedTimestamp",
          ]}
          autoHeight
          hideFooter
          disableExport
          disableColumnsButton
          disableQuickFilter
        />
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <GenericDataGrid
          rows={invoice ? ([invoice] as Invoice[]) : []}
          columns={invoiceColumns}
          loading={isLoadingInvoice}
          onDelete={(invoice) => deleteInvoice(invoice.id)}
          onEdit={() => onEditCreateInvoice()}
          onView={(invoice) => navigate(`/invoices/view/${invoice.id}`)}
          {...canCreateInvoice}
          title="Invoice"
          createTitle="Create Invoice"
          ignoreFields={[
            "id",
            "invoiceLineItems",
            "createdTimestamp",
            "modifiedTimestamp",
          ]}
          autoHeight
          hideFooter
          disableExport
          disableColumnsButton
          disableQuickFilter
        />
        {invoice && (
          <InvoiceDrawRequestDataGrid
            sx={{ mt: 2 }}
            rows={invoiceDrawRequests ?? []}
            onView={(invoice) =>
              navigate(`/invoice-draw-request/view/${invoice.id}`)
            }
            title="Invoice Draw Request"
            invoiceId={invoice.id}
            loading={false}
            disableColumnsButton
            disableExport
            disableQuickFilter
            autoHeight
          />
        )}
      </Box>
      <BasicAccordion title={`Timeline - ${workOrderTimelines?.length}`}>
        <WorkOrderTimelineDataGrid
          rows={workOrderTimelines ?? []}
          loading={false}
        />
      </BasicAccordion>
      <ModalContainerChangeDetection
        open={openScheduleGroupModal}
        handleClose={() => {
          setOpenScheduleGroupModal(false);
        }}
        title="Create Group Schedule"
      >
        <ScheduleGroupForm
          schedule={
            {
              workOrder,
              scheduleType: scheduleTypes?.find(
                (scheduleType) => scheduleType.id === NORMAL
              ),
            } as Schedule
          }
          onSubmitted={() => setOpenScheduleGroupModal(false)}
          workOrderOnly={true}
        />
      </ModalContainerChangeDetection>
    </>
  );
};
