import { useState } from "react";
import { useDeleteCustomerTier } from "../../../hooks/customer-tier";
import { CustomerTier } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { customerTierColumns as columns } from "./customer-tier.columns";
import { CustomerTierForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const CustomerTierDataGrid = (props: Props<CustomerTier>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [customerTier, setCustomerTier] = useState<CustomerTier>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteCustomerTier } = useDeleteCustomerTier();

  const onEditCreate = (customerTier?: CustomerTier) => {
    customerTier ? setCustomerTier(customerTier) : setCustomerTier(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDelete={(customerTier) => deleteCustomerTier(customerTier.id)}
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={onLink}
      title={title}
      createTitle="Create Customer Tier"
      deleteTitle="Do you want to delete this Customer Tier"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${customerTier ? "Edit" : "Create"} Customer Tier`}
      >
        <CustomerTierForm
          onSubmitted={() => setOpenModal(false)}
          customerTier={customerTier}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
