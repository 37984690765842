import {
  Button,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

import { PageHeader } from "../../components/layout";
import {
  AutoSearch,
  AutoSearchMultiple,
  ChipDropDown,
  EmailModal,
  LoadingSpinner,
  ModalContainer,
} from "../../components/ui";
import { Address, CustomerAddress, EmailPayload } from "../../models";
import { useCustomerSearch } from "../../hooks/customer-address";
import { useEffect, useState } from "react";
import { CustomerOverdueInvoices } from "./components/CustomerOverdueInvoices";
import {
  useDownloadInvoiceStatements,
  useInvoiceStatements,
} from "../../hooks/invoice-statement";
import { DatePicker } from "@mui/x-date-pickers";
import { add, sub } from "date-fns";
import { useWorkOrderStates } from "../../hooks/work-order-state";
import { useAddressSearch } from "../../hooks/address";
import { arrayAddOrDelete } from "../../utils";
import { useSendCustomerStatements } from "../../hooks/email";
import { useSearchParams } from "react-router-dom";
import { useAppSettingsContext } from "../../contexts";
import { APPLICATION_SETTING } from "../../globals/enums";
import { useAuth } from "../../contexts/AuthContext";

export const StatementsPage = () => {
  const [searchParams] = useSearchParams();
  let customerId = searchParams.get("customerId") ?? "";
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const {
    data: workOrderStates,
    isLoading,
    isIdle,
    isError,
    error,
  } = useWorkOrderStates();
  const { mutateAsync: sendCustomerStatements } = useSendCustomerStatements();
  const { getSetting } = useAppSettingsContext();
  const DEFAULT_WORK_ORDER_STATES = getSetting(
    APPLICATION_SETTING.DEFAULT_WORK_ORDER_STATES
  )?.appSettingDetails;
  const [workOrderStateGuids, setWorkOrderStateGuids] = useState<string[]>(
    DEFAULT_WORK_ORDER_STATES?.map((x) => x.settingId)
  );
  const workOrderStateDefaults = workOrderStates?.filter((x) =>
    DEFAULT_WORK_ORDER_STATES?.map((x) => x.settingId).includes(x.id)
  );
  const [addressGuids, setAddressGuids] = useState<string[]>([]);
  const [excludes, setExcludes] = useState<string[]>([]);
  const [customer, setCustomer] = useState<string | undefined>(customerId);
  const [paidStatus, setPaidStatus] = useState<string | undefined>("unpaid");
  const [isOverdue, setIsOverdue] = useState<string | undefined>("all");
  const [download, setDownload] = useState<boolean>(false);
  const [showOverdueCharges, setShowOverdueCharges] = useState<boolean>(true);
  const [showDaysOverdue, setShowDaysOverdue] = useState<boolean>(true);
  const { email } = useAuth();
  const [startDate, setStartDate] = useState<Date>(
    sub(new Date(), { months: 2 })
  );
  const [endDate, setEndDate] = useState<Date>(add(new Date(), { months: 1 }));
  const { data: statements, refetch: getInvoiceStatements } =
    useInvoiceStatements(
      startDate,
      endDate,
      customer,
      paidStatus,
      isOverdue,
      workOrderStateGuids,
      addressGuids,
      excludes
    );
  const { refetch: downloadZip } = useDownloadInvoiceStatements(
    startDate,
    endDate,
    customer,
    paidStatus,
    isOverdue,
    workOrderStateGuids,
    addressGuids,
    excludes,
    showOverdueCharges,
    showDaysOverdue,
    download
  );

  const sendEmail = async (emailPayload: EmailPayload) => {
    if (emailPayload) {
      emailPayload.from = email();
      await sendCustomerStatements({
        startDate,
        endDate,
        customerId: customer,
        paidStatus,
        isOverdue,
        workOrderStates: workOrderStateGuids,
        excludes,
        showOverdueCharges,
        showDaysOverdue,
        emailPayload,
      });
    }
    setOpenEmailModal(false);
  };

  useEffect(() => {
    getInvoiceStatements();
    setDownload(false);
  }, [
    startDate,
    endDate,
    customer,
    paidStatus,
    isOverdue,
    workOrderStateGuids,
    addressGuids,
    getInvoiceStatements,
  ]);

  useEffect(() => {
    download && downloadZip();
    setDownload(false);
  }, [download, downloadZip]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader title="Customer Statement" />
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Start Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={startDate}
            onChange={(value) => setStartDate(value ?? new Date())}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={endDate}
            onChange={(value) => setEndDate(value ?? new Date())}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <AutoSearch
            label="Search Customer By Name, Address, or P.O ..."
            keys={["fullName", "email", "phone", "address", "po"]}
            onOptionSelected={(customer: CustomerAddress | null) => {
              customer
                ? setCustomer(customer?.customerId)
                : setCustomer(undefined);
            }}
            searchFunction={useCustomerSearch}
            separator="|"
            sx={{ width: 700 }}
          />
          <Button variant="outlined" onClick={() => setDownload(true)}>
            Download Statements
          </Button>
          <Button
            variant="outlined"
            endIcon={<EmailIcon />}
            onClick={() => setOpenEmailModal(true)}
          >
            Prepare Email
          </Button>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
          {workOrderStates && (
            <ChipDropDown
              data={workOrderStates}
              label="Work Order States"
              labelKey={"name"}
              valueKey={"id"}
              defaultValue={workOrderStateDefaults}
              onOptionChange={(selectedWorkOrderStates) =>
                setWorkOrderStateGuids(selectedWorkOrderStates.map((x) => x.id))
              }
            />
          )}
          <AutoSearchMultiple
            label="Search Sites..."
            keys={["unit", "streetNumber", "streetName", "postalCode", "city"]}
            onOptionSelected={(addresses: Address[] | null) =>
              addresses && setAddressGuids(addresses.map((a) => a.id))
            }
            searchFunction={useAddressSearch}
            sx={{ width: 600 }}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={paidStatus}
            onChange={(_, value) => setPaidStatus(value)}
          >
            {["all", "paid", "unpaid"].map((option, index) => (
              <ToggleButton value={option} key={index}>
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={isOverdue}
            onChange={(_, value) => setIsOverdue(value)}
          >
            {["all", "not overdue", "overdue"].map((option, index) => (
              <ToggleButton value={option} key={index}>
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <FormControlLabel
            control={
              <Switch
                onChange={(event) =>
                  setShowOverdueCharges(event.target.checked)
                }
                checked={showOverdueCharges}
              />
            }
            label={
              showOverdueCharges
                ? "Show Overdue Charges"
                : "Hide Overdue Charges"
            }
          />
          <FormControlLabel
            control={
              <Switch
                onChange={(event) => setShowDaysOverdue(event.target.checked)}
                checked={showDaysOverdue}
              />
            }
            label={showDaysOverdue ? "Show Days Overdue" : "Hide Days Overdue"}
          />
        </Stack>
      </Paper>
      <Paper>
        {statements && statements.length > 0 && (
          <CustomerOverdueInvoices
            statements={statements ?? []}
            showDaysOverdue={showDaysOverdue}
            showOverdueCharges={showOverdueCharges}
            onExcluded={(invoiceId, checked) => {
              setExcludes(arrayAddOrDelete(excludes, invoiceId, checked));
            }}
          />
        )}
      </Paper>
      <ModalContainer
        open={openEmailModal}
        title="Prepare Email"
        handleClose={() => setOpenEmailModal(false)}
      >
        <EmailModal
          onSubmit={(emailPayload) => {
            sendEmail(emailPayload);
          }}
        />
      </ModalContainer>
    </>
  );
};
