import axios, { AxiosInstance } from "axios";
import config from "../config/app-config";
// import { stripObjectTypesFromType } from "../utils/strip-object-type-from-type";
import { Auth } from "aws-amplify";

// Axios Instance
const api: AxiosInstance = axios.create({
  baseURL: config.API.URL,
});

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    const currentSession = await Auth.currentSession();
    let accessToken = currentSession.getAccessToken();
    let jwt = accessToken.getJwtToken();

    config.headers = {
      Authorization: `Bearer ${jwt}`,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

// Strip properties
api.interceptors.request.use(
  async (config) => {
    let configClone = { ...config };
    if (config.method === "patch") {
      delete configClone.data?.id;
      delete configClone.data?.createdTimestamp;
      delete configClone.data?.modifiedTimestamp;
    }
    return configClone;
  },
  (error) => Promise.reject(error)
);

export default api;
