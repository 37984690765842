import React, { useContext, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { useSnackbar } from "notistack";
import { Role } from "../globals/enums";

const AuthContext = React.createContext<any>("");

interface Props {
  children: JSX.Element;
}

export const useAuth = () => useContext(AuthContext);

Hub.listen("app", (data) => {
  switch (data.payload.event) {
    case "auth_state":
      if (data.payload.data === false) {
        localStorage.setItem("isAuthenticated", "false");
      } else {
        localStorage.setItem("isAuthenticated", "true");
      }
  }
});

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  const signIn = async (email: string, password: string) => {
    try {
      const currentUser = await Auth.signIn(email, password);
      setUser(currentUser);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("email", currentUser?.attributes?.email);

      const roles =
        currentUser?.signInUserSession?.accessToken?.payload["cognito:groups"];

      roles
        ? localStorage.setItem("roles", JSON.stringify(roles))
        : localStorage.removeItem("roles");

      if (currentUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        localStorage.setItem("authState", "NEW_PASSWORD_REQUIRED");
      }
      enqueueSnackbar("Signed In Successful", { variant: "success" });
      return currentUser;
    } catch (error) {
      enqueueSnackbar("Signed In Failed", { variant: "error" });
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    localStorage.clear();
  };

  const forgotPassword = (username: string) =>
    Auth.forgotPassword(username).then((value) => console.log(value));

  const forgotPasswordSubmit = (
    username: string,
    code: string,
    password: string
  ) => Auth.forgotPasswordSubmit(username, code, password);

  const isAuthenticated = (): boolean => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        Hub.dispatch("app", { event: "auth_state", data: true });
      })
      .catch(() => {
        Hub.dispatch("app", { event: "auth_state", data: false });
      });

    let value = localStorage.getItem("isAuthenticated");
    return value === "true" ? true : false;
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    Auth.changePassword(currentUser, oldPassword, newPassword);
  };

  const completeNewPassword = async (newPassword: string) => {
    const result = await Auth.completeNewPassword(user, newPassword);
    const roles =
      result?.signInUserSession?.accessToken?.payload["cognito:groups"];
    roles
      ? localStorage.setItem("roles", JSON.stringify(roles))
      : localStorage.removeItem("roles");

    localStorage.setItem("isAuthenticated", "true");
    enqueueSnackbar("Password Updated", { variant: "success" });
  };

  const userRoles = (): string[] => {
    const roles = localStorage.getItem("roles");
    return JSON.parse(roles ?? "[]");
  };

  const authState = () => {
    return localStorage.getItem("authState");
  };

  const hasRole = (roles: Role[]): boolean => {
    if (!roles) {
      return false;
    }
    return roles.some((role) => userRoles().indexOf(role) >= 0) ? true : false;
  };

  const email = () => {
    return localStorage.getItem("email");
  };

  const setQbStatus = (status: boolean) => {
    localStorage.setItem("QB_STATUS", JSON.stringify(status));
  };

  const isQBAuthenticated = () => {
    const value = localStorage.getItem("QB_STATUS");
    return value === "true" ? true : false;
  };

  const setQBAlert = (show: boolean) => {
    localStorage.setItem("QB_ALERT", JSON.stringify(show));
  };

  const getQbAlert = () => {
    const value = localStorage.getItem("QB_ALERT");
    return value === "true" ? true : false;
  };

  const value = {
    user,
    email,
    authState,
    userRoles,
    hasRole,
    signIn,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    isAuthenticated,
    changePassword,
    completeNewPassword,
    setQbStatus,
    isQBAuthenticated,
    setQBAlert,
    getQbAlert,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
