import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Invoice } from "../../../models";
import { WarrantyDisplay } from "./WarrantyDisplay";

interface Props {
  invoice: Invoice;
}

export const InvoiceIndividualSection = (props: Props) => {
  const { invoice } = props;
  const containsEquipment = invoice.invoiceLineItems.filter(
    (inv) => inv.inventoryItem.inventoryType.showInInvoiceCategorySection
  );

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 3, border: "none" }}
      elevation={0}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Item</Typography>
            </TableCell>
            <TableCell>
              {containsEquipment.length > 0 && (
                <Typography variant="h6">Model</Typography>
              )}
            </TableCell>
            <TableCell>
              {containsEquipment.length > 0 && (
                <Typography variant="h6">Serial Number</Typography>
              )}
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6">Each</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6">Quantity</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6">Price</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice?.invoiceLineItems
            .filter((x) => x.hide === false)
            .sort((a, b) => Number(a.ordinal) - Number(b.ordinal))
            .map((invoiceLineItem) => (
              <TableRow key={invoiceLineItem.id}>
                <TableCell>
                  <Typography variant="body1">
                    {invoiceLineItem.display
                      ? invoiceLineItem.display
                      : invoiceLineItem.inventoryItem.name}
                  </Typography>
                  <WarrantyDisplay warranty={invoiceLineItem} />
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {invoiceLineItem.inventoryItem.model}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {invoiceLineItem.serialNumber}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    ${" "}
                    {invoiceLineItem.price ??
                      Number(invoiceLineItem.inventoryItem.price).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    {invoiceLineItem.quantity}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    ${" "}
                    {Number(
                      invoiceLineItem.quantity * invoiceLineItem.price
                    ).toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
