import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useAuth } from "../../../contexts/AuthContext";
import { useSnackbar } from "notistack";

interface ResetPassword {
  oldPassword: string;
  newPassword: string;
}

const schema = yup.object().shape({
  oldPassword: yup.string().required().label("Old Password"),
  newPassword: yup.string().required().label("New Password"),
});

export const ChangePasswordForm = () => {
  const { changePassword } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ResetPassword>({
    resolver: yupResolver(schema),
  });

  return (
    <FormContainer
      onSubmit={handleSubmit(async (login) => {
        changePassword(login.oldPassword, login.newPassword).then(() => {
          enqueueSnackbar("Password Changed Successfully", {
            variant: "success",
          });
        });
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="oldPassword"
          label="Old Password"
          control={control}
          required
          type="password"
        />
        <FormTextField
          name="newPassword"
          label="New Password"
          control={control}
          required
          type="password"
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
