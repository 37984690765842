import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { EmployeeTimesheet } from "../../../models";
import { employeeTimesheetSchema as schema } from "./employee-timesheet.schema";
import {
  useAddEmployeeTimesheet,
  usePatchEmployeeTimesheet,
} from "../../../hooks/employee-timesheet";
import { FormContainer } from "../../layout";
import { FormDateTimePicker, FormSubmitButton } from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { useWorkOrdersByQuery } from "../../../hooks/work-order";
import { useEmployees } from "../../../hooks/employee";
import { FormAutocomplete } from "../../form-components";

interface Props {
  employeeTimesheet?: EmployeeTimesheet;
  onSubmitted?: (employeeTimesheet: EmployeeTimesheet) => void;
}

export const EmployeeTimesheetForm = (props: Props) => {
  const { employeeTimesheet, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const [searchTermWorkOrder, setSearchTermWorkOrder] = useState<string>("");
  const { mutateAsync: createEmployeeTimesheet } = useAddEmployeeTimesheet();
  const { mutateAsync: patchEmployeeTimesheet } = usePatchEmployeeTimesheet();
  const {
    data: workOrders,
    isLoading: isLoadingWorkOrders,
    isFetching: isFetchingWorkOrders,
    refetch: getWorkOrders,
  } = useWorkOrdersByQuery(searchTermWorkOrder);
  const { data: allEmployees } = useEmployees();
  const {
    control,
    watch,
    handleSubmit,
    trigger,
    formState: { isSubmitting, defaultValues },
  } = useForm<EmployeeTimesheet>({
    resolver: yupResolver(schema),
    defaultValues: employeeTimesheet,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, setChangeDetection, defaultValues]);

  useEffect(() => {
    searchTermWorkOrder && getWorkOrders();
  }, [searchTermWorkOrder, getWorkOrders]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (employeeTimesheet) => {
        employeeTimesheet = employeeTimesheet.id
          ? await patchEmployeeTimesheet(employeeTimesheet)
          : await createEmployeeTimesheet(employeeTimesheet);
        onSubmitted && onSubmitted(employeeTimesheet);
        setChangeDetection(false);
      })}
    >
      <Stack direction="column" spacing={2}>
        <FormAutocomplete
          name="workOrderId"
          label="Work Order Number"
          labelKeys={["workOrderNumber"]}
          valueKey="id"
          options={workOrders ?? []}
          loading={isLoadingWorkOrders || isFetchingWorkOrders}
          onInputChange={setSearchTermWorkOrder}
          control={control}
          defaultValue={employeeTimesheet?.workOrder ?? undefined}
        />
        <FormAutocomplete
          name="employeeId"
          label="Employee"
          valueKey="id"
          options={allEmployees ?? []}
          control={control}
          loading={false}
          labelKeys={["firstName", "lastName"]}
          defaultValue={employeeTimesheet?.employee ?? undefined}
        />
        <FormDateTimePicker
          control={control}
          name={`clockIn`}
          label="Clock In"
          trigger={trigger}
          minTime={new Date(0, 0, 0, 7)}
          maxTime={new Date(0, 0, 0, 20, 0)}
        />
        <FormDateTimePicker
          control={control}
          name={`clockOut`}
          label="Clock Out"
          trigger={trigger}
          minTime={new Date(0, 0, 0, 7)}
          maxTime={new Date(0, 0, 0, 20, 0)}
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
