import {
  Button,
  CardActions,
  CardContent,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { PageHeader } from "../../components/layout";
import { useWorkOrdersByFilter } from "../../hooks/work-order";
import { useNavigate } from "react-router-dom";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { data: workOrders } = useWorkOrdersByFilter(new Date());
  return (
    <>
      <PageHeader title="Dashboard" />
      <Typography variant="h5">Todays Work Orders</Typography>
      <Stack direction="column">
        {workOrders?.map((workOrder, index) => {
          return (
            <Paper sx={{ width: 300, marginTop: 2 }} key={index}>
              <CardContent sx={{ pb: 0 }}>
                <Typography variant="body2">
                  <b>{workOrder.workOrderNumber}</b>
                  <br />
                  {workOrder.customer.firstName} {workOrder.customer.lastName}
                  <br />
                  {workOrder.workOrderState.name}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => navigate(`/work-order/${workOrder.id}`)}
                >
                  View
                </Button>
              </CardActions>
            </Paper>
          );
        })}
      </Stack>
    </>
  );
};
