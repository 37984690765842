import { Button, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

interface Props {
  title?: string;
}

export const ReportHeader = (props: Props) => {
  const { title } = props;
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Button
          onClick={() => navigate("/reports")}
          startIcon={<ArrowBackIosIcon />}
          variant="contained"
        >
          Back
        </Button>
      </Grid>
      <Grid item>
        <h3>{title}</h3>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
};
