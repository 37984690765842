import { useQuery } from "react-query";

import { PaymentType } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const usePaymentTypes = () => {
  return useQuery<PaymentType[], Error>(RQ.PAYMENT_TYPES, async () =>
    getAll<PaymentType>("payment-types")
  );
};
