import { Box } from "@mui/material";

export const PrivacyPolicyPage = () => {
  return (
    <Box style={{ padding: 20 }}>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> July 11, 2024
      </p>
      <h2>Introduction</h2>
      <p>
        Welcome to CJ Brubacher ("we," "our," or "us"). We are committed to
        protecting your privacy and ensuring that your personal information is
        handled in a safe and responsible manner. This Privacy Policy explains
        how we collect, use, and disclose information when you use our
        application ("App").
      </p>

      <h2>Information We Collect</h2>
      <p>
        Our App only collects information that you voluntarily provide. This
        includes:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you use our App, you may
          provide personal information such as your name, email address, phone
          number, or any other details you choose to share.
        </li>
        <li>
          <strong>Content:</strong> Any content or data you enter into the App,
          such as text, photos, or other inputs, is collected and stored as part
          of your usage of the App.
        </li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We use the information you provide to:</p>
      <ul>
        <li>Operate, maintain, and improve the App.</li>
        <li>Respond to your comments, questions, and requests.</li>
        <li>Provide customer support and assistance.</li>
        <li>
          Communicate with you about updates, promotions, and other news related
          to the App.
        </li>
      </ul>

      <h2>Information Sharing and Disclosure</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties. We may share your information in the following
        circumstances:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who perform services on our behalf, such
          as hosting, data storage, and customer support.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information
          if required to do so by law or in response to valid requests by public
          authorities.
        </li>
      </ul>

      <h2>Data Security</h2>
      <p>
        We take reasonable measures to protect the information you provide to us
        from unauthorized access, use, or disclosure. However, no method of
        transmission over the internet or method of electronic storage is 100%
        secure, so we cannot guarantee absolute security.
      </p>

      <h2>Your Choices</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li>
          <strong>Access and Update:</strong> You can access and update your
          personal information by contacting us.
        </li>
        <li>
          <strong>Delete:</strong> You can request the deletion of your personal
          information by contacting us.
        </li>
        <li>
          <strong>Opt-out:</strong> You can opt out of receiving promotional
          communications from us by following the instructions in those
          communications or by contacting us.
        </li>
      </ul>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "Effective Date" at the top. We encourage you to review
        this Privacy Policy periodically to stay informed about how we are
        protecting your information.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <p>
        19 First St E,
        <br />
        Elmira, ON N3B 2E6
        <br />
        CJ Brubacher Ltd
        <br />
        (519) 669-3362
      </p>

      <p>
        Thank you for using CJ Brubacher. We value your privacy and are
        committed to protecting your personal information.
      </p>
    </Box>
  );
};
