import { useQuery } from "react-query";

import { WorkOrder } from "../../models";
import { queryEntity } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useWorkOrdersByQuery = (searchTerm: string) => {
  return useQuery<WorkOrder[], Error>(
    [RQ.WORK_ORDERS_SEARCH],
    async () => queryEntity("work-orders", searchTerm),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
