import { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

interface Props<T> extends SelectProps {
  data: T[];
  idKey: keyof T;
  valueKey: keyof T;
  label: string;
  initialValue?: T;
  onSelected?: (entity: T | undefined) => void;
}

export const SelectDropDown = <T,>(props: Props<T>) => {
  const { data, idKey, valueKey, label, initialValue, onSelected, ...rest } =
    props;
  // const [entity, setEntity] = useState<T>(initialValue ?? data[0]);
  const [entity, setEntity] = useState<T | undefined>(
    initialValue ?? undefined
  );
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        value={(entity && entity[idKey]) ?? ""}
        onChange={(event) => {
          if (event.target.value === "clear") {
            setEntity(undefined);
            onSelected && onSelected(undefined);
          } else {
            const selectedEntity = data.find(
              (x) => x[idKey as keyof T] === event.target.value
            );
            selectedEntity && onSelected && onSelected(selectedEntity);
            selectedEntity && setEntity(selectedEntity);
          }
        }}
        size="small"
        {...rest}
      >
        <MenuItem key="clear" value="clear">
          Clear selection
        </MenuItem>
        {data.map((item: T, index: number) => (
          <MenuItem key={index} value={item[idKey as keyof object]}>
            {item[valueKey as keyof object]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
