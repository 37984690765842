import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { ReferralType } from "../../../models";
import { referralTypeSchema as schema } from "./referral-type.schema";
import {
  useAddReferralType,
  usePatchReferralType,
} from "../../../hooks/referral-type";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  referralType?: ReferralType;
  onSubmitted?: (referralType: ReferralType) => void;
}

export const ReferralTypeForm = (props: Props) => {
  const { referralType, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createReferralType } = useAddReferralType();
  const { mutateAsync: patchReferralType } = usePatchReferralType();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, defaultValues },
  } = useForm<ReferralType>({
    resolver: yupResolver(schema),
    defaultValues: referralType,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (referralType) => {
        referralType = referralType.id
          ? await patchReferralType(referralType)
          : await createReferralType(referralType);
        onSubmitted && onSubmitted(referralType);
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting || isSubmitSuccessful} />
    </FormContainer>
  );
};
