import FullCalendar from "@fullcalendar/react";
import { endOfDay, format, startOfDay } from "date-fns";
import { EventSourceInput } from "@fullcalendar/core";

import { ExcludeDate, Schedule } from "../models";
import { getDatesByInterval } from "./get-dates-by-interval";
import { find } from "lodash";

export const formatCalendarEvents = (
  schedules: Schedule[],
  calendarRef: React.RefObject<FullCalendar>
) => {
  let allSchedules: EventSourceInput = [];
  setTimeout(() => {
    if (calendarRef.current) {
      calendarRef.current?.getApi().removeAllEvents();
    }
  }, 0);

  if (schedules && schedules.length > 0) {
    for (const schedule of schedules) {
      if (!schedule.scheduleType.requiresWorkOrder) {
        if (schedule.repeatDays.length > 0) {
          for (const repeatDay of schedule.repeatDays) {
            const weeks = getDatesByInterval(
              new Date(schedule.startDate as Date),
              new Date(schedule.endDate as Date),
              repeatDay.ordinal
            );

            for (const week of weeks) {
              let start = startOfDay(week);
              let end = endOfDay(week);
              const currentDate = format(week, "yyyy-MM-dd");
              let isFound = false;

              if (schedule.excludedDates.length > 0) {
                isFound = !!find(
                  schedule.excludedDates,
                  (excludedDate: ExcludeDate) =>
                    format(
                      new Date(excludedDate.excludedDate),
                      "yyyy-MM-dd"
                    ) === currentDate
                );
              }

              if (!isFound) {
                if (!schedule.scheduleType.allDay) {
                  const currentDate = format(week, "yyyy-MM-dd");
                  const startTime = format(
                    new Date(schedule.startDate as Date),
                    "HH:mm:ss.SSS"
                  );
                  start = new Date(currentDate + "T" + startTime) as Date;
                  const endTime = format(
                    new Date(schedule.endDate as Date),
                    "HH:mm:ss.SSS"
                  );
                  end = new Date(currentDate + "T" + endTime) as Date;
                }

                allSchedules.push({
                  resourceId: schedule.employee.id,
                  id: schedule.id,
                  scheduleTypeId: schedule.scheduleType?.id,
                  start: start,
                  end: end,
                  title: schedule.scheduleType.name,
                  color: schedule.scheduleType.color,
                  allDay: schedule.scheduleType.allDay,
                  repeats: true,
                  display: schedule.scheduleType.displayInBackground
                    ? "background"
                    : undefined,
                });
              }
            }
          }
        } else {
          allSchedules.push({
            resourceId: schedule.employee.id,
            id: schedule.id,
            scheduleTypeId: schedule.scheduleType?.id,
            start: new Date(schedule.startDate as Date),
            end: new Date(schedule.endDate as Date),
            title: schedule.scheduleType.name,
            color: schedule.scheduleType.color,
            allDay: schedule.scheduleType.allDay,
            display: schedule.scheduleType.displayInBackground
              ? "background"
              : undefined,
          });
        }
        continue;
      }

      if (schedule.scheduleType.requiresWorkOrder) {
        const jobTypeNames = schedule?.workOrder?.jobTypes?.map((x) => x.name);
        const address: string = `${schedule?.workOrder?.siteAddress?.streetNumber} ${schedule?.workOrder?.siteAddress?.streetName} ${schedule?.workOrder?.siteAddress?.city}`;

        if (schedule?.repeatDays?.length > 0) {
          for (const repeatDay of schedule.repeatDays) {
            const startTime = format(
              new Date(schedule.startDate as Date),
              "HH:mm:ss.SSS"
            );
            const endTime = format(
              new Date(schedule.endDate as Date),
              "HH:mm:ss.SSS"
            );

            if (schedule.startDate && schedule.endDate) {
              const start = new Date(schedule.startDate);
              const end = new Date(schedule.endDate);
              const weeks = getDatesByInterval(start, end, repeatDay.ordinal);

              for (const week of weeks) {
                const currentDate = format(week, "yyyy-MM-dd");
                let isFound = false;
                if (schedule.excludedDates.length > 0) {
                  isFound = !!find(
                    schedule.excludedDates,
                    (excludedDate: ExcludeDate) =>
                      format(
                        new Date(excludedDate.excludedDate),
                        "yyyy-MM-dd"
                      ) === currentDate
                  );
                }

                if (!isFound) {
                  allSchedules.push({
                    resourceId: schedule.employee.id,
                    id: schedule.id,
                    workOrderId: schedule.workOrder.id,
                    address: address,
                    title: schedule.workOrder.workOrderNumber,
                    start: new Date(currentDate + "T" + startTime) as Date,
                    end: new Date(currentDate + "T" + endTime) as Date,
                    color: schedule.scheduleType?.color,
                    scheduleTypeId: schedule?.scheduleType?.id,
                    workOrderStateName: schedule.workOrder.workOrderState.name,
                    jobTypes: jobTypeNames.toString(),
                    customerName: `${schedule?.workOrder?.customer?.firstName} 
                      ${schedule?.workOrder?.customer?.lastName}`,
                    repeats: true,
                    requiresWorkOrder: true,
                  });
                }
              }
            }
          }
        } else {
          allSchedules.push({
            resourceId: schedule.employee.id,
            id: schedule.id,
            workOrderId: schedule?.workOrder?.id,
            address: address,
            title: schedule?.workOrder?.workOrderNumber,
            start: schedule.startDate,
            end: schedule.endDate,
            color: schedule.scheduleType?.color,
            scheduleTypeId: schedule?.scheduleType?.id,
            workOrderStateName: schedule?.workOrder?.workOrderState?.name,
            jobTypes: jobTypeNames?.toString(),
            customerName: `${schedule?.workOrder?.customer?.firstName} 
                      ${schedule?.workOrder?.customer?.lastName}`,
            requiresWorkOrder: true,
          });
        }
        continue;
      }
    }
  }

  return allSchedules;
};
