import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ScheduleType } from "../../models";
import { ScheduleTypeForm } from "../../components/forms";
import { GenericDataGrid, scheduleTypeColumns } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import {
  useScheduleTypes,
  useDeleteScheduleType,
} from "../../hooks/schedule-type";

export const ScheduleTypesPage = () => {
  const {
    data: scheduleTypes,
    isLoading,
    isIdle,
    isError,
    error,
  } = useScheduleTypes();
  const { mutate: deleteScheduleType } = useDeleteScheduleType();
  const [scheduleType, setScheduleType] = useState<ScheduleType>();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (scheduleType?: ScheduleType) => {
    scheduleType ? setScheduleType(scheduleType) : setScheduleType(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Schedule Types"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <GenericDataGrid
        rows={scheduleTypes}
        columns={scheduleTypeColumns}
        loading={isLoading}
        onDelete={(scheduleType) => deleteScheduleType(scheduleType.id)}
        onEdit={onEditCreate}
        onCreate={onEditCreate}
        onDoubleClick={onEditCreate}
        createTitle="Create Schedule Type"
        deleteTitle="Would you like to delete this Schedule Type"
        ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        autoHeight
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${scheduleType ? "Edit" : "Create"} Schedule Type`}
        >
          <ScheduleTypeForm
            onSubmitted={() => setOpenModal(false)}
            scheduleType={scheduleType}
          />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
    </>
  );
};
