import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { AddressType } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteAddressType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("address-types", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Address Type Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.ADDRESS_TYPES,
        (addressTypes: AddressType[] | undefined) => {
          return !addressTypes
            ? []
            : deleteEntity<AddressType>(addressTypes, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Address Type Deleting Error", { variant: "error" });
    },
  });
};
