import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Contact } from "../../../models";
import { contactSchema as schema } from "../schemas";
import { useAddContact, usePatchContact } from "../../../hooks/contact";
import { FormContainer } from "../../layout";
import {
  FormPhoneNumber,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";

interface Props {
  contact?: Contact;
  onSubmitted?: (contact: Contact) => void;
}

export const ContactForm = (props: Props) => {
  const { contact, onSubmitted } = props;
  const { mutateAsync: createContact } = useAddContact();
  const { mutateAsync: patchContact } = usePatchContact();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<Contact>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(contact),
  });

  return (
    <FormContainer
      onSubmit={handleSubmit(async (contact) => {
        contact = contact.id
          ? await patchContact(contact)
          : await createContact(contact);
        onSubmitted && onSubmitted(contact);
      })}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
        spacing={2}
      >
        <FormTextField name="name" label="Name" control={control} required />
        <FormTextField name="email" label="Email" control={control} />
        <FormPhoneNumber name="phone" label="Phone" control={control} />
        <FormTextField name="note" label="Note" control={control} />
      </Stack>
      <FormSubmitButton
        text={contact?.id ? "Save Edits" : "Save"}
        disabled={isSubmitting}
      />
    </FormContainer>
  );
};
