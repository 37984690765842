import * as yup from "yup";
import { vendorCostSchema } from "./vendor-cost.schema";

export const inventoryItemSchema = yup.object().shape({
  name: yup.string().required().label("Name").default(""),
  quantity: yup.number().label("Quantity").default(0),
  warrantyPartsPeriod: yup.number().label("Warranty Parts Period").default(0),
  warrantyLabourPeriod: yup.number().label("Warranty Labour Period").default(0),
  description: yup
    .string()
    .label("Description")
    .transform((value) => value ?? ""),
  model: yup
    .string()
    .label("Model")
    .transform((value) => value ?? ""),
  sku: yup
    .string()
    .label("SKU")
    .transform((value) => value ?? ""),
  unitsOfMeasure: yup
    .string()
    .label("Units Of Measure")
    .transform((value) => value ?? ""),
  cost: yup.number().label("Cost").default(0),
  price: yup.number().label("Price").default(0),
  inventoryTypeId: yup
    .string()
    .required()
    .transform((value) => value ?? ""),
  markup: yup.number().min(0).max(100).label("Markup").default(50),
  rate: yup.number().min(0).max(100).label("Rate").default(0),
  vendorCosts: yup.array().of(vendorCostSchema).label("Vendor Costs"),
  skipDuplicationCheck: yup
    .boolean()
    .label("Skip Duplication Check")
    .default(false),
});
