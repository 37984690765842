import { useQuery } from "react-query";
import { Vendor } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useVendors = () => {
  return useQuery<Vendor[], Error>(RQ.VENDORS, async () =>
    getAll<Vendor>("vendors")
  );
};
