import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchCustomerDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerDocument: Partial<CustomerDocument>) =>
      patch({
        route: `customers/${customerDocument.customerId}/documents`,
        id: customerDocument.id ?? "",
        entity: customerDocument,
      }),
    {
      onSuccess: (customerDocument) => {
        enqueueSnackbar("Customer Document Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMER_DOCUMENTS, customerDocument.customerId],
          (customerDocuments: CustomerDocument[] | undefined) =>
            !customerDocuments
              ? []
              : patchEntity<CustomerDocument>(
                  customerDocuments,
                  customerDocument,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Document Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
