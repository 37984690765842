import * as yup from "yup";
import { proposalLineItemSchema } from "./proposal-line-item.schema";

export const proposalSchema = yup.object().shape({
  proposalLineItems: yup
    .array()
    .of(proposalLineItemSchema)
    .min(1)
    .label("Line Items"),
  subtotal: yup.number().default(0),
  grandTotal: yup.number().default(0),
  sent: yup.boolean().default(false),
  workOrderId: yup
    .string()
    .required()
    .label("Work Order Number")
    .default("")
    .transform((value) => value ?? ""),
});
