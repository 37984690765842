import { Box, Stack, Typography } from "@mui/material";
import { Invoice } from "../../../models";

interface Props {
  invoice: Invoice;
}

export const InvoiceTotalFooter = (props: Props) => {
  const { invoice } = props;
  return (
    <Box
      style={{
        borderStyle: "double",
        borderRight: "none",
        borderLeft: "none",
        borderColor: "#DCDCDC",
      }}
      sx={{ p: 1 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={5}
      >
        <Stack direction="row" spacing={5}>
          <Typography variant="h6">
            Subtotal: ${Number(invoice?.subtotal).toFixed(2)}
          </Typography>
          <Typography variant="h6">
            HST: ${Number(invoice.grandTotal - invoice.subtotal).toFixed(2)}
          </Typography>
          {invoice.customerDiscount > 0 && (
            <Typography variant="h6">
              Customer Discount: ${Number(invoice.customerDiscount).toFixed(2)}
            </Typography>
          )}
        </Stack>
        <Typography variant="h6">
          Total: ${Number(invoice?.grandTotal).toFixed(2)}
        </Typography>
      </Stack>
    </Box>
  );
};
