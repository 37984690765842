import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderRoleTitle } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchWorkOrderRoleTitle = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderRoleTitle: WorkOrderRoleTitle) =>
      patch({
        route: "work-order-role-titles",
        id: workOrderRoleTitle.id ?? "",
        entity: workOrderRoleTitle,
      }),
    {
      onSuccess: (workOrderRoleTitle) => {
        enqueueSnackbar("Work Order Role Title Updated", {
          variant: "success",
        });
        queryClient.setQueryData(
          RQ.WORK_ORDER_ROLE_TITLES,
          (workOrderRoleTitles: WorkOrderRoleTitle[] | undefined) =>
            !workOrderRoleTitles
              ? []
              : patchEntity<WorkOrderRoleTitle>(
                  workOrderRoleTitles,
                  workOrderRoleTitle,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Role Title Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
