import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { memo, useEffect, useState } from "react";
import { add } from "date-fns";

import { Schedule } from "../../../models";
import { scheduleSchema as schema } from "../schemas";
import { useAddSchedule, usePatchSchedule } from "../../../hooks/schedule";
import {
  useScheduleType,
  useScheduleTypes,
} from "../../../hooks/schedule-type";
import { useEmployee, useEmployeesByQuery } from "../../../hooks/employee";
import { FormContainer } from "../../layout";
import {
  FormCheckBoxGroup,
  FormDatePicker,
  FormSelect,
  FormSubmitButton,
} from "../../form-components";
import { LoadingSpinner } from "../../ui";
import { useRepeatDays } from "../../../hooks/repeat-day";
import { FormAutocomplete } from "../../form-components";

interface Props {
  schedule?: Schedule;
  onSubmitted?: () => void;
}

export const ScheduleServiceForm = memo((props: Props) => {
  const { schedule, onSubmitted } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { mutateAsync: createSchedule } = useAddSchedule();
  const { mutateAsync: patchSchedule } = usePatchSchedule();
  const { data: employee, isLoading: isLoadingEmployee } = useEmployee(
    schedule?.employeeId
  );
  const { data: scheduleType } = useScheduleType(schedule?.scheduleTypeId);
  const {
    data: scheduleTypes,
    isLoading: isLoadingScheduleTypes,
    isError: isErrorScheduleTypes,
    isIdle: isIdleScheduleTypes,
    error,
  } = useScheduleTypes();
  const { data: employees } = useEmployeesByQuery(searchTerm);
  const { data: repeatDays } = useRepeatDays();

  const {
    setValue,
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting },
  } = useForm<Omit<Schedule, "workOrder">>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({
      ...schedule,
      repeatDayIds: schedule?.repeatDays?.map((x) => x.id) ?? [],
    }),
  });

  useEffect(() => {
    schedule &&
      schedule.scheduleType &&
      setValue("scheduleTypeId", schedule.scheduleType.id);
  }, [schedule, setValue]);

  useEffect(() => {
    if (employee || schedule?.employee) {
      schedule && setValue("employeeId", employee?.id ?? schedule.employee.id);
    }
  }, [schedule, employee, setValue]);

  if (isLoadingEmployee || isLoadingScheduleTypes || isIdleScheduleTypes) {
    return <LoadingSpinner />;
  }

  if (isErrorScheduleTypes) {
    return <h2>{error.message}</h2>;
  }

  return (
    <FormContainer
      onSubmit={handleSubmit(async (schedule) => {
        schedule.id
          ? await patchSchedule(schedule)
          : await createSchedule(schedule);
        onSubmitted && onSubmitted();
      })}
    >
      <Stack direction="column" spacing={2}>
        <FormAutocomplete
          name="employeeId"
          label="Employee"
          valueKey="id"
          options={employees ?? []}
          control={control}
          loading={false}
          labelKeys={["firstName", "lastName"]}
          defaultValue={schedule?.employee ?? employee}
          onInputChange={setSearchTerm}
        />
        <FormSelect
          name="scheduleTypeId"
          label="Schedule Type"
          labelKeys={["name"]}
          valueKey="id"
          data={scheduleTypes}
          control={control}
          defaultValue={schedule?.scheduleType ?? scheduleType}
          disabled
        />
        <FormDatePicker
          name="startDate"
          label="Start Date"
          trigger={trigger}
          onSelected={(date) => setValue("endDate", add(date, { days: 1 }))}
          control={control}
        />
        <FormDatePicker
          control={control}
          name="endDate"
          label="End Date"
          trigger={trigger}
        />
        <FormCheckBoxGroup
          name="repeatDayIds"
          label="Repeat Days"
          options={repeatDays ?? []}
          labelKey="name"
          valueKey="id"
          control={control}
          direction="row"
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
});
