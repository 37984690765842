import * as yup from "yup";

export const proposalLineItemSchema = yup.object().shape({
  price: yup.number().label("Price").default(0),
  display: yup.string().label("Display").default(""),
  addToTotal: yup.boolean().label("Add To Total").default(false),
  inventoryItem: yup.object().required().label("Inventory Item").default({}),
  inventoryItemId: yup
    .string()
    .default("")
    .label("Inventory Item")
    .required()
    .transform((value) => value ?? ""),
});
