import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InventoryType } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddInventoryType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (inventoryType: InventoryType) =>
      add<InventoryType>("inventory-types", inventoryType),
    {
      onSuccess: (inventoryType) => {
        enqueueSnackbar("Inventory Type Added", { variant: "success" });
        queryClient.setQueryData<InventoryType[]>(
          RQ.INVENTORY_TYPES,
          (inventoryTypes: InventoryType[] | undefined) => {
            return [...(inventoryTypes ?? []), inventoryType];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Inventory Type Adding Error", { variant: "error" });
      },
    }
  );
};
