/**
 * Finds an entity with the key among the array of entities and replaces it with a provided one
 * @param entities array of objects
 * @param updatedEntity the new updated object that will replace the old one
 * @param key attribute property name of the model you would like to use when comparing
 * @returns {T[]}
 */
export function updateEntity<T>(
  entities: T[],
  updatedEntity: T,
  key: string
): T[] {
  return entities.map((entity: T) =>
    entity[key as keyof T] === updatedEntity[key as keyof T]
      ? updatedEntity
      : entity
  );
}

/**
 * Finds an entity with the key among the array of entities and updates it with a provided one
 * @param entities array of objects
 * @param updatedEntity the new updated object that will replace the old one
 * @param key attribute property name of the model you would like to use when comparing
 * @returns {T[]}
 */
export function patchEntity<T>(
  entities: T[],
  updatedEntity: Partial<T>,
  key: string
): T[] {
  return entities.map((entity: T) =>
    entity[key as keyof T] === updatedEntity[key as keyof T]
      ? { ...entity, ...updatedEntity }
      : entity
  );
}

/**
 * @param entities array of objects
 * @param key attribute property name of the model you would like to use when comparing
 * @param value id of the target value you would like to delete
 * @return {T[]}
 */
export function deleteEntity<T>(
  entities: T[],
  key: string,
  value: string
): T[] {
  return (
    entities?.filter((entity: T) => entity[key as keyof T] !== value) ?? []
  );
}
