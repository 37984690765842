import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { RecursiveKeyof } from "../../../types";
import { getAttributeFromObject } from "../../../utils/get-attribute-from-object";
import { uncamelize } from "../../../utils";
import { getLabel } from "../../../utils/getLabel";
import { InvoiceLineItem, MergeItem } from "../../../models";

interface Props {
  groups: Array<InvoiceLineItem[]>;
  displayLabels: RecursiveKeyof<InvoiceLineItem>[];
  open: boolean;
  onMerge: (entity: MergeItem) => void;
  handleClose?: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  overflow: "scroll",
  overflowY: "auto",
  overflowX: "auto",
  maxHeight: "900px",
  display: "block",
  boxShadow: 24,
  p: 4,
};

export const DuplicatesModal = (props: Props) => {
  const { groups, displayLabels, open, onMerge, handleClose } = props;
  const [done, setDone] = useState<{ index: number; show: boolean }[]>([]);
  const [mergeSettings, setMergeSettings] = useState<MergeItem[]>([]);

  const renderIcon = (index: number) => {
    const isChecked = done.some((d) => d.index === index && d.show === true);
    if (isChecked) {
      return <DoneIcon />;
    }
    return <DoNotDisturbOnIcon />;
  };

  const prepareData = () => {
    let index = 0;
    let mergeItems: MergeItem[] = [];
    for (const group of groups) {
      let quantity = 0;
      let id = "";
      let cost = 0;
      let price = 0;
      for (const item of group) {
        id = item.inventoryItem.id;
        quantity += item.quantity;
        cost = item.cost;
        price = item.price;
      }
      index++;
      mergeItems.push({ index, id, cost, price, quantity });
    }
    setMergeSettings(mergeItems);
  };

  useEffect(() => {
    groups && prepareData();
  }, [groups]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setDone([]);
        handleClose && handleClose();
      }}
    >
      <Box sx={style}>
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Typography variant="h5" gutterBottom>
            Merge Duplicates
          </Typography>
        </Box>

        {groups && mergeSettings.length === groups.length && (
          <Grid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            direction="column"
          >
            {groups.map((items, groupIndex) => {
              return (
                <React.Fragment key={groupIndex}>
                  <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table stickyHeader aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          {displayLabels.map((displayLabel, labelIndex) => (
                            <TableCell key={labelIndex}>
                              {uncamelize(getLabel(displayLabel), " ")}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((item, itemIndex) => (
                          <TableRow
                            key={itemIndex}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            {displayLabels.map((displayLabel, labelIndex) => (
                              <TableCell align="left" key={labelIndex}>
                                <>
                                  {getAttributeFromObject(item, displayLabel)}
                                </>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                    <TextField
                      id="new-cost"
                      label="New Cost"
                      defaultValue={mergeSettings[groupIndex]?.cost}
                      size="small"
                      onChange={(event) => {
                        let newArry = [...mergeSettings];
                        newArry[groupIndex] = {
                          ...newArry[groupIndex],
                          cost: Number(event.target.value),
                        };
                        setMergeSettings(newArry);
                      }}
                    />
                    <TextField
                      id="new-price"
                      label="New Price"
                      defaultValue={mergeSettings[groupIndex]?.price}
                      onChange={(event) => {
                        let newArry = [...mergeSettings];
                        newArry[groupIndex] = {
                          ...newArry[groupIndex],
                          price: Number(event.target.value),
                        };
                        setMergeSettings(newArry);
                      }}
                      size="small"
                    />
                    <TextField
                      id="new-quantity"
                      label="New Quantity"
                      defaultValue={mergeSettings[groupIndex]?.quantity}
                      onChange={(event) => {
                        let newArry = [...mergeSettings];
                        newArry[groupIndex] = {
                          ...newArry[groupIndex],
                          quantity: Number(event.target.value),
                        };
                        setMergeSettings(newArry);
                      }}
                      size="small"
                    />
                  </Stack>
                  <Button
                    variant="contained"
                    onClick={() => {
                      onMerge(mergeSettings[groupIndex]);
                      setDone([...done, { index: groupIndex, show: true }]);
                    }}
                    endIcon={renderIcon(groupIndex)}
                  >
                    Merge
                  </Button>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                </React.Fragment>
              );
            })}
          </Grid>
        )}
      </Box>
    </Modal>
  );
};
