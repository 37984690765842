import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { BoxProps } from "@mui/system";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { ConfirmationModal } from "../ConfirmationModal/Confirmation.modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  overflow: "scroll",
  overflowY: "auto",
  overflowX: "auto",
  maxHeight: "900px",
  display: "block",
  boxShadow: 24,
  p: 4,
};

interface Props extends BoxProps {
  open: boolean;
  handleClose?: () => void;
  children: JSX.Element | JSX.Element[];
  title?: string;
}

export const ModalContainerChangeDetection = (props: Props) => {
  const { open, handleClose, children, title } = props;
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const { getChangeDetection, setChangeDetection } = useChangeDetection();

  useEffect(() => setChangeDetection(false), [setChangeDetection]);

  const onClose = () => {
    if (getChangeDetection() === true) {
      setConfirmationModal(true);
    } else {
      handleClose && handleClose();
      setChangeDetection(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Box sx={{ textAlign: "center" }}>
            {title && (
              <Typography variant="h5" gutterBottom>
                {title}
              </Typography>
            )}
          </Box>
          {children}
        </Box>
      </Modal>

      <ConfirmationModal
        title="Changes Were Made But Not Saved"
        open={openConfirmationModal}
        showWarning
        onClose={(selection) => {
          setConfirmationModal(false);
          if (selection) {
            handleClose && handleClose();
            setChangeDetection(false);
          }
        }}
      >
        <>Are you sure you want to leave this page without saving changes?</>
      </ConfirmationModal>
    </>
  );
};
