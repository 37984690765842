import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Proposal } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchProposal = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (proposal: Partial<Proposal>) =>
      patch({ route: "proposals", id: proposal.id ?? "", entity: proposal }),
    {
      onSuccess: (proposal) => {
        enqueueSnackbar("Proposal Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.PROPOSALS,
          (proposals: Proposal[] | undefined) =>
            !proposals ? [] : patchEntity<Proposal>(proposals, proposal, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Proposal Updating Error", { variant: "error" });
      },
    }
  );
};
