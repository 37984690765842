import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InventoryType } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteInventoryType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("inventory-types", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Inventory Type Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.INVENTORY_TYPES,
        (inventoryTypes: InventoryType[] | undefined) => {
          return !inventoryTypes
            ? []
            : deleteEntity<InventoryType>(inventoryTypes, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Inventory Type Deleting Error", { variant: "error" });
    },
  });
};
