import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";

export const customerPaymentColumns: GridColDef[] = [
  {
    field: "workOrderNumber",
    headerName: "Work Order Number",
    flex: 1,
  },
  { field: "amount", headerName: "Amount", flex: 1 },
  {
    field: "paymentDate",
    headerName: "Payment Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.paymentDate
        ? format(parseISO(params.row.paymentDate), "MMM dd, yyyy")
        : "",
  },
  { field: "note", headerName: "Note", flex: 1 },
  {
    field: "paymentType",
    headerName: "Payment Type",
    flex: 1,
    valueGetter: (params) =>
      params.row.paymentType ? params.row.paymentType.name : "",
  },
  {
    field: "paymentMethod",
    headerName: "Payment Method",
    flex: 1,
    valueGetter: (params) =>
      params.row.paymentMethod ? params.row.paymentMethod.name : "",
  },
];
