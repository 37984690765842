import * as yup from "yup";

export const companyDocumentTagSchema = yup.object().shape({
  tag: yup
    .string()
    .required()
    .label("Tag")
    .default("")
    .transform((value) => value ?? ""),
});
