import { useQuery } from "react-query";

import { WorkOrderState } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useWorkOrderStates = () => {
  return useQuery<WorkOrderState[], Error>(RQ.WORK_ORDER_STATES, async () =>
    getAll<WorkOrderState>("work-order-states")
  );
};
