import { useState } from "react";
import { useDeletePaymentMethod } from "../../../hooks/payment-method";
import { PaymentMethod } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { paymentMethodColumns as columns } from "./payment-method.columns";
import { PaymentMethodForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const PaymentMethodDataGrid = (props: Props<PaymentMethod>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deletePaymentMethod } = useDeletePaymentMethod();

  const onEditCreate = (paymentMethod?: PaymentMethod) => {
    paymentMethod
      ? setPaymentMethod(paymentMethod)
      : setPaymentMethod(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDelete={(paymentMethod) => deletePaymentMethod(paymentMethod.id)}
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={onLink}
      title={title}
      createTitle="Create Payment Method"
      deleteTitle="Do you want to delete this Payment Method"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${paymentMethod ? "Edit" : "Create"} Payment Method`}
      >
        <PaymentMethodForm
          onSubmitted={() => setOpenModal(false)}
          paymentMethod={paymentMethod}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
