import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { Schedule } from "../../../models";

export const scheduleColumns: GridColDef<Schedule>[] = [
  {
    field: "workOrderId",
    headerName: "Work Order",
    flex: 1,
    valueGetter: (params) =>
      params.row.workOrder ? params.row.workOrder.workOrderNumber : "",
  },
  {
    field: "customer",
    headerName: "Customer",
    flex: 1,
    valueGetter: (params) =>
      params.row.workOrder
        ? `${params.row.workOrder.customer.firstName} ${params.row.workOrder.customer.lastName}`
        : "",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.startDate
        ? format(parseISO(params.row.startDate.toString()), "PPpp")
        : "",
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.endDate
        ? format(parseISO(params.row.endDate.toString()), "PPpp")
        : "",
  },
  {
    field: "scheduleTypeId",
    headerName: "Schedule Type",
    flex: 1,
    valueGetter: (params) =>
      params.row.scheduleType ? params.row.scheduleType.name : "",
  },
  {
    field: "employeeId",
    headerName: "Employee",
    flex: 1,
    valueGetter: (params) =>
      params.row.employee
        ? `${params.row.employee.firstName} ${params.row.employee.lastName}`
        : "",
  },
  {
    field: "repeats",
    headerName: "Repeats",
    flex: 1,
    valueGetter: (params) =>
      params.row.repeatDays
        ? `${params.row.repeatDays.map((x) => x.name)}`
        : "",
  },
];
