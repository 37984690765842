import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

interface Props {
  description?: string;
}

export const JobDescription = (props: Props) => {
  const { description } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 3, border: "none" }}
      elevation={0}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
          <TableRow>
            <TableCell align="center">
              <Typography variant="h6">Job Description</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                {description}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
