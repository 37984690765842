import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { TaskDuration } from "../../../models";
import { taskDurationSchema as schema } from "./task-duration.schema";
import {
  useAddTaskDuration,
  usePatchTaskDuration,
} from "../../../hooks/task-duration";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  taskDuration?: TaskDuration;
  onSubmitted?: () => void;
}

export const TaskDurationForm = (props: Props) => {
  const { taskDuration, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createTaskDuration } = useAddTaskDuration();
  const { mutate: patchTaskDuration } = usePatchTaskDuration();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<TaskDuration>({
    resolver: yupResolver(schema),
    defaultValues: taskDuration,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  return (
    <FormContainer
      onSubmit={handleSubmit((taskDuration) => {
        taskDuration.id
          ? patchTaskDuration(taskDuration)
          : createTaskDuration(taskDuration);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="column" spacing={2}>
        <FormTextField name="name" label="Name" control={control} />
        <FormTextField
          name="duration"
          label="Duration"
          control={control}
          type="number"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Hours</InputAdornment>
            ),
          }}
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
