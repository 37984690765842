import { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, SxProps, Theme } from "@mui/material";

import { ConfirmationModal } from "../../ui/ConfirmationModal/Confirmation.modal";
import {
  DataGridContainer,
  DisplayInfo,
  GridActions,
  DataGridToolBar,
} from "../../ui";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { RecursiveKeyof } from "../../../types";

export interface DataGridProps<T extends object> {
  rows?: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onDoubleClick?: (entity: T) => void;
  onDelete?: (entity: T) => void;
  onCreate?: () => void;
  onEdit?: (entity: T) => void;
  onDuplicate?: (entity: T) => void;
  onDownload?: (entity: T) => void;
  onSend?: (entity: T) => void;
  onView?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onPay?: (entity: T) => void;
  onChecked?: (entity: T, checked: boolean) => void;
  onPageChange?: (page: number) => void;
  title?: string;
  createTitle?: string;
  deleteTitle?: string;
  columns: GridColDef[];
  columnVisibilityModel?: GridColumnVisibilityModel;
  ignoreFields?: RecursiveKeyof<T>[];
  children?: JSX.Element;
  autoHeight?: boolean;
  hideFooter?: boolean;
  initialState?: GridInitialStateCommunity | undefined;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  skipConfirmation?: boolean;
  sx?: SxProps<Theme>;
  checkId?: keyof T;
  currentUserId?: string;
}

export const GenericDataGrid = <T extends object>(props: DataGridProps<T>) => {
  const {
    rows,
    loading,
    onClick,
    onDoubleClick,
    onDelete,
    onEdit,
    onDuplicate,
    onDownload,
    onCreate,
    onSend,
    onView,
    onLink,
    onPay,
    onChecked,
    onPageChange,
    title,
    createTitle,
    deleteTitle,
    ignoreFields,
    columnVisibilityModel,
    children,
    autoHeight,
    hideFooter,
    initialState,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
    skipConfirmation,
    checkId,
    currentUserId,
    sx = { height: 300 },
  } = props;
  let { columns } = props;
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [entity, setEntity] = useState<T>();
  const [gridColumns, setGridColumns] = useState(columns);

  // Update with a useCallback
  useEffect(() => {
    if (onEdit || onDelete || onView) {
      if (!gridColumns.find((column) => column.field === "actions")) {
        attachColumns();
      }
    }
  }, []);

  const attachColumns = () => {
    setGridColumns([
      ...gridColumns,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 170,
        renderCell: (params) =>
          params && (
            <GridActions
              onRowEdit={
                (checkId && params.row[checkId] === currentUserId) ?? onEdit
                  ? onRowEdit
                  : undefined
              }
              onRowDelete={
                (checkId && params.row[checkId] === currentUserId) ?? onDelete
                  ? onRowDelete
                  : undefined
              }
              onRowDuplicate={onDuplicate ? onRowDuplicate : undefined}
              onRowDownload={onDownload ? onRowDownload : undefined}
              onRowSend={onSend ? onRowSend : undefined}
              onRowView={onView ? onRowView : undefined}
              onRowLink={onLink ? onRowLink : undefined}
              onRowPay={onPay ? onRowPay : undefined}
              onRowChecked={onChecked ? onRowChecked : undefined}
              row={params.row}
            />
          ),
      },
    ]);
  };

  const create = () => onCreate && onCreate();
  const onRowEdit = (entity: T) => onEdit && onEdit(entity);
  const onRowDuplicate = (entity: T) => onDuplicate && onDuplicate(entity);
  const onRowDownload = (entity: T) => onDownload && onDownload(entity);
  const onRowSend = (entity: T) => onSend && onSend(entity);
  const onRowView = (entity: T) => onView && onView(entity);
  const onRowLink = (entity: T) => onLink && onLink(entity);
  const onRowPay = (entity: T) => onPay && onPay(entity);
  const onRowChecked = (entity: T, checked: boolean) =>
    onChecked && onChecked(entity, checked);

  const onRowDelete = (entity: T) => {
    if (skipConfirmation) {
      onDelete && onDelete(entity);
    } else {
      setEntity(entity);
      setOpenConfirmationModal(true);
    }
  };

  const onConfirmationModalClosed = (selection: boolean) => {
    setOpenConfirmationModal(false);
    if (selection && entity) {
      onDelete && onDelete(entity);
    }
  };

  return (
    <DataGridContainer>
      <DataGrid
        {...props}
        {...sx}
        rows={rows || []}
        columns={gridColumns}
        columnVisibilityModel={columnVisibilityModel}
        onCellClick={(params: GridCellParams) => onClick && onClick(params.row)}
        onCellDoubleClick={(params: GridCellParams) =>
          onDoubleClick && onDoubleClick(params.row)
        }
        loading={loading}
        disableDensitySelector={true}
        disableColumnFilter={true}
        density="compact"
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: DataGridToolBar,
        }}
        componentsProps={{
          toolbar: {
            title,
            createTitle,
            createHandler: create,
            disableExport,
            disableColumnsButton,
            disableQuickFilter,
            disableCreate,
          },
        }}
        autoHeight={autoHeight}
        hideFooter={hideFooter}
        initialState={initialState}
        onPageChange={(page) => onPageChange && onPageChange(page)}
        paginationMode="server"
        rowCount={1000}
        keepNonExistentRowsSelected
      />

      {children}

      <ConfirmationModal
        title={deleteTitle ?? ""}
        open={openConfirmationModal}
        onClose={(selection: boolean) => onConfirmationModalClosed(selection)}
      >
        {entity && (
          <Box sx={{ mb: 1 }}>
            <DisplayInfo
              entity={entity}
              displayEmpties={true}
              ignore={ignoreFields}
            />
          </Box>
        )}
      </ConfirmationModal>
    </DataGridContainer>
  );
};
