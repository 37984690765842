import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { ReportType } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchReportType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (reportType: ReportType) =>
      patch({
        route: "report-types",
        id: reportType.id ?? "",
        entity: reportType,
      }),
    {
      onSuccess: (reportType) => {
        enqueueSnackbar("Report Type Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.REPORT_TYPES,
          (reportTypes: ReportType[] | undefined) =>
            !reportTypes
              ? []
              : patchEntity<ReportType>(reportTypes, reportType, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Report Type Updating Error", { variant: "error" });
      },
    }
  );
};
