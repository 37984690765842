import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Invoice, InvoiceLineItem } from "../../../models";
import { useEffect, useState } from "react";
import { useInventoryTypes } from "../../../hooks/inventory-type";
import { WarrantyDisplay } from "./WarrantyDisplay";

interface Props {
  invoice: Invoice;
}

export const InvoiceCategorySection = (props: Props) => {
  let { invoice } = props;
  const { data: inventoryTypes } = useInventoryTypes();
  const [equipmentGroup, setEquipmentGroup] = useState<InvoiceLineItem[]>([]);
  const containsEquipment = invoice.invoiceLineItems.filter(
    (inv) => inv.inventoryItem.inventoryType.showInInvoiceCategorySection
  );

  useEffect(() => {
    const equipmentGroupSet: InvoiceLineItem[] = [];

    if (invoice && inventoryTypes) {
      const dontShowIds = inventoryTypes
        .filter((x) => x.showInInvoiceCategorySection)
        .map((y) => y.invoiceCategory.id);

      for (const invoiceLineItem of invoice.invoiceLineItems) {
        if (
          invoiceLineItem.inventoryItem.inventoryType
            .showInInvoiceCategorySection
        ) {
          equipmentGroupSet.push(invoiceLineItem);
        }
      }
      setEquipmentGroup(equipmentGroupSet);

      invoice.invoiceCostsPrices = invoice.invoiceCostsPrices.filter(
        (icp) => !dontShowIds?.includes(icp.invoiceCategory.id)
      );
    }
    setEquipmentGroup(equipmentGroupSet);
  }, [invoice, inventoryTypes]);

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 3, border: "none" }}
      elevation={0}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#eee", border: "none" }}>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Item</Typography>
            </TableCell>
            <TableCell>
              {containsEquipment && <Typography variant="h6">Model</Typography>}
            </TableCell>
            <TableCell>
              {containsEquipment && (
                <Typography variant="h6">Serial Number</Typography>
              )}
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6">Price</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {equipmentGroup
            .filter((x) => x.hide === false)
            .map((invoiceLineItem) => (
              <TableRow key={invoiceLineItem.id}>
                <TableCell>
                  <Typography variant="body1">
                    {invoiceLineItem.inventoryItem.name}
                  </Typography>
                  <WarrantyDisplay warranty={invoiceLineItem} />
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {invoiceLineItem.inventoryItem.model}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {invoiceLineItem.serialNumber}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {Number(invoiceLineItem.inventoryItem.price).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          {invoice.invoiceCostsPrices.map((icp, index) => {
            return (
              <TableRow key={index}>
                <TableCell colSpan={3}>
                  <Typography variant="body1">
                    {icp.invoiceCategory.name}
                  </Typography>
                </TableCell>
                <TableCell align="right">{icp.price}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
