import { useState } from "react";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

import { ReferralType } from "../../models";
import { ReferralTypeForm } from "../../components/forms";
import { GenericDataGrid } from "../../components/grids";
import { referralTypeColumns } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import {
  useReferralTypes,
  useDeleteReferralType,
} from "../../hooks/referral-type";

export const ReferralTypesPage = () => {
  const {
    data: referralTypes,
    isLoading,
    isIdle,
    isError,
    error,
  } = useReferralTypes();
  const { mutate: deleteReferralType } = useDeleteReferralType();
  const [referralType, setReferralType] = useState<ReferralType>();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (referralType?: ReferralType) => {
    referralType ? setReferralType(referralType) : setReferralType(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Referral Types"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <GenericDataGrid
        rows={referralTypes}
        columns={referralTypeColumns}
        loading={isLoading}
        onDelete={(referralType) => deleteReferralType(referralType.id)}
        onEdit={onEditCreate}
        onDoubleClick={onEditCreate}
        onCreate={onEditCreate}
        createTitle="Create Referral Type"
        deleteTitle="Would you like to delete this Referral Type"
        ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        autoHeight
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${referralType ? "Edit" : "Create"} Referral Type`}
        >
          <ReferralTypeForm
            onSubmitted={() => setOpenModal(false)}
            referralType={referralType}
          />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
    </>
  );
};
