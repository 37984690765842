import { useQuery } from "react-query";

import { BalanceSheet } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useBalanceSheet = () => {
  return useQuery<BalanceSheet, Error>(RQ.REPORT_BALANCE_SHEET, async () =>
    getSingle<BalanceSheet>("reports/balance-sheet")
  );
};
