import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Invoice } from "../../models";
import { patch } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";

export const usePatchInvoice = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (invoice: Partial<Invoice>) =>
      patch({ route: "invoices", id: invoice.id ?? "", entity: invoice }),
    {
      onSuccess: (invoice) => {
        enqueueSnackbar("Invoice Updated", { variant: "success" });

        queryClient.setQueryData(
          RQ.INVOICES,
          (invoices: Invoice[] | undefined) =>
            !invoices ? [] : patchEntity<Invoice>(invoices, invoice, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Invoice Updating Error", { variant: "error" });
      },
    }
  );
};
