import { Autocomplete, TextField } from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface Props<T, K extends FieldValues> {
  options: T[];
  label: string;
  defaultValue?: T | undefined;
  onInputChange?: (value: string) => void;
  name: Path<K>;
  control: Control<K>;
}

export const FormAutocompleteFreeSolo = <T, K extends FieldValues>(
  props: Props<T, K>
) => {
  const {
    options = [],
    label,
    defaultValue = undefined,
    name,
    control,
    onInputChange,
  } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <Autocomplete
      freeSolo
      id={label}
      options={options}
      value={field.value}
      size="small"
      fullWidth
      {...defaultValue}
      onChange={(_, entity, reason) => {
        if (reason === "selectOption" && entity) {
          entity && field.onChange(entity);
        }
      }}
      onInputChange={(event, value) => {
        if (event && event.type !== "click") {
          field.onChange(value);
          onInputChange && onInputChange(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          size="small"
          fullWidth
        />
      )}
    />
  );
};
