import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { CustomerDocument } from "../../../models";
import { customerDocumentSchema as schema } from "./customer-document.schema";
import {
  useAddCustomerDocument,
  usePatchCustomerDocument,
} from "../../../hooks/customer-document";
import { FormContainer } from "../../layout";
import {
  FormFileUpload,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import {
  doesExtensionExist,
  getFileExtension,
  getFileNameFromPath,
} from "../../../utils";

interface Props {
  customerDocument?: CustomerDocument;
  onSubmitted?: (customerDocument: CustomerDocument) => void;
}

export const CustomerDocumentForm = (props: Props) => {
  const { customerDocument, onSubmitted } = props;
  const [fileExtension, setFileExtension] = useState("");
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createCustomerDocument } = useAddCustomerDocument();
  const { mutateAsync: patchCustomerDocument } = usePatchCustomerDocument();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { defaultValues },
  } = useForm<CustomerDocument>({
    resolver: yupResolver(schema),
    defaultValues: customerDocument,
  });

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (customerDocument) => {
        if (doesExtensionExist(customerDocument.name)) {
          customerDocument.name = customerDocument.name + fileExtension;
        }
        customerDocument = customerDocument.id
          ? await patchCustomerDocument(customerDocument)
          : await createCustomerDocument(customerDocument);
        onSubmitted && onSubmitted(customerDocument);
        setChangeDetection(false);
      })}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormFileUpload
          name="file"
          label=""
          control={control}
          type="file"
          required
          onInputChanged={(value) => {
            if (typeof value === "string") {
              setFileExtension(getFileExtension(value));
              setValue("name", getFileNameFromPath(value));
            }
          }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField name="name" label="Name" control={control} required />
        <FormTextField name="note" label="Note" control={control} />
        <FormSubmitButton />
      </Stack>
    </FormContainer>
  );
};
