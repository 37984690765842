import { useQuery } from "react-query";

import { InventoryType } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useInventoryTypes = () => {
  return useQuery<InventoryType[], Error>(
    RQ.INVENTORY_TYPES,
    async () => getAll<InventoryType>("inventory-types"),
    {
      select: (inventoryTypes) => {
        inventoryTypes.forEach((inventoryType: InventoryType) => {
          if (inventoryType.invoiceCategory) {
            inventoryType.invoiceCategoryId = inventoryType.invoiceCategory.id;
          }
        });

        return inventoryTypes;
      },
    }
  );
};
