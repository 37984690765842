import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { uploadFile } from "../../api/generic-api";

export const useUploadVendors = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (file: File) => uploadFile<{ file: File }>(`/vendors/upload`, { file }),
    {
      onSuccess: () => {
        enqueueSnackbar("Vendors Added", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Vendors Adding Error", { variant: "error" });
      },
    }
  );
};
