import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { EmployeeType } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddEmployeeType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (employeeType: EmployeeType) =>
      add<EmployeeType>("employee-types", employeeType),
    {
      onSuccess: (employeeType) => {
        enqueueSnackbar("Employee Type Added", { variant: "success" });
        queryClient.setQueryData<EmployeeType[]>(
          RQ.EMPLOYEE_TYPES,
          (employeeTypes: EmployeeType[] | undefined) => {
            return [...(employeeTypes ?? []), employeeType];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Employee Type Adding Error", { variant: "error" });
      },
    }
  );
};
