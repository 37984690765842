import * as yup from "yup";

export const workOrderDocumentSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .label("Name")
    .default("")
    .transform((value) => value ?? ""),
  note: yup
    .string()
    .label("Note")
    .default("")
    .transform((value) => value ?? ""),
  file: yup.mixed().label("File"),
});
