import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useAuth } from "../../../contexts/AuthContext";

interface Login {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().required().label("Email"),
});

export const ForgotPasswordForm = () => {
  const { forgotPassword } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Login>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  return (
    <FormContainer
      onSubmit={handleSubmit((form) =>
        forgotPassword(form.email).then(() => navigate("/confirm-code"))
      )}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="email" label="Email" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} text="Reset Password" />
    </FormContainer>
  );
};
