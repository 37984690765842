import { useNavigate } from "react-router-dom";
import { LoginForm } from "../../components/forms";
import { Button } from "@mui/material";

export const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "absolute",
        top: "40%",
        left: "40%",
        transform: "translate(0px, -50%)",
      }}
    >
      <h2>Login</h2>
      <LoginForm />
      <Button onClick={() => navigate("/forgot-password")} sx={{ mt: 5 }}>
        Forgot Password ?
      </Button>
    </div>
  );
};
