import { useQuery } from "react-query";

import { InventoryItem } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useSearchInventoryItems = (searchTerm: string) => {
  searchTerm = encodeURI(searchTerm);
  return useQuery<InventoryItem[], Error>(
    [RQ.INVENTORY_ITEM, searchTerm],
    () =>
      getAll<InventoryItem>(`inventory-items/search?searchTerm=${searchTerm}`),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
