import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { ScheduleType } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchScheduleType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (scheduleType: ScheduleType) =>
      patch({
        route: "schedule-types",
        id: scheduleType.id ?? "",
        entity: scheduleType,
      }),
    {
      onSuccess: (scheduleType) => {
        enqueueSnackbar("Schedule Type Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.SCHEDULE_TYPES,
          (scheduleTypes: ScheduleType[] | undefined) =>
            !scheduleTypes
              ? []
              : patchEntity<ScheduleType>(scheduleTypes, scheduleType, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Schedule Type Updating Error", { variant: "error" });
      },
    }
  );
};
