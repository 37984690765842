import * as yup from "yup";

export const customerPaymentSchema = yup.object().shape({
  amount: yup.number().required().label("Amount").default(0),
  paymentDate: yup.date().required().label("Payment Date").default(new Date()),
  name: yup
    .string()
    .label("Note")
    .default("")
    .transform((value) => value ?? ""),
  workOrderId: yup
    .string()
    .label("Work Order")
    .default("")
    .required()
    .transform((value) => value ?? ""),
  paymentTypeId: yup
    .string()
    .label("Payment Type")
    .default("Normal")
    .transform((value) => value ?? ""),
  paymentMethodId: yup
    .string()
    .label("Payment Method")
    .default("Cash")
    .transform((value) => value ?? ""),
});
