import { MuiChipsInput } from "mui-chips-input";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface Props<T, K extends FieldValues> {
  name: Path<K>;
  control: Control<K, any>;
  label: string;
  valueKey: keyof T;
}

export const FormChipsInput = <T, K extends FieldValues>(
  props: Props<T, K>
) => {
  const { control, name, valueKey, label } = props;
  const { field, fieldState } = useController({ name, control });

  return (
    <MuiChipsInput
      {...field}
      value={field?.value?.map((x: T) => x[valueKey as keyof object])}
      onChange={(value) =>
        field.onChange(
          value.map((x) => {
            return {
              [valueKey as keyof object]: x,
            };
          })
        )
      }
      hideClearAll
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      label={label}
      size="small"
    />
  );
};
