import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";

export const customerDocumentColumns: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1 },
  { field: "note", headerName: "Note", flex: 1 },
  {
    field: "createdTimestamp",
    headerName: "Created Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.createdTimestamp
        ? format(parseISO(params.row.createdTimestamp), "PPpp")
        : "",
  },
];
