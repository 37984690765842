import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { PaymentType } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchPaymentType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (paymentType: PaymentType) =>
      patch({
        route: "payment-types",
        id: paymentType.id ?? "",
        entity: paymentType,
      }),
    {
      onSuccess: (paymentType) => {
        enqueueSnackbar("Payment Type Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.PAYMENT_TYPES,
          (paymentTypes: PaymentType[] | undefined) =>
            !paymentTypes
              ? []
              : patchEntity<PaymentType>(paymentTypes, paymentType, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Payment Type Updating Error", { variant: "error" });
      },
    }
  );
};
