import { Address } from "../models";

export function convertToAddress(
  address_components: google.maps.GeocoderAddressComponent[]
) {
  interface IShouldBeComponent {
    streetNumber: string[];
    streetName: string[];
    unit: string[];
    postalCode: string[];
    city: string[];
    province: string[];
    country: string[];
  }

  var shouldBeComponent: IShouldBeComponent = {
    streetNumber: ["street_number"],
    streetName: ["street_address", "route"],
    unit: ["subpremise"],
    postalCode: ["postal_code"],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
      "administrative_area_level_3",
    ],
    province: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    country: ["country"],
  };

  var address: Partial<Address> = {
    streetNumber: "",
    streetName: "",
    unit: "",
    postalCode: "",
    city: "",
    province: "",
    country: "",
  };

  address_components.forEach((component) => {
    for (var shouldBe in shouldBeComponent) {
      if (
        shouldBeComponent[shouldBe as keyof IShouldBeComponent].indexOf(
          component.types[0]
        ) !== -1
      ) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe as keyof IShouldBeComponent] = component.long_name;
        }
      }
    }
  });
  return address;
}
