import { isValid } from "date-fns";
import * as yup from "yup";

export const employeeSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label("First Name")
    .transform((value) => value ?? ""),
  lastName: yup
    .string()
    .label("Last Name")
    .transform((value) => value ?? ""),
  displayName: yup
    .string()
    .label("Display Name")
    .transform((value) => value ?? ""),
  email: yup
    .string()
    .email()
    .required()
    .label("Email")
    .transform((value) => value ?? ""),
  homePhone: yup
    .string()
    .label("Home Phone")
    .transform((value) => value ?? ""),
  cellPhone: yup
    .string()
    .label("Cell Phone")
    .transform((value) => value ?? ""),
  streetNumber: yup
    .string()
    .required()
    .label("Street Number")
    .default("")
    .transform((value) => value ?? ""),
  streetName: yup
    .string()
    .required()
    .label("Street Name")
    .transform((value) => value ?? ""),
  unit: yup
    .string()
    .label("Unit")
    .transform((value) => value ?? ""),
  postalCode: yup
    .string()
    .label("Postal Code")
    .transform((value) => value ?? ""),
  city: yup
    .string()
    .required()
    .label("City")
    .transform((value) => value ?? ""),
  province: yup
    .string()
    .label("Province")
    .default("ON")
    .transform((value) => value ?? "ON"),
  country: yup
    .string()
    .label("Country")
    .default("CA")
    .transform((value) => value ?? "CA"),
  buzzCode: yup
    .string()
    .label("Buzz Code")
    .transform((value) => value ?? ""),
  startDate: yup
    .date()
    .required()
    .label("Due Date")
    .transform((value) => (isValid(value) ? (value as Date) : new Date()))
    .default(new Date()),
  signOff: yup
    .string()
    .label("Sign Off")
    .transform((value) => value ?? ""),
  dispatchPriority: yup
    .number()
    .required()
    .label("Dispatch Priority")
    .transform((value) => value ?? ""),
  employeeRoleId: yup
    .string()
    .required()
    .label("Employee Role")
    .transform((value) => value ?? ""),
  inactive: yup.boolean().label("Inactive").default(false),
});
