import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerEquipment } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchCustomerEquipment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerEquipment: Partial<CustomerEquipment>) =>
      patch({
        route: "customer-equipment",
        id: customerEquipment.id ?? "",
        entity: customerEquipment,
      }),
    {
      onSuccess: (customerEquipment) => {
        enqueueSnackbar("Customer Equipment Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMER_EQUIPMENTS, customerEquipment.customerId],
          (customerEquipments: CustomerEquipment[] | undefined) =>
            !customerEquipments
              ? []
              : patchEntity<CustomerEquipment>(
                  customerEquipments,
                  customerEquipment,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Equipment Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
