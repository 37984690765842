import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { quickBooksColumns as columns } from "./quickBooks.columns";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const QuickBooksDataGrid = (props: Props<any>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onLink={onLink}
      title={title}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    />
  );
};
