import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import { InvoiceStatement } from "../../../models";
import { format } from "date-fns";
import { sumBy } from "lodash";

interface Props {
  statements: InvoiceStatement[];
  showOverdueCharges?: boolean;
  showDaysOverdue?: boolean;
  onExcluded: (invoiceId: string, checked: boolean) => void;
}

export const CustomerOverdueInvoices = (props: Props) => {
  const { statements, showOverdueCharges, showDaysOverdue, onExcluded } = props;
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Invoice</b>
            </TableCell>
            <TableCell align="left">
              <b>Customer</b>
            </TableCell>
            <TableCell align="left">
              <b>Work Order State</b>
            </TableCell>
            <TableCell align="left">
              <b>Site Address</b>
            </TableCell>
            <TableCell align="left">
              <b>Summary</b>
            </TableCell>
            <TableCell align="left">
              <b>Due Date</b>
            </TableCell>
            {showDaysOverdue && (
              <TableCell align="left">
                <b>Days Overdue</b>
              </TableCell>
            )}
            <TableCell align="left">
              <b>Original Amount</b>
            </TableCell>
            <TableCell align="left">
              <b>Amount Paid</b>
            </TableCell>
            <TableCell align="left">
              <b>Amount Owing</b>
            </TableCell>
            {showOverdueCharges && (
              <>
                <TableCell align="left">
                  <b>Interest</b>
                </TableCell>
                <TableCell align="left">
                  <b>Balance</b>
                </TableCell>
              </>
            )}
            <TableCell align="right">Exclude</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statements.map((statement, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{statement.workOrderNumber}</TableCell>
              <TableCell align="left">{statement.customerName}</TableCell>
              <TableCell align="left">{statement.workOrderState}</TableCell>
              <TableCell align="left">{statement.address}</TableCell>
              <TableCell align="left">{statement.jobtypes}</TableCell>
              <TableCell align="left">
                {format(new Date(statement.dueDate), "yyyy-MMM-dd")}
              </TableCell>
              {showDaysOverdue && (
                <TableCell align="left">{statement.daysOverdue}</TableCell>
              )}
              <TableCell align="left">{statement.originalAmount}</TableCell>
              <TableCell align="left">{statement.amountPaid}</TableCell>
              <TableCell align="left">{statement.amountOwing}</TableCell>
              {showOverdueCharges && (
                <>
                  <TableCell align="left">{statement.interestOwing}</TableCell>
                  <TableCell align="left">{statement.balance}</TableCell>
                </>
              )}
              <TableCell align="right">
                <Checkbox
                  onChange={(_, checked) => {
                    onExcluded(statement.guid, checked);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell align="left">
              <b>TOTAL</b>
            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            {showDaysOverdue && <TableCell align="left"></TableCell>}
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left">
              {sumBy(statements, (s) => s.originalAmount).toFixed(2)}
            </TableCell>
            <TableCell align="left">
              {sumBy(statements, (s) => s.amountPaid).toFixed(2)}
            </TableCell>
            <TableCell align="left">
              {sumBy(statements, (s) => s.amountOwing).toFixed(2)}
            </TableCell>
            {showOverdueCharges && (
              <>
                <TableCell align="left">
                  {sumBy(statements, (s) => s.interestOwing).toFixed(2)}
                </TableCell>
                <TableCell align="left">
                  {sumBy(statements, (s) => s.balance).toFixed(2)}
                </TableCell>
              </>
            )}
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
