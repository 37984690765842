import { useQuery } from "react-query";

import { Customer } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useCustomer = (id?: string | null) => {
  return useQuery<Customer, Error>(
    [RQ.CUSTOMERS, id],
    () => getSingle(`customers/${id}`),
    {
      enabled: Boolean(id),
      select: (customer) => {
        if (customer.customerTier) {
          customer.customerTierId = customer.customerTier.id;
        }
        if (customer.addresses) {
          customer.addresses.forEach((address) => {
            if (address.addressType) {
              address.addressTypeId = address.addressType.id;
            }
          });
        }
        return customer;
      },
    }
  );
};
