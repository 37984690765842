import { useQuery } from "react-query";

import { ReferralType } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useReferralTypes = () => {
  return useQuery<ReferralType[], Error>(RQ.REFERRAL_TYPES, async () =>
    getAll<ReferralType>("referral-types")
  );
};
