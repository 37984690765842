import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { EmployeeTimesheet } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddEmployeeTimesheet = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (employeeTimesheet: EmployeeTimesheet) =>
      add<EmployeeTimesheet>("employee-timesheets", employeeTimesheet),
    {
      onSuccess: (employeeTimesheet) => {
        enqueueSnackbar("Employee Timesheet Added", { variant: "success" });
        queryClient.setQueryData<EmployeeTimesheet[]>(
          RQ.EMPLOYEE_TIMESHEETS,
          (employeeTimesheets: EmployeeTimesheet[] | undefined) => {
            return [...(employeeTimesheets ?? []), employeeTimesheet];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Employee Timesheet Adding Error", {
          variant: "error",
        });
      },
    }
  );
};
