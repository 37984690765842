import { Divider, Stack } from "@mui/material";
import { Customer } from "../../../models";

interface Props {
  customer: Customer;
}

export const DisplayCustomerShort = (props: Props) => {
  const { customer } = props;
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
    >
      <div>
        <b>
          {customer.firstName} {customer.lastName}
        </b>
      </div>
      <div>
        <b>{customer.email}</b>
      </div>
      <div>
        <b>{customer.phone}</b>
      </div>
    </Stack>
  );
};
