import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { EmployeeType } from "../../../models";
import { employeeTypeSchema as schema } from "../schemas";
import {
  useAddEmployeeType,
  usePatchEmployeeType,
} from "../../../hooks/employee-type";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  employeeType?: EmployeeType;
  onSubmitted?: () => void;
}

export const EmployeeTypeForm = (props: Props) => {
  const { employeeType, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createEmployeeType } = useAddEmployeeType();
  const { mutate: patchEmployeeType } = usePatchEmployeeType();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<EmployeeType>({
    resolver: yupResolver(schema),
    defaultValues: employeeType,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit((employee) => {
        employee.id
          ? patchEmployeeType(employee)
          : createEmployeeType(employee);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
