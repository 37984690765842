import { useQuery } from "react-query";

import { EmployeeRole } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useEmployeeRoles = () => {
  return useQuery<EmployeeRole[], Error>(RQ.EMPLOYEE_ROLES, async () =>
    getAll<EmployeeRole>("employee-roles")
  );
};
