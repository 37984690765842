import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Vendor } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteVendor = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("vendors", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Vendor Deleted", { variant: "success" });
      queryClient.setQueryData(RQ.VENDORS, (vendors: Vendor[] | undefined) => {
        return !vendors ? [] : deleteEntity<Vendor>(vendors, "id", id);
      });
    },
    onError: () => {
      enqueueSnackbar("Vendor Deleting Error", { variant: "error" });
    },
  });
};
