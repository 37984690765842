import { useQuery } from "react-query";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { InventoryItem } from "../../models";

export const useInventoryItems = (page: number = 0) => {
  return useQuery<InventoryItem[], Error>(
    RQ.INVENTORY_ITEMS,
    async () => getAll<InventoryItem>(`inventory-items?page=${page}`),
    {
      select: (inventoryItems) => {
        inventoryItems.forEach((inventoryItem: InventoryItem) => {
          if (
            inventoryItem.vendorCosts &&
            inventoryItem.vendorCosts.length > 0
          ) {
            inventoryItem.vendorCosts.forEach((vendorCost) => {
              if (vendorCost.vendor) {
                vendorCost.vendorId = vendorCost.vendor.id;
              }
            });
          }
        });

        return inventoryItems;
      },
    }
  );
};
