import { useQuery } from "react-query";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useWorkOrder = (id?: string | null) => {
  return useQuery<WorkOrder, Error>(
    [RQ.WORK_ORDERS, id],
    () => getSingle(`work-orders/${id}`),
    {
      enabled: Boolean(id),
      select: (workOrder) => {
        if (workOrder) {
          if (workOrder.workOrderRoles) {
            workOrder.workOrderRoles.forEach((workOrderRole) => {
              if (workOrderRole.employee) {
                workOrderRole.employeeId = workOrderRole.employee.id;
              }
              if (workOrderRole.workOrderRoleTitle) {
                workOrderRole.workOrderRoleTitleId =
                  workOrderRole.workOrderRoleTitle.id;
              }
            });
          }
          if (workOrder.customer) {
            workOrder.customerId = workOrder.customer.id;
          }
          if (workOrder.schedules) {
            workOrder.schedules.forEach((schedule) => {
              if (schedule.employee) {
                schedule.employeeId = schedule.employee.id;
              }
            });
          }
        }
        return workOrder;
      },
    }
  );
};
