import * as yup from "yup";
import { customerTierDetailSchema } from "./customer-tier-detail.schema";

export const customerTierSchema = yup.object().shape({
  name: yup.string().required().label("Name"),
  customerTierDetails: yup
    .array()
    .of(customerTierDetailSchema)
    .min(1)
    .label("Customer Tier Details"),
});
