import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Contact } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddContact = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (contact: Contact) =>
      add<Contact>(`customers/${contact.customerId}/contacts`, contact),
    {
      onSuccess: (contact, variables) => {
        enqueueSnackbar("Contact Added", { variant: "success" });
        queryClient.setQueryData<Contact[]>(
          [RQ.CONTACTS, variables.customerId],
          (contacts: Contact[] | undefined) => {
            return [...(contacts ?? []), contact];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Contact Adding Error", { variant: "error" });
      },
    }
  );
};
