import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { memo, useEffect, useState } from "react";
import { add } from "date-fns";
import { isEqual } from "lodash";

import { Employee, Schedule, ScheduleType, WorkOrder } from "../../../models";
import { scheduleGroupSchema as schema } from "../schemas";
import { useAddSchedule } from "../../../hooks/schedule";
import { useScheduleTypes } from "../../../hooks/schedule-type";
import { useEmployees } from "../../../hooks/employee";
import { FormContainer } from "../../layout";
import {
  FormCheckBoxGroup,
  FormDatePicker,
  FormDateTimePicker,
  FormSelect,
  FormSubmitButton,
} from "../../form-components";
import { AutoSearch, AutoSearchMultipleAlt, LoadingSpinner } from "../../ui";
import { useRepeatDays } from "../../../hooks/repeat-day";
import { useWorkOrdersSearch } from "../../../hooks/work-order";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  schedule?: Schedule;
  workOrderOnly?: boolean;
  onSubmitted?: () => void;
}

export const ScheduleGroupForm = memo((props: Props) => {
  const { schedule, workOrderOnly, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const [selectedScheduleType, setSelectedScheduleType] = useState<
    ScheduleType | undefined
  >(schedule?.scheduleType);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const { mutateAsync: createSchedule } = useAddSchedule();
  const {
    data: scheduleTypes,
    isLoading: isLoadingScheduleTypes,
    isError: isErrorScheduleTypes,
    isIdle: isIdleScheduleTypes,
    error,
  } = useScheduleTypes();
  const { data: allEmployees } = useEmployees();
  const { data: repeatDays } = useRepeatDays();

  const {
    setValue,
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { isSubmitting, defaultValues },
  } = useForm<Omit<Schedule, "workOrder">>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({
      ...schedule,
      workOrderId: schedule?.workOrder?.id,
      scheduleTypeId: schedule?.scheduleType?.id,
      repeatDayIds:
        schedule?.repeatDays?.map((repeatDay) => repeatDay.id) ?? [],
    }),
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  if (isLoadingScheduleTypes || isIdleScheduleTypes) {
    return <LoadingSpinner />;
  }

  if (isErrorScheduleTypes) {
    return <h2>{error.message}</h2>;
  }

  return (
    <FormContainer
      onSubmit={handleSubmit(async (schedule) => {
        for (const employee of selectedEmployees) {
          await createSchedule({ ...schedule, employeeId: employee.id });
        }
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="column" spacing={2}>
        {selectedScheduleType && selectedScheduleType.requiresWorkOrder && (
          <AutoSearch
            label="Work Order Search..."
            keys={[
              "workOrderNumber",
              "customer.firstName",
              "customer.lastName",
            ]}
            onOptionSelected={(workOrder: WorkOrder | null) =>
              workOrder && setValue("workOrderId", workOrder.id)
            }
            initialValue={schedule?.workOrder ?? undefined}
            searchFunction={useWorkOrdersSearch}
            separator="|"
            isDisabled={workOrderOnly ? true : false}
          />
        )}

        <AutoSearchMultipleAlt
          data={allEmployees ?? []}
          label="Search Employees"
          keys={["firstName", "lastName"]}
          onOptionSelected={(employees) =>
            employees && setSelectedEmployees(employees)
          }
        />

        <FormSelect
          name="scheduleTypeId"
          label="Schedule Type"
          labelKeys={["name"]}
          valueKey="id"
          data={
            workOrderOnly
              ? scheduleTypes.filter((s) => s.requiresWorkOrder)
              : scheduleTypes
          }
          control={control}
          defaultValue={scheduleTypes}
          onSelected={(scheduleType) => setSelectedScheduleType(scheduleType)}
        />

        {selectedScheduleType && selectedScheduleType.allDay ? (
          <FormDatePicker
            name="startDate"
            label="Start Date"
            trigger={trigger}
            onSelected={(date) => setValue("endDate", add(date, { days: 1 }))}
            control={control}
          />
        ) : (
          <FormDateTimePicker
            name="startDate"
            label="Start Date"
            trigger={trigger}
            onSelected={(date) => setValue("endDate", add(date, { hours: 2 }))}
            control={control}
            minTime={new Date(0, 0, 0, 7)}
            maxTime={new Date(0, 0, 0, 20, 0)}
          />
        )}

        {selectedScheduleType && selectedScheduleType.allDay ? (
          <FormDatePicker
            control={control}
            name="endDate"
            label="End Date"
            trigger={trigger}
          />
        ) : (
          <FormDateTimePicker
            name="endDate"
            label="End Date"
            trigger={trigger}
            control={control}
            minTime={new Date(0, 0, 0, 7)}
            maxTime={new Date(0, 0, 0, 20, 0)}
          />
        )}
        <FormCheckBoxGroup
          name="repeatDayIds"
          label="Repeats"
          options={repeatDays ?? []}
          labelKey="name"
          valueKey="id"
          control={control}
          direction="row"
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
});
