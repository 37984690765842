import { useQuery } from "react-query";

import { Employee } from "../../models";
import { getSingle } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useEmployeeProfile = () => {
  return useQuery<Employee, Error>(RQ.EMPLOYEE, async () =>
    getSingle<Employee>(`employees/profile`)
  );
};
