import { GridColDef } from "@mui/x-data-grid";
import { InventoryItem } from "../../../models";
import { format } from "date-fns";

export const inventoryItemColumns: GridColDef<InventoryItem>[] = [
  { field: "name", headerName: "Name", flex: 2 },
  { field: "quantity", headerName: "Quantity", flex: 1 },
  { field: "description", headerName: "Description", flex: 1 },
  { field: "model", headerName: "Model", flex: 1 },
  { field: "sku", headerName: "SKU", flex: 1 },
  { field: "cost", headerName: "Cost", flex: 1 },
  { field: "price", headerName: "Price", flex: 1 },
  {
    field: "inventoryType",
    headerName: "Inventory Type",
    flex: 1,
    valueGetter: (params) => params.row?.inventoryType?.name,
  },
  {
    field: "modifiedTimestamp",
    headerName: "Last Updated",
    flex: 1,
    valueGetter: (params) =>
      format(new Date(params.row.createdTimestamp), "yyyy-MM-dd"),
  },
];
