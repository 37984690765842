import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteCustomerDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerDocument: CustomerDocument) =>
      remove("customers/documents", customerDocument.id),
    {
      onSuccess: (_, customerDocument) => {
        enqueueSnackbar("Customer Document Deleted", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMER_DOCUMENTS, customerDocument.customerId],
          (customerDocuments: CustomerDocument[] | undefined) => {
            return !customerDocuments
              ? []
              : deleteEntity<CustomerDocument>(
                  customerDocuments,
                  "id",
                  customerDocument.id
                );
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Document Deleting Error", {
          variant: "error",
        });
      },
    }
  );
};
