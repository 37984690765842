import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { WorkOrderDocument } from "../../../models";
import { workOrderDocumentSchema as schema } from "./work-order-document.schema";
import {
  useAddWorkOrderDocument,
  usePatchWorkOrderDocument,
} from "../../../hooks/work-order-document";
import { FormContainer } from "../../layout";
import {
  FormFileUpload,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import {
  doesExtensionExist,
  getFileExtension,
  getFileNameFromPath,
} from "../../../utils";

interface Props {
  workOrderDocument?: WorkOrderDocument;
  onSubmitted?: (workOrderDocument: WorkOrderDocument) => void;
}

export const WorkOrderDocumentForm = (props: Props) => {
  const { workOrderDocument, onSubmitted } = props;
  const [fileExtension, setFileExtension] = useState("");
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createWorkOrderDocument } = useAddWorkOrderDocument();
  const { mutateAsync: patchWorkOrderDocument } = usePatchWorkOrderDocument();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { defaultValues },
  } = useForm<WorkOrderDocument>({
    resolver: yupResolver(schema),
    defaultValues: workOrderDocument,
  });

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (workOrderDocument) => {
        // if extension is missing then attach it
        if (doesExtensionExist(workOrderDocument.name)) {
          workOrderDocument.name = workOrderDocument.name + fileExtension;
        }
        workOrderDocument = workOrderDocument.id
          ? await patchWorkOrderDocument(workOrderDocument)
          : await createWorkOrderDocument(workOrderDocument);
        onSubmitted && onSubmitted(workOrderDocument);
        setChangeDetection(false);
      })}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormFileUpload
          name="file"
          label=""
          control={control}
          type="file"
          required
          onInputChanged={(value) => {
            if (typeof value === "string") {
              setFileExtension(getFileExtension(value));
              setValue("name", getFileNameFromPath(value));
            }
          }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField name="name" label="Name" control={control} required />
        <FormTextField name="note" label="Note" control={control} />
        <FormSubmitButton />
      </Stack>
    </FormContainer>
  );
};
