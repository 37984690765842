import { GridColDef } from "@mui/x-data-grid";
import { InvoiceLineItem } from "../../../models";

export const invoiceLineItemColumns: GridColDef<InvoiceLineItem>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => params.row.inventoryItem?.name,
  },
  { field: "quantity", headerName: "Quantity", flex: 1 },
  { field: "serialNumber", headerName: "Serial Number", flex: 1 },
  {
    field: "cost",
    headerName: "Cost",
    flex: 1,
    valueGetter: (params) => params.row.cost ?? params.row.inventoryItem?.cost,
  },
  {
    field: "price",
    headerName: "Price",
    flex: 1,
    valueGetter: (params) =>
      params.row.price ?? params.row.inventoryItem?.price,
  },
  { field: "note", headerName: "Note", flex: 1 },
  { field: "hide", headerName: "Hide", flex: 1 },
];
