import { Divider, SxProps, Theme, Typography } from "@mui/material";

interface Props {
  title: string;
  sx?: SxProps<Theme>;
}

export const SubTitle = (props: Props) => {
  const { title } = props;
  return (
    <>
      <Typography color={"#2196f3"}>
        <b>{title}</b>
      </Typography>
      <Divider sx={{ mb: 2, borderColor: "#2196f3" }} />
    </>
  );
};
