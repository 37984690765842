import { useState } from "react";
import { useDeletePaymentType } from "../../../hooks/payment-type";
import { PaymentType } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { paymentTypeColumns as columns } from "./payment-type.columns";
import { PaymentTypeForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const PaymentTypeDataGrid = (props: Props<PaymentType>) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deletePaymentType } = useDeletePaymentType();

  const onEditCreate = (paymentType?: PaymentType) => {
    paymentType ? setPaymentType(paymentType) : setPaymentType(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDelete={(paymentType) => deletePaymentType(paymentType.id)}
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={onLink}
      title={title}
      createTitle="Create Payment Type"
      deleteTitle="Do you want to delete this Payment Type"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${paymentType ? "Edit" : "Create"} Payment Type`}
      >
        <PaymentTypeForm
          onSubmitted={() => setOpenModal(false)}
          paymentType={paymentType}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
