import {
  Autocomplete,
  Box,
  BoxProps,
  CircularProgress,
  TextField,
} from "@mui/material";
// import { isEqual } from "lodash";
import React from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { RecursiveKeyof } from "../../../types";
import { entityStringLabel } from "../../../utils";

interface Props<T, K extends FieldValues> {
  options: T[];
  label: string;
  labelKeys: RecursiveKeyof<T>[];
  valueKey?: keyof T;
  defaultValue?: T | undefined;
  separator?: string;
  loading?: boolean;
  onInputChange?: (value: string) => void;
  onChange?: (option: T) => void;
  name: Path<K>;
  control: Control<K>;
  sx?: BoxProps;
  autoFocus?: boolean;
}

export const FormAutocomplete = <T, K extends FieldValues>(
  props: Props<T, K>
) => {
  const {
    options = [],
    separator,
    label,
    labelKeys,
    valueKey,
    defaultValue = undefined,
    loading = false,
    name,
    control,
    onChange,
    onInputChange,
    sx = undefined,
  } = props;
  let value =
    defaultValue && typeof defaultValue === "object"
      ? { defaultValue: defaultValue }
      : null;
  const { field, fieldState } = useController({ name, control });

  return (
    <Box {...sx}>
      <Autocomplete
        id={label}
        options={options}
        loading
        size="small"
        fullWidth
        {...value}
        getOptionLabel={(option) =>
          entityStringLabel(option, labelKeys, separator)
        }
        // isOptionEqualToValue={(option, value) => isEqual(option, value)}
        filterOptions={(options) => options}
        isOptionEqualToValue={(option, value) => true}
        onChange={(_, entity, reason) => {
          if (
            reason === "selectOption" &&
            entity &&
            typeof entity === "object"
          ) {
            onChange && onChange(entity);
            entity && valueKey
              ? field.onChange(entity[valueKey])
              : field.onChange(entity);
          }
        }}
        onInputChange={(event, value) => {
          if (event && event.type !== "click") {
            onInputChange && onInputChange(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            inputRef={field.ref}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
