export enum APPLICATION_SETTING {
  SITE_ADDRESS_TYPE = "SITE_ADDRESS_TYPE",
  BILLING_ADDRESS_TYPE = "BILLING_ADDRESS_TYPE",
  DEFAULT_ADDRESS = "DEFAULT_ADDRESS",
  DEFAULT_EMAIL_FROM = "DEFAULT_EMAIL_FROM",
  DEFAULT_INVENTORY_TYPE = "DEFAULT_INVENTORY_TYPE",
  DEFAULT_PAYMENT_METHOD = "DEFAULT_PAYMENT_METHOD",
  DEFAULT_PAYMENT_TYPE = "DEFAULT_PAYMENT_TYPE",
  DEFAULT_PROPOSAL_INVENTORY_ITEM = "DEFAULT_PROPOSAL_INVENTORY_ITEM",
  DISPATCH = "DISPATCH",
  INVOICE_DISPATCH_LIMIT = "INVOICE_DISPATCH_LIMIT",
  DEPOSIT_RATE = "DEPOSIT_RATE",
  EMAIL_SENDING_ACTIVE = "EMAIL_SENDING_ACTIVE",
  HST = "HST",
  NORMAL = "NORMAL",
  ON_SERVICE = "ON_SERVICE",
  URGENT = "URGENT",
  ON_NEW_WORK_ORDER = "ON_NEW_WORK_ORDER",
  ON_NEW_INVOICE = "ON_NEW_INVOICE",
  ON_NEW_PROPOSAL = "ON_NEW_PROPOSAL",
  ON_NEW_SCHEDULE = "ON_NEW_SCHEDULE",
  ON_OVER_THE_COUNTER_SALE = "ON_OVER_THE_COUNTER_SALE",
  ON_QUICK_BOOKS_SENT = "ON_QUICK_BOOKS_SENT",
  OVER_THE_COUNTER_INDICATOR = "OVER_THE_COUNTER_INDICATOR",
  COMPANY_ADDRESS = "COMPANY_ADDRESS",
  INVOICE_LEGAL_DISCLAIMER = "INVOICE_LEGAL_DISCLAIMER",
  PROPOSAL_LEGAL_DISCLAIMER = "PROPOSAL_LEGAL_DISCLAIMER",
  TEST_EMAIL = "TEST_EMAIL",
  DEFAULT_WORK_ORDER_STATES = "DEFAULT_WORK_ORDER_STATES",
  DEFAULT_EMPLOYEE_TYPES = "DEFAULT_EMPLOYEE_TYPES",
  USE_BLOCK_SCHEDULING = "USE_BLOCK_SCHEDULING",
}
