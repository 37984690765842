import { GridColDef } from "@mui/x-data-grid";
import { format, formatDistance, parseISO } from "date-fns";

export const employeeTimesheetColumns: GridColDef[] = [
  {
    field: "clockIn",
    headerName: "Clock In",
    flex: 1,
    valueGetter: (params) =>
      params.row.clockIn ? format(parseISO(params.row.clockIn), "PPpp") : "",
  },
  {
    field: "clockOut",
    headerName: "Clock Out",
    flex: 1,
    valueGetter: (params) =>
      params.row.clockOut ? format(parseISO(params.row.clockOut), "PPpp") : "",
  },
  {
    field: "employee",
    headerName: "Employee",
    flex: 1,
    valueGetter: (params) =>
      params.row.employee
        ? `${params.row.employee.firstName} ${params.row.employee.lastName}`
        : "",
  },
  {
    field: "workOrder",
    headerName: "WorkOrder",
    flex: 1,
    valueGetter: (params) =>
      params.row.workOrder ? params.row.workOrder.workOrderNumber : "",
  },
  {
    field: "difference",
    headerName: "Difference",
    flex: 1,
    valueGetter: (params) =>
      params.row.clockOut
        ? formatDistance(
            new Date(params.row.clockIn),
            new Date(params.row.clockOut)
          )
        : formatDistance(new Date(params.row.clockIn), new Date()),
  },
];
