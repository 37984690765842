import { Button, IconButton, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  FormAutocomplete,
  FormDateTimePicker,
  FormSelect,
} from "../../../form-components";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormTrigger,
} from "react-hook-form";
import {
  Employee,
  ScheduleType,
  Schedule,
  WorkOrder,
} from "../../../../models";

type FormData = {
  workOrder: Omit<WorkOrder, "schedules">;
  schedules: Partial<Omit<Schedule, "workOrder">>[];
};

interface Props {
  control: Control<FormData, any>;
  fields: FieldArrayWithId<FormData, "schedules", "id">[];
  scheduleTypes: ScheduleType[];
  employees: Employee[];
  schedules: Schedule[];
  trigger: UseFormTrigger<FormData>;
  onDateSelected: (date: Date, index: number) => void;
  onScheduleAdded: () => void;
  remove: (index?: number | number[] | undefined) => void;
}

export const TimeSchedules = (props: Props) => {
  const {
    control,
    fields,
    scheduleTypes,
    employees,
    schedules,
    trigger,
    onDateSelected,
    onScheduleAdded,
    remove,
  } = props;

  return (
    <>
      {fields.map((field, index) => (
        <Controller
          name={`schedules.${index}`}
          control={control}
          key={field.id}
          render={({ field }) => {
            return (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <FormAutocomplete
                  name={`schedules.${index}.employeeId`}
                  label="Employee"
                  labelKeys={["firstName", "lastName"]}
                  valueKey="id"
                  options={employees}
                  loading={false}
                  defaultValue={schedules && schedules[index]?.employee}
                  control={control}
                  sx={{ width: 300 }}
                />
                <FormDateTimePicker
                  control={control}
                  name={`schedules.${index}.startDate`}
                  label="Start Date"
                  trigger={trigger}
                  minTime={new Date(0, 0, 0, 7)}
                  maxTime={new Date(0, 0, 0, 20, 0)}
                  sx={{ width: 230 }}
                  onSelected={(date) => onDateSelected(date, index)}
                />
                <FormDateTimePicker
                  control={control}
                  name={`schedules.${index}.endDate`}
                  label="End Date"
                  trigger={trigger}
                  minTime={new Date(0, 0, 0, 7)}
                  maxTime={new Date(0, 0, 0, 20, 0)}
                  sx={{ width: 230 }}
                />
                {scheduleTypes && (
                  <FormSelect
                    name={`schedules.${index}.scheduleTypeId`}
                    label="Schedule Type"
                    labelKeys={["name"]}
                    valueKey="id"
                    data={scheduleTypes.filter((s) => s.requiresWorkOrder)}
                    control={control}
                    sx={{ width: 200 }}
                    defaultValue={schedules && schedules[index]?.scheduleType}
                  />
                )}
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }}
        />
      ))}
      <Button
        onClick={() => onScheduleAdded()}
        endIcon={<AddCircleIcon />}
        variant="outlined"
      >
        Schedule Tech
      </Button>
    </>
  );
};
