import React, { useContext, useMemo, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import { indigo, success } from "../theme/colors";
// import { createTheme } from "../theme";

const ThemeContext = React.createContext({
  toggleColorMode: () => {},
});

export const useThemeMode = () => useContext(ThemeContext);

export function ThemeMode({ ...props }) {
  const { children } = props;
  const [mode, setMode] = useState<PaletteMode>(
    (localStorage.getItem("theme") as PaletteMode) || "light"
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode: PaletteMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("theme", newMode);
          return newMode;
        });
      },
    }),
    []
  );

  // const theme = createTheme(mode);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: indigo,
          success,
        },
        typography: {
          fontSize: 12,
        },
        shape: {
          borderRadius: 8,
        },
      }),
    [mode]
  );

  // const theme = useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode,
  //       },
  //       typography: {
  //         fontSize: 12,
  //       },
  //     }),
  //   [mode]
  // );

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
