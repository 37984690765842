import { useQuery } from "react-query";

import { Schedule } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";
import { format } from "date-fns";

export const useEmployeeHolidays = (startDate: Date, endDate?: Date) => {
  let params = new URLSearchParams();
  startDate && params.append("startDate", format(startDate, "yyyy-MM-dd"));
  endDate && params.append("endDate", format(endDate, "yyyy-MM-dd"));
  return useQuery<Schedule[], Error>(
    RQ.REPORT_EMPLOYEE_HOLIDAY,
    async () => getAll<Schedule>(`reports/employee-holidays?${params}`),
    {
      enabled: Boolean(startDate && endDate),
    }
  );
};
