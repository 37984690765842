import { useQuery } from "react-query";

import { EmailSearch } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useEmailSearch = (searchTerm: string) => {
  return useQuery<EmailSearch[], Error>(
    [RQ.EMAIL_SEARCH, searchTerm],
    () => getAll(`emails/search?searchTerm=${searchTerm}`),
    {
      enabled: Boolean(searchTerm),
    }
  );
};
