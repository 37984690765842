import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Invoice } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddInvoice = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((invoice: Invoice) => add<Invoice>("invoices", invoice), {
    onSuccess: (invoice) => {
      enqueueSnackbar("Invoice Added", { variant: "success" });
      queryClient.setQueryData<Invoice[]>(
        RQ.INVOICES,
        (invoices: Invoice[] | undefined) => {
          return [...(invoices ?? []), invoice];
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Invoice Adding Error", { variant: "error" });
    },
  });
};
