import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { AppSetting } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchAppSetting = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (appSetting: Partial<AppSetting>) =>
      patch({
        route: "app-settings",
        id: appSetting.id ?? "",
        entity: appSetting,
      }),
    {
      onSuccess: (appSetting) => {
        enqueueSnackbar("App Settings Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.APP_SETTINGS,
          (appSettings: AppSetting[] | undefined) =>
            !appSettings
              ? []
              : patchEntity<AppSetting>(appSettings, appSetting, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("App Settings Updating Error", { variant: "error" });
      },
    }
  );
};
