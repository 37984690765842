import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BadgeIcon from "@mui/icons-material/Badge";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InventoryIcon from "@mui/icons-material/Inventory";
import EventIcon from "@mui/icons-material/Event";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ConstructionIcon from "@mui/icons-material/Construction";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArticleIcon from "@mui/icons-material/Article";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";

import { MenuItem } from "./props/MenuItem";
import { Role } from "./enums";

export const menuItems: MenuItem[] = [
  {
    title: "Customers",
    to: "/customers",
    icon: <PeopleAltIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Over The Counter Sale",
    to: "/over-the-counter-sale",
    icon: <PeopleAltIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Dispatch",
    to: "/dispatch",
    icon: <CalendarMonthIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Search",
    icon: <SearchIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
    items: [
      {
        title: "Work Orders",
        to: "/work-orders",
        icon: <ConstructionIcon />,
        navType: "default",
        roles: [Role.Admin, Role.OfficeStaff],
      },
      {
        title: "Proposals",
        to: "/proposals",
        icon: <ContentPasteIcon />,
        navType: "default",
        roles: [Role.Admin, Role.OfficeStaff],
      },
      {
        title: "Invoices",
        to: "/invoices",
        icon: <ReceiptIcon />,
        navType: "default",
        roles: [Role.Admin, Role.OfficeStaff],
      },
      {
        title: "Schedules",
        to: "/schedules",
        icon: <EventIcon />,
        navType: "default",
        roles: [Role.Admin, Role.OfficeStaff],
      },
    ],
  },
  {
    title: "Inventory",
    to: "/Inventory-items",
    icon: <InventoryIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Employees",
    to: "/employees",
    icon: <BadgeIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Vendors",
    to: "/vendors",
    icon: <PeopleAltIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Statements",
    to: "/statements",
    icon: <PeopleAltIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Company Documents",
    to: "/company-documents",
    icon: <ArticleIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Reports",
    to: "/reports",
    icon: <AssessmentIcon />,
    navType: "default",
    roles: [Role.Admin, Role.OfficeStaff],
  },
  {
    title: "Settings",
    to: "/settings",
    icon: <SettingsIcon />,
    navType: "admin",
    roles: [Role.Admin],
  },
];
