import * as yup from "yup";
import { companyDocumentTagSchema } from "./company-document-tag.schema";

export const companyDocumentSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .label("Name")
    .default("")
    .transform((value) => value ?? ""),
  note: yup
    .string()
    .label("Note")
    .default("")
    .transform((value) => value ?? ""),
  companyDocumentTags: yup
    .array()
    .of(companyDocumentTagSchema)
    .label("Tags")
    .default([]),
  file: yup.mixed().label("File"),
});
