import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { PageHeader } from "../../components/layout";
import { OAuthPopup } from "../../components/ui";
import config from "../../config/app-config";

export const SettingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title="Settings" />
      <Grid container spacing={2}>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Address Types
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of schedules in the
                drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/address-types")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Employee Types
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of employee roles in
                the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/employee-types")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Inventory Types
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of inventory types in
                the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/inventory-types")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Job Types
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of job types /
                services in the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/job-types")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 2 }}>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Payment Settings
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of payment types and
                payment options in the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => navigate("/payment-settings")}
              >
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Schedule Types
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of schedule types in
                the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/schedule-types")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Customer Tiers
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of customer tiers in
                the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/customer-tiers")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Work Order States
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of work order states
                in the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => navigate("/work-order-states")}
              >
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 2 }}>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Task Durations
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify all of the various task a common
                tech will do, and it allows you to assign a default duration of
                time to them.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/task-durations")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Invoice Categories
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of invoice categories
                in the drop down appear
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => navigate("/invoice-categories")}
              >
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Work Order Role Titles
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify the various titles that
                employees can assume on any given work order. For example you
                can have a role for 'Primary Tech', 'Estimator', 'Lead
                Generator' etc
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => navigate("/work-order-role-title")}
              >
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                App Defaults
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify the application defaults
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/app-defaults")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 2 }}>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Referral Types
              </Typography>
              <Typography variant="body2">
                This Page allows you to specify what kind of referrals you would
                like to have. These are options that indicate how you heard of
                the company.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => navigate("/referral-types")}>
                Modify
              </Button>
            </CardActions>
          </Paper>
        </Grid>
        <Grid xs={3} item>
          <Paper>
            <CardContent>
              <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>
                Quick Books
              </Typography>
              <Typography variant="body2">
                You can sign into QuickBooks here.
              </Typography>
            </CardContent>
            <CardActions>
              <OAuthPopup
                authorizationUrl={`${config.QUICK_BOOKS.AUTHORIZATION_URL}`}
                clientId={`${config.QUICK_BOOKS.CLIENT_ID}`}
                redirectUri={`${config.QUICK_BOOKS.REDIRECT_URL}`}
                scope={`${config.QUICK_BOOKS.SCOPE}`}
              />
            </CardActions>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
