import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
} from "react-hook-form";
import { Button, IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { Schedule, WorkOrder, WorkOrderNote } from "../../../../models";
import { FormTextField } from "../../../form-components";

type FormData = {
  workOrder: Omit<WorkOrder, "schedules">;
  schedules: Partial<Omit<Schedule, "workOrder">>[];
};

interface Props {
  control: Control<FormData, any>;
  fields: FieldArrayWithId<FormData, "workOrder.workOrderNotes", "id">[];
  append: UseFieldArrayAppend<FormData, "workOrder.workOrderNotes">;
  remove: (index?: number | number[] | undefined) => void;
}

export const WorkOrderNotes = (props: Props) => {
  const { control, fields, remove, append } = props;

  return (
    <>
      {fields.map((field, index) => (
        <Controller
          name={`workOrder.workOrderNotes.${index}`}
          control={control}
          key={field.id}
          render={({ field }) => {
            return (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <FormTextField
                  name={`workOrder.workOrderNotes.${index}.note`}
                  label="Note"
                  control={control}
                  multiline
                  fullWidth
                />
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }}
        />
      ))}
      <Button
        onClick={() =>
          append({
            note: "",
          } as WorkOrderNote)
        }
        endIcon={<AddCircleIcon />}
        variant="outlined"
      >
        Add Note
      </Button>
    </>
  );
};
