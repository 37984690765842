import { useQuery } from "react-query";

import { getSingle } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { InventoryItem } from "../../models";

export const useInventoryItem = (id: string) => {
  return useQuery<InventoryItem, Error>(
    [RQ.INVENTORY_ITEM, id],
    () => getSingle<InventoryItem>(`inventory-items/${id}`),
    {
      enabled: Boolean(id),
    }
  );
};
