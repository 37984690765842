import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useRenderDocument } from "../../hooks/document/useRenderDocument";
import { PageHeader } from "../../components/layout";
import { Button } from "@mui/material";
import { LoadingSpinner } from "../../components/ui";

export const DocumentPage = () => {
  const [searchParams] = useSearchParams();
  let documentId = searchParams.get("documentId");
  let documentType = searchParams.get("documentType");
  const {
    data: document,
    isLoading,
    isIdle,
    isError,
    error,
  } = useRenderDocument(documentId, documentType);
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title="Document"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      {document && (
        <iframe
          src={document}
          width="100%"
          height={window.innerHeight}
          title="Document Viewer"
        />
      )}
    </>
  );
};
