import { BoxProps, TextField } from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { PhoneNumberMask } from "../PhoneNumberMask/PhoneNumberMask";

interface Props<K extends FieldValues> extends BoxProps {
  label: string;
  name: Path<K>;
  control: Control<K, any>;
}

export const FormPhoneNumber = <K extends FieldValues>(props: Props<K>) => {
  const { name, label, control } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <TextField
      label={label}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      size="small"
      InputProps={{
        inputComponent: PhoneNumberMask as any,
      }}
      defaultValue=""
      onChange={(phone) => field.onChange(phone.target.value)}
      value={field.value}
    />
  );
};
