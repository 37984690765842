import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { uploadFile } from "../../api/generic-api";
import { EmailPayload } from "../../models";

export const useSendPaymentReceipt = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (payload: { workOrderId: string; emailPayload: EmailPayload }) =>
      uploadFile<EmailPayload>(
        `emails/payment-receipt/${payload.workOrderId}`,
        payload.emailPayload
      ),
    {
      onSuccess: () => {
        enqueueSnackbar("Payment Receipt Sent", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Payment Receipt Send Error", {
          variant: "error",
        });
      },
    }
  );
};
