import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, InputAdornment, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { InvoiceDrawRequest } from "../../../models";
import { invoiceDrawRequestSchema as schema } from "../schemas/invoice-draw-request.schema";
import {
  useAddInvoiceDrawRequest,
  usePatchInvoiceDrawRequest,
} from "../../../hooks/invoice-draw-request";
import { FormContainer } from "../../layout";
import {
  FormDatePicker,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { useInvoiceStatementByInvoice } from "../../../hooks/invoice-statement";

interface Props {
  invoiceDrawRequest?: InvoiceDrawRequest;
  invoiceId: string;
  onSubmitted?: () => void;
}

export const InvoiceDrawRequestForm = (props: Props) => {
  const { invoiceDrawRequest, invoiceId, onSubmitted } = props;
  const { data: invoiceStatement } = useInvoiceStatementByInvoice(invoiceId);
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createInvoiceDrawRequest } = useAddInvoiceDrawRequest();
  const { mutate: patchInvoiceDrawRequest } = usePatchInvoiceDrawRequest();
  const {
    control,
    trigger,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<InvoiceDrawRequest>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(invoiceDrawRequest),
  });

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  return (
    <FormContainer
      onSubmit={handleSubmit((invoiceDrawRequest) => {
        invoiceDrawRequest.invoiceId = invoiceId;
        invoiceDrawRequest.id
          ? patchInvoiceDrawRequest(invoiceDrawRequest)
          : createInvoiceDrawRequest(invoiceDrawRequest);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormDatePicker
          control={control}
          name="dueDate"
          label="Due Date"
          trigger={trigger}
        />
        <FormTextField
          name="total"
          label="Total"
          control={control}
          type="number"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Stack>
      <Divider />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <b>Amount Paid: {invoiceStatement?.amountPaid}</b>
        <b>Amount Owing: {invoiceStatement?.amountOwing}</b>
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
