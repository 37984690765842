import { GridColDef } from "@mui/x-data-grid";
import { CustomerNote } from "../../../models";
import { format, parseISO } from "date-fns";
import { GridActions } from "../../ui";

interface Props {
  onEdit: (customerNote: CustomerNote) => void;
  onDelete: (customerNote: CustomerNote) => void;
}

export const customerNoteColumns = (props: Props) => {
  const { onEdit, onDelete } = props;
  return [
    { field: "note", headerName: "Notes", flex: 1 },
    {
      field: "modifiedTimestamp",
      headerName: "Last Updated",
      flex: 1,
      minWidth: 150,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) =>
        params.row.modifiedTimestamp
          ? format(parseISO(params.row.modifiedTimestamp), "PPpp")
          : "",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) =>
        params && (
          <GridActions
            onRowEdit={onEdit}
            onRowDelete={onDelete}
            row={params.row}
          />
        ),
    },
  ] as GridColDef<CustomerNote>[];
};
