import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { add } from "../../api/generic-api";
import { useCurrentUser } from "../../contexts";
import axios from "axios";

interface Payload {
  invoiceId: string;
  customerId: string;
}

export const useQuickBooksCreateBatchInvoice = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsQBAuthenticated, setQBAlert } = useCurrentUser();
  return useMutation(
    (request: Payload[]) =>
      add<Payload[]>("quick-books/customer/invoices", request),
    {
      onSuccess: () => {
        enqueueSnackbar("Quick Books Create Batch Added", {
          variant: "success",
        });
      },
      onError: (error) => {
        enqueueSnackbar("Quick Books Create Batch Adding Error", {
          variant: "error",
        });
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            setIsQBAuthenticated(false);
            setQBAlert("SHOW");
          }
        }
      },
    }
  );
};
