import { useQuery } from "react-query";

import { WorkOrderNote } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useWorkOrderNotesByWorkOrder = (workOrderId?: string | null) => {
  return useQuery<WorkOrderNote[], Error>(
    [RQ.WORK_ORDER_NOTES, workOrderId],
    () => getAll(`work-order-notes/work-order/${workOrderId}`),
    {
      enabled: Boolean(workOrderId),
      select: (workOrderNotes) => {
        if (workOrderNotes && workOrderNotes.length > 0) {
          workOrderNotes.forEach((workOrderNote) => {
            if (workOrderNote && workOrderNote.employee) {
              workOrderNote.employeeId = workOrderNote.employee.id;
            }
          });
        }
        return workOrderNotes;
      },
    }
  );
};
