import { GridColDef } from "@mui/x-data-grid";

export const inventoryTypeColumns: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1 },
  {
    field: "showInInvoiceCategorySection",
    headerName: "Show In Invoice Category Section",
    flex: 1,
  },
  { field: "proportional", headerName: "Proportional", flex: 1 },
  { field: "trackQuantity", headerName: "Track Quantity", flex: 1 },
  {
    field: "invoiceCategoryId",
    headerName: "Invoice Category",
    flex: 1,
    valueGetter: (params) =>
      params.row.invoiceCategory && params.row.invoiceCategory?.name,
  },
];
