import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayMove,
} from "react-hook-form";
import { FormSwitch, FormTextField } from "../../../form-components";
import { InventoryItem, Proposal, ProposalLineItem } from "../../../../models";
import { FormAutocomplete } from "../../../form-components";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

interface Props {
  control: Control<Proposal, any>;
  fields: FieldArrayWithId<Proposal, "proposalLineItems", "id">[];
  errors: FieldErrors<Proposal>;
  inventoryItems: InventoryItem[];
  defaultInventoryItem: InventoryItem;
  append: UseFieldArrayAppend<Proposal, "proposalLineItems">;
  move: UseFieldArrayMove;
  onRemoveItem?: (index: number) => void;
  onInventoryItemInputChanged: (searchTerm: string) => void;
  onInventoryItemChanged: (inventoryItem: InventoryItem, index: number) => void;
  resolveDefaultItem: (
    field: ControllerRenderProps<Proposal, `proposalLineItems.${number}`>
  ) => InventoryItem | undefined;
  onCalculate: () => void;
}

export const ProposalLineItems = (props: Props) => {
  const {
    control,
    fields,
    errors,
    inventoryItems,
    defaultInventoryItem,
    append,
    move,
    onRemoveItem,
    onInventoryItemInputChanged,
    onInventoryItemChanged,
    resolveDefaultItem,
    onCalculate,
  } = props;

  const handleDrag = ({ source, destination }: DropResult) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <FormControl error={errors.proposalLineItems && !!errors.proposalLineItems}>
      <DragDropContext onDragEnd={handleDrag}>
        <Stack direction="column" spacing={1}>
          <Droppable droppableId="test-items">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((item, index) => (
                  <Draggable
                    key={`proposalLineItems[${index}]`}
                    draggableId={`item-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Controller
                        name={`proposalLineItems.${index}`}
                        control={control}
                        key={item.id}
                        render={({ field }) => (
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            key={item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <b>{index + 1}</b>
                            <FormAutocomplete
                              name={`proposalLineItems.${index}.inventoryItem`}
                              label="Inventory Item"
                              labelKeys={["name", "model", "price"]}
                              options={inventoryItems ?? []}
                              onChange={(inventoryItem) =>
                                onInventoryItemChanged(inventoryItem, index)
                              }
                              loading={false}
                              onInputChange={onInventoryItemInputChanged}
                              defaultValue={resolveDefaultItem(field)}
                              control={control}
                              separator="|"
                              sx={{ width: 500 }}
                            />
                            <FormTextField
                              name={`proposalLineItems.${index}.display`}
                              label="Description"
                              control={control}
                              sx={{ width: 500 }}
                              multiline
                            />
                            <FormTextField
                              name={`proposalLineItems.${index}.price`}
                              label="Price"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              onInputChanged={onCalculate}
                              type="number"
                              control={control}
                              sx={{ width: 100 }}
                            />
                            <FormSwitch
                              name={`proposalLineItems.${index}.addToTotal`}
                              label="Add To Total"
                              onChanged={onCalculate}
                              control={control}
                            />
                            <IconButton
                              onClick={() =>
                                onRemoveItem && onRemoveItem(index)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                            <div {...provided.dragHandleProps}>
                              <DragIndicatorIcon />
                            </div>
                          </Stack>
                        )}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Stack>
      </DragDropContext>
      <Button
        onClick={() =>
          append(
            {
              display: "",
              addToTotal: true,
              inventoryItem: defaultInventoryItem,
              inventoryItemId: defaultInventoryItem && defaultInventoryItem.id,
              price: 0,
            } as ProposalLineItem,
            {
              shouldFocus: true,
            }
          )
        }
        endIcon={<AddIcon />}
      >
        Add Line Item
      </Button>
      <FormHelperText>
        {errors.proposalLineItems && errors.proposalLineItems?.message}
      </FormHelperText>
    </FormControl>
  );
};
