import { useState } from "react";
import { Box, Button, Divider, Stack } from "@mui/material";
import { Invoice } from "../../../models";
import { ModalContainer } from "../../../components/ui";
import { useQuickBooksCreateBatchInvoice } from "../../../hooks/quick-books";

interface Props {
  invoices: Invoice[];
}

export const SendToQuickBooksModal = (props: Props) => {
  const { invoices } = props;
  const [open, setOpen] = useState(false);
  const { mutateAsync: sendQuickBooksBatchInvoice } =
    useQuickBooksCreateBatchInvoice();

  const send = () => {
    const payload = invoices.map((invoice) => {
      return {
        invoiceId: invoice.id,
        customerId: invoice.customerId,
      };
    });
    sendQuickBooksBatchInvoice(payload);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        View Selected Invoices To Send To Quick Books ({invoices.length})
      </Button>
      <ModalContainer
        open={open}
        title="Send Selected Invoices To Quick Books"
        handleClose={() => setOpen(false)}
      >
        <Box sx={{ mt: 2 }}>
          {invoices.map((invoice, index) => {
            return (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  key={index}
                >
                  <Box>{invoice.workOrderNumber}</Box>
                  <Box>
                    {invoice.customerFirstName} {invoice.customerLastName}
                  </Box>
                </Stack>
                <Divider sx={{ mb: 2 }} />
              </>
            );
          })}
          <Button variant="contained" onClick={send}>
            Send All
          </Button>
        </Box>
      </ModalContainer>
    </>
  );
};
