import { find, indexOf } from "lodash";
import {
  Customer,
  CustomerTierDetail,
  InventoryItem,
  Invoice,
  InvoiceCostPrice,
  InvoiceLineItem,
} from "../../../../models";

export const calculateInvoice = (
  invoice: Invoice,
  customer: Customer,
  HST: number
) => {
  invoice.subtotal = 0;
  invoice.totalPrice = 0;
  invoice.totalCost = 0;
  invoice.invoiceCostsPrices = [];

  invoice?.invoiceLineItems?.forEach((invoiceLineItem) => {
    if (
      !invoiceLineItem.inventoryItem ||
      typeof invoiceLineItem.inventoryItem === "string"
    )
      return;

    const inventoryItem = invoiceLineItem.inventoryItem;
    const quantity = Number(invoiceLineItem.quantity) ?? 1;

    // set price
    invoiceLineItem.price = invoiceLineItem.price ?? inventoryItem.price;

    // set cost
    invoiceLineItem.cost = invoiceLineItem.cost ?? inventoryItem.cost;

    // set inventory item id
    invoiceLineItem.inventoryItemId = inventoryItem.id;

    // add subtotal
    invoice.subtotal += Number(invoiceLineItem.price * quantity);

    // add total price
    invoice.totalPrice += Number(invoiceLineItem.price * quantity);

    // add total cost
    invoice.totalCost += Number(invoiceLineItem.cost * quantity);

    // add costs and prices
    invoice.invoiceCostsPrices = upsertCostsPrices(
      invoice.invoiceCostsPrices,
      inventoryItem,
      invoiceLineItem.price,
      invoiceLineItem.cost,
      quantity
    );
  });

  applyProportionalRate(invoice);

  // set total profit
  invoice.totalProfit = invoice.totalPrice - invoice.totalCost;

  // set profit margin
  invoice.profitMargin = calculateProfitMargin(
    invoice.totalCost,
    invoice.totalPrice
  );

  // set customer savings
  // invoice.customerDiscount = (customerDiscountRate / 100) * invoice.subtotal;
  invoice.customerDiscount = customerDiscounts(
    invoice.invoiceLineItems,
    customer
  );

  // set hst total
  invoice.hstTotal =
    (invoice.subtotal - invoice.customerDiscount) * (HST / 100);

  // set grand total
  invoice.grandTotal = invoice.subtotal + invoice.hstTotal;

  return invoice;
};

const upsertCostsPrices = (
  invoiceCostsPrices: InvoiceCostPrice[],
  inventoryItem: InventoryItem,
  price: number,
  cost: number,
  quantity: number
) => {
  let matchInvoiceCostPrice = find(
    invoiceCostsPrices,
    (invoiceCostPrice) =>
      invoiceCostPrice.invoiceCategoryId ===
      inventoryItem.inventoryType.invoiceCategory.id
  );
  if (matchInvoiceCostPrice) {
    matchInvoiceCostPrice.price += Number(price) * quantity;
    matchInvoiceCostPrice.cost += Number(cost) * quantity;
    var index = indexOf(invoiceCostsPrices, matchInvoiceCostPrice);
    invoiceCostsPrices.splice(index, 1, matchInvoiceCostPrice);
  } else {
    invoiceCostsPrices.push({
      invoiceCategoryId: inventoryItem.inventoryType.invoiceCategory.id,
      invoiceCategory: inventoryItem.inventoryType.invoiceCategory,
      cost: cost * quantity,
      price: price * quantity,
    } as InvoiceCostPrice);
  }
  return invoiceCostsPrices;
};

const calculateProfitMargin = (totalCost: number, totalPrice: number) => {
  return totalCost > 0 ? (totalPrice / totalCost - 1) * 100 : 100;
};

const applyProportionalRate = (invoice: Invoice) => {
  const proportionalRates = invoice?.invoiceLineItems?.filter(
    (invoiceLineItem) => invoiceLineItem?.inventoryItem?.rate > 0
  );

  proportionalRates?.forEach((proportionalRate: InvoiceLineItem) => {
    const diffPrice =
      invoice.totalPrice * (proportionalRate.inventoryItem.rate / 100);
    const diffCost =
      invoice.totalCost * (proportionalRate.inventoryItem.rate / 100);

    invoice.totalPrice = invoice.totalPrice + diffPrice;
    invoice.totalCost = invoice.totalPrice + diffPrice;

    invoice.invoiceCostsPrices = upsertCostsPrices(
      invoice.invoiceCostsPrices,
      proportionalRate.inventoryItem,
      diffPrice,
      diffCost,
      1
    );
  });
};

const customerDiscounts = (
  invoiceLineItems: InvoiceLineItem[],
  customer: Customer
) => {
  let total = 0;
  const customerTierDetails: CustomerTierDetail[] =
    customer.customerTier.customerTierDetails;

  invoiceLineItems?.forEach((invoiceLineItem) => {
    const discount =
      customerTierDetails.find(
        (x) =>
          x.invoiceCategory.id ===
          invoiceLineItem?.inventoryItem?.inventoryType?.invoiceCategory.id
      )?.discountRate ?? 0;

    total +=
      invoiceLineItem.price * invoiceLineItem.quantity * (discount / 100);
  });

  return total;
};
