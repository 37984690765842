import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Toolbar, Drawer, Divider, Stack } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";

import { menuItems } from "../../../globals/menu-items";
import { Auth } from "aws-amplify";
import { MainHeader } from "../MainHeader/MainHeader";
import { SettingsDropDown } from "../../ui";
import { useAuth } from "../../../contexts/AuthContext";
import config from "../../../config/app-config";
import { NavigationMenuItem } from "../../ui/Menu/Menu";
import { QuickBooksAlert } from "./components/QuickBooksAlert";
import { useCurrentUser } from "../../../contexts";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const MainContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const [displayName, setDisplayName] = useState<String>();
  const { hasRole } = useAuth();
  const { shouldShowQBAlert, setQBAlert } = useCurrentUser();

  useEffect(() => {
    Auth.currentUserInfo().then((user) => {
      const display = user?.attributes?.name
        ? user?.attributes?.name
        : user?.attributes?.email;
      const displayContent = user ? display : "";
      setDisplayName(displayContent);
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <MainHeader title={config.COMPANY_NAME ?? ""}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {displayName}
              <SettingsDropDown />
            </Stack>
          </MainHeader>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {menuItems
          .filter((x) => x.navType === "default" && hasRole(x.roles))
          .map((menuItem, key) => {
            return <NavigationMenuItem item={menuItem} key={key} />;
          })}
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {menuItems
          .filter((x) => x.navType === "admin" && hasRole(x.roles))
          .map((menuItem, key) => {
            return <NavigationMenuItem item={menuItem} key={key} />;
          })}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {shouldShowQBAlert() && (
          <QuickBooksAlert
            onClose={() => {
              setQBAlert("HIDE");
            }}
          />
        )}
        {children}
      </Main>
    </Box>
  );
};
