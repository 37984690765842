import { useState } from "react";
import { useDeleteWorkOrderRoleTitle } from "../../../hooks/work-order-role-title";
import { WorkOrderRoleTitle } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { workOrderRoleTitleColumns as columns } from "./work-order-role-title.columns";
import { WorkOrderRoleTitleForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
}

export const WorkOrderRoleTitleDataGrid = (
  props: Props<WorkOrderRoleTitle>
) => {
  const {
    rows,
    loading,
    onClick,
    onLink,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
  } = props;
  const [workOrderRoleTitle, setWorkOrderRoleTitle] =
    useState<WorkOrderRoleTitle>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteWorkOrderRoleTitle } = useDeleteWorkOrderRoleTitle();

  const onEditCreate = (workOrderRoleTitle?: WorkOrderRoleTitle) => {
    workOrderRoleTitle
      ? setWorkOrderRoleTitle(workOrderRoleTitle)
      : setWorkOrderRoleTitle(undefined);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      onClick={onClick}
      onDelete={(workOrderRoleTitle) =>
        deleteWorkOrderRoleTitle(workOrderRoleTitle.id)
      }
      onCreate={onEditCreate}
      onEdit={onEditCreate}
      onLink={onLink}
      title={title}
      createTitle="Create Title"
      deleteTitle="Do you want to delete this Title"
      ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "desc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${
          workOrderRoleTitle ? "Edit" : "Create"
        } Work Order Role Title`}
      >
        <WorkOrderRoleTitleForm
          onSubmitted={() => setOpenModal(false)}
          workOrderRoleTitle={workOrderRoleTitle}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
