import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";

import { WorkOrderState } from "../../models";
import { WorkOrderStateForm } from "../../components/forms";
import { GenericDataGrid } from "../../components/grids";
import { workOrderStateColumns } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import {
  useWorkOrderStates,
  useDeleteWorkOrderState,
} from "../../hooks/work-order-state";

export const WorkOrderStatesPage = () => {
  const {
    data: workOrderStates,
    isLoading,
    isIdle,
    isError,
    error,
  } = useWorkOrderStates();
  const { mutate: deleteWorkOrderState } = useDeleteWorkOrderState();
  const [workOrderState, setWorkOrderState] = useState<WorkOrderState>();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (workOrderState?: WorkOrderState) => {
    workOrderState
      ? setWorkOrderState(workOrderState)
      : setWorkOrderState(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Work Order States"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <GenericDataGrid
        rows={workOrderStates}
        columns={workOrderStateColumns}
        loading={isLoading}
        onDelete={(workOrderState) => deleteWorkOrderState(workOrderState.id)}
        onEdit={onEditCreate}
        onCreate={onEditCreate}
        onDoubleClick={onEditCreate}
        createTitle="Create Work Order State"
        deleteTitle="Would you like to delete this Work Order State"
        ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
        autoHeight
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${workOrderState ? "Edit" : "Create"} Work Order State`}
        >
          <WorkOrderStateForm
            onSubmitted={() => setOpenModal(false)}
            workOrderState={workOrderState}
          />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
    </>
  );
};
