import { yupResolver } from "@hookform/resolvers/yup";
import { Box, BoxProps, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { Address, AddressType } from "../../../models";
import { addressSchema as schema } from "../schemas";
import { useAddAddress, usePatchAddress } from "../../../hooks/address";
import { useAddressTypes } from "../../../hooks/address-type";
import { FormContainer } from "../../layout";
import {
  FormSelect,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { AddressSearch } from "../../ui";

interface Props extends BoxProps {
  address?: Address;
  defaultAddressTypeId: string;
  onSubmitted?: (address: Address) => void;
}

export const AddressForm = (props: Props) => {
  const { address, defaultAddressTypeId, onSubmitted, ...rest } = props;
  const { mutateAsync: createAddress } = useAddAddress();
  const { mutateAsync: patchAddress } = usePatchAddress();
  const { data: addressTypes } = useAddressTypes();
  const [defaultAddressType, setDefaultAddressType] = useState<AddressType>();
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { defaultValues, isSubmitting },
  } = useForm<Address>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(address),
  });
  const addressTypeId = watch("addressTypeId");

  useEffect(() => {
    addressTypes &&
      setDefaultAddressType(
        addressTypes.find((x) => x.id === defaultAddressTypeId)
      );

    if (!address?.addressTypeId && addressTypes) {
      setValue(
        "addressTypeId",
        addressTypes.find((x) => x.id === defaultAddressTypeId)?.id ?? ""
      );
    }
  }, [addressTypes, address, defaultAddressTypeId, setValue]);

  const onSubmit = async (address: Address) => {
    address = address.id
      ? await patchAddress(address)
      : await createAddress(address);
    onSubmitted && onSubmitted(address);
  };

  return (
    <Box {...rest}>
      <FormContainer
        onSubmit={async (event) => {
          if (event) {
            // sometimes not true, e.g. React Native
            if (typeof event.preventDefault === "function") {
              event.preventDefault();
            }
            if (typeof event.stopPropagation === "function") {
              // prevent any outer forms from receiving the event too
              event.stopPropagation();
            }
          }
          return handleSubmit(onSubmit)(event);
        }}
      >
        <Stack direction="row" spacing={2}>
          <AddressSearch
            sx={{ width: 460 }}
            label="Address"
            onSelected={(selectedAddress) => {
              reset({
                ...defaultValues,
                addressType: addressTypeId
                  ? addressTypes?.find((x) => x.id === addressTypeId)
                  : defaultAddressType,
                addressTypeId: addressTypeId ?? defaultAddressType?.id,
                ...selectedAddress,
              });
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormTextField
            name="streetNumber"
            label="Street Number"
            control={control}
          />
          <FormTextField
            name="streetName"
            label="Street Name"
            control={control}
          />
          <FormTextField name="unit" label="Unit" control={control} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormTextField
            name="postalCode"
            label="Postal Code"
            control={control}
          />
          <FormTextField name="city" label="City" control={control} />
          <FormTextField name="province" label="Province" control={control} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <FormTextField name="buzzCode" label="Buzz Code" control={control} />
          <FormTextField name="note" label="Note" control={control} />
          {addressTypes && defaultAddressType && (
            <FormSelect
              name="addressTypeId"
              label="Address Type"
              labelKeys={["name"]}
              valueKey="id"
              data={addressTypes}
              control={control}
              defaultValue={address?.addressType ?? defaultAddressType}
              sx={{ width: 222 }}
            />
          )}
        </Stack>
        <FormSubmitButton disabled={isSubmitting} />
      </FormContainer>
    </Box>
  );
};
