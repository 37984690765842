import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import { PaymentMethod } from "../../../models";
import { paymentMethodSchema as schema } from "../schemas";
import {
  useAddPaymentMethod,
  usePatchPaymentMethod,
} from "../../../hooks/payment-method";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";

interface Props {
  paymentMethod?: PaymentMethod;
  onSubmitted?: () => void;
}

export const PaymentMethodForm = (props: Props) => {
  const { paymentMethod, onSubmitted } = props;
  const { mutate: createPaymentMethod } = useAddPaymentMethod();
  const { mutate: patchPaymentMethod } = usePatchPaymentMethod();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<PaymentMethod>({
    resolver: yupResolver(schema),
    defaultValues: paymentMethod,
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((payment) => {
        payment.id ? patchPaymentMethod(payment) : createPaymentMethod(payment);
        onSubmitted && onSubmitted();
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting || isSubmitSuccessful} />
    </FormContainer>
  );
};
