import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { sub } from "date-fns";
import { Button, Paper, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { CustomerAddress, Proposal, WorkOrder } from "../../models";
import {
  GenericDataGrid,
  ProposalDataGrid,
  proposalLineItemColumns,
} from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { AutoSearch, ChipDropDown, LoadingSpinner } from "../../components/ui";
import { useProposalsByFilter } from "../../hooks/proposal";
import { useWorkOrdersSearch } from "../../hooks/work-order";
import { useCustomerSearch } from "../../hooks/customer-address";
import { useWorkOrderStates } from "../../hooks/work-order-state";

export const ProposalsPage = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date>(
    sub(new Date(), { months: 1 })
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [workOrderId, setWorkOrderId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const { data: workOrderStates } = useWorkOrderStates();
  const [workOrderStateGuids, setWorkOrderStateGuids] = useState<string[]>([]);
  const {
    data: proposals,
    isLoading,
    isIdle,
    isError,
    error,
    refetch: getProposals,
  } = useProposalsByFilter(
    startDate,
    endDate,
    workOrderStateGuids,
    workOrderId,
    customerId
  );
  const [proposal, setProposal] = useState<Proposal | undefined>();

  useEffect(() => {
    startDate && endDate && getProposals();
  }, [
    startDate,
    endDate,
    workOrderStateGuids,
    workOrderId,
    customerId,
    getProposals,
  ]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader title="Proposals" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Start Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={startDate}
            onChange={(value) => setStartDate(value ?? new Date())}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={endDate}
            onChange={(value) => {
              setEndDate(value ?? new Date());
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <AutoSearch
            label="Work Order Search..."
            keys={["workOrderNumber"]}
            onOptionSelected={(workOrder: WorkOrder | null) =>
              workOrder ? setWorkOrderId(workOrder?.id) : setWorkOrderId("")
            }
            searchFunction={useWorkOrdersSearch}
            separator="|"
            sx={{ width: 200 }}
          />
          <ChipDropDown
            data={workOrderStates ?? []}
            label="Work Order States"
            labelKey={"name"}
            valueKey={"id"}
            onOptionChange={(selectedWorkOrderStates) =>
              setWorkOrderStateGuids(selectedWorkOrderStates.map((x) => x.id))
            }
          />
          <AutoSearch
            label="Search Customer By Name, Address, or P.O ..."
            keys={["fullName", "email", "phone", "address", "po"]}
            onOptionSelected={(customer: CustomerAddress | null) =>
              customer ? setCustomerId(customer.customerId) : setCustomerId("")
            }
            searchFunction={useCustomerSearch}
            separator="|"
            sx={{ width: 500 }}
          />
          <Button
            variant="contained"
            onClick={() => {
              if (proposal) {
                if (proposal.invoiceId) {
                  navigate(`/invoices/edit/${proposal.invoiceId}`);
                } else {
                  navigate(
                    `/invoices/create?workOrderId=${proposal.workOrderId}`
                  );
                }
              }
            }}
          >
            {proposal && proposal.invoiceId ? (
              <>Update Invoice</>
            ) : (
              <>Create Invoice</>
            )}
          </Button>
        </Stack>
      </Paper>
      <ProposalDataGrid
        rows={proposals}
        loading={isLoading}
        onView={(proposal) => navigate(`/proposals/view/${proposal.id}`)}
        onEdit={(proposal) => navigate(`/proposals/edit/${proposal.id}`)}
        onClick={(proposal) => setProposal(proposal)}
        sx={{ height: 300 }}
      />
      <h3>Proposal Line Items</h3>
      {proposal?.proposalLineItems && (
        <GenericDataGrid
          rows={proposal.proposalLineItems}
          columns={proposalLineItemColumns}
          loading={isLoading}
          ignoreFields={["id", "inventoryItem"]}
          autoHeight
          hideFooter
          disableExport
          disableQuickFilter
          disableColumnsButton
          disableCreate
        />
      )}
    </>
  );
};
