import { Box, BoxProps } from "@mui/material";

export const DataGridContainer = (props: BoxProps) => {
  const { children, ...rest } = props;
  return (
    <Box
      sx={{
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
        },
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
