import { Paper, PaperProps } from "@mui/material";

export const PageSection = (props: PaperProps) => {
  const { children } = props;
  return (
    <Paper sx={{ p: 2, mt: 1, mb: 1 }} elevation={3} {...props}>
      {children}
    </Paper>
  );
};
