import { Grid, Typography } from "@mui/material";
import config from "../../../config/app-config";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  children?: JSX.Element;
}

export const MainHeader = (props: Props) => {
  const { title, children } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Link to="/">
          <img
            src={`${config.COMPANY_IMAGE_URL}/logo.png`}
            width={100}
            alt="logo"
          />
        </Link>
      </Grid>
      <Grid item>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};
