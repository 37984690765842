import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerPayment } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddCustomerPayment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerPayment: Omit<CustomerPayment, "id">) =>
      add<CustomerPayment>("customer-payments", customerPayment),
    {
      onSuccess: (customerPayment, variables) => {
        enqueueSnackbar("Customer Payment Added", { variant: "success" });
        queryClient.setQueryData<CustomerPayment[]>(
          [RQ.CUSTOMER_PAYMENTS, variables.customerId],
          (customerPayments: CustomerPayment[] | undefined) => {
            return [...(customerPayments ?? []), customerPayment];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Payment Adding Error", { variant: "error" });
      },
    }
  );
};
