import { PageHeader, PageSection } from "../../components/layout";
import { ChangePasswordForm } from "../../components/forms";
import { useEmployeeProfile } from "../../hooks/employee";
import { DisplayInfo, LoadingSpinner } from "../../components/ui";

export const ProfilePage = () => {
  const {
    data: employee,
    isLoading: isLoadingEmployee,
    isIdle: isIdleEmployee,
    isError: isErrorEmployee,
  } = useEmployeeProfile();

  if (isLoadingEmployee || isIdleEmployee) {
    return <LoadingSpinner />;
  }

  if (isErrorEmployee) {
    return <h2>Page Error</h2>;
  }

  return (
    <>
      <PageHeader title="Profile" />
      <PageSection>
        <h3>Employee Info</h3>
        <DisplayInfo
          entity={employee}
          displayEmpties={true}
          ignore={[
            "id",
            "createdTimestamp",
            "modifiedTimestamp",
            "employeeTypeIds",
            "employeeTypes",
          ]}
        />
      </PageSection>
      <PageSection>
        <h3>Change Password</h3>
        <ChangePasswordForm />
      </PageSection>
    </>
  );
};
