import { useState } from "react";
import { InvoiceForm } from "../../components/forms";
import { PageHeader } from "../../components/layout";
import { AutoSearch } from "../../components/ui";
import { useCustomerSearch } from "../../hooks/customer-address";
import { CustomerAddress, Invoice, WorkOrder } from "../../models";
import { useCustomer } from "../../hooks/customer";
import { APPLICATION_SETTING } from "../../globals/enums";
import { useAppSettingsContext } from "../../contexts";
import { useAddWorkOrder } from "../../hooks/work-order";
import { useAddInvoice } from "../../hooks/invoice";
import { useNavigate } from "react-router-dom";
import { InvoiceTotalFooter } from "../invoices/components/InvoiceTotalFooter";

export const OverTheCounterSalePage = () => {
  const { getSetting } = useAppSettingsContext();
  const DEFAULT_ADDRESS = getSetting(
    APPLICATION_SETTING.DEFAULT_ADDRESS
  ).settingId;
  const ON_OVER_THE_COUNTER_SALE = getSetting(
    APPLICATION_SETTING.ON_OVER_THE_COUNTER_SALE
  ).settingId;
  const OVER_THE_COUNTER_INDICATOR = getSetting(
    APPLICATION_SETTING.OVER_THE_COUNTER_INDICATOR
  ).settingId;
  const [workOrder, setWorkOrder] = useState<WorkOrder>({
    callDate: new Date(),
    jobDescription: "OVER THE COUNTER SALE",
    siteAddressId: DEFAULT_ADDRESS,
    billingAddressId: DEFAULT_ADDRESS,
    workOrderStateId: ON_OVER_THE_COUNTER_SALE,
    jobTypeIds: [OVER_THE_COUNTER_INDICATOR],
  } as WorkOrder);

  const [invoice, setInvoice] = useState<any>();
  const { data: customer } = useCustomer(workOrder.customerId);
  const { mutateAsync: createWorkOrder } = useAddWorkOrder();
  const { mutateAsync: createInvoice } = useAddInvoice();
  const navigate = useNavigate();

  const save = async (invoice: Invoice, navigateToPreview?: boolean) => {
    const newWorkOrder = await createWorkOrder(workOrder as WorkOrder);
    const newInvoice = await createInvoice({
      ...invoice,
      workOrderId: newWorkOrder.id,
    } as Invoice);
    navigateToPreview && navigate(`/invoices/view/${newInvoice.id}`);
  };

  return (
    <>
      <PageHeader title="Over The Counter Sale" />
      <AutoSearch
        label="Search Customer By Name, Address, or P.O ..."
        keys={["customerNumber", "fullName", "email", "phone", "address", "po"]}
        onOptionSelected={(customer: CustomerAddress | null) => {
          customer &&
            setWorkOrder({ ...workOrder, customerId: customer.customerId });
        }}
        searchFunction={useCustomerSearch}
        separator="|"
        sx={{ width: 700, mt: 2, mb: 2 }}
      />
      {customer && (
        <InvoiceForm
          customer={customer}
          mode="OVER_THE_COUNTER"
          onSubmitted={(invoice, navigateToPreview) =>
            invoice && save(invoice, navigateToPreview)
          }
          onInvoiceChanged={(entity) => setInvoice(entity)}
        />
      )}
      {invoice && <InvoiceTotalFooter invoice={invoice} />}
    </>
  );
};
