import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { AddressType } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchAddressType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (addressType: Partial<AddressType>) =>
      patch({
        route: "address-types",
        id: addressType.id ?? "",
        entity: addressType,
      }),
    {
      onSuccess: (addressType) => {
        enqueueSnackbar("Address Type Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.ADDRESS_TYPES,
          (addressTypes: AddressType[] | undefined) =>
            !addressTypes
              ? []
              : patchEntity<AddressType>(addressTypes, addressType, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Address Type Updating Error", { variant: "error" });
      },
    }
  );
};
