import { Link, Paper, Typography } from "@mui/material";
import { forwardRef, memo } from "react";
import { UnscheduledEvent } from "../../../models";

interface Props {
  unsecheduledEvents: UnscheduledEvent[];
}

export const UnscheduledEvents = memo(
  forwardRef((props: Props, ref: any) => {
    const { unsecheduledEvents } = props;
    return (
      <div ref={ref}>
        {unsecheduledEvents.map((event, index) => {
          return (
            <Paper
              sx={{ m: 2, p: 1 }}
              className="fc-event"
              key={index}
              title={event.title}
              data-id={event.id}
              data-workorderid={event.workOrderId}
              data-color={event.color}
              data-workorderstateid={event.workOrderStateId}
              data-workorderstatename={event.workOrderStateName}
              data-jobtypes={event.jobTypes}
              data-address={event.address}
              data-scheduletypeid={event.scheduleTypeId}
              data-customername={event.customerName}
              style={{
                backgroundColor: event.color,
                borderColor: event.color,
                cursor: "pointer",
              }}
            >
              <Typography style={{ overflowWrap: "break-word" }}>
                <Link
                  onClick={() =>
                    window
                      .open(
                        `/work-order/edit?workOrderId=${event.workOrderId}`,
                        "_blank"
                      )
                      ?.focus()
                  }
                >
                  {event.title}
                </Link>{" "}
                - <b>{event.workOrderStateName}</b>
                <br />
                {event.jobTypes}
                <br />
                {event.customerName}
                <br />
                {event.address}
              </Typography>
            </Paper>
          );
        })}
      </div>
    );
  })
);
