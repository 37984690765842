import { useQuery } from "react-query";

import { EmployeeTimesheet } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useEmployeeTimesheetsByFilter = (
  workOrderId?: string,
  employeeId?: string
) => {
  let params = new URLSearchParams();
  workOrderId && params.append("workOrderId", workOrderId);
  employeeId && params.append("employeeId", employeeId);
  return useQuery<EmployeeTimesheet[], Error>(
    [RQ.EMPLOYEE_TIMESHEETS],
    () => getAll<EmployeeTimesheet>(`employee-timesheets/filter?${params}`),
    {
      select: (employeeTimesheets) => {
        employeeTimesheets.forEach((employeeTimesheet) => {
          employeeTimesheet.employeeId = employeeTimesheet.employee.id;
          employeeTimesheet.workOrderId = employeeTimesheet?.workOrder?.id;
        });
        return employeeTimesheets;
      },
    }
  );
};
