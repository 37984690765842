import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  InventoryItemForm,
  InvoiceForm,
  WorkOrderForm,
} from "../../components/forms";
import {
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useWorkOrder } from "../../hooks/work-order";
import { WorkOrder } from "../../models";
import { useCustomer } from "../../hooks/customer";

export const InvoicePage = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: invoice,
    isLoading,
    isIdle,
    isError,
    error,
  } = useInvoice(id ?? "");
  const [searchParams] = useSearchParams();
  let workOrderId = searchParams.get("workOrderId");
  let customerId = searchParams.get("customerId") ?? invoice?.customerId;
  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    isIdle: isIdleWorkOrder,
  } = useWorkOrder(invoice?.workOrderId ?? workOrderId);
  const { data: customer } = useCustomer(customerId ?? workOrder?.customer.id);
  const [openModal, setOpenModal] = useState(false);
  const [openEditWorkOrder, setOpenEditWorkOrder] = useState<boolean>(false);
  const navigate = useNavigate();

  if ((isLoading || isIdle) && id) {
    return <LoadingSpinner />;
  }

  if ((isLoadingWorkOrder || isIdleWorkOrder) && workOrderId) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <Paper sx={{ p: 2, mb: 1 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
                variant="contained"
              >
                Back
              </Button>

              {workOrder && (
                <>
                  {/* <Button
                    onClick={() => setOpenWorkOrderModal(true)}
                    variant="contained"
                  >
                    View Work Order
                  </Button> */}
                  <Button
                    variant="contained"
                    onClick={() => setOpenEditWorkOrder(true)}
                  >
                    Edit Work Order
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {`${invoice ? "Edit" : "Create"} Invoice`}
              {workOrder &&
                workOrder.workOrderNumber &&
                ` - ${workOrder?.workOrderNumber}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={() => setOpenModal(true)} variant="contained">
              Create Inventory Item
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {customer && <InvoiceForm invoice={invoice} customer={customer} />}
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Create Inventory Item"
      >
        <InventoryItemForm onSubmitted={() => setOpenModal(false)} />
      </ModalContainerChangeDetection>
      {/* {workOrder && (
        <ModalContainerChangeDetection
          open={openWorkOrderModal}
          handleClose={() => setOpenWorkOrderModal(false)}
          title="Work Order"
        >
          <DisplayWorkOrder workOrder={workOrder} />
        </ModalContainerChangeDetection>
      )} */}
      <ModalContainerChangeDetection
        open={openEditWorkOrder}
        title="Edit Work Order"
        handleClose={() => setOpenEditWorkOrder(false)}
      >
        <WorkOrderForm workOrder={workOrder as Partial<WorkOrder>} />
      </ModalContainerChangeDetection>
    </>
  );
};
