export function uncamelize(str: string, separator: string) {
  // Assume default separator is a single space.
  if (typeof separator == "undefined") {
    separator = " ";
  }
  // Replace all capital letters by separator followed by lowercase one
  str = str.replace(/[A-Z]/g, function (letter) {
    return separator + letter.toLowerCase();
  });
  // Remove first separator
  let words = str.replace("/^" + separator + "/", "");

  return capitalizeFirstLetterOfEachWord(words.split(" "));
}

function capitalizeFirstLetterOfEachWord(words: string[]) {
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}
