import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Employee } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchEmployee = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (employee: Partial<Employee>) =>
      patch({ route: "employees", id: employee.id ?? "", entity: employee }),
    {
      onSuccess: (employee) => {
        enqueueSnackbar("Employee Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.EMPLOYEES,
          (employees: Employee[] | undefined) =>
            !employees ? [] : patchEntity<Employee>(employees, employee, "id")
        );
        queryClient.setQueryData([RQ.EMPLOYEES, employee.id], employee);
      },
      onError: () => {
        enqueueSnackbar("Employee Updating Error", { variant: "error" });
      },
    }
  );
};
