import { useQuery } from "react-query";

import { InventoryUsage } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useInventoryUsage = () => {
  return useQuery<InventoryUsage[], Error>(
    RQ.REPORT_INVENTORY_USAGE,
    async () => getAll<InventoryUsage>("reports/inventory-usage")
  );
};
