import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Stack,
} from "@mui/material";
import {
  Control,
  ControllerRenderProps,
  FieldValues,
  Path,
  useController,
} from "react-hook-form";

interface Props<T, K extends FieldValues> {
  name: Path<K>;
  label?: string;
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  control: Control<K, any>;
  required?: boolean;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
}

export const FormCheckBoxGroup = <T, K extends FieldValues>(
  props: Props<T, K>
) => {
  const {
    name,
    label,
    options,
    labelKey,
    valueKey,
    control,
    required,
    direction,
  } = props;

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    field: ControllerRenderProps<K, Path<K>>
  ) => {
    if (checked) {
      field.onChange(
        field.value
          ? [...field.value, event.target.value]
          : [event.target.value]
      );
    } else {
      field.onChange(
        field?.value?.filter((value: string) => value !== event.target.value)
      );
    }
  };

  const isChecked = (
    field: ControllerRenderProps<K, Path<K>>,
    option: T
  ): boolean => {
    return field.value
      ? field.value.some(
          (existingValue: string) => existingValue === option[valueKey]
        )
      : false;
  };
  const { field, fieldState } = useController({ name, control });

  return (
    <FormControl error={fieldState.invalid} required={required}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup>
        <Stack direction={direction} sx={{ flexWrap: "wrap" }}>
          {options.map((option, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event, checked) =>
                      onChange(event, checked, field)
                    }
                    name={option[labelKey] as string}
                    value={option[valueKey]}
                    checked={isChecked(field, option)}
                  />
                }
                label={option[labelKey] as string}
                key={index}
              />
            );
          })}
        </Stack>
      </FormGroup>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};
