import api from "./api-instance";
import fileDownload from "js-file-download";
import { serialize } from "object-to-formdata";

export const getAll = async <T>(route: string): Promise<T[]> => {
  return api.get<T[]>(route).then((response) => response.data);
};

export const getSingle = async <T>(route: string): Promise<T> => {
  return api.get<T>(route).then((response) => response.data);
};

export const add = async <T>(
  route: string,
  entity?: Partial<T>
): Promise<T> => {
  return api.post<T>(route, entity).then((response) => response.data);
};

export const update = async <T>(
  route: string,
  id: string,
  entity: T
): Promise<T> => {
  return api.put<T>(`${route}/${id}`, entity).then((response) => response.data);
};

export const patch = async <T>(patchEntity: {
  route: string;
  id: string;
  entity: Partial<T>;
}): Promise<T> => {
  return api
    .patch<T>(`${patchEntity.route}/${patchEntity.id}`, patchEntity.entity)
    .then((response) => response.data);
};

export const remove = async (route: string, id: string): Promise<void> => {
  return api.delete<void>(`${route}/${id}`).then((response) => response.data);
};

export const downloadFile = async (route: string, fileName: string) => {
  return api
    .get(route, { responseType: "blob" })
    .then((respose) => fileDownload(respose.data, fileName));
};

export const uploadFile = async <T>(route: string, entity: T): Promise<T> => {
  let formData = serialize(entity, {
    indices: true,
    dotsForObjectNotation: true,
  });

  return api
    .post<T>(route, formData, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((response) => response.data);
};

export const getSingleByAttribute = async <T>(
  route: string,
  property: string,
  value: string
): Promise<T> => {
  let url = `${route}?search=${property}=="${value}*"`;
  return api.get<T[]>(url).then((response) => response.data[0]);
};

export const queryEntity = async <T>(
  route: string,
  searchTerm: string
): Promise<T[]> => {
  return api.get(`${route}?q=${searchTerm}`).then((respose) => respose.data);
};
