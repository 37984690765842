import { Box, Divider, Grid } from "@mui/material";
import { Customer } from "../../../models";

interface Props {
  customer: Customer;
}

export const DisplayCustomer = (props: Props) => {
  const { customer } = props;
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <strong>Name</strong>
        </Grid>
        <Grid item xs={9}>
          {customer.firstName} {customer.lastName}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Email</strong>
        </Grid>
        <Grid item xs={9}>
          {customer.email}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Phone</strong>
        </Grid>
        <Grid item xs={9}>
          {customer.phone}
        </Grid>
        {customer.taxRate !== -1 && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              <strong>Tax Rate</strong>
            </Grid>
            <Grid item xs={9}>
              {customer.taxRate}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Interest Terms</strong>
        </Grid>
        <Grid item xs={9}>
          {customer.interestTerm} Days
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Customer Teir</strong>
        </Grid>
        <Grid item xs={9}>
          {customer?.customerTier?.name}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Customer Number</strong>
        </Grid>
        <Grid item xs={9}>
          {customer.customerNumber}
        </Grid>
        {customer?.customerQuickBooksDetails?.length > 0 && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={3}>
              <strong>Quick Books ID</strong>
            </Grid>
            <Grid item xs={9}>
              {customer.customerQuickBooksDetails
                .map((x) => x.quickBooksId)
                .toString()}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
