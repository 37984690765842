import React, { useContext, useEffect } from "react";
import { useAppSettings } from "../hooks/app-settings";
import { AppSetting } from "../models";

interface Props {
  children: JSX.Element;
}

interface AppSettingProp {
  getSetting: (name: string) => AppSetting;
}

const AppSettingContext = React.createContext<AppSettingProp>({
  getSetting: (name: string) => ({} as AppSetting),
});

export const useAppSettingsContext = () => useContext(AppSettingContext);

export const AppSettingProvider = ({ children }: Props) => {
  const { data: appSettings } = useAppSettings();

  useEffect(() => {
    localStorage.setItem("appSettings", JSON.stringify(appSettings));
  }, [appSettings]);

  const getSetting = (name: string): AppSetting => {
    const currentAppSettings = localStorage.getItem("appSettings");

    if (
      currentAppSettings &&
      currentAppSettings.length > 0 &&
      currentAppSettings !== undefined &&
      currentAppSettings !== "undefined"
    ) {
      const appSettingsLocal: AppSetting[] = JSON.parse(currentAppSettings);

      return (
        appSettingsLocal.find((appSetting) => appSetting.name === name) ??
        ({} as AppSetting)
      );
    } else {
      return {} as AppSetting;
    }
  };

  const value = {
    getSetting,
  };

  return (
    <AppSettingContext.Provider value={value}>
      {children}
    </AppSettingContext.Provider>
  );
};
