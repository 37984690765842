import { useState } from "react";
import { SxProps, Theme } from "@mui/material";
import { useDeleteWorkOrderNote } from "../../../hooks/work-order-note";
import { WorkOrder, WorkOrderNote } from "../../../models";
import { ModalContainerChangeDetection } from "../../ui";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { workOrderNoteColumns } from "./work-order-note.columns";
import { WorkOrderNoteForm } from "../../forms";
import { useCurrentUser } from "../../../contexts";

interface Props {
  rows: WorkOrderNote[];
  loading: boolean;
  workOrder?: WorkOrder;
  onClick?: (entity: WorkOrderNote) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  sx?: SxProps<Theme>;
}

export const WorkOrderNoteDataGrid = (props: Props) => {
  const {
    rows,
    loading,
    workOrder,
    onClick,
    onModalClosed,
    title,
    autoHeight,
    disableColumnsButton,
    disableExport,
    disableQuickFilter,
    disableCreate,
    sx,
  } = props;
  const [workOrderNote, setWorkOrderNote] = useState<WorkOrderNote>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteWorkOrderNote } = useDeleteWorkOrderNote();
  const { getCurrentUser } = useCurrentUser();

  const onEditCreate = (workOrderNote?: WorkOrderNote) => {
    workOrderNote
      ? setWorkOrderNote(workOrderNote)
      : setWorkOrderNote({ workOrderId: workOrder?.id } as WorkOrderNote);
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      sx={sx}
      rows={rows}
      columns={workOrderNoteColumns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onDelete={(workOrderNote) => deleteWorkOrderNote(workOrderNote)}
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      title={title}
      createTitle="Create Work Order Note"
      ignoreFields={[
        "id",
        "workOrderId",
        "createdTimestamp",
        "modifiedTimestamp",
        "employee.id",
        "employee.createdTimestamp",
        "employee.modifiedTimestamp",
        "employee.employeeRole",
        "employee.employeeTypes",
        "employeeId",
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "modifiedTimestamp", sort: "asc" }],
        },
      }}
      disableColumnsButton={disableColumnsButton}
      disableExport={disableExport}
      disableQuickFilter={disableQuickFilter}
      disableCreate={disableCreate}
      autoHeight={autoHeight}
      checkId="employeeId"
      currentUserId={getCurrentUser()?.id}
      hideFooter
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${workOrderNote?.id ? "Edit" : "Create"} Work Order Note`}
      >
        <WorkOrderNoteForm
          onSubmitted={() => setOpenModal(false)}
          workOrderNote={workOrderNote}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
