import { useQuery } from "react-query";

import { Proposal } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";
import { orderBy } from "lodash";

export const useProposal = (id: string) => {
  return useQuery<Proposal, Error>(
    [RQ.PROPOSALS, id],
    () => getSingle(`proposals/${id}`),
    {
      enabled: Boolean(id),
      select(proposal) {
        if (proposal) {
          proposal.proposalLineItems = orderBy(
            proposal.proposalLineItems,
            ["inventoryItemId"],
            "desc"
          );
          proposal.proposalLineItems.forEach((proposalLineItem) => {
            proposalLineItem.inventoryItemId =
              proposalLineItem.inventoryItem.id;
          });
        }
        return proposal;
      },
    }
  );
};
