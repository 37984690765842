import { useEffect, useState } from "react";
import { Paper, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format, sub } from "date-fns";

import { CalendarDateRange, Employee, WorkOrder } from "../../models";
import { ScheduleDataGrid } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { AutoSearch, ChipDropDown, LoadingSpinner } from "../../components/ui";
import { useSchedulesByDateRange } from "../../hooks/schedule";
import { useNavigate } from "react-router-dom";
import { useScheduleTypes } from "../../hooks/schedule-type";
import { useWorkOrdersSearch } from "../../hooks/work-order";
import { useEmployeeSearch } from "../../hooks/employee";

export const SchedulesPage = () => {
  const [scheduleTypeGuids, setScheduleTypeGuids] = useState<string[]>([]);
  const [workOrderId, setWorkOrderId] = useState<string>("");
  const [employeeId, setEmployeeId] = useState<string>("");
  const [dateRange, setDateRange] = useState<CalendarDateRange>({
    startDate: sub(new Date(), { months: 3 }),
    endDate: new Date(),
  });
  const { data: scheduleTypes } = useScheduleTypes();
  const {
    data: schedules,
    isLoading,
    isIdle,
    isError,
    error,
    refetch: getSchedules,
  } = useSchedulesByDateRange(
    dateRange,
    employeeId,
    scheduleTypeGuids,
    workOrderId
  );

  const navigate = useNavigate();

  useEffect(() => {
    dateRange.startDate && getSchedules();
  }, [dateRange, scheduleTypeGuids, getSchedules, workOrderId, employeeId]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader title="Schedules" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Start Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={dateRange.startDate}
            onChange={(value) =>
              setDateRange({
                ...dateRange,
                startDate: value ?? new Date(),
              })
            }
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={dateRange.endDate}
            onChange={(value) =>
              setDateRange({
                ...dateRange,
                endDate: value ?? new Date(),
              })
            }
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <ChipDropDown
            data={scheduleTypes ?? []}
            label="Schedule Types"
            labelKey={"name"}
            valueKey={"id"}
            onOptionChange={(selectedScheduleTypes) =>
              setScheduleTypeGuids(selectedScheduleTypes.map((x) => x.id))
            }
          />
          <AutoSearch
            label="Work Order Search..."
            keys={["workOrderNumber"]}
            onOptionSelected={(workOrder: WorkOrder | null) =>
              workOrder ? setWorkOrderId(workOrder?.id) : setWorkOrderId("")
            }
            searchFunction={useWorkOrdersSearch}
            separator="|"
            sx={{ width: 200 }}
          />
          <AutoSearch
            label="Employee Search..."
            keys={["firstName", "lastName"]}
            onOptionSelected={(employee: Employee | null) =>
              employee ? setEmployeeId(employee?.id) : setEmployeeId("")
            }
            searchFunction={useEmployeeSearch}
            separator="|"
            sx={{ width: 200 }}
          />
        </Stack>
      </Paper>
      <ScheduleDataGrid
        rows={schedules}
        loading={isLoading}
        onLink={(schedule) => {
          const startDate = format(
            new Date(schedule.startDate as Date),
            "yyyy-MM-dd"
          );
          navigate(`/dispatch?goToDate=${startDate}`);
        }}
        autoHeight
      />
    </>
  );
};
