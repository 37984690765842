import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { InvoiceDrawRequest } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteInvoiceDrawRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (invoiceDrawRequest: InvoiceDrawRequest) =>
      remove("invoice-draw-requests", invoiceDrawRequest.id),
    {
      onSuccess: (_, invoiceDrawRequest) => {
        enqueueSnackbar("Invoice Draw Request Deleted", { variant: "success" });
        queryClient.setQueryData(
          [RQ.INVOICE_DRAW_REQUESTS, invoiceDrawRequest.invoiceId],
          (invoiceDrawRequests: InvoiceDrawRequest[] | undefined) => {
            return !invoiceDrawRequests
              ? []
              : deleteEntity<InvoiceDrawRequest>(
                  invoiceDrawRequests,
                  "id",
                  invoiceDrawRequest.id
                );
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Invoice Draw Request Deleting Error", {
          variant: "error",
        });
      },
    }
  );
};
