import React, { useContext, useEffect, useState } from "react";
import { useCurrentEmployee } from "../hooks/employee";
import { Employee } from "../models";

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface CurrentUserProp {
  getCurrentUser: () => Employee;
  isQBAuthenticated: () => boolean;
  setIsQBAuthenticated: (state: boolean) => void;
  setQBAlert: (state: string) => void;
  shouldShowQBAlert: () => boolean;
}

const CurrentUserContext = React.createContext<CurrentUserProp | undefined>(
  undefined
);

export const CurrentUserProvider = ({ children }: Props) => {
  const [user, setUser] = useState<Employee>({} as Employee);
  const [qBAuthState, setQBAuthState] = useState(false);
  const [qBShowAlert, setQBShowAlert] = useState("");
  const { data: employee } = useCurrentEmployee();

  useEffect(() => {
    employee && setUser(employee);
  }, [employee]);

  const getCurrentUser = () => user;
  const isQBAuthenticated = () => qBAuthState;
  const setIsQBAuthenticated = (state: boolean) => setQBAuthState(state);
  const setQBAlert = (state: string) => {
    if (qBShowAlert !== "HIDE") {
      setQBShowAlert(state);
    }
  };
  const shouldShowQBAlert = () => (qBShowAlert === "SHOW" ? true : false);

  const value = {
    getCurrentUser,
    isQBAuthenticated,
    setIsQBAuthenticated,
    setQBAlert,
    shouldShowQBAlert,
  };

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error("CurrentUserContext is undefined");
  }
  return context;
};
