import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin, {
  EventReceiveArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollgrid from "@fullcalendar/scrollgrid";
import {
  EventContentArg,
  EventDropArg,
  EventSourceInput,
} from "@fullcalendar/core";
import { add } from "date-fns";
import { ResourceLabelContentArg } from "@fullcalendar/resource-common";
import { Box } from "@mui/material";

import { Employee, ExcludeDate, Schedule } from "../../../models";
import { CalendarEvent } from "./CalendarEvent";
import { renderResourceContent } from "./CalendarResource";
import {
  useAddSchedule,
  useDeleteSchedule,
  usePatchSchedule,
  useSchedule,
} from "../../../hooks/schedule";
import { ModalContainerChangeDetection } from "../ModalContainerChangeDetection/ModalContainerChangeDetection";
import { ScheduleForm, ScheduleServiceForm } from "../../forms";
import { useScheduleTypes } from "../../../hooks/schedule-type";
import { useAuth } from "../../../contexts/AuthContext";
import { Role } from "../../../globals/enums";
import { ModalPrompt } from "../ModalPrompt/ModalPrompt.modal";
import { DELETE_SCHEDULE_OPTION } from "../../../pages/dispatch/enums/delete-schedule-option.enum";
import { ConfirmationModal } from "../ConfirmationModal/Confirmation.modal";
import { DisplayInfo } from "../DisplayInfo/DisplayInfo";
import { useAppSettingsContext } from "../../../contexts";
import { APPLICATION_SETTING } from "../../../globals/enums";

interface Props {
  events: EventSourceInput;
  employees: Employee[];
  selectedEmployeeTypes: string[];
  calendarRef: React.RefObject<FullCalendar>;
  onDateUpdated: (date: Date, endDate?: Date) => void;
  onEventDeleted?: (scheduleId: string) => void;
  onEventAdded?: (schedule: Partial<Schedule>) => void;
}

export const DispatchCalendar = (props: Props) => {
  const {
    events,
    employees,
    selectedEmployeeTypes,
    calendarRef,
    onDateUpdated,
    onEventDeleted,
    onEventAdded,
  } = props;
  const { getSetting } = useAppSettingsContext();
  const NORMAL = getSetting(APPLICATION_SETTING.NORMAL).settingId;
  const ON_SERVICE = getSetting(APPLICATION_SETTING.ON_SERVICE).settingId;
  const { mutate: patchSchedule } = usePatchSchedule();
  const { mutate: createSchedule } = useAddSchedule();
  const { mutateAsync: removeSchedule } = useDeleteSchedule();
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [openScheduleServiceModal, setOpenScheduleServiceModal] =
    useState<boolean>(false);
  const [serviceSchedule, setServiceSchedule] = useState<Schedule>();
  const [scheduleId, setScheduleId] = useState<string>();
  const { data: scheduleTypes } = useScheduleTypes();
  const [openConfirmationModalReoccuring, setOpenConfirmationModalReoccuring] =
    useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showWeekend, setShowWeekend] = useState(false);
  const { hasRole } = useAuth();
  const { data: schedule } = useSchedule(scheduleId);
  const [selectedDate, setSelectedDate] = useState<Date | null>();

  const deleteSchedule = (arg: EventContentArg) => {
    setSelectedDate(arg.event.start);
    setScheduleId(arg.event.id);
    if (arg.event.extendedProps["repeats"]) {
      setOpenConfirmationModalReoccuring(true);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const mapResources = (
    employees: Employee[],
    selectedEmployeeTypes: string[]
  ) => {
    if (selectedEmployeeTypes?.length > 0) {
      employees = employees.filter((employee) => {
        let isEmployeeCapable = false;
        const employeeTypeIds = employee.employeeTypes.map((x) => x.id);
        selectedEmployeeTypes.forEach((selectedEmployeeType) => {
          if (employeeTypeIds.includes(selectedEmployeeType)) {
            isEmployeeCapable = true;
          }
        });
        return isEmployeeCapable;
      });
    }

    return employees.map((employee) => {
      if (employee) {
        return {
          id: employee.id,
          title: `${employee?.firstName} ${
            employee?.lastName ? employee?.lastName[0] : ""
          }`,
          dispatchPriority: employee.dispatchPriority,
        };
      } else {
        return {};
      }
    });
  };

  const dateUpdating = () => {
    if (calendarRef.current?.getApi().view.type === "resourceTimeGridWeek") {
      onDateUpdated(
        calendarRef.current?.getApi().view.activeStart,
        calendarRef.current?.getApi().view.activeEnd
      );
    } else {
      onDateUpdated(calendarRef.current?.getApi().getDate() || new Date());
    }
  };

  // On New Schedule
  const onEventReceive = (arg: EventReceiveArg) => {
    if (arg.event.start) {
      const startDate: Date = arg.event.start;
      const endDate: Date = add(arg.event.start, { hours: 3 });
      arg.event.setEnd(endDate);

      const scheduleType = scheduleTypes?.find(
        (scheduleType) => scheduleType.id === NORMAL
      );

      if (scheduleType) {
        const schedule: Partial<Schedule> = {
          startDate: startDate,
          endDate: endDate,
          employeeId: arg.event.getResources()[0].id,
          workOrderId: arg.event.extendedProps["workOrderId"],
          scheduleTypeId: scheduleType.id,
        };
        createSchedule(schedule);
        onEventAdded && onEventAdded(schedule);
      }
    }
  };

  const onEventResize = (arg: EventResizeDoneArg) => {
    if (arg.event.start && arg.event.end) {
      let schedule: Partial<Schedule> = {
        id: arg.event.id,
        startDate: arg.event.start,
        endDate: arg.event.end,
      };
      patchSchedule(schedule);
    }
  };

  // On Schedule Moved
  const onEventDrop = (arg: EventDropArg) => {
    if (arg.event.start && arg.event.end) {
      let schedule: Partial<Schedule> = {
        id: arg.event.id,
        startDate: arg.event.start,
        endDate: arg.event.end,
      };
      if (arg.newResource) {
        schedule.employeeId = arg.newResource.id;
      }
      patchSchedule(schedule);
    }
  };

  const onNext = () => {
    calendarRef.current?.getApi().next();
    dateUpdating();
  };

  const onPrevious = () => {
    calendarRef.current?.getApi().prev();
    dateUpdating();
  };

  const onToday = () => {
    calendarRef.current?.getApi().today();
    dateUpdating();
  };

  const onEdit = (eventInfo: EventContentArg) => {
    setScheduleId(eventInfo.event.id);
    setOpenScheduleModal(true);
  };

  const onWorkOrderEdit = (eventInfo: EventContentArg) =>
    window
      .open(
        `/work-order/edit?workOrderId=${eventInfo.event.extendedProps["workOrderId"]}`,
        "_blank"
      )
      ?.focus();

  const onDuplicate = (eventInfo: EventContentArg) => {
    const newSchedule: Partial<Schedule> = {
      workOrderId: eventInfo.event.extendedProps["workOrderId"],
      scheduleTypeId: eventInfo.event.extendedProps["scheduleTypeId"],
      startDate: add(eventInfo.event.start ?? new Date(), { hours: 1 }),
      endDate: add(eventInfo.event.end ?? new Date(), { hours: 1 }),
      employeeId: eventInfo.event.getResources()[0].id,
    };
    createSchedule(newSchedule);
  };

  const onServiceToggle = (eventInfo: ResourceLabelContentArg) => {
    if (!hasRole([Role.Admin])) return false;
    setServiceSchedule({
      scheduleTypeId: scheduleTypes?.find((x) => x.id === ON_SERVICE)?.id,
      employeeId: eventInfo.resource.id,
    } as Schedule);
    setOpenScheduleServiceModal(true);
  };

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        plugins={[
          interactionPlugin,
          resourceTimelinePlugin,
          resourceTimeGridPlugin,
          scrollgrid,
        ]}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        aspectRatio={1.9}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "resourceTimeGridDay,resourceTimeGridWeek,hideWeekends",
        }}
        initialView="resourceTimeGridDay"
        resourceAreaWidth="20%"
        weekends={true}
        editable={true}
        droppable={true}
        scrollTimeReset={true}
        dragScroll={true}
        events={events}
        progressiveEventRendering={false}
        selectable={true}
        slotMinTime="7:00:00"
        slotMaxTime="21:00:00"
        dayMinWidth={150}
        stickyFooterScrollbar={true}
        resourceOrder={"-dispatchPriority,title"}
        resources={mapResources(employees, selectedEmployeeTypes)}
        resourceLabelContent={(eventInfo: ResourceLabelContentArg) =>
          renderResourceContent(eventInfo, onServiceToggle)
        }
        titleFormat={{
          month: "long",
          year: "numeric",
          day: "numeric",
          weekday: "long",
        }}
        customButtons={{
          prev: {
            text: "Prev",
            click: () => onPrevious(),
          },
          next: {
            text: "Next",
            click: () => onNext(),
          },
          today: {
            text: "GO TO TODAY",
            click: () => onToday(),
          },
          resourceTimeGridDay: {
            text: "Daily",
            click: () => {
              calendarRef.current?.getApi().changeView("resourceTimeGridDay");
              dateUpdating();
            },
          },
          resourceTimeGridWeek: {
            text: "Weekly",
            click: () => {
              calendarRef.current?.getApi().changeView("resourceTimeGridWeek");
              dateUpdating();
            },
          },
          hideWeekends: {
            text: "Toggle Weekend",
            click: () => {
              if (showWeekend) {
                calendarRef.current?.getApi().setOption("hiddenDays", []);
                setShowWeekend(false);
              } else {
                calendarRef.current?.getApi().setOption("hiddenDays", [0, 6]);
                setShowWeekend(true);
              }
            },
          },
        }}
        eventContent={(eventInfo: EventContentArg) =>
          CalendarEvent(
            eventInfo,
            ON_SERVICE,
            deleteSchedule,
            onEdit,
            onDuplicate,
            onWorkOrderEdit,
            hasRole
          )
        }
        eventReceive={onEventReceive}
        eventResize={onEventResize}
        eventDrop={onEventDrop}
      />

      {schedule && (
        <ModalContainerChangeDetection
          open={openScheduleModal}
          title={`${schedule?.id ? "Edit" : "Create"} Schedule`}
          handleClose={() => setOpenScheduleModal(false)}
        >
          <ScheduleForm
            schedule={schedule}
            onSubmitted={() => setOpenScheduleModal(false)}
          />
        </ModalContainerChangeDetection>
      )}

      <ModalContainerChangeDetection
        open={openScheduleServiceModal}
        title={`${serviceSchedule?.id ? "Edit" : "Create"} Service Schedule`}
        handleClose={() => setOpenScheduleServiceModal(false)}
      >
        <ScheduleServiceForm
          schedule={serviceSchedule}
          onSubmitted={() => setOpenScheduleServiceModal(false)}
        />
      </ModalContainerChangeDetection>

      <ModalPrompt
        title="Would you like to remove from schedule?"
        buttonOptions={Object.values(DELETE_SCHEDULE_OPTION)}
        open={openConfirmationModalReoccuring}
        showWarning
        onClose={async (selection?: string) => {
          if (schedule) {
            if (selection === DELETE_SCHEDULE_OPTION.ONLY_THIS_INSTANCE) {
              patchSchedule({
                id: schedule.id,
                excludedDates: [{ excludedDate: selectedDate } as ExcludeDate],
              });
            } else if (selection === DELETE_SCHEDULE_OPTION.ALL_INSTANCES) {
              await removeSchedule(schedule.id);
              onEventDeleted && onEventDeleted(schedule.id);
            }
          }
          setOpenConfirmationModalReoccuring(false);
        }}
      />

      {schedule && (
        <ConfirmationModal
          title="Are you sure you want to delete this Schedule"
          open={openConfirmationModal}
          onClose={async (selection: boolean) => {
            if (selection && schedule) {
              await removeSchedule(schedule.id);
              onEventDeleted && onEventDeleted(schedule.id);
            }
            setOpenConfirmationModal(false);
          }}
        >
          <Box sx={{ mb: 1 }}>
            <DisplayInfo
              entity={schedule}
              displayEmpties={true}
              ignore={[
                "id",
                "createdTimestamp",
                "modifiedTimestamp",
                "workOrder",
                "scheduleType",
                "employee",
                "repeatDayIds",
                "repeatDays",
                "scheduleTypeId",
                "employeeId",
                "excludedDates",
                "workOrderId",
              ]}
            />
          </Box>
        </ConfirmationModal>
      )}
    </>
  );
};
