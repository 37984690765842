import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerTier } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteCustomerTier = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("customer-tiers", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Customer Tier Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.CUSTOMER_TIERS,
        (customerTiers: CustomerTier[] | undefined) => {
          return !customerTiers
            ? []
            : deleteEntity<CustomerTier>(customerTiers, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Customer Tier Deleting Error", { variant: "error" });
    },
  });
};
