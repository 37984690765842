import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerNote } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddCustomerNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerNote: CustomerNote) =>
      add<CustomerNote>(
        `customers/${customerNote.customerId}/customer-notes`,
        customerNote
      ),
    {
      onSuccess: (customerNote, variables) => {
        enqueueSnackbar("Customer Note Added", { variant: "success" });
        queryClient.setQueryData<CustomerNote[]>(
          [RQ.CUSTOMER_NOTES, variables.customerId],
          (customerNotes: CustomerNote[] | undefined) => {
            return [...(customerNotes ?? []), customerNote];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Note Adding Error", { variant: "error" });
      },
    }
  );
};
