import { useQuery } from "react-query";

import { JobType } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useJobTypes = () => {
  return useQuery<JobType[], Error>(RQ.JOB_TYPES, async () =>
    getAll<JobType>("job-types")
  );
};
