import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { uploadFile } from "../../api/generic-api";

export const useUploadInventoryItems = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (file: File) =>
      uploadFile<{ file: File }>(`/inventory-items/upload`, { file }),
    {
      onSuccess: () => {
        enqueueSnackbar("Inventory Items Added", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Inventory Items Adding Error", { variant: "error" });
      },
    }
  );
};
