import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useDeleteCustomerNote } from "../../../hooks/customer-notes";
import { Customer, CustomerNote } from "../../../models";
import {
  ConfirmationModal,
  DataGridToolBar,
  DisplayInfo,
  ModalContainerChangeDetection,
} from "../../ui";
import { customerNoteColumns } from "./customer-note.columns";
import { CustomerNoteForm } from "../../forms";

interface Props<T> {
  rows: T[];
  loading: boolean;
  customer: Customer;
  onModalClosed?: () => void;
  disableCreate?: boolean;
  title?: string;
}

export const CustomerNoteDataGrid = (props: Props<CustomerNote>) => {
  const { rows, loading, customer, onModalClosed, disableCreate, title } =
    props;
  const [customerNote, setCustomerNote] = useState<CustomerNote>();
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { mutate: deleteCustomerNote } = useDeleteCustomerNote();

  const onEditCreate = (customerNote?: CustomerNote) => {
    customerNote
      ? setCustomerNote({ ...customerNote, customerId: customer.id })
      : setCustomerNote({ customerId: customer.id } as CustomerNote);
    setOpenModal(true);
  };

  const onDelete = (customerNote: CustomerNote) => {
    setCustomerNote(customerNote);
    setOpenConfirmationModal(true);
  };

  const onConfirmationModalClosed = (selection: boolean) => {
    if (selection && customerNote) {
      deleteCustomerNote(customerNote);
    }
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <DataGrid
        rows={rows}
        columns={customerNoteColumns({ onEdit: onEditCreate, onDelete })}
        loading={loading}
        disableDensitySelector={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        density="compact"
        hideFooter
        autoHeight
        components={{
          Toolbar: DataGridToolBar,
        }}
        componentsProps={{
          toolbar: {
            title,
            createTitle: `Create Note`,
            createHandler: onEditCreate,
            disableExport: true,
            disableColumnsButton: true,
            disableQuickFilter: true,
            disableCreate,
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "modifiedTimestamp", sort: "desc" }],
          },
        }}
      />

      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${customerNote?.id ? "Edit" : "Create"} Customer Note`}
      >
        <CustomerNoteForm
          onSubmitted={() => setOpenModal(false)}
          customerNote={customerNote}
        />
      </ModalContainerChangeDetection>

      <ConfirmationModal
        title="Do you want to delete this Customer Note"
        open={openConfirmationModal}
        onClose={(selection: boolean) => onConfirmationModalClosed(selection)}
      >
        {customerNote && (
          <Box sx={{ mb: 1 }}>
            <DisplayInfo
              entity={customerNote}
              displayEmpties={true}
              ignore={[
                "id",
                "customerId",
                "createdTimestamp",
                "modifiedTimestamp",
              ]}
            />
          </Box>
        )}
      </ConfirmationModal>
    </>
  );
};
