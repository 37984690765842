import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

interface Props<T> {
  data: T[];
  label: string;
  labelKey: keyof T;
  valueKey: keyof T;
  onOptionChange?: (entity: T[]) => void;
  defaultValue?: T[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ChipDropDown = <T,>(props: Props<T>) => {
  const { data, label, labelKey, valueKey, onOptionChange, defaultValue } =
    props;
  const [field, setField] = useState<T[]>(defaultValue ?? []);

  const handleChange = (event: SelectChangeEvent<typeof field>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === "object") {
      setField(value);
      onOptionChange && onOptionChange(value as T[]);
    }
  };

  const onDelete = (value: T) => {
    const filteredValues = field.filter((x) => x[valueKey] !== value[valueKey]);
    setField(filteredValues);
    onOptionChange && onOptionChange(filteredValues as T[]);
  };

  return (
    <FormControl size="small" sx={{ minWidth: 200 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={`label - ${label}`}
        size="small"
        multiple
        value={field}
        onChange={handleChange}
        input={<OutlinedInput label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value[labelKey as keyof object]}
                label={value[labelKey as keyof object]}
                onDelete={() => onDelete(value)}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {data.map((item: T, index: number) => (
          <MenuItem key={index} value={item as any}>
            {item[labelKey as keyof object]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
