import * as yup from "yup";

export const employeeEmployeeTypeSchema = yup.object().shape({
  employeeTypeIds: yup
    .array()
    .required()
    .min(1)
    .label("Employee Type")
    .default([]),
});
