import {
  FormControlLabel,
  StandardTextFieldProps,
  Switch,
  Typography,
} from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface Props<K extends FieldValues> extends StandardTextFieldProps {
  name: Path<K>;
  label: string;
  control: Control<K, any>;
  onChanged?: (checked: boolean) => void;
}

export const FormSwitch = <K extends FieldValues>(props: Props<K>) => {
  const { name, label, control, onChanged } = props;
  const { field } = useController({ name, control });
  return (
    <FormControlLabel
      label={<Typography noWrap>{label}</Typography>}
      control={
        <Switch
          checked={field.value ?? false}
          onChange={(_, checked) => {
            field.onChange(checked);
            onChanged && onChanged(checked);
          }}
        />
      }
    />
  );
};
