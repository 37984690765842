export const config = {
  API: {
    URL: process.env.REACT_APP_API_URL,
  },
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME,
  COMPANY_IMAGE_URL: process.env.REACT_APP_COMPANY_IMAGE_URL,
  FAVICON_URL: process.env.REACT_APP_COMPANY_IMAGE_URL + "/favicon.ico",
  QUICK_BOOKS: {
    AUTHORIZATION_URL: process.env.REACT_APP_QB_AUTHORIZATION_URL,
    CLIENT_ID: process.env.REACT_APP_QB_CLIENT_ID,
    REDIRECT_URL: process.env.REACT_APP_QB_REDIRECT_URL,
    SCOPE: process.env.REACT_APP_QB_SCOPE,
  },
};

export default config;
