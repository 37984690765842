import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, Grid } from "@mui/material";

import { PageHeader } from "../../components/layout";
import { LoadingSpinner } from "../../components/ui";
import { useAppSettings } from "../../hooks/app-settings";
import { useScheduleTypes } from "../../hooks/schedule-type";
import { AppSettingForm } from "../../components/forms";
import { useWorkOrderStates } from "../../hooks/work-order-state";
import { AppSetting } from "../../models";
import { useAddressTypes } from "../../hooks/address-type";
import { usePaymentMethods } from "../../hooks/payment-method";
import { usePaymentTypes } from "../../hooks/payment-type";
import { useInventoryTypes } from "../../hooks/inventory-type";
import {
  useInventoryItems,
  useSearchInventoryItems,
} from "../../hooks/inventory-item";
import { useCustomerTiers } from "../../hooks/customer-tier";
import { useState } from "react";
import { useEmployeeTypes } from "../../hooks/employee-type";
import { useCustomerSearch } from "../../hooks/customer-address";
import { useJobTypes } from "../../hooks/job-type";

export const AppDefaultsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermAddress, setSearchTermAddress] = useState("");
  const {
    data: appSettings,
    isLoading,
    isIdle,
    isError,
    error,
  } = useAppSettings();
  const { data: addressTypes } = useAddressTypes();
  const { data: inventoryItems } = useInventoryItems();
  const { data: inventoryItemsAlt } = useSearchInventoryItems(searchTerm);
  const { data: addresses } = useCustomerSearch(searchTermAddress);
  const { data: inventoryTypes } = useInventoryTypes();
  const { data: scheduleTypes } = useScheduleTypes();
  const { data: workOrderStates } = useWorkOrderStates();
  const { data: paymentMethods } = usePaymentMethods();
  const { data: paymentTypes } = usePaymentTypes();
  const { data: customerTiers } = useCustomerTiers();
  const { data: employeeTypes } = useEmployeeTypes();
  const { data: jobTypes } = useJobTypes();
  const navigate = useNavigate();

  const renderForm = (appSetting: AppSetting) => {
    switch (appSetting.optionType) {
      case "JOB_TYPE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={jobTypes ?? []}
            optionType="autocomplete"
            label="Job Type"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "ADDRESS_TYPE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={addressTypes ?? []}
            optionType="autocomplete"
            label="Address Type"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "CUSTOMER_TIER":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={customerTiers ?? []}
            optionType="autocomplete"
            label="Customer Tiers"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "INVENTORY_ITEM":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={inventoryItemsAlt ?? []}
            optionType="autocomplete"
            label="Inventory Item"
            labelKeys={["name"]}
            valueKey={"id"}
            onInputChange={(searchTerm) => setSearchTerm(searchTerm)}
          />
        );
      case "ADDRESS":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={addresses ?? []}
            optionType="autocomplete"
            label="Default Address"
            labelKeys={["firstName", "lastName", "address"]}
            valueKey={"addressId"}
            onInputChange={setSearchTermAddress}
          />
        );
      case "INVENTORY_TYPE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={inventoryTypes ?? []}
            optionType="autocomplete"
            label="Inventory Type"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "PAYMENT_METHOD":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={paymentMethods ?? []}
            optionType="autocomplete"
            label="Payment Method"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "PAYMENT_TYPE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={paymentTypes ?? []}
            optionType="autocomplete"
            label="Payment Type"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "SCHEDULE_TYPE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={scheduleTypes ?? []}
            optionType="autocomplete"
            label="Schedule Type"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "WORK_ORDER_STATE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={workOrderStates ?? []}
            optionType="autocomplete"
            label="Work Order State"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "WORK_ORDER_STATE_MULTIPLE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={workOrderStates ?? []}
            optionType="multiple"
            label="Work Order State"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      case "EMPLOYEE_TYPE_MULTIPLE":
        return (
          <AppSettingForm
            appSetting={appSetting}
            options={employeeTypes ?? []}
            optionType="multiple"
            label="Employee Types"
            labelKeys={["name"]}
            valueKey={"id"}
          />
        );
      default:
        return <AppSettingForm appSetting={appSetting} label="Schedule Type" />;
    }
  };

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title="App Defaults"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="start"
        spacing={2}
        sx={{ mb: 2, mt: 2 }}
      >
        <Grid item xs={2}>
          <b>Name</b>
        </Grid>
        <Grid item xs={2}>
          <b>Display Name</b>
        </Grid>
        <Grid item xs={2}>
          <b>Option</b>
        </Grid>
        <Grid item xs={2}>
          <b>Default Value</b>
        </Grid>
        <Grid item xs={2}>
          <b>Enabled</b>
        </Grid>
        <Grid item xs={2}>
          <b>Action</b>
        </Grid>
      </Grid>
      {addressTypes &&
        inventoryItems &&
        inventoryTypes &&
        paymentMethods &&
        paymentTypes &&
        scheduleTypes &&
        workOrderStates &&
        employeeTypes &&
        appSettings.map((appSetting, index) => (
          <Box sx={{ mb: 3 }} key={index}>
            {renderForm(appSetting)}
          </Box>
        ))}
    </>
  );
};
