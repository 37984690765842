export enum UNIT_OF_MEASURE {
  Gram = "Gram",
  Milligram = "Milligram",
  Kilogram = "Kilogram",
  Pound = "Pound",
  Ton = "Ton",
  Litre = "Litre",
  Millilitre = "Millilitre",
  Kilolitre = "Kilolitre",
  Gallon = "Gallon",
  Pint = "Pint",
  Fluid = "Fluid",
  Ounce = "Ounce",
  Meter = "Meter",
  Kilometer = "Kilometer",
  Centimeter = "Centimeter",
  Millimeter = "Millimeter",
  Feet = "Feet",
  Yard = "Yard",
  Inch = "Inch",
  Mile = "Mile",
}
