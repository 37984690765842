import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { CompanyDocument } from "../../../models";

export const companyDocumentColumns: GridColDef<CompanyDocument>[] = [
  { field: "name", headerName: "Name", flex: 1 },
  { field: "note", headerName: "Note", flex: 1 },
  {
    field: "tags",
    headerName: "Tags",
    flex: 1,
    valueGetter: (params) =>
      params.row.companyDocumentTags
        ? params.row.companyDocumentTags.map((x) => x.tag).toString()
        : "",
  },
  {
    field: "createdTimestamp",
    headerName: "Created Date",
    flex: 1,
    valueGetter: (params) =>
      params.row.createdTimestamp
        ? format(parseISO(params.row.createdTimestamp), "PPpp")
        : "",
  },
];
