import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { AddressType } from "../../../models";
import { addressTypeSchema as schema } from "./address-type.schema";
import {
  useAddAddressType,
  usePatchAddressType,
} from "../../../hooks/address-type";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  addressType?: AddressType;
  onSubmitted?: (addressType: AddressType) => void;
}

export const AddressTypeForm = (props: Props) => {
  const { addressType, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createAddressType } = useAddAddressType();
  const { mutateAsync: patchAddressType } = usePatchAddressType();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, defaultValues },
  } = useForm<AddressType>({
    resolver: yupResolver(schema),
    defaultValues: addressType,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (addressType) => {
        addressType = addressType.id
          ? await patchAddressType(addressType)
          : await createAddressType(addressType);
        onSubmitted && onSubmitted(addressType);
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting || isSubmitSuccessful} />
    </FormContainer>
  );
};
