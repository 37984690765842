import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEmployees } from "../../../../hooks/employee";
import { Employee } from "../../../../models";

interface Props {
  onSelected: (employee: Employee) => void;
  children?: JSX.Element | JSX.Element[];
}

export const SelectEmployeesModal = (props: Props) => {
  const { onSelected } = props;
  const { data: employees } = useEmployees();

  return (
    <Box>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell align="left">
                <b>Email</b>
              </TableCell>
              <TableCell align="right">Selected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees &&
              employees.map((employee, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {`${employee.firstName} ${employee.lastName}`}
                  </TableCell>
                  <TableCell align="left">{employee.email}</TableCell>
                  <TableCell align="right">
                    <Checkbox
                      onChange={(_, checked) => {
                        onSelected(employee);
                        // onExcluded(statement.guid, checked);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
