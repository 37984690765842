import { Box, BoxProps } from "@mui/material";

interface Props {
  body: string;
  sx?: BoxProps;
}

export const LegalDisclaimer = (props: Props) => {
  const { body, sx } = props;
  return (
    <Box sx={{ border: 2, p: 2 }} {...sx}>
      {body}
    </Box>
  );
};
