import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { isEqual } from "lodash";

import { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import { Box, BoxProps } from "@mui/material";
import { entityStringLabel } from "../../../utils";
import { RecursiveKeyof } from "../../../types";

interface Props<T> extends BoxProps {
  label: string;
  data?: T[];
  keys: RecursiveKeyof<T>[];
  separator?: string;
  error?: boolean;
  helperText?: string;
  onOptionSelected: (entity: T[] | null) => void;
  searchFunction: (searchTerm: string) => UseQueryResult<T[], Error>;
  initialValue?: T[];
  defaultSearchTerm?: string;
  values?: T[];
}

export function AutoSearchMultiple<T>(props: Props<T>) {
  const {
    label,
    keys,
    error,
    helperText,
    onOptionSelected,
    searchFunction,
    separator,
    initialValue,
    defaultSearchTerm,
    values,
    ...rest
  } = props;
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm ?? "");
  const { data, isLoading, isFetching, refetch } = searchFunction(searchTerm);

  useEffect(() => {
    searchTerm && refetch();
  }, [searchTerm]);

  return (
    <Box {...rest}>
      <Autocomplete
        value={values}
        multiple
        clearOnBlur
        id={label}
        fullWidth
        getOptionLabel={(option) => entityStringLabel(option, keys, separator)}
        onChange={(_, value) => value && onOptionSelected(value)}
        options={data ?? []}
        loading={isLoading}
        onInputChange={(event, newInputValue) => {
          if (event && event.type !== "click") {
            setSearchTerm(newInputValue);
          }
        }}
        defaultValue={initialValue}
        isOptionEqualToValue={(option, value) => isEqual(option, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading || isFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
