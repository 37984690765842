import { Grid, Paper, Typography } from "@mui/material";

interface Props {
  title: string;
  left?: JSX.Element;
  right?: JSX.Element;
}

export const PageHeader = (props: Props) => {
  const { title, left, right } = props;
  return (
    <Paper sx={{ p: 1, mb: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Grid item>{left}</Grid>
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item>{right}</Grid>
      </Grid>
    </Paper>
  );
};
