import { memo, useEffect, useState } from "react";
import { Button, IconButton, Stack } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { isEqual } from "lodash";

import {
  Address,
  AddressType,
  Contact,
  Customer,
  CustomerNote,
} from "../../../models";
import { newCustomerSchema as schema } from "../schemas";
import { useAddCustomer } from "../../../hooks/customer";
import { useAddressTypes } from "../../../hooks/address-type";
import { FormContainer } from "../../layout";
import {
  FormArrayObjectSelect,
  FormPhoneNumber,
  FormSelect,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { AddressSearch } from "../../ui";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { useCustomerTiers } from "../../../hooks/customer-tier";
import { APPLICATION_SETTING } from "../../../globals/enums";
import { useAppSettingsContext } from "../../../contexts";

interface FormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  customerTierId: string;
  addresses: Address[];
  contacts: Contact[];
  customerNotes: CustomerNote[];
}

interface Props {
  onSubmitted?: (customer: Customer) => void;
}

export const NewCustomerForm = memo((props: Props) => {
  const { onSubmitted } = props;
  const { getSetting } = useAppSettingsContext();
  const { setChangeDetection } = useChangeDetection();
  const { data: addressTypes } = useAddressTypes();
  const [defaultAddressType, setDefaultAddressType] = useState<AddressType>();
  const { mutateAsync: createCustomer } = useAddCustomer();
  const { data: customerTiers } = useCustomerTiers();
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { defaultValues },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const {
    fields: fieldContacts,
    append: appendContact,
    remove: removeContact,
  } = useFieldArray({
    name: "contacts",
    control,
  } as never);

  const {
    fields: fieldNotes,
    append: appendNote,
    remove: removeNote,
  } = useFieldArray({
    name: "customerNotes",
    control,
  } as never);
  const BILLING_ADDRESS_TYPE = getSetting(
    APPLICATION_SETTING.BILLING_ADDRESS_TYPE
  ).settingId;

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, setChangeDetection, defaultValues]);

  useEffect(() => {
    addressTypes &&
      setDefaultAddressType(
        addressTypes.find((x) => x.id === BILLING_ADDRESS_TYPE)
      );

    addressTypes &&
      setValue(
        "addresses.0.addressTypeId",
        addressTypes.find((x) => x.id === BILLING_ADDRESS_TYPE)?.id ?? ""
      );
  }, [addressTypes, setValue, BILLING_ADDRESS_TYPE]);

  useEffect(() => {
    customerTiers && setValue("customerTierId", customerTiers[0].id);
  }, [customerTiers, setValue]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (customer) => {
        customer = await createCustomer(customer as Customer);
        onSubmitted && onSubmitted(customer as Customer);
        setChangeDetection(false);
      })}
    >
      <h3>Customer</h3>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="firstName"
          label="First Name / Company Name"
          control={control}
          required
          fullWidth
        />
        <FormTextField
          name="lastName"
          label="Last Name"
          control={control}
          fullWidth
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="email" label="Email" control={control} fullWidth />
        <FormPhoneNumber name="phone" label="Phone" control={control} />
      </Stack>
      <Stack spacing={2} sx={{ mt: 1 }}>
        {customerTiers && (
          <FormArrayObjectSelect
            name="customerTierId"
            label="Customer Tier"
            data={customerTiers}
            control={control}
            labelKey="name"
            valueKey="id"
            defaultValue={customerTiers[0].id}
            required
          />
        )}
      </Stack>

      <h3>Address</h3>
      <Stack direction="row" spacing={2}>
        <AddressSearch
          sx={{ width: 460 }}
          label="Address"
          onSelected={(selectedAddress) => {
            setValue("addresses.0", selectedAddress as Address);
            defaultAddressType &&
              setValue("addresses.0.addressTypeId", defaultAddressType.id);
          }}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="addresses.0.streetNumber"
          label="Street Number"
          control={control}
        />
        <FormTextField
          name="addresses.0.streetName"
          label="Street Name"
          control={control}
        />
        <FormTextField name="addresses.0.unit" label="Unit" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="addresses.0.postalCode"
          label="Postal Code"
          control={control}
        />
        <FormTextField name="addresses.0.city" label="City" control={control} />
        <FormTextField
          name="addresses.0.province"
          label="Province"
          control={control}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField
          name="addresses.0.buzzCode"
          label="Buzz Code"
          control={control}
        />
        <FormTextField
          name="addresses.0.note"
          label="Address Note"
          control={control}
        />
        {addressTypes && defaultAddressType && (
          <FormSelect
            name="addresses.0.addressTypeId"
            label="Address Type"
            labelKeys={["name"]}
            valueKey="id"
            data={addressTypes}
            control={control}
            defaultValue={defaultAddressType}
            sx={{ width: 222 }}
          />
        )}
      </Stack>

      <h3>Contacts</h3>
      <Stack>
        {fieldContacts.map((field, index) => (
          <Controller
            name={`contacts.${index}`}
            control={control}
            key={field.id}
            render={() => {
              return (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <FormTextField
                    name={`contacts.${index}.name`}
                    label="Name"
                    control={control}
                  />
                  <FormTextField
                    name={`contacts.${index}.email`}
                    label="Email"
                    control={control}
                  />
                  <FormPhoneNumber
                    name={`contacts.${index}.phone`}
                    label="Phone"
                    control={control}
                  />
                  <FormTextField
                    name={`contacts.${index}.note`}
                    label="Note"
                    control={control}
                  />
                  <IconButton onClick={() => removeContact(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              );
            }}
          />
        ))}
        <Button
          onClick={() =>
            appendContact({
              name: "",
              note: "",
              phone: "",
              email: "",
            } as Contact)
          }
          endIcon={<AddCircleIcon />}
          variant="outlined"
        >
          Add Contact
        </Button>
      </Stack>

      <h3>Customer Notes</h3>
      <Stack>
        {fieldNotes.map((field, index) => (
          <Controller
            name={`customerNotes.${index}`}
            control={control}
            key={field.id}
            render={() => (
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <FormTextField
                  name={`customerNotes.${index}.note`}
                  label="Note"
                  control={control}
                />
                <IconButton onClick={() => removeNote(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            )}
          />
        ))}
        <Button
          onClick={() => appendNote({ note: "" } as CustomerNote)}
          endIcon={<AddCircleIcon />}
          variant="outlined"
        >
          Add Note
        </Button>
      </Stack>
      <FormSubmitButton text="Save" />
    </FormContainer>
  );
});
