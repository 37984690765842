import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddWorkOrder = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrder: Omit<WorkOrder, "id">) =>
      add<WorkOrder>("work-orders", workOrder),
    {
      onSuccess: async () => {
        enqueueSnackbar("Work Order Added", { variant: "success" });
        queryClient.invalidateQueries([RQ.WORK_ORDERS]);
      },
      onError: () => {
        enqueueSnackbar("Work Order Adding Error", {
          variant: "error",
        });
      },
    }
  );
};
