import { useState } from "react";
import { WorkOrderTimeline } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { workOrderTimelineColumns as columns } from "./work-order-timeline.columns";
import { SxProps, Theme } from "@mui/material";
import { DisplayWorkOrderTimeline, ModalContainer } from "../../ui";

interface Props<T> {
  rows: T[];
  loading: boolean;
  onClick?: (entity: T) => void;
  onLink?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
  autoHeight?: boolean;
  disableColumnsButton?: boolean;
  disableExport?: boolean;
  disableQuickFilter?: boolean;
  disableCreate?: boolean;
  sx?: SxProps<Theme>;
}

export const WorkOrderTimelineDataGrid = (props: Props<WorkOrderTimeline>) => {
  const { rows, loading, sx } = props;
  const [workOrderTimeline, setWorkOrderTimeline] = useState<
    WorkOrderTimeline | undefined
  >();
  const [open, setOpen] = useState(false);

  return (
    <>
      <GenericDataGrid
        sx={sx}
        rows={rows}
        columns={columns}
        loading={loading}
        onDoubleClick={(workOrderTimeline) => {
          setWorkOrderTimeline(workOrderTimeline);
          setOpen(true);
        }}
        onView={(workOrderTimeline) => {
          setWorkOrderTimeline(workOrderTimeline);
          setOpen(true);
        }}
        autoHeight
        disableCreate
        disableExport
        disableColumnsButton
        disableQuickFilter
      />
      {workOrderTimeline && (
        <ModalContainer
          open={open}
          title="Work Order Timeline"
          handleClose={() => setOpen(false)}
        >
          <DisplayWorkOrderTimeline workOrderTimeline={workOrderTimeline} />
        </ModalContainer>
      )}
    </>
  );
};
