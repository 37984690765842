import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerEquipment } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddCustomerEquipment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerEquipment: CustomerEquipment) =>
      add<CustomerEquipment>("customer-equipment", customerEquipment),
    {
      onSuccess: (customerEquipment) => {
        enqueueSnackbar("Customer Equipment Added", { variant: "success" });
        queryClient.setQueryData<CustomerEquipment[]>(
          RQ.CUSTOMER_EQUIPMENTS,
          (customerEquipments: CustomerEquipment[] | undefined) => {
            return [...(customerEquipments ?? []), customerEquipment];
          }
        );
        queryClient.setQueryData<CustomerEquipment[]>(
          [RQ.CUSTOMER_EQUIPMENTS, customerEquipment.customerId],
          (customerEquipments: CustomerEquipment[] | undefined) => {
            return [...(customerEquipments ?? []), customerEquipment];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Equipment Adding Error", {
          variant: "error",
        });
      },
    }
  );
};
