import { useState } from "react";
import { useDeleteContact } from "../../../hooks/contact";
import { Contact } from "../../../models";
import { GenericDataGrid } from "../generic-grid/Generic.grid";
import { contactColumns } from "./contact.columns";
import { ContactForm } from "../../forms";
import { ModalContainerChangeDetection } from "../../ui";

interface Props<T> {
  rows: T[];
  customerId: string;
  loading: boolean;
  onClick?: (entity: T) => void;
  onModalClosed?: () => void;
  title?: string;
}

export const ContactDataGrid = (props: Props<Contact>) => {
  const { rows, customerId, loading, onClick, onModalClosed, title } = props;
  const [contact, setContact] = useState<Partial<Contact> | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteContact } = useDeleteContact();

  const onEditCreate = (contact?: Contact) => {
    contact
      ? setContact({ ...contact, customerId })
      : setContact({ customerId });
    setOpenModal(true);
  };

  return (
    <GenericDataGrid
      rows={rows}
      columns={contactColumns}
      loading={loading}
      onClick={onClick}
      onDoubleClick={onEditCreate}
      onDelete={(contact) => deleteContact(contact)}
      onEdit={onEditCreate}
      onCreate={onEditCreate}
      title={title}
      createTitle="Create Contact"
      deleteTitle="Do you to this Contact?"
      ignoreFields={["id", "customerId"]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "desc" }],
        },
      }}
      autoHeight
      disableExport
      disableColumnsButton
      disableQuickFilter
    >
      <ModalContainerChangeDetection
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          onModalClosed && onModalClosed();
        }}
        title={`${contact ? "Edit" : "Create"} Contact`}
      >
        <ContactForm
          onSubmitted={() => setOpenModal(false)}
          contact={contact as Contact}
        />
      </ModalContainerChangeDetection>
    </GenericDataGrid>
  );
};
