import { useQuery } from "react-query";

import { AddressType } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useAddressTypes = () => {
  return useQuery<AddressType[], Error>(RQ.ADDRESS_TYPES, async () =>
    getAll<AddressType>("address-types")
  );
};
