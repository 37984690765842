import { Box, Divider, Grid, Typography } from "@mui/material";
import { WorkOrder } from "../../../models";
import { format } from "date-fns";
import { AddressDisplay } from "../Display/AddressDisplay";
import React from "react";
import { DisplayCustomerShort } from "../DisplayCustomer/DisplayCustomerShort";

interface Props {
  workOrder: WorkOrder;
}

export const DisplayWorkOrder = (props: Props) => {
  const { workOrder } = props;
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <strong>Work Order Number</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrder.workOrderNumber}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Work Order State</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrder.workOrderState.name}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Customer</strong>
        </Grid>
        <Grid item xs={9}>
          <DisplayCustomerShort customer={workOrder.customer} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Call Date</strong>
        </Grid>
        <Grid item xs={9}>
          {format(new Date(workOrder.callDate), "MMMM do, yyyy")}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Job Description</strong>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {workOrder.jobDescription}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Site Address</strong>
        </Grid>
        <Grid item xs={9}>
          <AddressDisplay address={workOrder.siteAddress} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Billing Address</strong>
        </Grid>
        <Grid item xs={9}>
          <AddressDisplay address={workOrder.billingAddress} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Job Type</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrder.jobTypes.map((jobType, index) => {
            return (
              <React.Fragment key={index}>{`${jobType.name}, `}</React.Fragment>
            );
          })}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <strong>Notes</strong>
        </Grid>
        <Grid item xs={9}>
          {workOrder.workOrderNotes.map((workOrderNote, index) => {
            return (
              <React.Fragment key={index}>{workOrderNote.note}</React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
