import OauthPopup from "react-oauth-popup";

import { useQuickBooksCallback } from "../../../hooks/quick-books";
import { Link } from "@mui/material";

interface Props {
  authorizationUrl: string;
  clientId: string;
  redirectUri: string;
  scope: string;
}

const OAuthPopup = (props: Props) => {
  const { authorizationUrl, clientId, redirectUri, scope } = props;
  const { mutateAsync: quickBooksCallback } = useQuickBooksCallback();
  const url = `${authorizationUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=somestatev3`;

  const onCode = (code: string, params: URLSearchParams) => {
    console.log("Received Auth Token", code);
    console.log("code", code);
    quickBooksCallback(code);
  };
  const onClose = () => console.log("closed!");

  return (
    <OauthPopup
      url={url}
      onCode={onCode}
      onClose={onClose}
      title="Sign Into QuickBooks"
      width={500}
      height={500}
    >
      <Link>Sign Into QuickBooks</Link>
    </OauthPopup>
  );
};

export default OAuthPopup;
