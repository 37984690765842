import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Address, Customer } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchAddress = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (address: Address) =>
      patch({
        route: "addresses",
        id: address.id ?? "",
        entity: address,
      }),
    {
      onSuccess: (address, variables) => {
        enqueueSnackbar("Address Updated", { variant: "success" });

        queryClient.setQueryData(
          [RQ.CUSTOMERS, variables.customerId],
          (customer: Customer | undefined) => {
            if (!customer) {
              return {} as Customer;
            }
            customer.addresses = patchEntity<Address>(
              customer.addresses,
              address,
              "id"
            );
            return customer;
          }
        );

        queryClient.setQueryData(
          [RQ.ADDRESSES, variables.customerId],
          (addresses: Address[] | undefined) =>
            !addresses ? [] : patchEntity<Address>(addresses, address, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Address Updating Error", { variant: "error" });
      },
    }
  );
};
