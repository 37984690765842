import {
  Button,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ModalContainer } from "../../../ui";
import { FormSwitch, FormTextField } from "../../../form-components";
import { useState } from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import SettingsIcon from "@mui/icons-material/Settings";

interface Props<K extends FieldValues> {
  control: Control<K, any>;
}

export const InvoiceSettingsModal = <K extends FieldValues>(
  props: Props<K>
) => {
  const { control } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        endIcon={<SettingsIcon />}
      >
        Invoice Settings
      </Button>
      <ModalContainer open={open} handleClose={() => setOpen(false)}>
        <Stack spacing={2}>
          <Typography variant="h6">Interest Settings</Typography>
          <Paper sx={{ p: 1, mb: 1 }}>
            <Stack spacing={2}>
              <FormSwitch
                name={"invoice.waveInterest" as Path<K>}
                label="Wave Interest"
                control={control}
              />
              <FormTextField
                name={"invoice.interestDollars" as Path<K>}
                label="Interest Dollars"
                control={control}
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <FormTextField
                name={"invoice.interestRate" as Path<K>}
                label="Interest Rate"
                control={control}
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Paper>
          <Typography variant="h6">Is This a Write Off?</Typography>
          <Paper sx={{ p: 1, mb: 1 }}>
            <FormSwitch
              name={"invoice.writeOff" as Path<K>}
              label="Write Off"
              control={control}
            />
          </Paper>
        </Stack>
      </ModalContainer>
    </>
  );
};
