import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { CompanyDocument } from "../../../models";
import { companyDocumentSchema as schema } from "./company-document.schema";
import {
  useAddCompanyDocument,
  usePatchCompanyDocument,
} from "../../../hooks/company-document";
import { FormContainer } from "../../layout";
import {
  FormChipsInput,
  FormFileUpload,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import {
  doesExtensionExist,
  getFileExtension,
  getFileNameFromPath,
} from "../../../utils";

interface Props {
  companyDocument?: CompanyDocument;
  onSubmitted?: (companyDocument: CompanyDocument) => void;
}

export const CompanyDocumentForm = (props: Props) => {
  const { companyDocument, onSubmitted } = props;
  const [fileExtension, setFileExtension] = useState("");
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createCompanyDocument } = useAddCompanyDocument();
  const { mutateAsync: patchCompanyDocument } = usePatchCompanyDocument();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { defaultValues },
  } = useForm<CompanyDocument>({
    resolver: yupResolver(schema),
    defaultValues: companyDocument,
  });

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      type === "change" && setChangeDetection(!isEqual(value, defaultValues));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (companyDocument) => {
        if (doesExtensionExist(companyDocument.name)) {
          companyDocument.name = companyDocument.name + fileExtension;
        }
        companyDocument = companyDocument.id
          ? await patchCompanyDocument(companyDocument)
          : await createCompanyDocument(companyDocument);
        onSubmitted && onSubmitted(companyDocument);
        setChangeDetection(false);
      })}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormFileUpload
          name="file"
          label=""
          control={control}
          type="file"
          required
          onInputChanged={(value) => {
            if (typeof value === "string") {
              setFileExtension(getFileExtension(value));
              setValue("name", getFileNameFromPath(value));
            }
          }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormTextField name="name" label="Name" control={control} required />
        <FormTextField name="note" label="Note" control={control} />
        <FormSubmitButton />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <FormChipsInput
          name="companyDocumentTags"
          label="Company Document Tags"
          valueKey="tag"
          control={control}
        />

        {/* <Autocomplete
          multiple
          id="tags-filled"
          options={[{ tag: "abc", id: 1 }].map((option) => option.tag)}
          // defaultValue={[{ id: 1, tag: "qwert" }]}
          onChange={(event, value) => console.log(event, value)}
          freeSolo
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="freeSolo"
              placeholder="Favorites"
            />
          )}
        /> */}
      </Stack>
    </FormContainer>
  );
};
