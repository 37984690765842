import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { memo, useEffect, useState } from "react";
import { add } from "date-fns";
import { isEqual } from "lodash";

import { Schedule, ScheduleType, WorkOrder } from "../../../models";
import { scheduleSchema as schema } from "../schemas";
import { useAddSchedule, usePatchSchedule } from "../../../hooks/schedule";
import {
  useScheduleType,
  useScheduleTypes,
} from "../../../hooks/schedule-type";
import { useEmployee, useEmployees } from "../../../hooks/employee";
import { FormContainer } from "../../layout";
import {
  FormCheckBoxGroup,
  FormDatePicker,
  FormDateTimePicker,
  FormSelect,
  FormSubmitButton,
} from "../../form-components";
import { AutoSearch, LoadingSpinner } from "../../ui";
import { useRepeatDays } from "../../../hooks/repeat-day";
import { useWorkOrdersSearch } from "../../../hooks/work-order";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { FormAutocomplete } from "../../form-components";

interface Props {
  schedule?: Schedule;
  workOrderOnly?: boolean;
  onSubmitted?: () => void;
}

export const ScheduleForm = memo((props: Props) => {
  const { schedule, workOrderOnly, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const [selectedScheduleType, setSelectedScheduleType] = useState<
    ScheduleType | undefined
  >(schedule?.scheduleType);
  const { mutateAsync: createSchedule } = useAddSchedule();
  const { mutateAsync: patchSchedule } = usePatchSchedule();
  const { data: employee, isLoading: isLoadingEmployee } = useEmployee(
    schedule?.employeeId
  );
  const { data: scheduleType } = useScheduleType(schedule?.scheduleTypeId);
  const {
    data: scheduleTypes,
    isLoading: isLoadingScheduleTypes,
    isError: isErrorScheduleTypes,
    isIdle: isIdleScheduleTypes,
    error,
  } = useScheduleTypes();
  const { data: allEmployees } = useEmployees();
  const { data: repeatDays } = useRepeatDays();

  const {
    setValue,
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { isSubmitting, defaultValues },
  } = useForm<Omit<Schedule, "workOrder">>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({
      ...schedule,
      workOrderId: schedule?.workOrder?.id,
      scheduleTypeId: schedule?.scheduleType?.id,
      repeatDayIds:
        schedule?.repeatDays?.map((repeatDay) => repeatDay.id) ?? [],
    }),
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, defaultValues, setChangeDetection]);

  useEffect(() => {
    if (employee || schedule?.employee) {
      schedule && setValue("employeeId", employee?.id ?? schedule.employee.id);
    }
  }, [schedule, employee, setValue]);

  if (isLoadingEmployee || isLoadingScheduleTypes || isIdleScheduleTypes) {
    return <LoadingSpinner />;
  }

  if (isErrorScheduleTypes) {
    return <h2>{error.message}</h2>;
  }

  return (
    <FormContainer
      onSubmit={handleSubmit(async (schedule) => {
        schedule.id
          ? await patchSchedule(schedule)
          : await createSchedule(schedule);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="column" spacing={2}>
        {selectedScheduleType && selectedScheduleType.requiresWorkOrder && (
          <AutoSearch
            label="Work Order Search..."
            keys={[
              "workOrderNumber",
              "customer.firstName",
              "customer.lastName",
            ]}
            onOptionSelected={(workOrder: WorkOrder | null) =>
              workOrder && setValue("workOrderId", workOrder.id)
            }
            initialValue={schedule?.workOrder ?? undefined}
            searchFunction={useWorkOrdersSearch}
            separator="|"
            isDisabled={workOrderOnly ? true : false}
          />
        )}
        <FormAutocomplete
          name="employeeId"
          label="Employee"
          valueKey="id"
          options={allEmployees ?? []}
          control={control}
          loading={false}
          labelKeys={["firstName", "lastName"]}
          defaultValue={schedule?.employee ?? employee}
        />
        <FormSelect
          name="scheduleTypeId"
          label="Schedule Type"
          labelKeys={["name"]}
          valueKey="id"
          data={
            workOrderOnly
              ? scheduleTypes.filter((s) => s.requiresWorkOrder)
              : scheduleTypes
          }
          control={control}
          defaultValue={schedule?.scheduleType ?? scheduleType}
          onSelected={(scheduleType) => setSelectedScheduleType(scheduleType)}
        />

        {/* <FormTimePicker name="startDate" label="Start Time" control={control} /> */}

        {selectedScheduleType && selectedScheduleType.allDay ? (
          <FormDatePicker
            name="startDate"
            label="Start Date"
            trigger={trigger}
            onSelected={(date) => setValue("endDate", add(date, { days: 1 }))}
            control={control}
          />
        ) : (
          <FormDateTimePicker
            name="startDate"
            label="Start Date"
            trigger={trigger}
            onSelected={(date) => setValue("endDate", add(date, { hours: 2 }))}
            control={control}
            minTime={new Date(0, 0, 0, 7)}
            maxTime={new Date(0, 0, 0, 20, 0)}
          />
        )}

        {selectedScheduleType && selectedScheduleType.allDay ? (
          <FormDatePicker
            control={control}
            name="endDate"
            label="End Date"
            trigger={trigger}
          />
        ) : (
          <FormDateTimePicker
            name="endDate"
            label="End Date"
            trigger={trigger}
            control={control}
            minTime={new Date(0, 0, 0, 7)}
            maxTime={new Date(0, 0, 0, 20, 0)}
          />
        )}
        <FormCheckBoxGroup
          name="repeatDayIds"
          label="Repeats"
          options={repeatDays ?? []}
          labelKey="name"
          valueKey="id"
          control={control}
          direction="row"
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
});
