import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { isIsoDate, uncamelize } from "../../../utils";
import { format, isValid, parseISO } from "date-fns";
import { RecursiveKeyof } from "../../../types";

interface Props<T extends object> {
  entity: T;
  ignore?: RecursiveKeyof<T>[];
  displayEmpties?: boolean;
  title?: string;
  density?: number;
  level?: number;
}

const getIgnores = (level: number = 1, ignores: string[]) => {
  let newIgnore: string[] = [];
  ignores.forEach((identifier) => {
    const splitIdentifier = identifier.split(".");
    if (splitIdentifier.length === level) {
      newIgnore.push(splitIdentifier[level - 1]);
    }
  });
  return newIgnore;
};

export const DisplayInfo = <T extends object>(props: Props<T>) => {
  const { ignore, displayEmpties, title, density } = props;
  let { entity, level } = props;
  let copiedEntity = Object.assign({}, entity);
  let keys = Object.keys(copiedEntity) as (keyof T)[];
  level = level ? level + 1 : 1;

  if (ignore) {
    keys = keys.filter(
      (key) => !getIgnores(level, ignore).includes(key as string)
    );
  }

  if (!displayEmpties) {
    keys = keys.filter((key) => copiedEntity[key as keyof object]);
  }

  // Format Dates that come in as strings
  keys.forEach((key: keyof T) => {
    const value: any = copiedEntity[key];
    if (value instanceof Date) {
      copiedEntity[key] = format(value, "MMMM do, yyyy H:mma") as T[keyof T];
    } else {
      if (typeof value === "string" && isIsoDate(value)) {
        const parsedDate = parseISO(copiedEntity[key as keyof object]);
        if (isValid(parsedDate)) {
          copiedEntity[key] = format(
            new Date(value),
            "MMMM do, yyyy H:mma"
          ) as T[keyof T];
        }
      }
    }
  });

  // TODO slice array into partitions
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={density ? density : 1}>
        {keys.map((key: keyof T, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <strong>{uncamelize(key as string, " ")}</strong>
            </Grid>
            <Grid item xs={9}>
              {typeof copiedEntity[key as keyof object] === "object" &&
              copiedEntity[key as keyof object] ? (
                <DisplayInfo
                  entity={copiedEntity[key as keyof object]}
                  ignore={ignore}
                  level={level}
                />
              ) : (
                <>{copiedEntity[key as keyof object]}</>
              )}
            </Grid>
            <Grid item xs={12}>
              {typeof copiedEntity[key as keyof object] === "object" ||
              index + 1 === keys.length ? null : (
                <Divider />
              )}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};
