import { useCallback } from "react";
import type { unstable_BlockerFunction as BlockerFunction } from "react-router-dom";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

interface Props {
  changeDetection: () => boolean;
}

export const useShouldBlock = (props: Props) => {
  const { changeDetection } = props;

  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (
        changeDetection() === true &&
        currentLocation.pathname !== nextLocation.pathname
      ) {
        if (window.confirm("Unsaved Changes Are you Sure You Want To Leave?")) {
          blocker && blocker.proceed?.();
        } else {
          return true;
        }
      }
      return false;
    },
    [changeDetection]
  );

  let blocker = useBlocker(shouldBlock);
};
