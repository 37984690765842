import { useQuery } from "react-query";

import { ScheduleType } from "../../models";
import { getSingle } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useScheduleType = (id?: string) => {
  return useQuery<ScheduleType, Error>(
    [RQ.SCHEDULE_TYPES, id],
    () => getSingle<ScheduleType>(`schedule-types/${id}`),
    {
      enabled: Boolean(id),
    }
  );
};
