import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchWorkOrderDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderDocument: Partial<WorkOrderDocument>) =>
      patch({
        route: `work-orders/${workOrderDocument.workOrderId}/documents`,
        id: workOrderDocument.id ?? "",
        entity: workOrderDocument,
      }),
    {
      onSuccess: (workOrderDocument, varaibles) => {
        enqueueSnackbar("Work Order Document Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.WORK_ORDER_DOCUMENTS, varaibles.workOrderId],
          (workOrderDocuments: WorkOrderDocument[] | undefined) =>
            !workOrderDocuments
              ? []
              : patchEntity<WorkOrderDocument>(
                  workOrderDocuments,
                  workOrderDocument,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Document Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
