import * as yup from "yup";
import { customerStatementDetailSchema } from "./customer-statement-detail.schema";

export const customerStatementSchema = yup.object().shape({
  sent: yup.boolean().default(false).label("Sent"),
  customerStatementDetails: yup
    .array()
    .of(customerStatementDetailSchema)
    .label("Customer Statement Detail"),
});
