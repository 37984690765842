import { useQuery } from "react-query";

import { Invoice } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";
import { orderBy } from "lodash";

export const useInvoice = (id: string) => {
  return useQuery<Invoice, Error>(
    [RQ.INVOICE, id],
    () => getSingle(`invoices/${id}`),
    {
      enabled: Boolean(id),
      select(invoice) {
        if (invoice) {
          invoice.invoiceLineItems = orderBy(
            invoice.invoiceLineItems,
            ["inventoryItemId"],
            "desc"
          );
          invoice.invoiceLineItems.forEach((invoiceLineItem) => {
            invoiceLineItem.inventoryItemId = invoiceLineItem.inventoryItem.id;
          });
        }
        return invoice;
      },
    }
  );
};
