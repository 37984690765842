import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import { ReportType } from "../../../models";
import { reportTypeSchema as schema } from "../schemas";
import {
  useAddReportType,
  usePatchReportType,
} from "../../../hooks/report-type";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";

interface Props {
  reportType?: ReportType;
  onSubmitted?: (reportType: ReportType) => void;
}

export const ReportTypeForm = (props: Props) => {
  const { reportType, onSubmitted } = props;
  const { mutate: createReportType } = useAddReportType();
  const { mutate: patchReportType } = usePatchReportType();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm<ReportType>({
    resolver: yupResolver(schema),
    defaultValues: reportType,
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((reportType) => {
        reportType.id
          ? patchReportType(reportType)
          : createReportType(reportType);
        onSubmitted && onSubmitted(reportType);
      })}
    >
      <Stack direction="column" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
        <FormTextField
          name="description"
          label="Description"
          control={control}
          sx={{ width: 600 }}
        />
        <FormTextField name="url" label="URL" control={control} required />
      </Stack>
      <FormSubmitButton disabled={isSubmitting || isSubmitSuccessful} />
    </FormContainer>
  );
};
