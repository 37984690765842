import { Proposal } from "../../../../models";

export const calculateProposal = (
  proposal: Proposal,
  HST: number
): Proposal => {
  let subtotal = 0;

  proposal.proposalLineItems.forEach((proposalLineItem) => {
    const inventoryItem = proposalLineItem.inventoryItem;

    //  set price
    proposalLineItem.price = proposalLineItem.price ?? inventoryItem.price;

    // set inventory item id
    proposalLineItem.inventoryItemId = inventoryItem.id;

    // add to subtotal
    if (proposalLineItem.addToTotal) {
      subtotal += Number(proposalLineItem.price);
    }
  });

  // set sub total
  proposal.subtotal = subtotal;

  // set grand total
  proposal.grandTotal = subtotal * (HST / 100 + 1);

  // set hst total
  proposal.hstTotal = proposal.grandTotal - subtotal;

  return proposal;
};
