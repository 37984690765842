import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerEquipment } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteCustomerEquipment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("customer-equipment", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Customer Equipment Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.CUSTOMER_EQUIPMENTS,
        (customerEquipments: CustomerEquipment[] | undefined) => {
          return !customerEquipments
            ? []
            : deleteEntity<CustomerEquipment>(customerEquipments, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Customer Equipment Deleting Error", {
        variant: "error",
      });
    },
  });
};
