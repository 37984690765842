import { useQuery } from "react-query";

import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useCustomerBalance = (customerId?: string) => {
  return useQuery<number, Error>(
    [RQ.CUSTOMERS, customerId, "balance"],
    () => getSingle<number>(`customers/${customerId}/balance`),
    {
      enabled: Boolean(customerId),
    }
  );
};
