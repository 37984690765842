import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { isEqual } from "lodash";

import { Box, BoxProps } from "@mui/material";
import { entityStringLabel } from "../../../utils";
import { RecursiveKeyof } from "../../../types";

interface Props<T> extends BoxProps {
  label: string;
  data?: T[];
  keys: RecursiveKeyof<T>[];
  separator?: string;
  error?: boolean;
  helperText?: string;
  onOptionSelected: (entity: T[] | null) => void;
  initialValue?: T[];
  defaultSearchTerm?: string;
}

export function AutoSearchMultipleAlt<T>(props: Props<T>) {
  const {
    data,
    label,
    keys,
    error,
    helperText,
    onOptionSelected,
    separator,
    initialValue,
    defaultSearchTerm,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <Autocomplete
        multiple
        clearOnBlur
        id={label}
        fullWidth
        getOptionLabel={(option) => entityStringLabel(option, keys, separator)}
        onChange={(_, value) => value && onOptionSelected(value)}
        options={data ?? []}
        loading={false}
        value={initialValue}
        isOptionEqualToValue={(option, value) => isEqual(option, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
