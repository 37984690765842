import { StandardTextFieldProps, TextField } from "@mui/material";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface Props<K extends FieldValues> extends StandardTextFieldProps {
  name: Path<K>;
  label: string;
  control: Control<K, any>;
  onInputChanged?: (data: string | number) => void;
}

export const FormFileUpload = <K extends FieldValues>(props: Props<K>) => {
  const { name, label, control, onInputChanged, ...rest } = props;
  const { field, fieldState } = useController({ name, control });
  return (
    <TextField
      label={label}
      size="small"
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      onChange={(value) => {
        if (value.target.type === "file") {
          const file = value.target as HTMLInputElement;
          if (file && file.files) {
            if (rest?.inputProps?.multiple) {
              field.onChange(file.files);
            } else {
              field.onChange(file.files[0]);
            }
          }
        }
        onInputChanged && onInputChanged(value.target.value);
      }}
      {...rest}
    />
  );
};
