import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";

import { PageHeader } from "../../components/layout";
import { LoadingSpinner } from "../../components/ui";
import { useTaskDurations } from "../../hooks/task-duration";
import { TaskDurationDataGrid } from "../../components/grids";

export const TaskDurationsPage = () => {
  const {
    data: taskDurations,
    isLoading,
    isIdle,
    isError,
    error,
  } = useTaskDurations();
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title="Task Durations"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <TaskDurationDataGrid rows={taskDurations} loading={false} autoHeight />
    </>
  );
};
