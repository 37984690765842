import { GridColDef } from "@mui/x-data-grid";

export const scheduleTypeColumns: GridColDef[] = [
  { field: "name", headerName: "Name", flex: 1 },
  {
    field: "color",
    headerName: "Color",
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          backgroundColor: `${params.row.color}`,
          width: 100,
          height: 25,
          border: "2px solid white",
          borderRadius: 7,
        }}
      />
    ),
  },
  { field: "allDay", headerName: "All Day Event", flex: 1 },
  {
    field: "displayInBackground",
    headerName: "Display In Background",
    flex: 1,
  },
  {
    field: "requiresWorkOrder",
    headerName: "Requires Work Order",
    flex: 1,
  },
];
