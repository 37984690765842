import { useQuery } from "react-query";

import { EmployeeType } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useEmployeeTypes = () => {
  return useQuery<EmployeeType[], Error>(RQ.EMPLOYEE_TYPES, async () =>
    getAll<EmployeeType>("employee-types")
  );
};
