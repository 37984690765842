import { useState } from "react";
import { Paper } from "@mui/material";

import { Vendor } from "../../models";
import {
  useDeleteVendor,
  useUploadVendors,
  useVendors,
} from "../../hooks/vendor";
import { VendorForm } from "../../components/forms";
import { GenericDataGrid, vendorColumns } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  FileUploadModal,
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";

export const VendorsPage = () => {
  const { data: vendors, isLoading, isIdle, isError, error } = useVendors();
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteVendor } = useDeleteVendor();
  const [vendor, setVendor] = useState<Vendor>();
  const { mutate: uploadVendors } = useUploadVendors();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (vendor?: Vendor) => {
    vendor ? setVendor(vendor) : setVendor(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader title="Vendors" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <FileUploadModal
          buttonTitle={"Upload Vendors"}
          subTitle="* Records with an existing name will update the record"
          sampleFileUrl="sample-files/vendors.csv"
          onSubmit={(file: File) => uploadVendors(file)}
        />
      </Paper>
      <GenericDataGrid
        rows={vendors}
        columns={vendorColumns}
        loading={isLoading}
        onDelete={(vendor) => deleteVendor(vendor.id)}
        onEdit={onEditCreate}
        onDoubleClick={onEditCreate}
        onCreate={onEditCreate}
        createTitle="Create Vendor"
        deleteTitle="Would you like to delete this Vendor?"
        ignoreFields={["id", "createdTimestamp", "modifiedTimestamp"]}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${vendor ? "Edit" : "Create"} Vendor`}
        >
          <VendorForm onSubmitted={() => setOpenModal(false)} vendor={vendor} />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
    </>
  );
};
