import { useQuery } from "react-query";
import { getSingle } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { InvoiceStatement } from "../../models";

export const useInvoiceStatementByInvoice = (invoiceId: string) => {
  return useQuery<InvoiceStatement, Error>(RQ.INVOICE_STATEMENT, async () =>
    getSingle<InvoiceStatement>(`invoice-statements/invoice/${invoiceId}`)
  );
};
