import { memo, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Button, Paper, Stack } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

import { InventoryItem } from "../../models";
import { InventoryItemForm } from "../../components/forms";
import {
  GenericDataGrid,
  inventoryItemColumns as columns,
} from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  ChipDropDown,
  EmailModal,
  FileUploadModal,
  LoadingSpinner,
  ModalContainer,
  ModalContainerChangeDetection,
} from "../../components/ui";
import {
  useAddInventoryItem,
  useDeleteInventoryItem,
  useInventoryItemsFilter,
  useUploadInventoryItems,
} from "../../hooks/inventory-item";
import { useInventoryTypes } from "../../hooks/inventory-type";
import { useEmployeeProfile } from "../../hooks/employee";
import { objectAddOrDelete } from "../../utils";

export const InventoryItemsPage = memo(() => {
  const [page, setPage] = useState(0);
  const [selectedInventoryTypes, setSelectedInventoryTypes] =
    useState<string[]>();
  const { data: employee } = useEmployeeProfile();
  const {
    data: inventoryItems,
    isLoading,
    isIdle,
    isError,
    error,
    refetch: getInventoryItems,
  } = useInventoryItemsFilter(page, selectedInventoryTypes);
  const { data: inventoryTypes } = useInventoryTypes();
  const [openModal, setOpenModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [inventoryItem, setInventoryItem] = useState<InventoryItem>();
  const [selectedInventoryItems, setSelectedInventoryItems] = useState<
    InventoryItem[]
  >([]);
  const { mutate: deleteInventoryItem } = useDeleteInventoryItem();
  const { mutateAsync: createInventoryItem } = useAddInventoryItem();
  const { mutate: uploadInventoryItems } = useUploadInventoryItems();

  useEffect(() => {
    getInventoryItems();
  }, [page, selectedInventoryTypes, getInventoryItems]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error?.message}</h2>;
  }

  const onEditCreate = (inventoryItem?: InventoryItem) => {
    inventoryItem
      ? setInventoryItem(inventoryItem)
      : setInventoryItem(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader title="Inventory" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <Stack direction="row" spacing={2}>
          <ChipDropDown
            data={inventoryTypes ?? []}
            label="Inventory Types"
            labelKey={"name"}
            valueKey={"id"}
            onOptionChange={(InventoryTypesChanged) => {
              if (InventoryTypesChanged) {
                const ids = InventoryTypesChanged.map((y) => y.id);
                setSelectedInventoryTypes(ids);
              }
            }}
          />
          <Button
            variant="outlined"
            endIcon={<EmailIcon />}
            onClick={() => setOpenEmailModal(true)}
          >
            Email Vendor
          </Button>
          <FileUploadModal
            buttonTitle={"Upload Inventory Items"}
            subTitle="* Records with an existing name will update the record"
            sampleFileUrl="sample-files/inventory.csv"
            onSubmit={(file: File) => uploadInventoryItems(file)}
          />
        </Stack>
      </Paper>
      <GenericDataGrid
        rows={inventoryItems}
        columns={columns}
        loading={false}
        onDelete={(inventoryItem) => deleteInventoryItem(inventoryItem.id)}
        onEdit={onEditCreate}
        onDoubleClick={onEditCreate}
        onCreate={onEditCreate}
        onChecked={(row, checked) => {
          setSelectedInventoryItems((current) =>
            objectAddOrDelete(current, row, checked, "id")
          );
        }}
        onPageChange={(value) => setPage(value)}
        onDuplicate={async (inventoryItem: InventoryItem) => {
          const { id, ...newInventoryItem } = inventoryItem;
          await createInventoryItem({
            ...newInventoryItem,
            name: newInventoryItem.name + "-COPY",
            inventoryTypeId: inventoryItem.inventoryType.id,
          });
        }}
        createTitle="Create Inventory Item"
        deleteTitle="Would you like to delete this Create Inventory Item"
        ignoreFields={[
          "id",
          "createdTimestamp",
          "modifiedTimestamp",
          "inventoryType.id",
          "inventoryType.createdTimestamp",
          "inventoryType.modifiedTimestamp",
          "inventoryTypeId",
          "inventoryType.invoiceCategory",
        ]}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "inventoryType", sort: "asc" }],
          },
        }}
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${inventoryItem ? "Edit" : "Create"} Inventory Item`}
        >
          <InventoryItemForm
            inventoryItem={inventoryItem}
            onSubmitted={() => setOpenModal(false)}
          />
        </ModalContainerChangeDetection>
      </GenericDataGrid>
      <ModalContainer
        open={openEmailModal}
        title="Prepare Email"
        handleClose={() => setOpenEmailModal(false)}
      >
        <EmailModal
          onSubmit={(emailPayload) => console.log(emailPayload)}
          defaultBody={`Hello would like to order the following \n\n${selectedInventoryItems.reduce(
            (acc, x) =>
              acc +
              `- #QUANTITY - ${x.name} ${x.sku ? ` | SKU: ${x.sku}` : ""} \n`,
            ""
          )} \n\n${employee?.signOff ?? ""}`}
        />
      </ModalContainer>
      <Outlet />
    </>
  );
});
