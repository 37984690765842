import * as yup from "yup";

export const customerSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label("First Name / Company")
    .default("")
    .transform((value) => value ?? ""),
  lastName: yup
    .string()
    .label("Last Name")
    .default("")
    .transform((value) => value ?? ""),
  email: yup
    .string()
    .email()
    .label("Email")
    .default("")
    .transform((value) => value ?? ""),
  phone: yup
    .string()
    .label("Phone")
    .default("")
    .required()
    .transform((value) => value ?? ""),
  interestTerm: yup.number().label("Phone").default(0).required(),
  taxRate: yup.number().label("Tax Rate").default(0),
  inactive: yup.boolean().label("Inactive").default(false),
  customerTierId: yup
    .string()
    .label("Customer Tier")
    .default("")
    .required()
    .transform((value) => value ?? ""),
  referralType: yup
    .string()
    .label("Referral Type")
    .default("")
    .transform((value) => value ?? ""),
});
