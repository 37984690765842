import { useQuery } from "react-query";

import { CustomerPayment } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useCustomerPaymentsByFilter = (
  customerId?: string,
  workOrderId?: string
) => {
  let params = new URLSearchParams();
  customerId && params.append("customerId", customerId);
  workOrderId && params.append("workOrderId", workOrderId);
  return useQuery<CustomerPayment[], Error>(
    [RQ.CUSTOMER_PAYMENTS, customerId],
    () => getAll(`customer-payments/filter?${params}`),
    {
      enabled: Boolean(customerId || workOrderId),
    }
  );
};
