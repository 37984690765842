import { MenuItem } from "../globals/props";

export const hasNavigationChildren = (item: MenuItem) => {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
};
