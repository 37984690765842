import { Alert } from "@mui/material";

interface Props {
  limit: number;
}

export const DispatchFeeAlert = (props: Props) => {
  const { limit } = props;
  return (
    <Alert severity="warning" sx={{ mb: 1 }}>
      The invoice is less than ${limit} and it is recommended that a dispatch
      fee is applied
    </Alert>
  );
};
