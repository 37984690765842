import { useQuery } from "react-query";
import { downloadFile } from "../../api/generic-api";
import { CustomerDocument } from "../../models";

export const useDownloadCustomerDocument = (
  customerDocument?: CustomerDocument,
  download: boolean = false
) => {
  return useQuery<any, Error>(
    [],
    async () =>
      downloadFile(
        `documents/customer-document/download/${customerDocument?.id}`,
        customerDocument?.name ?? "file"
      ),
    {
      enabled: Boolean(download && customerDocument),
    }
  );
};
