import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { Invoice } from "../../../models";
import { Chip } from "@mui/material";

export const invoiceColumns: GridColDef<Invoice>[] = [
  { field: "workOrderNumber", headerName: "Work Order Number", flex: 1 },
  { field: "workOrderState", headerName: "Work Order State", flex: 1 },
  {
    field: "customerName",
    headerName: "Customer",
    flex: 1,
    valueGetter: (params) =>
      `${params.row.customerFirstName}  ${params.row?.customerLastName ?? ""}`,
  },
  { field: "grandTotal", headerName: "Grand Total", flex: 1 },
  { field: "subtotal", headerName: "Subtotal", flex: 1, hide: true },
  { field: "profitMargin", headerName: "Profit Margin", flex: 1, hide: true },
  { field: "totalPrice", headerName: "Total Price", flex: 1, hide: true },
  { field: "totalCost", headerName: "Total Cost", flex: 1, hide: true },
  { field: "totalProfit", headerName: "Total Profit", flex: 1, hide: true },
  {
    field: "writeOff",
    headerName: "Write Off",
    flex: 1,
    renderCell: (params) => (params.row.writeOff ? <CheckIcon /> : ""),
  },
  { field: "amountPaid", headerName: "Amount Paid", flex: 1 },
  { field: "amountOwing", headerName: "Amount Owing", flex: 1 },
  {
    field: "paid",
    headerName: "Paid",
    flex: 1,
    renderCell: (params) =>
      params.row.paid ? (
        <Chip
          avatar={
            <AttachMoneyIcon fontSize="small" style={{ color: "#00a152" }} />
          }
          label="Paid"
          size="small"
          style={{ color: "#00a152" }}
        />
      ) : (
        ""
      ),
  },
  {
    field: "sent",
    headerName: "Sent",
    flex: 1,
    renderCell: (params) =>
      params.row.sent ? (
        <Chip
          avatar={<SendIcon fontSize="small" style={{ color: "#ffc107" }} />}
          label="Sent"
          size="small"
          style={{ color: "#ffc107" }}
        />
      ) : (
        ""
      ),
  },
  {
    field: "dueDate",
    headerName: "Due",
    flex: 1,
    valueGetter: (params) => format(new Date(params.row.dueDate), "yyyy-MM-dd"),
  },
  { field: "quickbooksInvoiceId", headerName: "QB ID", flex: 1 },
];
