import { Paper, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EmployeeEmployeeTypeForm } from "../../components/forms";
import {
  EmployeeTimesheetDataGrid,
  ScheduleDataGrid,
} from "../../components/grids";
import {
  BasicAccordion,
  ChipDropDown,
  LoadingSpinner,
} from "../../components/ui";
import { useEmployee } from "../../hooks/employee";
import { CalendarDateRange } from "../../models";
import { useSchedulesByDateRange } from "../../hooks/schedule";
import { useScheduleTypes } from "../../hooks/schedule-type";
import { DatePicker } from "@mui/x-date-pickers";
import { format, sub } from "date-fns";
import { useEmployeeTimesheetsByFilter } from "../../hooks/employee-timesheet";

export const EmployeePage = () => {
  const [scheduleTypeGuids, setScheduleTypeGuids] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<CalendarDateRange>({
    startDate: sub(new Date(), { months: 1 }),
    endDate: new Date(),
  });
  const { id } = useParams<{ id: string }>();
  const { data: employee, isLoading, isIdle, isError, error } = useEmployee(id);
  const { data: schedules, refetch: getSchedules } = useSchedulesByDateRange(
    dateRange,
    id,
    scheduleTypeGuids
  );
  const { data: scheduleTypes } = useScheduleTypes();
  const {
    data: employeeTimesheets,
    isLoading: isLoadingEmployeeTimesheets,
    refetch: getEmployeeTimesheets,
  } = useEmployeeTimesheetsByFilter(undefined, id);
  const navigate = useNavigate();

  useEffect(() => {
    dateRange.startDate && getSchedules();
  }, [dateRange, scheduleTypeGuids, id, getSchedules]);

  useEffect(() => {
    id && getEmployeeTimesheets();
  }, [id, getEmployeeTimesheets]);

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <Paper sx={{ p: 1, mb: 1 }}>
        <EmployeeEmployeeTypeForm employee={employee} />
      </Paper>
      <BasicAccordion
        title={`Employee Timesheets - ${employeeTimesheets?.length}`}
      >
        <EmployeeTimesheetDataGrid
          rows={employeeTimesheets ?? []}
          loading={isLoadingEmployeeTimesheets}
          employee={employee}
          autoHeight
        />
      </BasicAccordion>
      <BasicAccordion title={`Schedules - ${schedules?.length}`}>
        <Paper sx={{ p: 1, mb: 1 }}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Start Date"
              inputFormat="LLL dd, yyyy"
              disableMaskedInput
              value={dateRange.startDate}
              onChange={(value) =>
                setDateRange({
                  ...dateRange,
                  startDate: value ?? new Date(),
                })
              }
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <DatePicker
              label="End Date"
              inputFormat="LLL dd, yyyy"
              disableMaskedInput
              value={dateRange.endDate}
              onChange={(value) =>
                setDateRange({
                  ...dateRange,
                  endDate: value ?? new Date(),
                })
              }
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <ChipDropDown
              data={scheduleTypes ?? []}
              label="Schedule Types"
              labelKey={"name"}
              valueKey={"id"}
              onOptionChange={(selectedScheduleTypes) =>
                setScheduleTypeGuids(selectedScheduleTypes.map((x) => x.id))
              }
            />
          </Stack>
        </Paper>
        <ScheduleDataGrid
          rows={schedules ?? []}
          loading={isLoading}
          employee={employee}
          onLink={(schedule) => {
            const startDate = format(
              new Date(schedule.startDate as Date),
              "yyyy-MM-dd"
            );
            navigate(`/dispatch?goToDate=${startDate}`);
          }}
        />
      </BasicAccordion>
    </>
  );
};
