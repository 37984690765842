import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useState } from "react";

interface Props {
  onFileSelected: (file: File) => void;
  title?: string;
}

export const FileSelector = (props: Props) => {
  const { onFileSelected, title } = props;
  const [selectedFile, setSelectedFile] = useState<File>();
  return (
    <div>
      <input
        accept="*"
        id="file-upload"
        type="file"
        onChange={(value) => {
          const file = value.target as HTMLInputElement;
          if (file && file.files) {
            if (value.target.type === "file") {
              onFileSelected(file.files[0]);
              setSelectedFile(file.files[0]);
            }
          }
        }}
        style={{ display: "none" }}
      />
      <label htmlFor="file-upload">
        {title}
        <IconButton component="span">
          <AttachFileIcon />
        </IconButton>
        {selectedFile?.name}
      </label>
    </div>
  );
};
