/**
 * Checks list of objects and looks for duplicate
 * @param items array of objects
 * @param key the field to check against
 * @returns boolean
 */
export const containsDuplicates = <T>(items: T[], key: keyof T): boolean => {
  const identifiers = items?.map((item) => item[key]);
  return identifiers?.some(
    (identifier, index) => identifiers.indexOf(identifier) !== index
  );
};
