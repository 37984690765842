import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { ScheduleType } from "../../../models";
import { scheduleTypeSchema as schema } from "../schemas";
import {
  useAddScheduleType,
  usePatchScheduleType,
} from "../../../hooks/schedule-type";
import { FormContainer } from "../../layout";
import {
  FormColorPicker,
  FormSubmitButton,
  FormSwitch,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts";

interface Props {
  scheduleType?: ScheduleType;
  onSubmitted?: (scheduleType: ScheduleType) => void;
}

export const ScheduleTypeForm = (props: Props) => {
  const { scheduleType, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createScheduleType } = useAddScheduleType();
  const { mutate: patchScheduleType } = usePatchScheduleType();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<ScheduleType>({
    resolver: yupResolver(schema),
    defaultValues: scheduleType,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit((scheduleType) => {
        scheduleType.id
          ? patchScheduleType(scheduleType)
          : createScheduleType(scheduleType);
        onSubmitted && onSubmitted(scheduleType);
        setChangeDetection(false);
      })}
    >
      <Stack direction="column" spacing={2}>
        <FormTextField name="name" label="Name" control={control} required />
        <FormColorPicker name="color" label="Color" control={control} />
        <FormSwitch name="allDay" label="All Day Event" control={control} />
        <FormSwitch
          name="displayInBackground"
          label="Display In Background"
          control={control}
        />
        <FormSwitch
          name="requiresWorkOrder"
          label="Requires Work Order"
          control={control}
        />
        <FormSwitch
          name="showInDropdown"
          label="Show In Dropdown"
          control={control}
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
