import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Address, Customer } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddAddress = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (address: Address) =>
      add<Address>(`customers/${address.customerId}/addresses`, address),
    {
      onSuccess: (address, variables) => {
        enqueueSnackbar("Address Added", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMERS, variables.customerId],
          (customer: Customer | undefined) => {
            if (!customer) {
              return {} as Customer;
            }
            customer.addresses = [...(customer.addresses ?? []), address];
            return customer;
          }
        );

        queryClient.setQueryData<Address[]>(
          [RQ.ADDRESSES, variables.customerId],
          (addresses: Address[] | undefined) => {
            return [...(addresses ?? []), address];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Address Adding Error", { variant: "error" });
      },
    }
  );
};
