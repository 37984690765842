import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";

import { Employee } from "../../../models";
import { employeeEmployeeTypeSchema as schema } from "../schemas";
import { useEmployeeTypes } from "../../../hooks/employee-type";
import { FormContainer } from "../../layout";
import { FormCheckBoxGroup, FormSubmitButton } from "../../form-components";

import { usePatchEmployee } from "../../../hooks/employee";

interface Props {
  employee: Employee;
  onSubmitted?: () => void;
}

export const EmployeeEmployeeTypeForm = (props: Props) => {
  const { employee, onSubmitted } = props;
  const { data: employeeTypes } = useEmployeeTypes();
  const { mutate: patchEmployee } = usePatchEmployee();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm<Employee>({
    resolver: yupResolver(schema),
    defaultValues: employee,
  });

  useEffect(() => {
    setValue(
      "employeeTypeIds",
      employee.employeeTypes.map((employeeType) => employeeType.id)
    );
  }, [employee]);

  return (
    <FormContainer
      onSubmit={handleSubmit(async (employee) => {
        patchEmployee(employee);
        onSubmitted && onSubmitted();
      })}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2 }}
      >
        <FormCheckBoxGroup
          name="employeeTypeIds"
          label="Employee Types"
          options={employeeTypes ?? []}
          labelKey="name"
          valueKey="id"
          control={control}
          direction="row"
          required
        />
        <FormSubmitButton disabled={isSubmitting} />
      </Stack>
    </FormContainer>
  );
};
