import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { ScheduleType } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddScheduleType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (scheduleType: ScheduleType) =>
      add<ScheduleType>("schedule-types", scheduleType),
    {
      onSuccess: (scheduleType) => {
        queryClient.setQueryData<ScheduleType[]>(
          RQ.SCHEDULE_TYPES,
          (scheduleTypes: ScheduleType[] | undefined) => {
            enqueueSnackbar("Schedule Type Added", { variant: "success" });
            return [...(scheduleTypes ?? []), scheduleType];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Schedule Type Adding Error", { variant: "error" });
      },
    }
  );
};
