import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { CustomerEquipment } from "../../../models";
import { customerEquipmentSchema as schema } from "./customer-equipment.schema";
import {
  useAddCustomerEquipment,
  usePatchCustomerEquipment,
} from "../../../hooks/customer-equipment";
import { FormContainer } from "../../layout";
import {
  FormDatePicker,
  FormSubmitButton,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts";

interface Props {
  customerEquipment?: CustomerEquipment;
  onSubmitted?: (customerEquipment: CustomerEquipment) => void;
}

export const CustomerEquipmentForm = (props: Props) => {
  const { customerEquipment, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutateAsync: createCustomerEquipment } = useAddCustomerEquipment();
  const { mutateAsync: patchCustomerEquipment } = usePatchCustomerEquipment();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<CustomerEquipment>({
    resolver: yupResolver(schema),
    defaultValues: customerEquipment,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch, setChangeDetection, defaultValues]);

  const onSubmit = async (customerEquipment: CustomerEquipment) => {
    customerEquipment = customerEquipment.id
      ? await patchCustomerEquipment(customerEquipment)
      : await createCustomerEquipment(customerEquipment);
    onSubmitted && onSubmitted(customerEquipment);
    setChangeDetection(false);
  };

  return (
    <FormContainer
      onSubmit={async (event) => {
        if (event) {
          // sometimes not true, e.g. React Native
          if (typeof event.preventDefault === "function") {
            event.preventDefault();
          }
          if (typeof event.stopPropagation === "function") {
            // prevent any outer forms from receiving the event too
            event.stopPropagation();
          }
        }
        return handleSubmit(onSubmit)(event);
      }}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="name"
          label="Equipment Name"
          control={control}
          required
        />
        <FormTextField
          name="description"
          label="Description"
          control={control}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField name="model" label="model" control={control} />
        <FormTextField name="sku" label="SKU" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormTextField
          name="serialNumber"
          label="Equipment Name"
          control={control}
        />
        <FormTextField name="vendor" label="Vendor" control={control} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormDatePicker
          name="warrantyPartsStartDate"
          label="Warranty Parts Start Date"
          control={control}
        />
        <FormDatePicker
          name="warrantyPartsExpiryDate"
          label="Warranty Parts Expiry Date"
          control={control}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormDatePicker
          name="warrantyLabourStartDate"
          label="Warranty Labour Start Date"
          control={control}
        />
        <FormDatePicker
          name="warrantyLabourExpiryDate"
          label="Warranty Labour Expiry Date"
          control={control}
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
