import { useQuery } from "react-query";

import { InventoryItem } from "../../models";
import { getAll } from "../../api/generic-api";
import { RQ } from "../../globals/enums";

export const useInventoryItemsFilter = (
  page: number = 0,
  inventoryTypes?: string[]
) => {
  let params = new URLSearchParams({
    page: page.toString(),
  });
  inventoryTypes &&
    inventoryTypes.length > 0 &&
    params.append("inventoryTypes", inventoryTypes.join(","));
  return useQuery<InventoryItem[], Error>(
    [RQ.INVENTORY_ITEMS],
    async () => getAll(`inventory-items/filter?${params}`),
    {
      enabled: Boolean(page),
    }
  );
};
