export const groupEntities = <T>(
  entities: T[],
  identifier: keyof T
): Array<T[]> => {
  let ids: string[] = [];

  return entities.reduce<Array<T[]>>((accumalator, entity: T) => {
    let objectIndex = ids.indexOf(entity[identifier as keyof object]);

    if (objectIndex !== -1) {
      // exists
      accumalator[objectIndex].push(entity);
    } else {
      // new
      ids.push(entity[identifier as keyof object]);
      accumalator.push([entity]);
    }
    return accumalator;
  }, []);
};
