import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { uploadFile } from "../../api/generic-api";
import { EmailPayload } from "../../models";

export const useSendInvoiceDrawRequest = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (payload: { invoiceDrawRequestId: string; emailPayload: EmailPayload }) =>
      uploadFile<EmailPayload>(
        `emails/invoice-draw-request/${payload.invoiceDrawRequestId}`,
        payload.emailPayload
      ),
    {
      onSuccess: () => {
        enqueueSnackbar("Invoice Draw Request Sent", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Invoice Draw Request Send Error", {
          variant: "error",
        });
      },
    }
  );
};
