import { useCallback, useEffect, useState } from "react";
import { isEmpty, orderBy, sumBy } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { ControllerRenderProps, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Customer,
  InventoryItem,
  Invoice,
  InvoiceLineItem,
  MergeItem,
} from "../../../models";
import { invoiceSchema as schema } from "../schemas";
import { useAddInvoice, usePatchInvoice } from "../../../hooks/invoice";
import { useSearchInventoryItems } from "../../../hooks/inventory-item";
import {
  useWorkOrder,
  useWorkOrderByInvoice,
  useWorkOrdersByQuery,
} from "../../../hooks/work-order";
import { useInvoiceLineItemsByWorkOrder } from "../../../hooks/invoice-line-item";
import { FormContainer } from "../../layout";
import {
  FormAutocomplete,
  FormDatePicker,
  FormSubmitButton,
} from "../../form-components";
import { InvoiceDisplay } from "../../../pages/invoices/InvoiceDisplay";
import { LoadingSpinner, ModalContainerChangeDetection } from "../../ui";
import { InventoryItemForm } from "../inventory-item/InventoryItem.form";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";
import { useShouldBlock } from "../../../globals/callbacks/useShouldBlock";
import {
  calculateInvoice,
  needsDispatchFee,
  hasDuplicateInvoiceLineItems,
} from "./functions";
import {
  AfterHoursAlert,
  DispatchFeeAlert,
  DoNotEditAlert,
  DuplicateItemAlert,
  InvoiceSettingsModal,
} from "./components";
import { getDuplicates } from "../../../utils/get-duplicates";
import { DuplicatesModal } from "../../ui/DuplicatesModal/DuplicatesModal";
import { groupEntities } from "../../../utils/group-entities";
import { APPLICATION_SETTING } from "../../../globals/enums";
import { useAppSettingsContext } from "../../../contexts/AppSettingContext";
import { useCustomerPaymentsByWorkOrder } from "../../../hooks/customer-payment";
import { assignCalculatedValues } from "./functions/assign-calculated-values";
import { InvoiceLineItems } from "./components/InvoiceLineItems";
import { add } from "date-fns";
import { WorkOrderStateModal } from "../../../pages/work-orders/modals";
import { useHasAfterHoursScheduled } from "../../../hooks/schedule";

interface Props {
  invoice?: Invoice;
  customer: Customer;
  mode?: "OVER_THE_COUNTER";
  onSubmitted?: (invoice?: Invoice, navigateToPreview?: boolean) => void;
  onInvoiceChanged?: (invoice: Invoice) => void;
}

export const InvoiceForm = (props: Props) => {
  const { invoice, customer, mode, onSubmitted, onInvoiceChanged } = props;
  const { getSetting } = useAppSettingsContext();
  const HST = Number(getSetting(APPLICATION_SETTING.HST).defaultValue);
  const INVOICE_DISPATCH_LIMIT = Number(
    getSetting(APPLICATION_SETTING.INVOICE_DISPATCH_LIMIT).defaultValue
  );
  const DISPATCH = getSetting(APPLICATION_SETTING.DISPATCH).settingId;
  const [workOrderState, setWorkOrderState] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [calculateAction, setCalculateAction] = useState<boolean>(false);
  const { mutateAsync: createInvoice } = useAddInvoice();
  const { mutateAsync: patchInvoice } = usePatchInvoice();
  const [searchParams] = useSearchParams();
  let workOrderId = searchParams.get("workOrderId");
  const [openInventoryItemModal, setOpenInventoryItemModal] = useState(false);
  const [dispatchFeeAlert, setDispatchFeeAlert] = useState(false);
  const [duplicateItemAlert, setDuplicateItemAlert] = useState(false);
  const [afterHoursAlert, setAfterHoursAlert] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchTermWorkOrder, setSearchTermWorkOrder] = useState<string>("");
  const [navigateToPreview, setNavigateToPreview] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<Array<InvoiceLineItem[]>>([]);
  const [duplicatesModal, setDuplicatesModal] = useState<boolean>(false);
  const {
    data: inventoryItems,
    isLoading: isLoadingInventoryItems,
    isFetching: isFetchingInventoryItems,
    refetch: getInventoryItems,
  } = useSearchInventoryItems(searchTerm);
  const {
    data: workOrders,
    isLoading: isLoadingWorkOrders,
    isFetching: isFetchingWorkOrders,
    refetch: getWorkOrders,
  } = useWorkOrdersByQuery(searchTermWorkOrder);
  const {
    data: workOrderByInvoice,
    isLoading: isLoadingWorkOrderByInvoice,
    isIdle: isIdleWorkOrderByInvoice,
  } = useWorkOrderByInvoice(invoice?.id);
  const { data: workOrder } = useWorkOrder(workOrderId || invoice?.workOrderId);
  const { data: customerPayments } = useCustomerPaymentsByWorkOrder(
    workOrderId || invoice?.workOrderId
  );
  const { data: invoiceLineItemsFromProposal, refetch: getInvoiceLineItems } =
    useInvoiceLineItemsByWorkOrder(workOrder?.id ?? "");
  const { data: hasAfterHoursScheduled } = useHasAfterHoursScheduled(
    workOrder?.id ?? ""
  );
  const navigate = useNavigate();
  const { setChangeDetection, getChangeDetection } = useChangeDetection();
  useShouldBlock({ changeDetection: getChangeDetection });

  const {
    trigger,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Invoice>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({ ...invoice, mode }),
  });
  const watchInvoice = watch();
  const watchInvoiceLineItems = watch("invoiceLineItems");

  const { fields, append, remove, move } = useFieldArray({
    name: "invoiceLineItems",
    control,
    keyName: "_id",
  });

  const calculate = useCallback(() => {
    assignCalculatedValues(
      calculateInvoice(watchInvoice, customer, HST),
      setValue
    );
    setDuplicateItemAlert(
      hasDuplicateInvoiceLineItems(watchInvoice as Invoice)
    );
    onInvoiceChanged && onInvoiceChanged(watchInvoice);
  }, [watchInvoice, HST, setValue, customer, onInvoiceChanged]);

  const resolveDefaultItem = (
    field: ControllerRenderProps<Invoice, `invoiceLineItems.${number}`>
  ): InventoryItem | undefined => {
    if (field?.value?.inventoryItem) {
      return field.value.inventoryItem;
    }
  };

  const onAddItem = useCallback(
    (invoiceLineItem: InvoiceLineItem | InvoiceLineItem[]) => {
      append(invoiceLineItem);
      setCalculateAction(true);
    },
    [append, setCalculateAction]
  );

  const onRemoveItem = (index: number) => {
    remove(index);
    setCalculateAction(true);
  };

  const applyOrdinal = (invoice: Invoice) => {
    invoice.invoiceLineItems.forEach((invoiceLineItem, index) => {
      invoiceLineItem.ordinal = index;
    });
    return invoice;
  };

  const hideAll = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    watchInvoiceLineItems.map((_, index) =>
      setValue(`invoiceLineItems.${index}.hide`, checked ? true : false)
    );
  };

  const prepareMergeItems = () => {
    let currentDuplicates = getDuplicates(
      watchInvoice.invoiceLineItems,
      "inventoryItemId"
    );
    const filtered = currentDuplicates.filter(
      (x) => x.inventoryItem.skipDuplicationCheck === false
    );
    setDuplicates(groupEntities(filtered, "inventoryItemId"));
    setDuplicatesModal(true);
  };

  const mergeItems = (mergeItem: MergeItem) => {
    let removeIndexes: number[] = [];
    const inventoryItem = watchInvoiceLineItems?.find(
      (x) => x?.inventoryItem?.id === mergeItem.id
    )?.inventoryItem;

    if (inventoryItem) {
      const newInvoiceLineItem: InvoiceLineItem = {
        quantity: mergeItem.quantity,
        inventoryItem: inventoryItem,
        inventoryItemId: mergeItem.id,
        cost: mergeItem.cost,
        price: mergeItem.price,
        hide: false,
        display: "",
        ordinal: watchInvoiceLineItems.length,
      } as InvoiceLineItem;

      // loop invoice line item
      watchInvoiceLineItems.forEach((inventoryLineItem, index) => {
        if (inventoryLineItem.inventoryItemId === mergeItem.id) {
          removeIndexes.push(index);
        }
      });
      remove(removeIndexes);
      onAddItem(newInvoiceLineItem);
    }
  };

  // fetch invoice line items from proposal
  useEffect(() => {
    if (!invoice?.id && workOrder) {
      getInvoiceLineItems();
    }
  }, [workOrder, searchTermWorkOrder, getInvoiceLineItems, invoice]);

  // listen for form changes
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        mode !== "OVER_THE_COUNTER" &&
        name?.includes("invoiceLineItems.")
      ) {
        setChangeDetection(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setChangeDetection, mode]);

  // SET LINE ITEMS FROM PROPOSAL
  useEffect(() => {
    if (watchInvoice && watchInvoice?.invoiceLineItems?.length === 0) {
      if (invoiceLineItemsFromProposal && !invoice?.invoiceLineItems) {
        onAddItem(invoiceLineItemsFromProposal);
      }
      // if there are no proposal line items insert one by default
      if (
        invoiceLineItemsFromProposal?.length === 0 &&
        !invoice?.invoiceLineItems
      ) {
        append({ quantity: 1, hide: false } as InvoiceLineItem);
      }
    }
  }, [
    invoiceLineItemsFromProposal,
    watchInvoice,
    invoice,
    setValue,
    append,
    onAddItem,
  ]);

  useEffect(() => {
    workOrderByInvoice && setValue("workOrderId", workOrderByInvoice.id);
  }, [workOrderByInvoice, setValue]);

  useEffect(() => {
    workOrder && setWorkOrderState(workOrder?.workOrderState?.name);
  }, [workOrder]);

  // set the workOrderId from the query
  useEffect(() => {
    workOrderId && setValue("workOrderId", workOrderId);
  }, [workOrderId, setValue]);

  useEffect(() => {
    searchTerm && searchTerm.length > 0 && getInventoryItems();
  }, [searchTerm, getInventoryItems]);

  useEffect(() => {
    searchTermWorkOrder && getWorkOrders();
  }, [searchTermWorkOrder, getWorkOrders]);

  useEffect(() => {
    watchInvoice &&
      setDispatchFeeAlert(
        needsDispatchFee(watchInvoice, DISPATCH, INVOICE_DISPATCH_LIMIT)
      );
    watchInvoice &&
      setDuplicateItemAlert(hasDuplicateInvoiceLineItems(watchInvoice));
  }, [watchInvoice, DISPATCH, INVOICE_DISPATCH_LIMIT]);

  useEffect(() => {
    watchInvoice && watchInvoice.hstRate === null && setValue("hstRate", HST);
  }, [setValue, watchInvoice, HST]);

  // ORDER LINE ITEMS BY ORDINAL
  useEffect(() => {
    invoice &&
      setValue(
        "invoiceLineItems",
        orderBy(invoice.invoiceLineItems, ["ordinal"])
      );
  }, [invoice, setValue]);

  useEffect(() => {
    if (submitted && errors) {
      if (isEmpty(errors)) {
        onSubmitted && onSubmitted(watchInvoice, true);
        setSubmitted(false);
      }
    }
  }, [errors, onSubmitted]);

  useEffect(() => {
    if (watchInvoice && calculateAction) {
      calculate();
      setCalculateAction(false);
    }
  }, [watchInvoice, calculateAction, calculate]);

  useEffect(() => {
    hasAfterHoursScheduled && setAfterHoursAlert(hasAfterHoursScheduled);
  }, [hasAfterHoursScheduled]);

  if (
    (isLoadingWorkOrderByInvoice || isIdleWorkOrderByInvoice) &&
    invoice?.id
  ) {
    return <LoadingSpinner />;
  }

  return (
    <FormContainer
      onSubmit={handleSubmit(async (invoice) => {
        invoice = applyOrdinal(invoice);
        invoice = invoice.id
          ? await patchInvoice(invoice as Invoice)
          : await createInvoice(invoice as Invoice);
        setValue("id", invoice.id);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
        navigateToPreview && navigate(`/invoices/view/${invoice.id}`);
      })}
    >
      {invoice?.quickbooksInvoiceId && <DoNotEditAlert />}
      {afterHoursAlert && <AfterHoursAlert />}
      {dispatchFeeAlert && mode !== "OVER_THE_COUNTER" && (
        <DispatchFeeAlert limit={INVOICE_DISPATCH_LIMIT} />
      )}
      {duplicateItemAlert && <DuplicateItemAlert onMerge={prepareMergeItems} />}
      <Paper sx={{ p: 1, mb: 1 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              {mode !== "OVER_THE_COUNTER" && (
                <>
                  <Grid item>
                    <FormDatePicker
                      control={control}
                      name="dueDate"
                      label="Due Date"
                      trigger={trigger}
                    />
                  </Grid>
                  <Grid item>
                    <FormAutocomplete
                      name="workOrderId"
                      label="Work Order Number"
                      labelKeys={["workOrderNumber"]}
                      valueKey="id"
                      options={workOrders ?? []}
                      loading={isLoadingWorkOrders || isFetchingWorkOrders}
                      onInputChange={setSearchTermWorkOrder}
                      control={control}
                      defaultValue={workOrderByInvoice ?? workOrder}
                      sx={{ width: 200 }}
                    />
                  </Grid>
                </>
              )}
              <Grid item>
                <FormControlLabel
                  control={<Switch onChange={hideAll} />}
                  label="Hide All"
                />
              </Grid>
              <Grid item>
                {workOrder && (
                  <>
                    <WorkOrderStateModal
                      workOrder={workOrder}
                      onSubmit={(updatedWorkOrder) =>
                        setWorkOrderState(
                          updatedWorkOrder?.workOrderState?.name
                        )
                      }
                    />
                    - Current Work Order State: <b>{workOrderState}</b>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <InvoiceSettingsModal control={control} />
              {mode === "OVER_THE_COUNTER" ? (
                <Button
                  onClick={() => {
                    trigger();
                    setSubmitted(true);
                  }}
                  color="success"
                  variant="contained"
                  sx={{ mt: 1 }}
                >
                  Save and View
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => setNavigateToPreview(true)}
                  color="success"
                >
                  Save & View Invoice Email
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <Stack direction="column">
        <Paper sx={{ p: 1, mt: 1, mb: 1 }}>
          <InvoiceLineItems
            invoice={watchInvoice}
            control={control}
            fields={fields}
            errors={errors}
            loading={isFetchingInventoryItems || isLoadingInventoryItems}
            append={append}
            move={move}
            inventoryItems={inventoryItems ?? []}
            onRemoveItem={onRemoveItem}
            onInventoryItemInputChanged={setSearchTerm}
            onInventoryItemChanged={(inventoryItem, index) => {
              if (inventoryItem && index) {
                setValue(
                  `invoiceLineItems.${index}.price`,
                  inventoryItem.price
                );
                setValue(`invoiceLineItems.${index}.cost`, inventoryItem.cost);
                if (inventoryItem.inventoryType.name === "Equipment") {
                  if (inventoryItem.warrantyPartsPeriod) {
                    setValue(
                      `invoiceLineItems.${index}.warrantyPartsStartDate`,
                      new Date()
                    );
                    setValue(
                      `invoiceLineItems.${index}.warrantyPartsExpiryDate`,
                      add(new Date(), {
                        years: inventoryItem.warrantyPartsPeriod,
                      })
                    );
                  }
                  if (inventoryItem.warrantyLabourPeriod) {
                    setValue(
                      `invoiceLineItems.${index}.warrantyLabourStartDate`,
                      new Date()
                    );
                    setValue(
                      `invoiceLineItems.${index}.warrantyLabourExpiryDate`,
                      add(new Date(), {
                        years: inventoryItem.warrantyLabourPeriod,
                      })
                    );
                  }
                }
              }
              setCalculateAction(true);
            }}
            resolveDefaultItem={resolveDefaultItem}
            onCalculate={calculate}
          />
          <ModalContainerChangeDetection
            open={openInventoryItemModal}
            handleClose={() => setOpenInventoryItemModal(false)}
            title="Edit Inventory Item"
          >
            <InventoryItemForm
              onSubmitted={() => setOpenInventoryItemModal(false)}
            />
          </ModalContainerChangeDetection>
        </Paper>
        {watchInvoice && workOrder && (
          <InvoiceDisplay
            invoice={watchInvoice as Invoice}
            workOrder={workOrder}
            amountPaid={
              customerPayments ? sumBy(customerPayments, (s) => s.amount) : 0
            }
          />
        )}
      </Stack>
      {mode !== "OVER_THE_COUNTER" && (
        <FormSubmitButton text="Save Invoice" disabled={isSubmitting} />
      )}
      <DuplicatesModal
        open={duplicatesModal}
        groups={duplicates}
        displayLabels={["inventoryItem.name", "quantity", "price"]}
        onMerge={(inventoryItemId) => mergeItems(inventoryItemId)}
        handleClose={() => {
          setDuplicatesModal(false);
          setDuplicateItemAlert(
            hasDuplicateInvoiceLineItems(watchInvoice as Invoice)
          );
        }}
      />
    </FormContainer>
  );
};
