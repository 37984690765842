import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface Props {
  onFilesSelected: (files: File[]) => void;
}

export const FilesSelector = (props: Props) => {
  const { onFilesSelected } = props;
  return (
    <div>
      <input
        accept="*"
        id="file-upload"
        multiple
        type="file"
        onChange={(value) => {
          const target = value.target as HTMLInputElement;
          const files = target.files;
          if (files) {
            onFilesSelected(Array.from(files));
          }
        }}
        style={{ display: "none" }}
      />
      <label htmlFor="file-upload">
        <IconButton component="span">
          <AttachFileIcon />
        </IconButton>
        <IconButton component="span" style={{ borderRadius: 2 }}>
          Upload Files
        </IconButton>
      </label>
    </div>
  );
};
