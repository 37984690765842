import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderNote } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchWorkOrderNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderNote: WorkOrderNote) =>
      patch({
        route: "work-order-notes",
        id: workOrderNote.id ?? "",
        entity: workOrderNote,
      }),
    {
      onSuccess: (workOrderNote, variables) => {
        enqueueSnackbar("Work Order Note Updated", { variant: "success" });
        queryClient.setQueryData(
          [RQ.WORK_ORDER_NOTES, variables.workOrderId],
          (workOrderNotes: WorkOrderNote[] | undefined) =>
            !workOrderNotes
              ? []
              : patchEntity<WorkOrderNote>(workOrderNotes, workOrderNote, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Note Updating Error", { variant: "error" });
      },
    }
  );
};
