import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

export const quickBooksColumns: GridColDef[] = [
  { field: "id", headerName: "QB ID", flex: 1 },
  { field: "totalAmt", headerName: "Total", flex: 1 },
  {
    field: "createTime",
    headerName: "Created",
    flex: 1,
    valueGetter: (params) =>
      format(new Date(params?.row?.metaData?.createTime), "yyyy-MM-dd"),
  },
];
