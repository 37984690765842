import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerPayment } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteCustomerPayment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerPayment: CustomerPayment) =>
      remove("customer-payments", customerPayment.id),
    {
      onSuccess: (_, variables) => {
        enqueueSnackbar("Customer Payment Deleted", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMER_PAYMENTS, variables.customerId],
          (customerPayments: CustomerPayment[] | undefined) => {
            return !customerPayments
              ? []
              : deleteEntity<CustomerPayment>(
                  customerPayments,
                  "id",
                  variables.id
                );
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Payment Deleting Error", {
          variant: "error",
        });
      },
    }
  );
};
