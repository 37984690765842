import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Address, Customer } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((address: Address) => remove("addresses", address.id), {
    onSuccess: (_, variables) => {
      enqueueSnackbar("Address Deleted", { variant: "success" });
      queryClient.setQueryData(
        [RQ.CUSTOMERS, variables.customerId],
        (customer: Customer | undefined) => {
          if (!customer) {
            return {} as Customer;
          }
          customer.addresses = deleteEntity<Address>(
            customer.addresses,
            "id",
            variables.id
          );
          return customer;
        }
      );

      queryClient.setQueryData(
        [RQ.ADDRESSES, variables.customerId],
        (addresses: Address[] | undefined) => {
          return !addresses
            ? []
            : deleteEntity<Address>(addresses, "id", variables.id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Address Deleting Error", { variant: "error" });
    },
  });
};
