import { useQuery } from "react-query";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useWorkOrderByInvoice = (invoiceId?: string) => {
  return useQuery<WorkOrder, Error>(
    [RQ.WORK_ORDERS, invoiceId],
    async () => await getSingle(`work-orders/invoice/${invoiceId}`),
    {
      enabled: Boolean(invoiceId),
    }
  );
};
