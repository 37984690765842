import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerTier } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchCustomerTier = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerTier: CustomerTier) =>
      patch({
        route: "customer-tiers",
        id: customerTier.id ?? "",
        entity: customerTier,
      }),
    {
      onSuccess: (customerTier) => {
        enqueueSnackbar("Customer Tier Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.CUSTOMER_TIERS,
          (customerTiers: CustomerTier[] | undefined) =>
            !customerTiers
              ? []
              : patchEntity<CustomerTier>(customerTiers, customerTier, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Tier Updating Error", { variant: "error" });
      },
    }
  );
};
