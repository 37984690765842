import { useQuery } from "react-query";

import { InvoiceDrawRequest } from "../../models";
import { RQ } from "../../globals/enums";
import { getSingle } from "../../api/generic-api";

export const useInvoiceDrawRequest = (invoiceDrawRequestId?: string) => {
  return useQuery<InvoiceDrawRequest, Error>(
    [RQ.INVOICE_DRAW_REQUEST, invoiceDrawRequestId],
    async () =>
      await getSingle(`invoice-draw-requests/${invoiceDrawRequestId}`),
    {
      enabled: Boolean(invoiceDrawRequestId),
    }
  );
};
