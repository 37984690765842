import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import axios from "axios";

import { add } from "../../api/generic-api";
import { useCurrentUser } from "../../contexts";

export const useQuickBooksCreateCustomer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsQBAuthenticated, setQBAlert } = useCurrentUser();
  return useMutation(
    (customerId: String) => add<void>(`quick-books/customers/${customerId}`),
    {
      onSuccess: () => {
        enqueueSnackbar("Quick Books Customer Added", { variant: "success" });
      },
      onError: (error) => {
        enqueueSnackbar("Quick Books Customer Adding Error", {
          variant: "error",
        });
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            setIsQBAuthenticated(false);
            setQBAlert("SHOW");
          }
        }
      },
    }
  );
};
