import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { EmployeeType } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchEmployeeType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (employeeType: EmployeeType) =>
      patch({
        route: "employee-types",
        id: employeeType.id ?? "",
        entity: employeeType,
      }),
    {
      onSuccess: (employeeType) => {
        enqueueSnackbar("Employee Type Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.EMPLOYEE_TYPES,
          (employeeTypes: EmployeeType[] | undefined) =>
            !employeeTypes
              ? []
              : patchEntity<EmployeeType>(employeeTypes, employeeType, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Employee Type Updating Error", { variant: "error" });
      },
    }
  );
};
