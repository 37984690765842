import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { Schedule } from "../../models";
import { deleteEntity } from "../../utils";

export const useDeleteSchedule = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("schedules", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Schedule Deleted", { variant: "success" });
      queryClient.setQueryData(
        [RQ.SCHEDULES],
        (schedules: Schedule[] | undefined) => {
          return !schedules ? [] : deleteEntity<Schedule>(schedules, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Schedule Deleting Error", { variant: "error" });
    },
  });
};
