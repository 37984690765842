import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

import { WorkOrderRoleTitleDataGrid } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { LoadingSpinner } from "../../components/ui";
import { useWorkOrderRoleTitles } from "../../hooks/work-order-role-title";

export const WorkOrderRoleTitlesPage = () => {
  const {
    data: paymentTypes,
    isLoading: isLoadingWorkOrderRoleTitles,
    isIdle,
    isError,
    error,
  } = useWorkOrderRoleTitles();
  const navigate = useNavigate();

  if (isLoadingWorkOrderRoleTitles || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  return (
    <>
      <PageHeader
        title="Work Order Role Title"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <WorkOrderRoleTitleDataGrid
        rows={paymentTypes}
        loading={isLoadingWorkOrderRoleTitles}
        disableColumnsButton
        disableExport
        disableQuickFilter
        autoHeight
      />
    </>
  );
};
