import React from "react";
import { IMaskInput } from "react-imask";

interface Props {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}
// https://mui.com/material-ui/react-text-field/
// https://stackblitz.com/run?file=demo.tsx
// JSX.Element | ReactElement

export const PhoneNumberMask = React.forwardRef<any, Props>(
  function PhoneNumberMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);
