import { useQuery } from "react-query";

import { CalendarDateRange, Schedule } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";
import { format } from "date-fns";

export const useSchedulesByDateRange = (
  dateRange: CalendarDateRange,
  employeeId?: string,
  scheduleTypes?: string[],
  workOrderId?: string
) => {
  let params = new URLSearchParams();
  dateRange &&
    dateRange.startDate &&
    params.append("startDate", format(dateRange.startDate, "yyyy-MM-dd"));
  dateRange &&
    dateRange.endDate &&
    params.append("endDate", format(dateRange.endDate, "yyyy-MM-dd"));
  employeeId && params.append("employeeId", employeeId);
  workOrderId && params.append("workOrderId", workOrderId);
  scheduleTypes &&
    scheduleTypes.length > 0 &&
    params.append("scheduleTypes", scheduleTypes.join(","));

  return useQuery<Schedule[], Error>(
    [RQ.SCHEDULES],
    async () => await getAll<Schedule>(`schedules/date-range?${params}`),
    {
      enabled: Boolean(dateRange.startDate),
    }
  );
};
