import { useQuery } from "react-query";

import { Proposal } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";

export const useProposalsByCustomer = (customerId?: string) => {
  return useQuery<Proposal[], Error>(
    [RQ.PROPOSALS, customerId],
    async () => await getAll(`proposals/customer/${customerId}`),
    {
      enabled: Boolean(customerId),
    }
  );
};
