import { add } from "date-fns";
import * as yup from "yup";

export const scheduleGroupSchema = yup.object().shape({
  startDate: yup.date().required().label("Start Date").default(new Date()),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date can't be before start date")
    .required()
    .label("End Date")
    .default(add(new Date(), { hours: 3 })),
  scheduleTypeId: yup
    .string()
    .required()
    .label("Schedule Type")
    .default("")
    .transform((value) => value ?? ""),
  workOrderId: yup
    .string()
    .label("Work Order")
    .transform((value) => value ?? ""),
  repeatDayIds: yup
    .array()
    .label("Repeat Days")
    .default([])
    .transform((value) => value ?? []),
});
