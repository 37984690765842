import React, { useContext } from "react";

interface Props {
  children: JSX.Element;
}

const ChangeDetectionContext = React.createContext<any>("");

export const useChangeDetection = () => useContext(ChangeDetectionContext);

export const ChangeDetectionProvider = ({ children }: Props) => {
  const getChangeDetection = (): boolean => {
    const value = localStorage.getItem("CHANGE_DETECTION");
    return value === "true" ? true : false;
  };

  const setChangeDetection = (value: boolean) =>
    localStorage.setItem("CHANGE_DETECTION", value.toString());

  const value = {
    getChangeDetection,
    setChangeDetection,
  };

  return (
    <ChangeDetectionContext.Provider value={value}>
      {children}
    </ChangeDetectionContext.Provider>
  );
};
