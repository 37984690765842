import { Checkbox, IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

interface Props<T> {
  onRowEdit?: (data: T) => void;
  onRowDelete?: (data: T) => void;
  onRowDuplicate?: (data: T) => void;
  onRowDownload?: (data: T) => void;
  onRowSend?: (data: T) => void;
  onRowView?: (data: T) => void;
  onRowLink?: (data: T) => void;
  onRowPay?: (data: T) => void;
  onRowChecked?: (data: T, checked: boolean) => void;
  row: T;
}

export const GridActions = <T,>(props: Props<T>) => {
  const {
    onRowEdit,
    onRowDelete,
    onRowDuplicate,
    onRowDownload,
    onRowSend,
    onRowView,
    onRowLink,
    onRowPay,
    onRowChecked,
    row,
  } = props;
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ width: 170 }}
    >
      {onRowEdit && (
        <IconButton aria-label="edit" onClick={() => onRowEdit(row)}>
          <EditIcon titleAccess="Edit" style={{ color: "#ff5722" }} />
        </IconButton>
      )}
      {onRowDuplicate && (
        <IconButton aria-label="duplicate" onClick={() => onRowDuplicate(row)}>
          <ContentCopyIcon titleAccess="Duplicate" />
        </IconButton>
      )}
      {onRowDownload && (
        <IconButton aria-label="download" onClick={() => onRowDownload(row)}>
          <DownloadIcon titleAccess="Download" style={{ color: "#00e676" }} />
        </IconButton>
      )}
      {onRowSend && (
        <IconButton aria-label="send" onClick={() => onRowSend(row)}>
          <SendIcon
            fontSize="small"
            titleAccess="Send"
            style={{ color: "#ffc107" }}
          />
        </IconButton>
      )}
      {onRowView && (
        <IconButton aria-label="view" onClick={() => onRowView(row)}>
          <VisibilityIcon
            fontSize="small"
            titleAccess="View Details"
            style={{ color: "#00e5ff" }}
          />
        </IconButton>
      )}
      {onRowLink && (
        <IconButton aria-label="link" onClick={() => onRowLink(row)}>
          <OpenInNewIcon
            fontSize="small"
            titleAccess="Navigate to"
            style={{ color: "#3d5afe" }}
          />
        </IconButton>
      )}
      {onRowPay && (
        <IconButton aria-label="link" onClick={() => onRowPay(row)}>
          <AttachMoneyIcon
            fontSize="small"
            titleAccess="Make Payment"
            style={{ color: "#00a152" }}
          />
        </IconButton>
      )}
      {onRowChecked && (
        <Checkbox onChange={(_, checked) => onRowChecked(row, checked)} />
      )}
      {onRowDelete && (
        <IconButton aria-label="delete" onClick={() => onRowDelete(row)}>
          <DeleteIcon titleAccess="Delete" style={{ color: "#ff1744" }} />
        </IconButton>
      )}
    </Stack>
  );
};
