import { Button, Stack, TextField, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useState } from "react";

import { Customer, EmailPayload, EmailSearch } from "../../../models";
import { useEmailSearch } from "../../../hooks/email";
import { AutoSearchMultiple } from "../Autocomplete/AutoSearchMultiple";
import { SelectEmployeesModal } from "./components/SelectEmployeesModal";
import { ModalContainer } from "../ModalContainer/ModalContainer";
import { SelectContactsModal } from "./components/SelectContactsModal";

interface Props {
  onSubmit: (emailPayload: EmailPayload) => void;
  defaultSearchTerm?: string;
  defaultBody?: string;
  customer?: Customer;
}

export const EmailModal = (props: Props) => {
  const { onSubmit, defaultSearchTerm, defaultBody, customer } = props;
  const [to, setTo] = useState<EmailSearch[]>([]);
  const [cc, setCC] = useState<EmailSearch[]>([]);
  const [body, setBody] = useState<string>(defaultBody ?? "");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [openEmployeesModal, setOpenEmployeesModal] = useState(false);
  const [openContactsModal, setOpenContactsModal] = useState(false);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          {customer && (
            <Button
              variant="contained"
              onClick={() => setOpenContactsModal(true)}
            >
              Select Contacts
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => setOpenEmployeesModal(true)}
          >
            Select Employees
          </Button>
        </Stack>
        <Typography variant="h6">TO:</Typography>
        <AutoSearchMultiple
          label="Search Email..."
          keys={["firstName", "lastName", "email", "note"]}
          onOptionSelected={(emailSearches: EmailSearch[] | null) =>
            emailSearches && setTo(emailSearches)
          }
          searchFunction={useEmailSearch}
          separator="|"
          defaultSearchTerm={defaultSearchTerm}
          sx={{ width: 700 }}
          values={to}
        />
        <Typography variant="h6">CC:</Typography>
        <AutoSearchMultiple
          label="Search Email..."
          keys={["firstName", "lastName", "email", "note"]}
          onOptionSelected={(emailSearches: EmailSearch[] | null) =>
            emailSearches && setCC(emailSearches)
          }
          searchFunction={useEmailSearch}
          separator="|"
          defaultSearchTerm={defaultSearchTerm}
          sx={{ width: 700 }}
        />
        <Typography variant="h6">Attachments</Typography>
        <TextField
          size="small"
          type="file"
          inputProps={{ multiple: true }}
          onChange={(value) => {
            if (value.target.type === "file") {
              const file = value.target as HTMLInputElement;
              if (file && file.files) {
                setAttachments(Array.from(file.files));
              }
            }
          }}
        />
        <Typography variant="h6">Email Body</Typography>
        <TextField
          label="Include body text here..."
          multiline
          fullWidth
          value={body}
          onChange={(event) => setBody(event.target.value)}
        />
        <Button
          variant="contained"
          endIcon={<EmailIcon />}
          onClick={() =>
            onSubmit({
              to: to.map((x) => x.email),
              cc: cc.map((x) => x.email),
              body,
              attachments,
            })
          }
        >
          Send Email
        </Button>
      </Stack>

      <ModalContainer
        open={openEmployeesModal}
        handleClose={() => setOpenEmployeesModal(false)}
      >
        <SelectEmployeesModal
          onSelected={(employee) =>
            setTo([
              ...to,
              {
                email: employee.email,
                firstName: employee.firstName,
                lastName: employee.lastName,
                note: "",
              },
            ])
          }
        />
      </ModalContainer>

      {customer && (
        <ModalContainer
          open={openContactsModal}
          handleClose={() => setOpenContactsModal(false)}
        >
          <SelectContactsModal
            onSelected={(contact) =>
              setTo([
                ...to,
                {
                  email: contact.email,
                  firstName: contact.name,
                  lastName: "",
                  note: "",
                } as EmailSearch,
              ])
            }
            customerId={customer.id}
          />
        </ModalContainer>
      )}
    </>
  );
};
