import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { WorkOrderNote } from "../../../models";
import { workOrderNotesSchema as schema } from "../schemas";
import {
  useAddWorkOrderNote,
  usePatchWorkOrderNote,
} from "../../../hooks/work-order-note";
import { FormContainer } from "../../layout";
import { FormSubmitButton, FormTextField } from "../../form-components";

interface Props {
  workOrderNote?: WorkOrderNote;
  onSubmitted?: (workOrderNote: WorkOrderNote) => void;
}

export const WorkOrderNoteForm = (props: Props) => {
  const { workOrderNote, onSubmitted } = props;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<WorkOrderNote>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(workOrderNote),
  });
  const { mutateAsync: createWorkOrderNote } = useAddWorkOrderNote();
  const { mutateAsync: patchWorkOrderNote } = usePatchWorkOrderNote();

  return (
    <FormContainer
      onSubmit={handleSubmit(async (workOrderNote) => {
        workOrderNote = workOrderNote.id
          ? await patchWorkOrderNote(workOrderNote)
          : await createWorkOrderNote(workOrderNote);
        onSubmitted && onSubmitted(workOrderNote);
        reset();
      })}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
        spacing={2}
      >
        <FormTextField
          name="note"
          label="Note"
          control={control}
          multiline
          sx={{ width: 400 }}
        />
        <FormSubmitButton
          text={workOrderNote?.id ? "Save Edits" : "Save Message"}
          disabled={isSubmitting}
        />
      </Stack>
    </FormContainer>
  );
};
