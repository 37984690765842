import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { sub } from "date-fns";
import { Paper, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { Customer, CustomerAddress, WorkOrder } from "../../models";
import { WorkOrderDataGrid } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import { AutoSearch, ChipDropDown, LoadingSpinner } from "../../components/ui";
import { useWorkOrdersByFilter } from "../../hooks/work-order";
import { useWorkOrderStates } from "../../hooks/work-order-state";
import { useCustomerSearch } from "../../hooks/customer-address";
import { useWorkOrdersSearch } from "../../hooks/work-order";

export const WorkOrdersPage = () => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<Customer>();
  const [startDate, setStartDate] = useState<Date>(
    sub(new Date(), { months: 6 })
  );
  const [searchParams] = useSearchParams();
  const { data: workOrdersSearch, isLoading: isLoadingWorkOrders } =
    useWorkOrdersSearch(searchParams.get("workOrderNumber") ?? "");
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [workOrderId, setWorkOrderId] = useState(
    searchParams.get("workOrderId") ?? ""
  );
  const [workOrderStateGuids, setWorkOrderStateGuids] = useState<string[]>([]);
  const {
    data: workOrders,
    isLoading,
    isIdle,
    isError,
    error,
    refetch: getWorkOrders,
  } = useWorkOrdersByFilter(
    startDate,
    endDate,
    workOrderStateGuids,
    customer?.id,
    workOrderId
  );
  const { data: workOrderStates } = useWorkOrderStates();

  useEffect(() => {
    startDate && endDate && getWorkOrders();
  }, [startDate, endDate, workOrderStateGuids, customer, workOrderId]);

  if (isLoading || isIdle || isLoadingWorkOrders) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onClick = (workOrder: WorkOrder) => {
    navigate(
      `/work-orders/${workOrder.id}?customerId=${workOrder.customer.id}`
    );
  };

  return (
    <>
      <PageHeader title="Work Orders" />
      <Paper sx={{ p: 2, mb: 1 }}>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Start Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={startDate}
            onChange={(value) => setStartDate(value ?? new Date())}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <DatePicker
            label="End Date"
            inputFormat="LLL dd, yyyy"
            disableMaskedInput
            value={endDate}
            onChange={(value) => setEndDate(value ?? new Date())}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <AutoSearch
            label="Work Order Search..."
            keys={["workOrderNumber"]}
            onOptionSelected={(workOrder: WorkOrder | null) =>
              workOrder ? setWorkOrderId(workOrder?.id) : setWorkOrderId("")
            }
            initialValue={workOrdersSearch ? workOrdersSearch[0] : undefined}
            initialSearchTerm={searchParams.get("workOrderNumber") ?? ""}
            searchFunction={useWorkOrdersSearch}
            separator="|"
            sx={{ width: 200 }}
          />
          <AutoSearch
            label="Search Customer By Name, Address, or P.O ..."
            keys={["fullName", "email", "phone", "address", "po"]}
            onOptionSelected={(customer: CustomerAddress | null) => {
              if (customer) {
                setCustomer({
                  id: customer.customerId,
                  firstName: customer.firstName,
                  lastName: customer.lastName,
                  email: customer.email,
                  phone: customer.phone,
                } as Customer);
              } else {
                setCustomer(undefined);
              }
            }}
            searchFunction={useCustomerSearch}
            separator="|"
            sx={{ width: 500 }}
          />
          <ChipDropDown
            data={workOrderStates ?? []}
            label="Work Order States"
            labelKey={"name"}
            valueKey={"id"}
            onOptionChange={(selectedWorkOrderStates) =>
              setWorkOrderStateGuids(selectedWorkOrderStates.map((x) => x.id))
            }
          />
        </Stack>
      </Paper>
      <WorkOrderDataGrid
        rows={workOrders}
        loading={isLoading}
        onClick={onClick}
        onModalClosed={() => navigate("/work-orders")}
        disableCreate
        sx={{ height: 300 }}
      />
      <Outlet />
    </>
  );
};
