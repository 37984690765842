import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CompanyDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";
import { patchEntity } from "../../utils";

export const usePatchCompanyDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (companyDocument: Partial<CompanyDocument>) =>
      patch({
        route: "documents",
        id: companyDocument.id ?? "",
        entity: companyDocument,
      }),
    {
      onSuccess: (companyDocument) => {
        enqueueSnackbar("Customer Document Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.COMPANY_DOCUMENTS,
          (companyDocuments: CompanyDocument[] | undefined) =>
            !companyDocuments
              ? []
              : patchEntity<CompanyDocument>(
                  companyDocuments,
                  companyDocument,
                  "id"
                )
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Document Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
