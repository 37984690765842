import { Alert } from "@mui/material";

export const AfterHoursAlert = () => {
  return (
    <Alert severity="warning" sx={{ mb: 1 }}>
      The Work Order has after hours scheduled against it. You might want to
      consider applying a charge
    </Alert>
  );
};
