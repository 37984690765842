import { useState } from "react";
import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

import { JobType } from "../../models";
import { JobTypeForm } from "../../components/forms";
import { JobTypeEmployeeTypeForm } from "../../components/forms";
import { GenericDataGrid, jobTypeColumns } from "../../components/grids";
import { PageHeader } from "../../components/layout";
import {
  LoadingSpinner,
  ModalContainerChangeDetection,
} from "../../components/ui";
import { useDeleteJobType, useJobTypes } from "../../hooks/job-type";

export const JobTypesPage = () => {
  const [jobType, setJobType] = useState<JobType | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const { mutate: deleteJobType } = useDeleteJobType();
  const { data: jobTypes, isLoading, isIdle, isError, error } = useJobTypes();
  const navigate = useNavigate();

  if (isLoading || isIdle) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const onEditCreate = (jobType?: JobType) => {
    jobType ? setJobType(jobType) : setJobType(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Job Types"
        left={
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            variant="contained"
          >
            Back
          </Button>
        }
      />
      <GenericDataGrid
        rows={jobTypes}
        columns={jobTypeColumns}
        loading={isLoading}
        onClick={(jobType) => setJobType(jobType)}
        onDoubleClick={onEditCreate}
        onDelete={(jobType) => deleteJobType(jobType.id)}
        onEdit={onEditCreate}
        onCreate={onEditCreate}
        createTitle="Create Job Type"
        deleteTitle="Would you like to delete this Job Type"
        ignoreFields={[
          "id",
          "createdTimestamp",
          "modifiedTimestamp",
          "employeeTypes",
          "employeeTypeIds",
        ]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        autoHeight
      >
        <ModalContainerChangeDetection
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`${jobType ? "Edit" : "Create"} Job Type`}
        >
          <JobTypeForm
            onSubmitted={() => setOpenModal(false)}
            jobType={jobType}
          />
        </ModalContainerChangeDetection>
      </GenericDataGrid>

      {jobType && (
        <Box sx={{ mt: 2 }}>
          <JobTypeEmployeeTypeForm jobType={jobType} />
        </Box>
      )}
    </>
  );
};
