import { useQuery } from "react-query";

import { CustomerTier } from "../../models";
import { RQ } from "../../globals/enums";
import { getAll } from "../../api/generic-api";
import { orderBy } from "lodash";

export const useCustomerTiers = () => {
  return useQuery<CustomerTier[], Error>(
    RQ.CUSTOMER_TIERS,
    async () => getAll<CustomerTier>("customer-tiers"),
    {
      select: (customerTiers) => {
        customerTiers.forEach((customerTier) => {
          if (
            customerTier.customerTierDetails &&
            customerTier.customerTierDetails.length > 0
          ) {
            customerTier.customerTierDetails = orderBy(
              customerTier?.customerTierDetails,
              ["discountRate"],
              "desc"
            );

            customerTier?.customerTierDetails?.forEach((customerTierDetail) => {
              if (customerTierDetail.invoiceCategory) {
                customerTierDetail.invoiceCategoryId =
                  customerTierDetail?.invoiceCategory?.id;
              }
            });
          }
        });
        return customerTiers;
      },
    }
  );
};
