import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { WorkOrderState } from "../../../models";
import { workOrderStateSchema as schema } from "../schemas";
import {
  useAddWorkOrderState,
  usePatchWorkOrderState,
} from "../../../hooks/work-order-state";
import { FormContainer } from "../../layout";
import {
  FormSubmitButton,
  FormSwitch,
  FormTextField,
} from "../../form-components";
import { useChangeDetection } from "../../../contexts/ChangeDetectionContext";

interface Props {
  workOrderState?: WorkOrderState;
  onSubmitted?: () => void;
}

export const WorkOrderStateForm = (props: Props) => {
  const { workOrderState, onSubmitted } = props;
  const { setChangeDetection } = useChangeDetection();
  const { mutate: createWorkOrderState } = useAddWorkOrderState();
  const { mutate: patchWorkOrderState } = usePatchWorkOrderState();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, defaultValues },
  } = useForm<WorkOrderState>({
    resolver: yupResolver(schema),
    defaultValues: workOrderState,
  });

  useEffect(() => {
    const subscription = watch(
      (value, { type }) =>
        type === "change" && setChangeDetection(!isEqual(value, defaultValues))
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <FormContainer
      onSubmit={handleSubmit((workOrderState) => {
        workOrderState.id
          ? patchWorkOrderState(workOrderState)
          : createWorkOrderState(workOrderState);
        onSubmitted && onSubmitted();
        setChangeDetection(false);
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormTextField name="name" label="Name" control={control} />
        <FormTextField name="ordinal" label="Order" control={control} />
      </Stack>
      <Stack>
        <FormSwitch
          name="showInDispatch"
          label="Show In Dispatch"
          control={control}
        />
        <FormSwitch
          name="showForTech"
          label="Show For Tech"
          control={control}
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
