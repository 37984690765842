import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { Proposal } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddProposal = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (proposal: Proposal) => add<Proposal>("proposals", proposal),
    {
      onSuccess: (proposal) => {
        enqueueSnackbar("Proposal Added", { variant: "success" });
        queryClient.setQueryData<Proposal[]>(
          RQ.PROPOSALS,
          (proposals: Proposal[] | undefined) => {
            return [...(proposals ?? []), proposal];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Proposal Adding Error", { variant: "error" });
      },
    }
  );
};
