import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CompanyDocument } from "../../models";
import { RQ } from "../../globals/enums";
import { remove } from "../../api/generic-api";
import { deleteEntity } from "../../utils";

export const useDeleteCompanyDocument = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (companyDocument: CompanyDocument) =>
      remove("documents", companyDocument.id),
    {
      onSuccess: (_, companyDocument) => {
        enqueueSnackbar("Company Document Deleted", { variant: "success" });
        queryClient.setQueryData(
          [RQ.COMPANY_DOCUMENTS],
          (companyDocuments: CompanyDocument[] | undefined) => {
            return !companyDocuments
              ? []
              : deleteEntity<CompanyDocument>(
                  companyDocuments,
                  "id",
                  companyDocument.id
                );
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Company Document Deleting Error", {
          variant: "error",
        });
      },
    }
  );
};
