import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrder } from "../../models";
import { RQ } from "../../globals/enums";
import { patchEntity } from "../../utils";
import { patch } from "../../api/generic-api";

export const usePatchWorkOrder = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrder: Partial<WorkOrder>) =>
      patch({
        route: "work-orders",
        id: workOrder.id ?? "",
        entity: workOrder,
      }),
    {
      onSuccess: async (workOrder) => {
        enqueueSnackbar("Work Order Updated", { variant: "success" });
        queryClient.setQueryData(
          RQ.WORK_ORDERS,
          (workOrders: WorkOrder[] | undefined) =>
            !workOrders
              ? []
              : patchEntity<WorkOrder>(workOrders, workOrder, "id")
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
