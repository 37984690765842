import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderState } from "../../models";
import { RQ } from "../../globals/enums";
import { patch } from "../../api/generic-api";

export const usePatchWorkOrderState = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderState: WorkOrderState) =>
      patch({
        route: "work-order-states",
        id: workOrderState.id ?? "",
        entity: workOrderState,
      }),
    {
      onSuccess: () => {
        enqueueSnackbar("Work Order State Updated", { variant: "success" });
        queryClient.invalidateQueries(RQ.WORK_ORDER_STATES);
      },
      onError: () => {
        enqueueSnackbar("Work Order State Updating Error", {
          variant: "error",
        });
      },
    }
  );
};
