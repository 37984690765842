import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { CustomerNote } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteCustomerNote = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (customerNote: CustomerNote) => remove("customer-notes", customerNote.id),
    {
      onSuccess: (_, variables) => {
        enqueueSnackbar("Customer Note Deleted", { variant: "success" });
        queryClient.setQueryData(
          [RQ.CUSTOMER_NOTES, variables.customerId],
          (customerNotes: CustomerNote[] | undefined) => {
            return !customerNotes
              ? []
              : deleteEntity<CustomerNote>(customerNotes, "id", variables.id);
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Customer Note Deleting Error", { variant: "error" });
      },
    }
  );
};
