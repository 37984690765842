import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import { JobType } from "../../../models";
import { jobTypeEmployeeTypeSchema as schema } from "../schemas";
import { FormContainer } from "../../layout";
import { FormCheckBoxGroup, FormSubmitButton } from "../../form-components";
import { useEmployeeTypes } from "../../../hooks/employee-type";
import { usePatchJobType } from "../../../hooks/job-type";

interface Props {
  jobType: JobType;
  onSubmitted?: () => void;
}

export const JobTypeEmployeeTypeForm = (props: Props) => {
  const { jobType, onSubmitted } = props;
  const { mutate: patchJobType } = usePatchJobType();
  const { data: allEmployeeTypes } = useEmployeeTypes();

  const {
    control,
    reset,
    handleSubmit,
    formState: { defaultValues, isSubmitting },
  } = useForm<JobType>({
    resolver: yupResolver(schema),
    defaultValues: jobType,
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      employeeTypeIds: jobType.employeeTypes?.map(
        (employeeType) => employeeType.id
      ),
    });
  }, [jobType]);

  return (
    <FormContainer
      onSubmit={handleSubmit((jobType) => {
        patchJobType(jobType);
        onSubmitted && onSubmitted();
      })}
    >
      <Stack direction="row" spacing={2}>
        <FormCheckBoxGroup
          name="employeeTypeIds"
          label="Employee Types"
          options={allEmployeeTypes ?? []}
          labelKey="name"
          valueKey="id"
          control={control}
          direction="row"
        />
      </Stack>
      <FormSubmitButton disabled={isSubmitting} />
    </FormContainer>
  );
};
