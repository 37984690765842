import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { WorkOrderRoleTitle } from "../../models";
import { RQ } from "../../globals/enums";
import { add } from "../../api/generic-api";

export const useAddWorkOrderRoleTitle = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    (workOrderRoleTitle: WorkOrderRoleTitle) =>
      add<WorkOrderRoleTitle>("work-order-role-titles", workOrderRoleTitle),
    {
      onSuccess: (workOrderRoleTitle) => {
        enqueueSnackbar("Work Order Role Title Added", { variant: "success" });
        queryClient.setQueryData<WorkOrderRoleTitle[]>(
          RQ.WORK_ORDER_ROLE_TITLES,
          (workOrderRoleTitles: WorkOrderRoleTitle[] | undefined) => {
            return [...(workOrderRoleTitles ?? []), workOrderRoleTitle];
          }
        );
      },
      onError: () => {
        enqueueSnackbar("Work Order Role Title Adding Error", {
          variant: "error",
        });
      },
    }
  );
};
