import { useEffect, useMemo, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import {
  Autocomplete,
  Box,
  BoxProps,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "@mui/material/utils";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";

import config from "../../../config/app-config";
import { convertToAddress } from "../../../utils";
import { Address } from "../../../models";

const loader = new Loader({
  apiKey: config.GOOGLE_MAPS_API_KEY as string,
  version: "weekly",
  libraries: ["places"],
});

interface Props extends BoxProps {
  label: string;
  onSelected?: (address: Partial<Address>) => void;
}

export const AddressSearch = (props: Props) => {
  const { label, onSelected, ...rest } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [options, setOptions] = useState<
    readonly google.maps.places.AutocompletePrediction[]
  >([]);
  let autocompleteService: google.maps.places.AutocompleteService;
  let placesService: google.maps.places.PlacesService;

  useEffect(() => {
    fetch(
      {
        input: searchTerm,
        componentRestrictions: { country: "ca" },
      },
      (results?: google.maps.places.AutocompletePrediction[] | null) => {
        let newOptions: readonly google.maps.places.AutocompletePrediction[] =
          [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    );
  }, [searchTerm]);

  loader.load().then(() => {
    autocompleteService = new google.maps.places.AutocompleteService();
    placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
  });

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: google.maps.places.AutocompletionRequest,
          callback?: (
            a: google.maps.places.AutocompletePrediction[] | null,
            b: google.maps.places.PlacesServiceStatus
          ) => void
        ) => {
          autocompleteService.getPlacePredictions(request, callback);
        },
        400
      ),
    []
  );

  const getDetails = (placeId: string) => {
    placesService.getDetails(
      {
        placeId,
        fields: ["address_components"],
      },
      (place) => {
        onSelected &&
          onSelected(convertToAddress(place?.address_components ?? []));
      }
    );
  };

  return (
    <Box {...rest}>
      <Autocomplete
        id="address-google-maps"
        fullWidth
        getOptionLabel={(option: google.maps.places.AutocompletePrediction) =>
          typeof option === "string" ? option : option.description
        }
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        noOptionsText="No Results"
        onChange={(
          _,
          newOptions: google.maps.places.AutocompletePrediction | null
        ) => {
          if (newOptions && newOptions.place_id) {
            getDetails(newOptions.place_id);
          }
          setOptions(newOptions ? [newOptions, ...options] : options);
        }}
        isOptionEqualToValue={() => true}
        onInputChange={(_, newInputValue) => setSearchTerm(newInputValue)}
        renderInput={(params) => (
          <TextField {...params} label={label} size="small" fullWidth />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Box>
  );
};
