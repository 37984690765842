import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { ReferralType } from "../../models";
import { remove } from "../../api/generic-api";
import { RQ } from "../../globals/enums";
import { deleteEntity } from "../../utils";

export const useDeleteReferralType = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation((id: string) => remove("referral-types", id), {
    onSuccess: (_, id) => {
      enqueueSnackbar("Referral Type Deleted", { variant: "success" });
      queryClient.setQueryData(
        RQ.REFERRAL_TYPES,
        (referralTypes: ReferralType[] | undefined) => {
          return !referralTypes
            ? []
            : deleteEntity<ReferralType>(referralTypes, "id", id);
        }
      );
    },
    onError: () => {
      enqueueSnackbar("Referral Type Deleting Error", { variant: "error" });
    },
  });
};
